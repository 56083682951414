import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VendorPageApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  getVendorFilterCloth(vendorLink: string, queryParams: string) {
    return this.httpConnection.get(`store/Site/GetVendorFilterCloth/${vendorLink}?${queryParams}`);
  }

}
