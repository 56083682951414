<a class="go-back-link" (click)="onGoBack()">
  <img class="icon" src="../../../../assets/images/core/left-arrow-grey.svg" alt="برگشت">
</a>
<div class="row responsive-category-wrapper">
  <div class="col-12 p-0">
    <a [routerLink]="['/category/' + childCategory.link]"
      *ngFor="let childCategory of listOfParentCategories.listOfChildern" class="responsive-category-link">
      {{childCategory.name}}
    </a>
    <a [routerLink]="['/category/' + listOfParentCategories.link]"
      class="responsive-category-link responsive-category-all">
      مشاهده همه موارد
      <img class="icon" src="../../../../assets/images/core/left-arrow.svg" alt="همه موارد">
    </a>
  </div>
</div>
