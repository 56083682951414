<div class="row successfull-payment-main-row">
  <div class="col-11 payment-result-main-col">
    <img class="payment-result-image" src="../../../../assets/images/core/successfull-payment.svg" alt="پرداخت موفق">
    <label class="payment-result-text">پرداخت موفق</label>
    <div class="peyment-result-number-container">
      <label class="payment-result-sub-text">پرداخت موفقیت آمیز بود</label>
      <label class="payment-result-sub-text">|</label>
      <label class="payment-result-sub-text">
        شماره پیگیری :
        <span class="peyment-result-number-span">{{ refId }}</span>
      </label>
    </div>
    <label class="go-to-orders-results-text">شما میتوانید مراحل خرید ، تا لحظه تحویل به دست خود را به طور لحظه ای ، از
      تاریخچه سفارشات پیگیری کنید</label>
    <button [routerLink]="['/user-club/user-profile/my-order']" class="medium-button" type="button">تاریخچه
      سفارشات</button>
  </div>
</div>
