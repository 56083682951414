import { Injectable } from '@angular/core';
import { HttpConnectionService } from './http-connection.service';

@Injectable({
  providedIn: 'root'
})
export class ClosetApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  ImageForProcess(formData: any) {
    return this.httpConnection.post("store/Site/ImageForProcess", formData);
  }

  SendCustomerImageForAI(closetInfo: any) {
    return this.httpConnection.post("store/Site/SendCustomerImageForAI", closetInfo);
  }

}
