import { environment } from './../../../../environments/environment';
import { Observable } from 'src/app/core/services/observable/observable';
import { Helper } from './../../utilities/helper';
import { SearchApiService } from './../../../core/services/api/search-api.service';
import { FormGroup, FormControl } from '@angular/forms';
import { LocalStorageService } from './../../../core/services/storage/local-storage.service';
import { ProductListApiService } from '../../../core/services/api/product-list-api.service';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { staticMenu } from '../../statics/data';
import { PlatformLocation } from '@angular/common';
import { Product } from '../../interfaces/product';
import { Category } from '../../interfaces/category';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.less'],
  animations: [
    trigger('topLine', [
      state('open', style({
        transform: 'translate3d(30%, 0, 0)'

      })),
      state('close', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      transition('open => close', animate('400ms ease-in-out')),
      transition('close => open', animate('400ms ease-in-out'))
    ]),
    trigger('slideInOut', [
      state('open', style({
        transform: 'translate3d(20%, 0, 0)'

      })),
      state('close', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      transition('open => close', animate('400ms ease-in-out')),
      transition('close => open', animate('400ms ease-in-out'))
    ]),
    trigger('profileSlide', [
      state('open', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('close', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('open => close', animate('400ms ease-in-out')),
      transition('close => open', animate('400ms ease-in-out'))
    ]),
    trigger('profileToggle', [
      state('open', style({
        display: 'flex'
      })),
      state('close', style({
        display: 'none'
      })),
      transition('open => close', animate('0ms ease-in-out')),
      transition('close => open', animate('400ms ease-in-out'))
    ]),
  ]
})
export class SiteLayoutComponent implements OnInit {

  profileMenu: string = 'close';
  showLogo: boolean = false;
  showFilter: boolean = false;
  toggleSearch: boolean = false;
  ShowResult: boolean = false;
  isShowGift: boolean = true;
  searchInfo: FormGroup;
  listOfProducts: any = [];
  buttonLoading: boolean = false;
  confirmTitle: string = 'آیا میخواهید خارج شوید؟';
  confirmMessage: string = 'با خروج به حساب کاربری و سبد خرید دسترسی نخواهید داشت.';
  confirmbutton: string = 'بله، خارج می شوم';
  productTitle: string = '';
  baseUrl: any = environment.baseUrl;
  productLink: string;
  staticMenu: any = staticMenu;
  readMore: boolean = false;
  constructor(
    public router: Router,
    private productListApiService: ProductListApiService,
    private localStorageService: LocalStorageService,
    private searchApiService: SearchApiService,
    private helper: Helper,
    private observable: Observable,
    private clipboard: Clipboard,
    private location: PlatformLocation
  ) {
    this.onCreateSearchForm();

    this.observable.get().subscribe(response => {
      this.productTitle = response;
      this.productLink = this.router.url;
      if (this.productLink) {
        if (window.location.hostname.indexOf('localhost') !== -1) {
          this.productLink = window.location.hostname + ":" + this.location.port + this.productLink;
        } else {
          this.productLink = window.location.hostname + this.productLink;
        }
      }
    });
    this.closeFilterOnBack();
  }

  ngOnInit(): void {
    this.observable.getFilter().subscribe(response => {
      this.showFilter = response;
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateSearchForm(): void {
    this.searchInfo = new FormGroup({
      id: new FormControl(null),
      search: new FormControl(null),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onToggleSearch(event: Event): void {
    event.stopPropagation();
    this.toggleSearch = this.toggleSearch === false ? true : false;
    this.searchInfo.get('search').patchValue(null);
    this.listOfProducts = [];
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCloseSearch(): void {
    this.toggleSearch = false;
    this.ShowResult = false;
    this.searchInfo.get('search').patchValue(null);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  isInRoue(route: string): boolean {
    if (this.router.url.indexOf(route) == -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  scrollPos = 0;
  showSearch: boolean = true;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: Event): void {
    if (this.toggleSearch === false) {
      if (window.pageYOffset > 0) {

        let topNav = document.getElementById('navbar');
        topNav.classList.add('navbar-inverse');

        let responsiveNav = document.getElementById('responsiveNav');
        responsiveNav.classList.add('responsive-navbar-inverse');

        this.showLogo = true;
      } else {

        let element = document.getElementById('navbar');
        element.classList.remove('navbar-inverse');

        let responsiveNav = document.getElementById('responsiveNav');
        responsiveNav.classList.remove('responsive-navbar-inverse');

        this.showLogo = false;
      }
    }
    if (!this.ShowResult) {
      if ((document.body.getBoundingClientRect()).top > this.scrollPos) {
        // console.log('Up');
        this.showSearch = true;
      }
      else {
        // console.log('Down');
        this.showSearch = false;
      }
      this.scrollPos = (document.body.getBoundingClientRect()).top;
    }
    if (window.pageYOffset <= 0) {
      this.showSearch = true;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getRoute(): boolean {
    if (this.router.url === '/' || this.router.url.startsWith('/seller')) {
      return true;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  filterRouterLinkActiveStartWith(): boolean | string {
    if (this.router.url.startsWith('/product-list') || this.router.url.startsWith('/seller') || this.router.url.startsWith('/category/')) {
      return "d-flex"
    }
    return false;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getRouteFooter(route: string): boolean | string {
    if (this.router.url === '/basket') {
      return "footer-on-basket-padding"
    }
    if (this.router.url.startsWith(route)) {
      return 'footer-on-product-deatils-padding';
    }
    return false;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  routerLinkActive(route: string): boolean {
    if (this.router.url == route) {
      return true;
    }
    return false;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  toggleProfileMenu(): void {
    this.profileMenu = this.profileMenu === 'close' ? 'open' : 'close';
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onFilter(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.showFilter == true ? this.showFilter = false : this.showFilter = true;
    this.observable.addFilter(this.showFilter);
    this.productListApiService.toggleFilterStatus(this.showFilter);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  closeFilterOnBack(): void {
    this.location.onPopState(() => {
      if ((this.router.url.startsWith('/seller') || this.router.url.startsWith('/category/') || this.router.url.startsWith('/product-list')) && this.showFilter) {
        this.showFilter == true ? this.showFilter = false : this.showFilter = true;
        this.productListApiService.toggleFilterStatus(this.showFilter);
      }
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onNavLinks(route: string): void {
    this.profileMenu = 'close';
    this.router.navigate([route]);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onGoToProfilePages(route: string): void {
    this.router.navigate([route]);
    this.profileMenu = 'close';
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onGotoBasket(): void {
    this.localStorageService.checkAuthUrl('/basket', true);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  showZPTrust(): void {
    let thewindow: any = window.open("https://www.zarinpal.com/trustPage/" + window.location.hostname, null, "width=450, height=600, scrollbars=no, resizable=no");
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onSearchInProducts(): void {
    if (this.searchInfo.get('search').value.length > 2) {
      this.ShowResult = true;
      this.searchApiService.getSearchInProduct(this.searchInfo.get('search').value).subscribe(response => {
        this.listOfProducts = response['data'];
      }, error => {
        this.ShowResult = false;
      })
    } else {
      this.ShowResult = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onNotCloseSearch(event: Event): void {
    event.stopPropagation();
  }

  onCloseSearchResult(event: Event, product?: Product): void {
    event.stopPropagation();
    this.ShowResult = false;
    this.searchInfo.get('search').patchValue(null);
    this.helper.redirectTo('/product/' + product?.link)
  }

  LogOut(eventDeleted: boolean): void {
    if (eventDeleted) {
      this.localStorageService.remove('userInfo');
      this.profileMenu = 'close';
      this.buttonLoading = eventDeleted;
      this.observable.add({ eventModal: eventDeleted });
      this.helper.redirectTo('/');
    }
  }

  productDeatilsOpstion: boolean = false;
  toggleProductDeatilsOptions(): void {
    this.productDeatilsOpstion == true ? this.productDeatilsOpstion = false : this.productDeatilsOpstion = true;
  }

  onReturn(): void {
    if (history.length > 2) {
      this.location.back();
    } else {
      this.helper.redirectTo('/');
    }
  }

  copyLinkToClipboard(link: string): void {
    this.clipboard.copy(link);
    this.helper.toaster(`لینک با موفقیت کپی شد`, ToasterType.SUCCESS);
    this.toggleProductDeatilsOptions();
  }

  isShowCategories: boolean = false;
  onOpenCategories(): void {
    this.isShowCategories = true;
  }

  getRouteSearch(): boolean {
    if (this.router.url != '/') {
      return false;
    } else {
      return true;
    }
  }

  showResponsiveCatgeory: boolean = false;
  onOpenResponsiveCatgeory(): void {
    this.showResponsiveCatgeory = true;
  }


  parentCategories: any = [];
  onGetParentCatgeory(parentLink: string): void {
    this.parentCategories = this.staticMenu.find(current => current.link === parentLink);
    this.onGetChildCatgories(this.parentCategories.listOfParents[0]);
  }

  activeLinkByHover: string;
  childCategories: any = [];
  onGetChildCatgories(parentCategory: Category): void {
    this.activeLinkByHover = parentCategory.link;
    this.childCategories = parentCategory;
  }

  copGiftToClipboard(): void {
    this.clipboard.copy('730765');
    this.helper.toaster(`کد با موفقیت کپی شد`, ToasterType.SUCCESS);
    this.onCloseGift();
  }

  onCloseGift(): void {
    this.isShowGift = false;
  }

  noClose(event: Event): void {
    event.stopPropagation();
  }

  onToggleReadMore() {
    this.readMore == true ? this.readMore = false : this.readMore = true;
  }

}
