import { UserAddress } from './../../shared/interfaces/address';
import { Basket } from './../../shared/interfaces/basket';
import { Product } from './../../shared/interfaces/product';
import { City } from './../../shared/interfaces/city';
import { PostType } from './../../shared/interfaces/post-type';
import { FactorItem } from './../../shared/interfaces/factor-item';
import { AddressApiService } from './../../core/services/api/address-api.service';
import { environment } from './../../../environments/environment';
import { Helper } from './../../shared/utilities/helper';
import { BasketApiService } from './../../core/services/api/basket-api.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormValidatorsService } from './../../shared/validators/form-validators.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Status } from 'src/app/core/enums/status.enum';
import { Observable } from 'src/app/core/services/observable/observable';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { staticListOfStates } from 'src/app/shared/statics/data';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.less'],
  animations: [
    trigger('togglePrice', [
      state('open', style({
        transform: 'translate3d(0, 0, 0)',
      })),
      state('close', style({
        transform: 'translate3d(0, 100%, 0)',
      })),
      transition('open => close', animate('400ms ease-in-out')),
      transition('close => open', animate('400ms ease-in-out'))
    ]),
    trigger('toggleIcon', [
      state('open', style({
        transform: 'rotate(-90deg)',
      })),
      state('close', style({
        transform: 'rotate(90deg)',
      })),
      transition('open => close', animate('400ms ease-in-out')),
      transition('close => open', animate('400ms ease-in-out'))
    ]),
  ]
})
export class BasketComponent implements OnInit {

  activeStep = 1;
  isEmptyBasket: boolean = false;
  calcDiscount = false;
  basketInfo: FormGroup;
  addressInfo: FormGroup;
  discountInfo: FormGroup;
  productQuantityInfo: FormGroup;
  priceToggleState: string = 'close';
  buttonLoading: boolean = false;
  buttonLoadingDiscountCode: boolean = false;
  newAddresstoggle: boolean = false;
  basket: Basket;
  cart: any;
  editStatus: boolean = false;
  listofCities: City[] = [];
  listOfAddresses: UserAddress[] = [];
  baseUrl = environment.baseUrl;
  product: Product;
  confirmTitle: string = 'آیا میخواهید محصول را حذف کنید؟';
  confirmMessage: string = 'با حذف محصول امکان بازگشت آن وجود ندارد. آیا با حذف آن موافقید ؟';
  confirmbutton: string = 'بله، حذف شود';
  status = Status;
  pageLoading: boolean = false;
  sectionLoading: string = 'default';
  address: UserAddress;
  factorItem: FactorItem[];
  currentStep: Number;
  transportCost: number = 0;
  history: string[] = []
  totalPrices: any = {
    totalAmount: 0,
    totalDiscount: 0,
    totalRealPrice: 0,
    totalPay: 0,
    totalDiscountCode: 0,
    seprateNumber: this.helper.seprateNumber
  }

  truncate: Function = this.helper.truncate;
  headerTitle: string = 'کاریزماکالر | سبد خرید'

  constructor(
    private formValidator: FormValidatorsService,
    private basketApiService: BasketApiService,
    private addressApiService: AddressApiService,
    private helper: Helper,
    private observable: Observable,
    private router: Router,
    private location: Location,
    private manageHeader: ManageHeaderService
  ) {
    this.onCreateBasketInfoForm();
    this.onCreateAddressInfo();
    this.onCreateProductQuantityForm();
    this.onCreateDiscountInfoForm();
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.history.push(event.urlAfterRedirects);
    //   }
    // });
  }

  ngOnInit(): void {
    this.getViewBasket();
    this.manageHeader.updateTitle(this.headerTitle);
  }

  ngOnDestroy(): void {
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateBasketInfoForm(): void {
    this.basketInfo = new FormGroup({
      id: new FormControl(null),
      paymentMethodId: new FormControl("8b02bc8e-4144-47a0-972f-8eb5cfd7c543", [this.formValidator.requiredValidator('paymentMethodId')]),
      postTypeId: new FormControl("092493b8-4cd6-4b1c-9b34-c2f05be7c959", [this.formValidator.requiredValidator('postTypeId')]),
      delivaryDate: new FormControl(null, [this.formValidator.requiredValidator('delivaryDate')]),
      addressId: new FormControl(null, [this.formValidator.requiredValidator('addressId')]),
      amount: new FormControl(null, [this.formValidator.requiredValidator('amount')]),
      discount: new FormControl(0),
      discountCodeId: new FormControl(null),
      description: new FormControl(null),
      listOfFactorItems: new FormControl(null, [this.formValidator.requiredValidator('listOfFactorItems')]),
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateAddressInfo(): void {
    this.addressInfo = new FormGroup({
      id: new FormControl(null),
      recipientName: new FormControl(null, [this.formValidator.requiredValidator("recipientName"), this.formValidator.minlengthValidator('recipientName', 3), this.formValidator.maxlengthValidator('recipientName', 200)]),
      phoneReciver: new FormControl(null, [this.formValidator.isMobileReg('phoneReciver'), this.formValidator.requiredValidator("phoneReciver"), this.formValidator.minlengthValidator('phoneReciver', 11), this.formValidator.maxlengthValidator('phoneReciver', 11)]),
      postalCode: new FormControl(null, [this.formValidator.minlengthValidator('postalCode', 10), this.formValidator.maxlengthValidator('postalCode', 10)]),
      houseNumber: new FormControl(null, [this.formValidator.requiredValidator("houseNumber"), this.formValidator.minlengthValidator('houseNumber', 1), this.formValidator.maxlengthValidator('houseNumber', 5)]),
      addressDetails: new FormControl(null, [this.formValidator.requiredValidator("addressDetails"), this.formValidator.minlengthValidator('addressDetails', 5), this.formValidator.maxlengthValidator('addressDetails', 200)]),
      state: new FormControl(null, [this.formValidator.requiredValidator("state")]),
      city: new FormControl(null, [this.formValidator.requiredValidator("city")]),
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateProductQuantityForm(): void {
    this.productQuantityInfo = new FormGroup({
      currentQuantity: new FormControl(null),
      similarProductId: new FormControl(null),
      count: new FormControl(null),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateDiscountInfoForm(): void {
    this.discountInfo = new FormGroup({
      discountCode: new FormControl(null, [
        this.formValidator.onlyNumber("discountCode"),
        this.formValidator.minlengthValidator('discountCode', 6),
        this.formValidator.maxlengthValidator('discountCode', 6)
      ]),
      discount: new FormControl(0),
      discountCodeId: new FormControl(null),
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getViewBasket(): void {
    this.pageLoading = true;
    this.basketApiService.getViewBasket().subscribe(response => {
      this.basket = response['data'];
      this.basket.listOfStates = staticListOfStates;
      this.cart = this.basket.cart;
      this.onCalculatePriceAndDiscount();
      this.pageLoading = false;
    }, error => {
      this.pageLoading = false;
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  step1(): boolean {
    if (this.activeStep === 1) {
      this.factorItem = this.basket.cart.listOfProducts.map(element => {
        return {
          quantity: element.quantity,
          price: element.price,
          discount: element.discount,
          similarProductId: element.similarProductId,
        }
      });
      if (this.factorItem.length != 0) {
        this.activeStep++;
        this.onPostType(this.basket.listOfPostTypes[0]);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else {
        this.helper.toaster('هیچ محصولی انتخاب نشده است', 'error')
      }
      return true;
    }
    return false;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  step2(): boolean {
    if (this.activeStep === 2 && this.basketInfo.get('addressId').valid && this.basketInfo.get('postTypeId').valid) {
      this.activeStep++;
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return true;
    } else {
      if (this.basketInfo.get('addressId').invalid) {
        this.helper.toaster('آدرس را انتخاب نکرده اید', 'error');
      }
      if (this.basketInfo.get('postTypeId').invalid) {
        this.helper.toaster('شیوه ارسال را انتخاب نکرده اید', 'error');
      }
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onIncreaceSteps(): void {
    if (this.step1()) {
      return;
    }
    if (this.step2()) {
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onDecreaceSteps(): boolean {
    if (this.activeStep > 1) {
      this.activeStep--;
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return true;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onChangeProductQuantity(product: Product, count: number, index: number): void {
    this.productQuantityInfo.get('similarProductId').patchValue(product.similarProductId);
    this.productQuantityInfo.get('currentQuantity').patchValue(product.quantity);
    this.productQuantityInfo.get('count').patchValue(count);
    this.buttonLoading = true;
    this.sectionLoading = (count === 1) ? 'plus_' + product.id : 'minus_' + product.id;
    this.basketApiService.postChangeCartItemQuantity(this.productQuantityInfo.value).subscribe(reponse => {
      product.quantity = product.quantity + count;
      this.basket.cart.listOfProducts.splice(index, 1, product);
      this.buttonLoading = false;
      this.sectionLoading = 'default';
      this.onCalculatePriceAndDiscount();
    }, error => {
      this.helper.showErrorMessage(error.error.errorMessages);
      this.buttonLoading = false;
      this.sectionLoading = 'default';
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  toggleDeleteMenu(product: Product): void {
    this.product = product;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onDelete(eventDeleted: boolean): void {
    if (eventDeleted) {
      if (!this.helper.isEmptyObject(this.product) && this.product.similarProductId !== null) {
        this.buttonLoading = eventDeleted;
        this.basketApiService.postChangeCartItemStatus({ id: this.product.similarProductId, status: Status.DELETE }).subscribe(response => {
          this.basket.cart.listOfProducts = this.basket.cart.listOfProducts.filter(x => x.similarProductId !== this.product.similarProductId); // deleted item
          this.helper.toaster("حذف با موفقیت انجام شد", "success");
          this.observable.add({ eventModal: eventDeleted });
          this.onCalculatePriceAndDiscount();
          this.buttonLoading = false;
        }, error => {
          this.helper.showErrorMessage(error.error.errorMessages);
          this.observable.add({ eventModal: eventDeleted });
          this.buttonLoading = false;
        });
      }
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCalcDiscount(state: string): void {
    const discountCode: any = this.discountInfo.get('discountCode');
    if (state === 'calcDiscount') {
      if (discountCode.valid) {
        if (discountCode.value !== null) {
          this.buttonLoadingDiscountCode = true;
          this.basketApiService.postUseDiscountCode({ listOfFactorItems: this.factorItem, discountCode: discountCode.value }).subscribe(response => {
            this.buttonLoadingDiscountCode = false;
            this.totalPrices.totalDiscountCode = Number(response['data']['discount']);
            this.discountInfo.get('discount').patchValue(this.totalPrices.totalDiscountCode);
            this.discountInfo.get('discountCodeId').patchValue(response['data']['discountCodeId']);
            this.totalPrices.totalDiscount += this.totalPrices.totalDiscountCode;
            this.totalPrices.totalAmount -= this.totalPrices.totalDiscountCode;
            this.totalPrices.totalPay -= this.totalPrices.totalDiscountCode;
            this.helper.toaster('کد تخفیف با موفقیت اعمال شد', ToasterType.SUCCESS);
            this.calcDiscount = true;
          }, error => {
            this.helper.showErrorMessage(error.error.errorMessages);
            this.onCreateDiscountInfoForm();
            this.buttonLoadingDiscountCode = false;
          });
        } else {
          this.helper.toaster('لطفا کد مورد نظر را وارد کنید', ToasterType.WARNING);
          return;
        }
      } else {
        this.discountInfo.get('discountCode').markAsTouched();
        this.helper.toaster('کد وارد شده صحیح نمی باشد', 'error')
      }
    }
    if (state === 'deleteDiscount') {
      this.totalPrices.totalAmount += this.totalPrices.totalDiscountCode;
      this.totalPrices.totalPay += this.totalPrices.totalDiscountCode;
      this.totalPrices.totalDiscountCode = 0;
      this.totalPrices.totalDiscount -= this.discountInfo.get('discount').value;
      this.discountInfo.get('discountCode').patchValue(null);
      this.discountInfo.get('discountCodeId').patchValue(null);
      this.discountInfo.get('discount').patchValue(0);
      this.calcDiscount = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onPostType(postType: PostType): void {
    this.transportCost = postType.transportCost;
    this.totalPrices.totalPay = this.helper.truncate(Number(this.totalPrices.totalRealPrice + this.transportCost));
    this.basketInfo.get('delivaryDate').patchValue(this.helper.addDayToDate(this.basket.currentDate, postType.delivaryTime));
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  togglePrice(): void {
    this.priceToggleState = this.priceToggleState === 'close' ? 'open' : 'close';
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  toggleNewAddress(): void {
    this.newAddresstoggle = this.newAddresstoggle === false ? true : false;
    this.basketInfo.get("addressId").patchValue(null);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCloseNewAddress(): void {
    this.newAddresstoggle = false;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateNewAddress(): void {
    let model: UserAddress = this.addressInfo.value;
    model.stateName = this.addressInfo.get("state").value?.name;
    model.cityName = this.addressInfo.get("city").value?.name;
    model.stateId = this.addressInfo.get("state").value?.id;
    model.cityId = this.addressInfo.get("city").value?.id;
    if (!this.helper.checkInvalidForm(this.addressInfo)) {
      this.buttonLoading = true;
      this.addressApiService.postCreateAddress(model).subscribe(response => {
        this.buttonLoading = false;
        this.helper.toaster('آدرس با موفقیت اضافه شد', 'success');
        model.id = response['data'];
        this.basket.listOfUserAdresses.push(model);
        this.onCreateAddressInfo();
        this.newAddresstoggle = false;
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      });
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', 'error');
      this.buttonLoading = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onToggleAddressUpdate(address: UserAddress): void {
    this.addressInfo.patchValue(address);
    this.newAddresstoggle = true;
    this.editStatus = true;
    this.onScrolltToElement('.add-new-address-cotainer', 'start');
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onUpdateAddress(): void {
    let model: UserAddress = this.addressInfo.value;
    model.stateName = this.addressInfo.get("state").value?.name;
    model.cityName = this.addressInfo.get("city").value?.name;
    model.stateId = this.addressInfo.get("state").value?.id;
    model.cityId = this.addressInfo.get("city").value?.id;
    if (!this.helper.checkInvalidForm(this.addressInfo)) {
      this.buttonLoading = true;
      this.addressApiService.putUpdateAddress(model).subscribe(response => {
        this.newAddresstoggle = false;
        this.editStatus = false;
        this.buttonLoading = false;
        this.onCreateAddressInfo();
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        this.helper.toaster('آدرس با موفقیت ویرایش شد', 'success');

      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      })
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', 'error');
      this.buttonLoading = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onFilterCities(): void {
    if (this.addressInfo.get('state').value?.id) {
      this.addressInfo.get('city').patchValue(null);
      this.listofCities = this.basket.listOfStates.find(current => current.id == this.addressInfo.get('state').value?.id)['listOfCities'];
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCalculatePriceAndDiscount(): void {
    this.totalPrices.totalAmount = 0;
    this.totalPrices.totalDiscount = 0;
    this.totalPrices.totalRealPrice = 0;
    this.totalPrices.totalPay = 0;
    for (let index in this.basket?.cart?.listOfProducts) {
      this.totalPrices.totalAmount += (this.basket.cart.listOfProducts[index].price * this.basket.cart.listOfProducts[index].quantity);
      this.totalPrices.totalRealPrice += this.helper.calculateDiscount(this.basket.cart.listOfProducts[index].price, this.basket.cart.listOfProducts[index].discount, this.basket.cart.listOfProducts[index].quantity);
    }
    this.totalPrices.totalDiscount = Number(this.totalPrices.totalAmount + this.discountInfo.get("discount").value - this.totalPrices.totalRealPrice).toFixed(2);
    this.totalPrices.totalDiscount = this.helper.truncate(this.totalPrices.totalDiscount);
    this.totalPrices.totalPay += this.helper.truncate(this.totalPrices.totalRealPrice);
    this.totalPrices.totalPay = this.helper.truncate(Number(this.totalPrices.totalRealPrice + this.transportCost));
    this.totalPrices.totalPay -= this.discountInfo.get('discount').value;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  finalPayment(): void {
    this.buttonLoading = true;
    this.basketInfo.get('listOfFactorItems').patchValue(this.factorItem);
    this.basketInfo.get('amount').patchValue(this.totalPrices.totalPay);
    this.basketInfo.get('discount').patchValue(this.discountInfo.get('discount').value);
    this.basketInfo.get('discountCodeId').patchValue(this.discountInfo.get('discountCodeId').value);
    if (!this.helper.checkInvalidForm(this.basketInfo)) {
      this.basketApiService.postCreateFactor(this.basketInfo.value).subscribe(response => {
        this.helper.toaster('در حال انتقال به درگاه بانک', ToasterType.SUCCESS);
        window.location.href = response["data"];
      }, error => {
        this.helper.toaster(error.error.errorMessages, ToasterType.ERROR)
        this.buttonLoading = false;
      })
    } else {
      this.helper.toaster('مراحل خرید تکمیل نمی باشد', ToasterType.WARNING);
      this.buttonLoading = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onScrolltToElement(element: string, block: ScrollLogicalPosition): void {
    document.querySelector(element).scrollIntoView({
      behavior: 'smooth',
      block: block
    });
  }

}

