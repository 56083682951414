import { Helper } from 'src/app/shared/utilities/helper';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.less']
})
export class FormInputComponent implements OnInit {

  constructor(
    private helper: Helper
  ) {
    this.onBlur = new EventEmitter();
  }

  @Input() appAutoFocus = false;
  @Input() formGroup: FormGroup;
  @Input() control: FormControl;
  @Input() type: 'text' | 'number' | 'file' | 'password' | 'email';
  @Input() id = '';
  @Input() inputCssClass = '';
  @Input() labelCssClass = '';
  @Input() formGroupClass = '';
  @Input() name: string = this.id || '';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() errorMessage: string | boolean = null;
  @Input() extraClass: string | string[] = '';
  @Input() inputValue = '';
  @Input() maxLength = 15;
  @Input() minLength = 0;

  @Input() guidesLinks = '';
  @Input() guidesLinksCssClass = '';
  @Input() onClickGuides;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onBlur: EventEmitter<boolean>;

  // HTML helpers
  objectFn = Object;

  ngOnInit(): void {
  }

  blur(): void {
    this.onBlur.emit(true);
  }

  onChangeToNumber(value: any): void {
    if (value) {
      this.control.patchValue(this.helper.convertToEnglishNumber(value?.toString()));
    }
  }

}
