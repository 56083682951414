import { Helper } from './../../utilities/helper';
import { BlogApiService } from './../../../core/services/api/blog-api.service';
import { FormGroup, FormControl } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-blog-layout',
  templateUrl: './blog-layout.component.html',
  styleUrls: ['./blog-layout.component.less'],
  animations: [
    trigger('topline', [
      state('open', style({
        transform: 'rotate(-45deg)',
        transformOrigin: 'left'
      })),
      state('close', style({
        transform: 'rotate(0deg)'
      })),
      transition('open => close', animate('400ms ease-in-out')),
      transition('close => open', animate('400ms ease-in-out'))
    ]),
    trigger('middleLine', [
      state('open', style({
        display: 'none'
      })),
      state('close', style({
        display: 'block'
      })),
      transition('open => close', animate('200ms ease-in-out')),
      transition('close => open', animate('200ms ease-in-out'))
    ]),
    trigger('bottomLine', [
      state('open', style({
        transform: 'rotate(45deg)',
        transformOrigin: 'left'
      })),
      state('close', style({
        transform: 'rotate(0deg)'
      })),
      transition('open => close', animate('400ms ease-in-out')),
      transition('close => open', animate('400ms ease-in-out'))
    ]),
  ]
})
export class BlogLayoutComponent implements OnInit {

  showNavbar: string = 'close';
  activeChildCategory: number;
  searchInfo: FormGroup;
  ShowResult: boolean = false;
  listOfBlogs: any = [];
  listOfAdminChoice: any = [];
  @ViewChild('closeNavToggle') closeNavToggle: any;

  constructor(
    private blogApiService: BlogApiService,
    private helper: Helper
  ) { }

  ngOnInit(): void {
    this.onCreateSearchInfoForm();
    this.getViewBlogDetails();
  }


  getViewBlogDetails(): void {
    this.blogApiService.getInfoBlogHome().subscribe(response => {
      this.listOfAdminChoice = response['data']['listOfBlogsNewest'];
    }, error => {
      console.log(error);
    })
  }

  onCreateSearchInfoForm() {
    this.searchInfo = new FormGroup({
      'id': new FormControl(null),
      'search': new FormControl(null),
    })
  }

  onToggleBlogNav() {
    this.showNavbar == 'close' ? this.showNavbar = 'open' : this.showNavbar = 'close';
    if (window.innerWidth < 1200) {
      this.closeNavToggle?.nativeElement.click();
    }
  }

  onScrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openBlogChildCategories(index) {
    this.activeChildCategory == null ? this.activeChildCategory = index : this.activeChildCategory = null;
  }

  getSearchInBlog(): void {
    if (this.searchInfo.get('search').value.length > 2) {
      this.ShowResult = true;
      this.blogApiService.getSearchInBlog(this.searchInfo.get('search').value).subscribe(response => {
        this.listOfBlogs = response['data'];
      }, error => {
        this.ShowResult = false;
      })
    } else {
      this.ShowResult = false;
    }
  }

  onNotCloseSearch(event: Event): void {
    event.stopPropagation();
  }

  onCloseSearchResult(event: Event, blog?: any): void {
    event.stopPropagation();
    this.ShowResult = false;
    this.searchInfo.get('search').patchValue(null);
    this.onToggleBlogNav();
    this.helper.redirectTo('blog/' + blog?.link);
  }

  onGoToSearchPage() {
    let searchedText = this.searchInfo.get('search').value;
    if (searchedText && searchedText.length > 2) {
      this.helper.redirectTo('blog/search/', searchedText);
      this.onToggleBlogNav();
      this.ShowResult = false;
    }
  }

}
