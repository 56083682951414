import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-form-ng-select',
  templateUrl: './form-ng-select.component.html',
  styleUrls: ['./form-ng-select.component.less']
})
export class FormNgSelectComponent implements OnInit {

  constructor() {
    this.onBlur = new EventEmitter();
  }

  @Input() appAutoFocus = false;
  @Input() formGroup: FormGroup;
  @Input() control: FormControl;
  @Input() type: 'text' | 'number' | 'file' | 'password' | 'email';
  @Input() id = '';
  @Input() inputCssClass = '';
  @Input() labelCssClass = '';
  @Input() formGroupClass = '';
  @Input() name: string = this.id || '';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() errorMessage: string | boolean = null;
  @Input() extraClass: string | string[] = '';
  @Input() multiple = '';
  @Input() closeOnSelect = '';
  @Input() searchable = '';
  @Input() bindLabel: any;
  @Input() value = '';
  @Input() options = '';
  @Input() listArray: any = [];
  @Input() itemValue = '';
  @Input() itemTitle = '';
  @Input() bindValue: any;
  @Input() items: any;
  @Input() ngSelectClass = '';


  // tslint:disable-next-line: no-output-on-prefix
  @Output() onBlur: EventEmitter<boolean>;

  // HTML helpers
  objectFn = Object;

  ngOnInit() { }

  blur(): void {
    this.onBlur.emit(true);
  }

  onChangeToNumber(value: any): void {
    if (this.type == 'number') {
      this.control.patchValue(Number(value));
    }
  }

}
