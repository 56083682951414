<div class="row responsive-category-wrapper">
  <div class="col-12 p-0">
    <a routerLink="{{category.link}}" *ngFor="let category of staticMenu.listOfParents"
      class="responsive-category-link">
      <img class="icon" src="../../../../assets/images/core/{{category.icon}}.svg" alt="{{category.name}}">
      {{category.name}}
    </a>
    <!-- <a href="#" class="responsive-category-link">
      <img class="icon" src="../../../../assets/images/core/handbag.svg" alt="کیف">
      کیف
    </a>
    <a href="#" class="responsive-category-link">
      <img class="icon" src="../../../../assets/images/core/shoes.svg" alt="کفش">
      کفش
    </a>
    <a href="#" class="responsive-category-link">
      <img class="icon" src="../../../../assets/images/core/diamond.svg" alt="اکسسوری">
      اکسسوری
    </a>
    <a href="#" class="responsive-category-link">
      <img class="icon" src="../../../../assets/images/core/set.svg" alt="ست">
      ست
    </a> -->
    <a [routerLink]="['/product-list']" class="responsive-category-link responsive-category-all">
      مشاهده همه موارد
      <img class="icon" src="../../../../assets/images/core/left-arrow.svg" alt="همه موارد">
    </a>
  </div>
</div>
