import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recommandProduct'
})
export class RecommandProductPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let statusMessage = "کاربر تصمیمی درباره پیشنهاد محصول ندارد";
    switch (value) {
      case true:
        statusMessage = "خرید این محصول را پیشنهاد میکنم"
        break;
      case false:
        statusMessage = "خرید این محصول را پیشنهاد نمیکنم"
        break;
      default:
        break;
    }
    return statusMessage;
  }

}
