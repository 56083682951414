import { Meta } from '@angular/platform-browser';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';
import { Inject, Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Paginate } from "../interfaces/paginate";
import { Guid } from "guid-typescript";
import * as moment from 'jalali-moment';
import { Clipboard } from '@angular/cdk/clipboard';
import { OrderSort } from "src/app/core/enums/order-sort.enum";
import { LocationStrategy } from "@angular/common";
import { LocalStorageService } from "src/app/core/services/storage/local-storage.service";
// import { CachestorageService } from "src/app/core/services/storage/cachestorage.service";


@Injectable({
  providedIn: 'root'
})

export class Helper {

  public id: Guid;
  private formGroup: FormGroup;
  private date: any;

  constructor(
    private toastr: ToastrService,
    private clipboard: Clipboard,
    private router: Router,
    private locationStrategy: LocationStrategy,
    private manageHeaderService: ManageHeaderService,
    private meta: Meta
  ) { }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public checkInvalidForm(form: FormGroup): boolean {
    if (form.invalid) {
      form.markAllAsTouched();
      return true;
    }
    return false;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public Guid(): Guid {
    return Guid.create()['value'];
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public toaster(message: string, type: string, title?: string): void {
    this.toastr[type](message, title);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public isOnlinInternet(): boolean {
    return window.navigator.onLine;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public uniqe(arary: any[], key: string): any[] {
    return arary.filter((item, pos, self) => self.findIndex(v => v[key] === item[key]) === pos);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public sort(array: any[], key: string, order: string): any[] { // Ascending || Descending
    return (OrderSort.DESCENDING === order) ? array.sort((a, b) => (a[key] > b[key] ? -1 : 1)) : array.sort((a, b) => (a[key] > b[key] ? -1 : 1));
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public limitText(text: string, start: number = 0, end: number = 100): string {
    return text.substring(start, end) + ' ...';
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public copyToClipboard(text: string): void {
    this.clipboard.copy(text);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public scrollTopByElementId(elementId: string): void {
    const topElement: Element = document.getElementById(elementId);
    if (topElement !== null && topElement !== undefined) {
      topElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public redirectTo(path: string, params?: any, queryParams?: boolean): void {
    if (path === "/") {
      this.router.navigate(['.']);
      return;
    }
    (queryParams === undefined || !queryParams) ? this.router.navigate([path, (params === undefined) ? {} : params]) : this.router.navigate([path], { queryParams: params })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  calculateDiscount(price: number, discount: number, count: number = 1): number {
    return (price - ((discount / 100) * price)) * count;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public truncate(value: number): number {
    if (value < 0) {
      return Math.ceil(value);
    }
    return Math.floor(value);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public showErrorMessage(messages: string[]): void {
    messages.forEach(message => {
      this.toaster(message, "error");
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public isEmptyObject(model: Object): boolean {
    if (model !== null)
      return Object.keys(model).length === 0;
    return true;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public removeAll(array: any[]): any[] {
    if (array.length !== 0)
      return array.splice(0, array.length);
  }

  public removeItemByIndex(array: any[], index: number): any[] {
    if (index > -1)
      return array.splice(index, 1);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public randomBetween(low: number, high: number): number {
    return Math.floor(Math.random() * (high - low + 1) + low);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public isValidDate(value: any): boolean {
    return moment(value, 'YYYY/MM/DD').isValid();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public addDayToDate(value: any, dayCount: number): any {
    this.date = moment(value, 'YYYY/MM/DD');
    return this.date.add(dayCount, 'day').locale('fa').format('YYYY/MM/DD');
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public isURL(str: string): boolean {
    let result = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (result !== null)
  }

  /**
  * Returns void (define a function to handle back button and use anywhere).
  *
  * @return {number} x raised to the n-th power.
  */
  public preventBackButton(): void {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public convertToEnglishNumber(value: string): string {
    let faNumStr = "";
    for (let mChar of Array.from(value?.toString())) {
      switch (mChar) {
        case '۰':
          faNumStr += "0"
          break;
        case '۱':
          faNumStr += "1"
          break;
        case '۲':
          faNumStr += "2"
          break;
        case '۳':
          faNumStr += "3"
          break;
        case '۴':
          faNumStr += "4"
          break;
        case '۵':
          faNumStr += "5"
          break;
        case '۶':
          faNumStr += "6"
          break;
        case '۷':
          faNumStr += "7"
          break;
        case '۸':
          faNumStr += "8"
          break;
        case '۹':
          faNumStr += "9"
          break;
        default:
          faNumStr += mChar;
          break;
      }
    }
    return faNumStr;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  public seprateNumber(value: number | string): string {
    /* seprate number input 3 number */
    let nStr = value + '';
    nStr = nStr.replace(/\,/g, "");
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  async paginate(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 10,
    maxPages: number = 10
  ): Promise<Paginate> {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */

  addMetaTags(metaTags: any): void {
    this.manageHeaderService.addMetaTags(metaTags);
    // const name = this.manageHeaderService.getMetaTags('name');
    // for (let i = 0; i < 20; i++) {
    //   console.log('name', name[i]);
    // }
    // const property = this.manageHeaderService.getMetaTags('property');
    // for (let i = 0; i < 20; i++) {
    //   console.log('property', property[i]);
    // }
  }

  createLinkForCanonicalURL(): void {
    const canonical = document.head.querySelector('link[rel="canonical"]');
    if (canonical === null) {
      let link = document.createElement('link');
      link?.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
      link?.setAttribute('href', document.URL);
      return;
    }
    canonical?.setAttribute('href', document.URL);
  }

}
