<div class="row not-found-main-row">
  <div class="col-12 not-found-main-col">
    <div class="main-containers-no-title not-found-main-container">
      <img class="not-found-icon" src="../../../assets/images/core/404.svg" alt="صفحه مورد نطر پیدا نشد">
      <label class="not-found-title">صفحه مورد نظر پیدا نشد ):</label>
      <label class="not-found-text">احتمالا آدرس صفحه را اشتباه وارد کرده اید یا این صفحه اخیرا حذف شده است</label>
      <button [routerLink]="['/']" class="medium-button" type="button">صفحه اصلی</button>
    </div>
  </div>
</div>
