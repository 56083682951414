<div class="row successfull-payment-main-row">
  <div class="col-11 payment-result-main-col">
    <img class="payment-result-image" src="../../../../assets/images/core/unsuccessfull-payment.svg"
      alt="پرداخت ناموفق">
    <label class="payment-result-text">پرداخت ناموفق</label>
    <div class="peyment-result-number-container">
      <label class="payment-result-sub-text">پرداخت ناموفق بود</label>
      <!-- <label class="payment-result-sub-text">|</label>
      <label class="payment-result-sub-text">
        شماره پیگیری :
        <span class="peyment-result-number-span">12345678</span>
      </label> -->
    </div>
    <label class="go-to-orders-results-text">چنانچه مبلغی از حساب شما کسر شد ، حداکثر تا ۷۲ ساعت به حساب شما برخواهد
      گشت</label>
    <button [routerLink]="['/']" class="medium-button" type="button">
      صفحه اصلی
    </button>
  </div>
</div>
