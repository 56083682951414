import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';
import { Order } from 'src/app/shared/interfaces/order';

@Injectable({
  providedIn: 'root'
})
export class OrderApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  getListOfFactors() {
    return this.httpConnection.get('customer/CustomerFactors/GetListOfFactors');
  }

  getFactorDetails(orderCode: Order) {
    return this.httpConnection.get(`customer/CustomerFactors/GetFactorDetail/${orderCode}`);
  }

}
