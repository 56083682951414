<div class="container-fluid">
  <header class="row sticky-top blog-nav-row">
    <nav class="col-12 navbar navbar-expand-xl blog-nav">
      <a class="hidden" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler"
        aria-expanded="false" aria-label="Toggle navigation" #closeNavToggle></a>
      <button (click)="onToggleBlogNav()" class="navbar-toggler" type="button">
        <svg class="blog-layout-svg-line">
          <line [@topline]="showNavbar" class="svg-line" x1="0" y1="13" x2="12" y2="13" />
          <line [@middleLine]="showNavbar" class="svg-line" x1="0" y1="7" x2="10" y2="7" />
          <line [@bottomLine]="showNavbar" class="svg-line" x1="0" y1="1" x2="17" y2="1" />
        </svg>
      </button>
      <a class="navbar-brand" [routerLink]="['/blog']">
        <img class="logo" src="../../../../assets/images/core/carisma-logo-solo.png" alt="کاریزماکالر">
      </a>
      <div class="collapse navbar-collapse navbar-collapse-custome" id="navbarToggler">
        <ul class="navbar-nav mr-auto mt-2 mt-xl-0">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']">فروشگاه کاریزماکالر</a>
          </li>
          <!-- <li class="nav-item">
            <a [routerLink]="['/blog']" [routerLinkActive]="['active-nav-link']" class="nav-link">صفحه نخست</a>
          </li> -->
          <!-- <li *ngFor="let parentCategory of staticBlogMenu; let i = index;" class="nav-item">
            <div class="d-flex justify-content-between">
              <a [routerLink]="['category/' + parentCategory.link]" (click)="onToggleBlogNav()" class="nav-link">
                {{parentCategory.title}}
              </a>
              <a *ngIf="parentCategory.listOfChildern" (click)="openBlogChildCategories(i)"
                class="nav-link dropdown-toggle blog-nav-dropdown"></a>
            </div>
            <ul *ngIf="parentCategory.listOfChildern" class="blog-dropdown-menu"
              [ngClass]="{'d-flex': activeChildCategory === i}">
              <li class="blog-dropdown-li" *ngFor="let childCategory of parentCategory.listOfChildern">
                <a class="dropdown-item" [routerLink]="['category/' + childCategory.link]" (click)="onToggleBlogNav()"
                  data-toggle="collapse" data-target="#navbarToggler">
                  {{childCategory.title}}
                </a>
              </li>
            </ul>
            <ul *ngIf="parentCategory.listOfChildern" class="childs-ul">
              <li *ngFor="let childCategory of parentCategory.listOfChildern" class="childs-li">
                <a [routerLink]="['category/' + childCategory.link]" class="childs-link">{{childCategory.title}}</a>
              </li>
            </ul>
          </li> -->

          <li class="nav-item">
            <div class="d-flex justify-content-between">
              <a [routerLink]="['/blog/category/دکوراسیون']" (click)="onToggleBlogNav()" class="nav-link">
                دکوراسیون
              </a>
              <a (click)="openBlogChildCategories(1)" class="nav-link dropdown-toggle blog-nav-dropdown"></a>
            </div>
            <ul class="blog-dropdown-menu" [ngClass]="{'d-flex': activeChildCategory === 1}">
              <li class="blog-dropdown-li">
                <a [routerLink]="['/blog/category/شهری']" class="dropdown-item" (click)="onToggleBlogNav()"
                  data-toggle="collapse" data-target="#navbarToggler">
                  شهری
                </a>
              </li>
              <li class="blog-dropdown-li">
                <a [routerLink]="['/blog/category/منزل']" class="dropdown-item" (click)="onToggleBlogNav()"
                  data-toggle="collapse" data-target="#navbarToggler">
                  منزل
                </a>
              </li>
            </ul>
            <ul class="childs-ul">
              <li class="childs-li">
                <a [routerLink]="['/blog/category/شهری']" class="childs-link">شهری</a>
              </li>
              <li class="childs-li">
                <a [routerLink]="['/blog/category/منزل']" class="childs-link">منزل</a>
              </li>
            </ul>

          </li>

          <li class="nav-item">
            <div class="d-flex justify-content-between">
              <a [routerLink]="['/blog/category/مد-و-فشن']" (click)="onToggleBlogNav()" class="nav-link">
                مد و فشن
              </a>
              <a (click)="openBlogChildCategories(2)" class="nav-link dropdown-toggle blog-nav-dropdown"></a>
            </div>
            <ul class="blog-dropdown-menu" [ngClass]="{'d-flex': activeChildCategory === 2}">
              <li class="blog-dropdown-li">
                <a [routerLink]="['/blog/category/زنانه']" class="dropdown-item" (click)="onToggleBlogNav()"
                  data-toggle="collapse" data-target="#navbarToggler">
                  زنانه
                </a>
              </li>
              <li class="blog-dropdown-li">
                <a [routerLink]="['/blog/category/مردانه']" class="dropdown-item" (click)="onToggleBlogNav()"
                  data-toggle="collapse" data-target="#navbarToggler">
                  مردانه
                </a>
              </li>
              <li class="blog-dropdown-li">
                <a [routerLink]="['/blog/category/متفرقه-مد-و-فشن']" class="dropdown-item" (click)="onToggleBlogNav()"
                  data-toggle="collapse" data-target="#navbarToggler">
                  متفرقه مد و فشن
                </a>
              </li>
              <li class="blog-dropdown-li">
                <a [routerLink]="['/blog/category/اکسسوری-مد-و-فشن']" class="dropdown-item" (click)="onToggleBlogNav()"
                  data-toggle="collapse" data-target="#navbarToggler">
                  اکسسوری مد و فشن
                </a>
              </li>
            </ul>
            <ul class="childs-ul">
              <li class="childs-li">
                <a [routerLink]="['/blog/category/زنانه']" class="childs-link">زنانه</a>
              </li>
              <li class="childs-li">
                <a [routerLink]="['/blog/category/مردانه']" class="childs-link">مردانه</a>
              </li>
              <li class="childs-li">
                <a [routerLink]="['/blog/category/متفرقه-مد-و-فشن']" class="childs-link">متفرقه مد و فشن</a>
              </li>
              <li class="childs-li">
                <a [routerLink]="['/blog/category/اکسسوری-مد-و-فشن']" class="childs-link">اکسسوری مد و فشن</a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <div class="d-flex justify-content-between">
              <a [routerLink]="['/blog/category/پوشاک-کودک-و-نوزاد']" (click)="onToggleBlogNav()" class="nav-link">
                پوشاک کودک و نوزاد
              </a>
            </div>
          </li>

          <li class="nav-item">
            <div class="d-flex justify-content-between">
              <a [routerLink]="['/blog/category/آشنایی-با-برند-ها']" (click)="onToggleBlogNav()" class="nav-link">
                آشنایی با برندها
              </a>
            </div>
          </li>

          <li class="nav-item">
            <div class="d-flex justify-content-between">
              <a [routerLink]="['/blog/category/جشنواره-های-دنیای-مد-و-رنگ']" (click)="onToggleBlogNav()"
                class="nav-link">
                جشنواره های دنیای مد و رنگ
              </a>
            </div>
          </li>

          <li class="nav-item">
            <div class="d-flex justify-content-between">
              <a [routerLink]="['/blog/category/آرایشی-و-زیبایی']" (click)="onToggleBlogNav()" class="nav-link">
                آرایشی و زیبایی
              </a>
            </div>
          </li>

          <li class="nav-item">
            <div class="d-flex justify-content-between">
              <a [routerLink]="['/blog/category/notes-for-attractive']" (click)="onToggleBlogNav()" class="nav-link">
                نکات جذابیت و خوش پوشی
              </a>
            </div>
          </li>

        </ul>
        <div class="search-wrapper blog-search-wrapper mx-auto">
          <div class="search-input-container">
            <form [formGroup]="searchInfo">
              <input (keyup.enter)="onGoToSearchPage()" (input)="getSearchInBlog()" (click)="onNotCloseSearch($event)"
                autocomplete="off" class="header-search-input" formControlName="search" name="search" type="text"
                placeholder="مطلب مورد نظر خود را جستجو کنید">
            </form>
            <img (click)="onGoToSearchPage()" class="search-icon" src="../../../../assets/images/core/search-icon.svg"
              alt="جستجو">
          </div>
          <div *ngIf="ShowResult" class="search-result-container">
            <a (click)="onCloseSearchResult($event, blog)" *ngFor="let blog of listOfBlogs" class="search-result-link">
              {{blog.title}}
            </a>
            <a *ngIf="listOfBlogs.length <= 0" class="search-result-link d-flex justify-content-center">
              هیچ محصولی یافت نشد
            </a>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <!-- <img class="main-banner-image" src="../../../../assets/images/blog/panjshanbe-Akhari.gif" alt="بنر"> -->
  <div class="row justify-content-center">
    <!-- <div class="col-2">
      <a class="navbar-brand" [routerLink]="['/blog']">
        <img class="logo" src="../../../../assets/images/core/carisma-logo-solo.png" alt="کاریزماکالر">
      </a>
    </div> -->
    <div class="col-12 col-md-9">
      <a [routerLink]="['/product-list']">
        <img class="main-banner-image" src="../../../../assets/images/blog/panjshanbe-Akhari.gif" alt="بنر">
      </a>
    </div>
    <div class="col-12 col-md-3">
      <a [routerLink]="['/']">
        <img class="main-banner-image" src="../../../../assets/images/blog/notif-300-600.jpg" alt="بنر">
      </a>
    </div>
  </div>
  <router-outlet></router-outlet>
  <footer class="row blog-footer">
    <div class="col-12 col-md-6 col-xl-5 footer-about-us my-3 my-xl-0">
      <label class="blog-headers">درباره ما</label>
      <p class="footer-text">
        کاریزما کالر نام یک مجله اینترنتی فعال در حوزه مد و فشن می باشد که با هدف تحول در حوزه خرید و فروش فشن پای به
        این عرصه نهاده است.
        کاریزما کالر با ورود به این عرصه، در نظر دارد تا با ایجاد یک سیستم استایلیست آنلاین، به عنوان یک مشاور برای
        داشتن استایلی جذاب در کنار شما عزیزان باشد.
        کماکان از همراهی شما عزیزان سپاسگذاریم.
      </p>
    </div>
    <div class="col-12 col-md-6 col-xl-5 my-3 my-xl-0">
      <label class="blog-headers">منتخب سردبیر</label>
      <ul class="footer-links-ul">
        <li *ngFor="let post of listOfAdminChoice" class="footer-links-li">
          <a [routerLink]="['/blog/' + post.link]" class="footer-links-link">{{post.title}}</a>
        </li>
      </ul>
    </div>
    <div class="col-12 col-md-6 col-xl-2 my-3 my-xl-0">
      <label class="blog-headers">راه های ارتباطی</label>
      <ul class="social-media-ul">
        <li class="footer-links-li">
          <a class="footer-links-link" href="https://www.instagram.com/carismacolor" target="_blank">
            <img class="icon" src="../../../../assets/images/core/instagram.svg" alt="اینستاگرام">
          </a>
        </li>
        <li class="footer-links-li">
          <a class="footer-links-link" href="https://api.whatsapp.com/send?phone=+989153221496" target="_blank">
            <img class="icon" src="../../../../assets/images/core/whatsapp.svg" alt="واتساپ">
          </a>
        </li>
        <!-- <li class="footer-links-li">
          <a class="footer-links-link" href="#">
            <img class="icon" src="../../../../assets/images/core/telegram.svg" alt="تلگرام">
          </a>
        </li> -->
      </ul>
    </div>
    <!-- <div class="col-12 col-md-6 col-xl-3 d-flex flex-wrap justify-content-center justify-content-md-start my-3 my-xl-0">
      <a href="#" class="certificates-links">
        <img class="icon" src="../../../../assets/images/site-home/enamd.png" alt="نماد ملی">
      </a>
      <a href="#" class="certificates-links">
        <img class="icon" src="../../../../assets/images/site-home/etehadie.png" alt="نماد ملی">
      </a>
      <a href="#" class="certificates-links">
        <img class="icon" src="../../../../assets/images/site-home/resane.png" alt="نماد ملی">
      </a>
    </div> -->
  </footer>
</div>

<button (click)="onScrollToTop()" class="scroll-top-button" type="button">
  <img class="icon" src="../../../../assets/images/core/left-arrow-grey.svg" alt="رو به بالا">
</button>
