<div *ngIf="!tableLoading" class="row orders-main-row">
  <!-- list of tickets start -->
  <div *ngIf="!tableLoading && listOfTickets.length != 0" class="col-12 tables-main-col">
    <table class="table charisma-table">
      <thead class="table-head">
        <tr class="tr">
          <th class="th" scope="col">#</th>
          <!-- <th class="th" scope="col">شماره تیکت</th> -->
          <th class="th" scope="col">موضوع تیکت</th>
          <th class="th" scope="col">تاریخ ایجاد</th>
          <th class="th" scope="col">وضعیت</th>
          <th class="th" scope="col">مشاهده</th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr *ngFor="let ticket of listOfTickets; let i = index;" class="tr">
          <th class="th" scope="row">{{ i + 1 }}</th>
          <!-- <td class="td">474747</td> -->
          <td class="td">{{ ticket.title }}</td>
          <td class="td">
            <span class="pr-2">{{ticket.createAt | jalali}} </span>
            <!-- <span>{{ticket.createAt| date:"hh:MM:ss"}}</span> -->
          </td>
          <td class="td">
            <label
              [ngClass]="{'pendding-for-check-status': ticket.status == 1, 'pendding-for-answer-status': ticket.status == 2, 'answered-status': ticket.status == 3, 'customer-answer-status': ticket.status == 4, 'closed-status': ticket.status == 5}">
              {{ticket.status | ticketStatus}}
            </label>

            <!-- <label class="pendding-for-answer-status">در حال بررسی</label> -->
            <!-- <label class="answered-status">پاسخ داده شده</label> -->
            <!-- <label class="customer-answer-status">پاسخ مشتری</label> -->
            <!-- <label class="closed-status">بسته شده</label> -->
          </td>
          <td class="td actions-td">
            <a [routerLink]="['/user-club/user-profile/support/chat/' + ticket.id]" class="actions-link">
              <img class="actions-icon" src="../../../../assets/images/core/down-arrow.svg" alt="جزییات">
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- list of tickets end -->
  <div class="col-12">
    <button data-toggle="modal" data-target="#ticketModal" (click)="onOpenModal()" class="add-new-address-button"
      type="button">
      <span>افزودن تیکت جدید</span>
      <img class="add-icon" src="../../../../assets/images/core/plus.svg" alt="افزودن تیکت">
    </button>
  </div>
</div>

<div *ngIf="tableLoading" class="profile-loading">
  <img class="profile-loading-gif" src="../../../../assets/images/core/carisma-logo-top-with-typo.png" alt="بارگذاری">
</div>

<!-- add new ticket modal start  -->
<div class="modal" id="ticketModal" tabindex="-1" role="dialog" aria-labelledby="ticketModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body main-containers">
        <form [formGroup]="ticketInfo">
          <div class="row">
            <div class="col-12 add-new-address-title-col">
              <label class="add-new-address-modal-title">
                افزودن تیکت جدید
              </label>
              <svg class="address-svg-line">
                <line class="svg-line" x1="0" y1="0" x2="1000" y2="0" />
              </svg>
            </div>
            <div class="col-12 col-md-12 col-xl-6">

              <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'عنوان تیکت خود را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input '" [maxLength]="500"
                [control]="ticketInfo.get('title')" [id]="'title'" [name]="'title'" [formGroup]="ticketInfo"
                [label]="'عنوان تیکت'">
              </app-form-input>

            </div>
            <div class="col-12 col-md-12 col-xl-6">

              <app-form-ng-select [formGroupClass]="'form-group form-group-custome basket-form-group-custome'"
                [placeholder]="'لطفا بخش مورد نظر خود را انتخاب کنید'" [label]="'انتخاب دپارتمان'"
                [labelCssClass]="'form-group-custome-label'" [formGroup]="ticketInfo"
                [control]="ticketInfo.get('department')" [multiple]="false" [name]="'department'" [id]="'department'"
                [bindLabel]="'name'" [bindValue]="'status'" [items]="department" [closeOnSelect]="true">
              </app-form-ng-select>

            </div>
            <div class="col-12">

              <app-form-textarea [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'متن کامل تیکت را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
                [textareaCssClass]="' form-control form-group-custome-textarea '" [control]="ticketInfo.get('text')"
                [id]="'text'" [name]="'text'" [formGroup]="ticketInfo" [cols]="'30'" [rows]="'7'" [label]="'متن تیکت'">
              </app-form-textarea>

            </div>
            <!-- <div class="col-12">
              <img class="address-map" src="../../../assets/images/contact-us/map.png" alt="map">
            </div> -->


            <div class="col-12">
              <button (click)="onCreate()" [disabled]="buttonLoading" class="large-button" type="button">
                <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
                  alt="بارگذاری">
                <span *ngIf="!buttonLoading">ذخیره تیکت</span>
              </button>
              <button class="hidden" type="button" #closebutton data-dismiss="modal"></button>
            </div>


          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- add new ticket modal end  -->
