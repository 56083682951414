import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-successfull-payment',
  templateUrl: './successfull-payment.component.html',
  styleUrls: ['./successfull-payment.component.less']
})
export class SuccessfullPaymentComponent implements OnInit {

  refId: string;
  headerTitle: string = 'کاریزماکالر | پرداخت موفق'

  constructor(
    private activeRoute: ActivatedRoute,
    private manageHeader: ManageHeaderService
  ) { }

  ngOnInit(): void {
    this.refId = this.activeRoute.snapshot.params["refId"];
    this.manageHeader.updateTitle(this.headerTitle);
  }

}
