<div [class]="formGroupClass" [formGroup]="formGroup" [ngClass]="extraClass"
  [class.invalid]="control.touched && control.invalid">
  <label *ngIf="label" [class]="labelCssClass">{{label}}</label>
  <textarea (focusout)="onChangeToNumber(control.value)" [placeholder]="placeholder" [attr.name]="name" [attr.id]="id"
    [formControl]="control" [maxlength]="maxLength" [minLength]="minLength" [class]="textareaCssClass"
    autocomplete="off" (blur)="blur()" [attr.cols]="cols" [attr.rows]="rows"></textarea>
  <div class="error-msg" *ngIf="control.errors && control.touched">
    <div>
      {{ label + ' ' + control.errors[objectFn.keys(control.errors)[0]] }}
    </div>
  </div>
</div>
