<!-- <section class="row">
  <div class="col-12 col-xl-6">
    <div class="row">
      <article class="col-12">
        <a href="#" class="main-post-cards">
          <img class="image" src="../../../assets/images/blog/Top-10-Best.jpg" alt="تصویر شاخص مقاله">
          <label class="article-information-text">منتشر شده در تاریخ 1400/17/8</label>
          <h3 class="article-title">جدیدترین مدل های کیف دوشی اسپرت زنانه</h3>
        </a>
      </article>
      <article class="col-6 col-xl-6">
        <a href="#" class="main-post-cards main-post-cards-vertical">
          <img class="image" src="../../../assets/images/blog/dolce.jpg" alt="تصویر شاخص مقاله">
          <label class="article-information-text">منتشر شده در تاریخ 1400/17/8</label>
          <h3 class="article-title">جدیدترین مدل های کیف دوشی اسپرت زنانه</h3>
        </a>
      </article>
      <article class="col-6 col-xl-6">
        <a href="#" class="main-post-cards main-post-cards-vertical">
          <img class="image" src="../../../assets/images/blog/pretty.jpg" alt="تصویر شاخص مقاله">
          <label class="article-information-text">منتشر شده در تاریخ 1400/17/8</label>
          <h3 class="article-title"> جدیدترین مدل های کیف دوشی اسپرت زنانه جدیدترین مدل های کیف دوشی اسپرت زنانه</h3>
        </a>
      </article>
    </div>
  </div>
  <div class="col-12 col-xl-6">
    <div class="row">
      <article class="col-6 col-xl-6">
        <a href="#" class="main-post-cards main-post-cards-vertical">
          <img class="image" src="../../../assets/images/blog/pretty.jpg" alt="تصویر شاخص مقاله">
          <label class="article-information-text">منتشر شده در تاریخ 1400/17/8</label>
          <h3 class="article-title">جدیدترین مدل های کیف دوشی اسپرت زنانه</h3>
        </a>
      </article>
      <article class="col-6 col-xl-6">
        <a href="#" class="main-post-cards main-post-cards-vertical">
          <img class="image" src="../../../assets/images/blog/dolce.jpg" alt="تصویر شاخص مقاله">
          <label class="article-information-text">منتشر شده در تاریخ 1400/17/8</label>
          <h3 class="article-title"> جدیدترین مدل های کیف دوشی اسپرت زنانه جدیدترین مدل های کیف دوشی اسپرت زنانه</h3>
        </a>
      </article>
      <article class="col-12">
        <a href="#" class="main-post-cards">
          <img class="image" src="../../../assets/images/blog/social-holding.jpg" alt="تصویر شاخص مقاله">
          <label class="article-information-text">منتشر شده در تاریخ 1400/17/8</label>
          <h3 class="article-title"> جدیدترین مدل های کیف دوشی اسپرت زنانه جدیدترین مدل های کیف دوشی اسپرت زنانه</h3>
        </a>
      </article>
    </div>
  </div>
</section> -->
<div class="row">
  <div *ngFor="let post of blog.listOfBlogsLatest; let i = index;" class="col"
    [ngClass]="{'col-12 col-xl-6': i == 0 || i == 5, 'col-6 col-xl-3': i == 1 || i == 2 || i == 3 || i == 4}">
    <a [routerLink]="[post.link]" class="main-post-cards"
      [ngClass]="{'main-post-cards-vertical': i == 1 || i == 2 || i == 3 || i == 4, 'mt-90': i == 3 || i == 4}">
      <img *ngIf="post.picture" class="image" [src]="post.picture" alt="تصویر شاخص مقاله">
      <img *ngIf="!post.picture" class="image object-fit-contain"
        src="../../../assets/images/core/not-found-image-1.png" alt="تصویر شاخص مقاله">
      <h3 class="article-title">{{post.title}}</h3>
      <ul class="main-post-info-ul">
        <li class="main-post-info-li">
          <img class="icon" src="../../../assets/images/core/white-pen.svg" alt="نویسنده">
          {{post.userName}}
        </li>
        <li class="main-post-info-li">
          <img class="icon" src="../../../assets/images/core/calendar.svg" alt="تاریخ انتشار">
          {{post.createAt | jalali}}
        </li>
        <li class="main-post-info-li">
          <img class="icon" src="../../../assets/images/core/comment.svg" alt="کامنت ها">
          {{post.commentCount}}
        </li>
      </ul>
    </a>
  </div>
</div>
<section class="row">
  <div class="col-12 col-md-12 col-xl-3">
    <div class="row">
      <div class="col-12 d-flex flex-column">
        <div class="quick-links-header-wrapper">
          <label class="quick-links-header">پربازدید ترین مطالب</label>
          <button [routerLink]="['/blog/category/مد-و-فشن']" class="blog-more-button" type="button">بیشتر</button>
        </div>
        <a [routerLink]="[item.link]" *ngFor="let item of blog.listOfBlogsVisited" class="posts-quick-links">
          <img *ngIf="item.picture" class="image" [src]="item.picture" alt="مقاله">
          <img *ngIf="!item.picture" class="image object-fit-contain"
            src="../../../assets/images/core/not-found-image-1.png" alt="مقاله">
          <h4 class="post-title">{{item.title}}</h4>
        </a>
      </div>
      <!-- <div class="col-12 d-flex flex-column">
        <div class="quick-links-header-wrapper">
          <label class="quick-links-header">آشنایی با برند ها</label>
          <button class="blog-more-button" type="button">بیشتر</button>
        </div>
        <a href="#" class="posts-quick-links">
          <img class="image" src="../../../assets/images/blog/pretty.jpg" alt="مقاله">
          <h4 class="post-title">تاریخچه برند نیوبالانس | فراز و فرودهای برندی به نام نیوبالانس</h4>
        </a>
        <a href="#" class="posts-quick-links">
          <img class="image" src="../../../assets/images/blog/social-holding.jpg" alt="مقاله">
          <h4 class="post-title">تاریخچه برند نیوبالانس | فراز و فرودهای برندی به نام نیوبالانس</h4>
        </a>
        <a href="#" class="posts-quick-links">
          <img class="image" src="../../../assets/images/blog/pretty.jpg" alt="مقاله">
          <h4 class="post-title">تاریخچه برند نیوبالانس | فراز و فرودهای برندی به نام نیوبالانس</h4>
        </a>
        <a href="#" class="posts-quick-links">
          <img class="image" src="../../../assets/images/blog/pretty.jpg" alt="مقاله">
          <h4 class="post-title">تاریخچه برند نیوبالانس | فراز و فرودهای برندی به نام نیوبالانس</h4>
        </a>
      </div> -->
    </div>
  </div>
  <div class="col-12 col-md-12 col-xl-6">
    <div class="row">
      <div class="col-12">
        <h2 class="blog-big-titles">جدیدترین مطالب</h2>
      </div>
    </div>
    <figure *ngFor="let post of blog.listOfBlogsNewest" class="row main-post-with-summery">
      <div class="col-12 col-md-4 p-0">
        <img *ngIf="post.picture" class="post-image" [src]="post.picture" alt="تصویر شاخص مقاله">
        <img *ngIf="!post.picture" class="post-image object-fit-contain"
          src="../../../assets/images/core/not-found-image-1.png" alt="تصویر شاخص مقاله">
      </div>
      <div class="col-12 col-md-8 post-info-container">
        <div class="author d-flex align-items-center my-2">
          <img *ngIf="!post.userPicture" class="author-picture" src="../../../assets/images/core/avatar.jpg"
            alt="نویسنده">
          <img *ngIf="post.userPicture" class="author-picture" [src]="baseUrl + '/image/' + post.userPicture"
            alt="نویسنده">
          <label class="author-name">
            نویسنده :
            {{post.userName}}
          </label>
        </div>
        <h2 [routerLink]="[post.link]" class="post-title">{{post.title}}</h2>
        <p class="post-summery" [innerHTML]="post.content | limitText:150"></p>
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-center my-2">
          <label class="article-date-text align-self-start">منتشر شده در تاریخ {{post.createAt | jalali}}</label>
          <button [routerLink]="[post.link]" type="button" class="medium-button align-self-end">ادامه مطلب</button>
        </div>
      </div>
    </figure>
    <!-- <figure class="row main-post-with-summery">
      <div class="col-12 col-md-4 p-0">
        <img class="post-image" src="../../../assets/images/blog/social-holding.jpg" alt="تصویر شاخص مقاله">
      </div>
      <div class="col-12 col-md-8 post-info-container">
        <div class="author d-flex align-items-center my-2">
          <img class="author-picture" src="../../../assets/images/blog/dolce.jpg" alt="نویسنده">
          <label class="author-name">
            نویسنده :
            مصطفی بخشیان
          </label>
        </div>
        <h2 class="post-title">راهنمای خرید اینترنتی کفش خاب سایز کفش</h2>
        <p class="post-summery">
          امروزه با گسترش فضای مجازی، بسیاری از افراد کسب و کارهای مختلفی را در فضای مجازی شکل در این حوزه بسیار موفق
          عمل…
        </p>
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-center my-2">
          <label class="article-date-text align-self-start">منتشر شده در تاریخ 1400/17/8</label>
          <button type="button" class="medium-button align-self-end">ادامه مطلب</button>
        </div>
      </div>
    </figure>
    <figure class="row main-post-with-summery">
      <div class="col-12 col-md-4 p-0">
        <img class="post-image" src="../../../assets/images/blog/pretty.jpg" alt="تصویر شاخص مقاله">
      </div>
      <div class="col-12 col-md-8 post-info-container">
        <div class="author d-flex align-items-center my-2">
          <img class="author-picture" src="../../../assets/images/blog/dolce.jpg" alt="نویسنده">
          <label class="author-name">
            نویسنده :
            مصطفی بخشیان
          </label>
        </div>
        <h2 class="post-title">زنانه و نحوه انتخاب سایز کفش</h2>
        <p class="post-summery">
          امروزه با گسترش فضای مجازی، بسیاری از افراد کسب و کارهای مختلفی را در فضای مجازی شکل داده اند، که در این بین
          بلاگری یکی از پرطرفدارترین و در حین حال سخت نرین کارهاست،فق
          عمل…
        </p>
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-center my-2">
          <label class="article-date-text align-self-start">منتشر شده در تاریخ 1400/17/8</label>
          <button type="button" class="medium-button align-self-end">ادامه مطلب</button>
        </div>
      </div>
    </figure> -->
  </div>
  <div class="col-12 col-md-12 col-xl-3">
    <div class="row">
      <div class="col-12 d-flex flex-column">
        <div class="quick-links-header-wrapper">
          <label class="quick-links-header">محبوب ترین مطالب</label>
          <button [routerLink]="['/blog/category/مد-و-فشن']" class="blog-more-button" type="button">بیشتر</button>
        </div>
        <a [routerLink]="[item.link]" *ngFor="let item of blog.listOfBlogsPopular" class="posts-quick-links">
          <img *ngIf="item.picture" class="image" [src]="item.picture" alt="مقاله">
          <img *ngIf="!item.picture" class="image" src="../../../assets/images/core/not-found-image-1.png" alt="مقاله">
          <h4 class="post-title">{{item.title}}</h4>
        </a>
      </div>
      <!-- <div class="col-12">
        <img class="advertise-picture" src="../../../assets/images/blog/notif-300-600.jpg" alt="تبلیغات">
      </div> -->
    </div>
  </div>
</section>

<app-page-loading *ngIf="pageLoading"></app-page-loading>
