import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-club',
  templateUrl: './user-club.component.html',
  styleUrls: ['./user-club.component.less']
})
export class UserClubComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
