import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  getVeiwHome() {
    return this.httpConnection.get('store/Site/GetHome');
  }

  getViewNewHome() {
    return this.httpConnection.get('store/Site/GetNewHome');
  }

  getProductByCategoryViewPart(categoryId: string, viewPart: number) {
    return this.httpConnection.get(`store/Site/GetProductByCategoryViewPart/${categoryId}/${viewPart}`);
  }

  getNewFilterClothByCategoryLink(categoryLink: string, queryParams: string) {
    return this.httpConnection.get(`store/Site/GetNewFilterCloth/${categoryLink}?${queryParams}`);
  }


}
