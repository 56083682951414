<div class="container">
  <div class="control">
    <!-- auth header start -->
    <div class="row sign-in-header">
      <div class="col go-back-col">
        <button (click)="onGoBack()" class="btn" type="button">
          <span class="button-span">بازگشت به سایت</span>
          <span class="back-arrow-wrapper">
            <span class="arrows"></span>
            <span class="arrow-line"></span>
            <span class="arrows"></span>
          </span>
        </button>
      </div>
      <div class="carisma-logo-col col">
        <img class="carisma-logo-icon" src="../../../../assets/images/core/Carisma-Logo-Nav.svg" alt="">
      </div>
    </div>
    <!-- auth header end -->
    <div class="body-control row">
      <!-- auth get mobile number start -->
      <div *ngIf="authenticationSteps == 'mobile'" class="form col-12 col-md-4 col-xl-3">
        <form [formGroup]="mobileInfo">
          <div class="form-group form-group-custome">
            <label class="form-group-custome-label sign-in-label">
              ورود به باشگاه
              <span class="sign-in-label-span">مشتریان</span>
            </label>
            <svg class="sign-in-svg-line">
              <line x1="0" y1="0" x2="200" y2="0" style="stroke: rgb(86, 86, 86, 6%);stroke-width:4" />
            </svg>
            <label class="form-group-custome-label sign-in-second-label">برای ورود لطفا تلفن همراهتو تایید کن</label>
            <div class="sign-in-button-container">
              <!-- <input class="form-control form-group-custome-input" type="text" placeholder="مثلا 98  15  587  0912"
                formControlName="mobile" name="mobile" id="mobile"> -->
              <app-form-input (input)="onCheckLength('mobile')" [type]="'text'"
                [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 98 15 587 0912'"
                [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
                [control]="mobileInfo.get('mobile')" [minLength]="3" [maxLength]="2000" [id]="'mobile'"
                [name]="'mobile'" [formGroup]="mobileInfo" [label]="'تلفن همراه'">
              </app-form-input>
              <div class="sign-in-mobile" [formGroup]="recaptchaReactiveInfo">
                <!--
                          the SITE_KEY is the sitekey from your admin console.
                          use form control for validation
                        -->
                <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"
                  siteKey="6LcZ3tUaAAAAAHTvCJlPfJT_1RizXNungVgpuSaF" required>
                </re-captcha>
                <span
                  *ngIf="recaptchaReactiveInfo.controls['recaptchaReactive'].hasError('required') && recaptchaReactiveInfo.get('recaptchaReactive').touched"
                  class="help-block">
                  بر روی من ربات نیستم کلیک کنید
                </span>
              </div>
              <button [disabled]="buttonLoading" (click)="onCreate()" class="carisma-button medium-button">
                <span *ngIf="!buttonLoading" class="button-text-span" id="sign-button">{{ textSign }}</span>
                <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core//loading.gif"
                  alt="">
              </button>
            </div>
          </div>
        </form>
      </div>
      <!-- auth get mobile number end -->
      <!-- auth get password start -->
      <div *ngIf="authenticationSteps == 'password'" class="form col-12 col-md-4 col-xl-3">
        <form [formGroup]="passwordInfo">
          <div class="form-group form-group-custome">
            <label class="sign-in-label">
              ورود به باشگاه
              <span class="sign-in-label-span">مشتریان</span>
            </label>
            <svg class="sign-in-svg-line">
              <line x1="0" y1="0" x2="200" y2="0" style="stroke: rgb(86, 86, 86, 6%);stroke-width:4" />
            </svg>
            <label class="sign-in-second-label">کد پیامک شده رو تو کادر زیر وارد کن</label>
            <span class="carisma-policy-span">ورود به منزله قبول
              <span class="carisma-policy-span-brakets">[</span>
              <span (click)="onOpenModal()" class="carisma-policy-link" data-toggle="modal"
                data-target="#exampleModal">قوانین</span>
              <span class="carisma-policy-span-brakets">]</span>
              کاریزما است
            </span>
            <div class="verify-password-buttons-container">

              <app-form-input (input)="onCheckLength('password')" [type]="'text'"
                [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 3475'"
                [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
                [control]="passwordInfo.get('password')" [minLength]="3" [maxLength]="2000" [id]="'password'"
                [name]="'password'" [formGroup]="passwordInfo" [label]="'کلمه عبور'">
              </app-form-input>

              <div class="w-100 d-flex justify-content-between">
                <button (click)="onCorrectNumber()" [disabled]="buttonLoading && sectionLoading != 'correctNumber'"
                  class="carisma-btton medium-button grey-button mr-3">
                  <span class="button-text-span">
                    اصلاح شماره
                  </span>
                  <!-- <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core//loading.gif"
                    alt=""> -->
                </button>

                <button (click)="onVerify()" [disabled]="buttonLoading" class="carisma-btton medium-button">
                  <span *ngIf="!buttonLoading && sectionLoading != 'verifyCode'" class="button-text-span">ورود</span>
                  <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core//loading.gif"
                    alt="">
                </button>
              </div>

            </div>
            <button *ngIf="!isShowTimer" class="carisma-button large-button send-message-again-button"
              type="button">ارسال دوباره کد</button>
          </div>
        </form>
      </div>
      <!-- auth get password end -->
      <!-- auth image container start -->
      <div class="image justify-content-md-center  col-10 offset-1 col-sm-12 col-md-8 offset-md-0 col-xl-7 col-xl-8">
        <img *ngIf="authenticationSteps == 'mobile'" class="img" src="../../../../assets/images/sign-in/Group 700.png"
          alt="">
        <img *ngIf="authenticationSteps == 'password'" class="img" src="../../../../assets/images/sign-in/Group 785.png"
          alt="">
      </div>
      <!-- auth image container end -->
    </div>
  </div>
</div>


<!-- carisma rules Modal start -->
<div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content sign-in-modal-content">
      <div class="modal-body d-flex flex-column">
        <h3 class="policy-header">ضوابط و قوانین</h3>
        <svg class="sign-in-modal-svg-line">
          <line class="svg-line" x1="0" y1="0" x2="2000" y2="0" />
        </svg>
        <p class="sign-in-modal-text">
          آسودگی خاطر و اطمینان و خریدی باب میل در حوزه لباس برای مشتریان ، اولین رسالت کاریزماکالر است و کاریزماکالر در
          این راستا می‏‌کوشد تا هر سفارش در شرایط مطلوب و مورد انتظار به دست مشتری برسد. با وجود این ممکن است مشتریان
          محترم پس از خرید، با مسایلی روبرو شوند که درچنین مواردی خدماتی در چارچوب خدمات پس از فروش در نظر گرفته شده
          است. برای بسیاری از محصولات، کاریزماکالر ۱۴ روز فرصت برای بازگشت درنظر گرفته است.
          <br>
          <strong>شرایط بازگرداندن کالا</strong>
          <br>
          استفاده از ۱۴ روز ضمانت بازگشت چه شرایطی دارد؟
          <br>
          اگر کالای خریداری شده، ایراد، زدگی یا پارگی داشته باشد.
          <br>
          - در صورتی که کارشناسان ما ایراد کالا را تایید نکنند، هزینه ارسال کالا به مشتری قابل بازگشت نخواهد بود.
          <br>
          اگر کالای خریداری شده از نظر مشخصات یا ظاهر با اطلاعات وب سایت مغایرت داشته باشد.
          <br>
          - اگر مغایرت، بدون استفاده از کالا قابل مشاهده است، مثل رنگ یا مشخصات درج شده روی آن، باید کالا در شرایط اولیه
          خود باشد و از آن استفاده نشده باشد.
          <br>
          - در صورتی که امکان تشخیص مغایرت تنها با باز کردن بسته بندی ممکن باشد، لازم است کارتن و جعبه اصلی محصولات
          نگهداری شود و از دور ریختن آن جداً خودداری شود.
          <br>
          استفاده از این سرویس تنها در صورتی امکان‌پذیر است که کالا در کارتن یا جعبه اصلی خود به کاریزماکالر بازگردانده
          شود. و اتیکت روی اجناس به هیچ عنوان نباید کنده شود. برچسب زدن یا نوشتن توضیحات، آدرس یا هر مورد دیگری روی
          کارتن یا جعبه اصلی کالا و یا پاره و مخدوش کردن آن، امکان استفاده از ضمانت بازگشت را از بین خواهد برد.
          <br>
          <strong>اگر مشتری از خرید خود منصرف شود.</strong>
          <br>
          - اگر هنوز سفارش ارسال نشده باشد، باید هر چه سریع‏‌تر به واحد پیگیری سفارش کاریزماکالر (شماره تلفن
          09153221496
          ) اطلاع داده شود.
          <br>
          - اگر پس از دریافت کالا مشتری از خرید خود منصرف شود، حداکثر تا ۱۴ روز‏، باید انصراف خود را به واحد خدمات پس از
          فروش اطلاع دهد.
          <br>
          - در این حالت، برگرداندن کالا پس از تایید کارشناس خدمات پس از فروش، تنها در صورتی امکان‌پذیر است که در شرایط
          اولیه خود (پلمپ) باشد، از آن استفاده نشده باشد. همچنین اگر کالا به‌ همراه هدیه فروخته شده باشد، بازگرداندن
          هدیه همراه آن نیز الزامی است. لازم به ذکر است کالاهایی که به دلیل ماهیت خاص یا استفاده شخصی و با توجه به لزوم
          رعایت مسایل بهداشتی نمی‌توانند بازپس داده شوند، از شمول این بند خارج هستند.
          <br>
          - لطفاً توجه داشته باشید که هرگونه تغییر در شرایط اولیه کالا، امکان استفاده از این سرویس را از بین خواهد برد.
          <br>
          <strong>اگر سایز کالا مناسب نباشد.</strong>
          <br>
          - درخواست بازگشت کالا تنها در صورتی پذیرفته می‌شود که کفش یا پوشاک تنها تست شده باشند و در وضعیت اولیه و نو
          باشند، و روی پوشاک هیچگونه آثار استفاده مانند لکه، رنگ پریدگی یا بوی عطر و بدن وجود نداشته باشد، و برچسب،
          مارک، دکمه و سایر ملحقات جدا نشده باشد.
          <br>
          <strong>وضعیت تحویل کالاهای قابل بازگشت چگونه باید باشد ؟</strong>
          <br>
          - انواع کفش ها در شرایطی که کفی کفش سالم و تمیزبوده ،خط خوردگی و تا شدگی و از بین رفتن بوی طبیعی کفش اتفاق
          نیفتاده باشد، قابل بازگشت هستند.
          <br>
          - انواع کیف ها در شرایطی که کیف کاملا سالم و به همراه تمامی پلمپ های پلاستیکی دسته و بند و حجم دهنده های درون
          آن باشد، قابل بازگشت هستند.
          <br>
          - پوشیدنیهای پارچه ای که محدودیت بهداشتی جهت بازگشت ندارند، در صورت تایید کارشناس و در شرایط نو به معنی عدم
          باقی ماندن بو، چروک، از بین رفتن خط های تا محصول و نداشتن لکه و همراه با برچسب، مارک، دکمه و سایر ملحقات آن ،
          پذیرفته خواهند شد.
          <br>
          - محصولات چرمی که محدودیت بهداشتی جهت بازگشت ندارند، در شرایطی پذیرفته می شوند که کارشناسان ما عدم باقی ماندن
          چروک ناشی از استفاده، بو و برخورد با مواد مخرب چرم را تایید نمایند.
          <br>
          - لطفاً قبل از هر اقدامی با کارشناسان پشتیبانی خدمات پس از فروش تماس بگیرید.
          <br>
          - از ارسال کالا بدون هماهنگی با خدمات پس از فروش کاریزماکالر جداً، خودداری کنید.
          <br>
          - برای ارسال، باید کالا در جعبه یا کارتن اصلی خود به ‏خوبی بسته‌بندی شود.
          <br>
          - برچسب زدن یا نوشتن توضیحات، آدرس یا هر مورد دیگری روی کارتن یا جعبه اصلی کالا و یا پاره و مخدوش کردن آن،
          امکان استفاده از ضمانت بازگشت را از بین خواهد برد. (در صورت لزوم، توضیحات خود را پشت فاکتور خرید یا قطعه کاغذ
          جداگانه‌ای بنویسید.)
          <br>
          - در صورتی که ساکن سایر شهرها هستید ، میتوانید کالای خود را بصورت پس کرایه پست کنید ، در صورتی که امکان پس
          کرایه در شهر شما وجود نداشت بعد از ارسال کالا رسید پستی مهر شده را به واتس اپ شماره 09153221496 ارسال نمایید.
          <br>
          - از آنجا که واریز هزینه ارسال، منوط به دریافت رسید پستی است، میبایست عکس از رسید پستی به واتس اپ شماره
          09153221496 ارسال نمایید.
          <br>
          - اگر در یکی از شهرهای اکسپرس هستید، برای هماهنگی ارسال پیک برای تحویل گرفتن کالا، با کاریزماکالر تماس بگیرید.
          <br>
          - از نوشتن آدرس روی جعبه اصلی کالا، جداً خودداری کنید و در صورت لزوم ، جعبه اصلی محصول را درون کارتن دیگری
          گذاشته، آن را ارسال کنید.
          <br>
          - بسته خود را به آدرس زیر ارسال کنید.
          <br>
          مشهد - خیابان راهنمایی - راهنمایی 6 - پلاک 4 - زنگ پایین سمت چپ
          <br>
          <strong>کدام کالاها قابل بازگشت نیستند؟</strong>
          <br>
          - زیورآلات و اکسسوری ها مانند گوشواره و اکسسوری های مشابه که تماس مستقیم با پوست دارند امکان بازگشت سلیقه ای
          ندارند.
          <br>
          - لباس های زیر مانند شورت به دلیل مسایل بهداشتی قابل بازگشت نیستند.
          <br>
          <strong>هزینه ارسال چطور محاسبه خواهد شد؟</strong>
          <br>
          - هزینه مرجوعی کالا توسط کاریزماکالر پرداخت خواهد شد.
          <br>
          <strong>پس از رسیدن و دریافت کالا توسط خدمات پس از فروش، روند رسیدگی به درخواست مشتری چگونه خواهد
            بود؟</strong>
          <br>
          - پس از دریافت و پذیرش کالا توسط کاریزماکالر ، بسته به نوع محصول و مشکل اعلام شده توسط مشتری، تست و اعلام نظر
          نهایی کارشناسان خدمات پس از فروش، تا حداکثر ۴۸ ساعت کاری زمان خواهد برد.
          <br>
          - ملاک عمل و اقدام شرکت، نظر کارشناسان پشتیبانی و خدمات پس از فروش کاریزماکالر است.
          <br>
          - کارشناسان خدمات پس از فروش کاریزماکالر ایرادهای اعلام شده توسط مشتری را بررسی و کنترل می‌‏کنند. اگر ایراد،
          مغایرت یا آسیب‌دیدگی توسط کارشناسان کاریزماکالر تایید شود،کالا تعویض خواهد شد و یا مشتری می‌تواند کالای خود را
          با کالای دیگری جایگزین کند.
          <br>
          لازم به ذکر است که تعویض کالا منوط و مشروط به موجود بودن کالا نزد فروشنده مربوطه است. اگر در زمان رسیدگی و طی
          مراحل تست کارشناسی و اقدامات لازم برای تعویض کالا، موجودی آن به اتمام برسد؛ کاریزماکالر بنا به انتخاب مشتری
          کالای دیگری را ارسال می‌کند و یا وجه کالای مرجوعی را به حساب مشتری واریز می‌کند. بدیهی است که در صورت انتخاب
          کالای دیگر، تفاوت قیمت کالای جدید با کالای مرجوعی (مبلغ درج شده در فاکتور) محاسبه و قبل از ارسال تسویه می‌شود.
          برای کالای گران‌تر، مشتری تفاوت را پرداخت می‌کند و برای کالای ارزان‌تر، کاریزماکالر تفاوت را به حساب مشتری
          واریز می‌کند.
          <br>
          - اتمام موجودی کالا و یا تغییرات قیمت کالا در مراحل تعویض و مرجوعی، به هیچ عنوان حقی را برای مشتری ایجاد
          نمی‌کند و کاریزماکالر در قبال تهیه همان کالا و یا تغییرات قیمت کالا هیچ‌گونه تعهدی نداشته و فقط تعهد بازپرداخت
          وجه کالا -درج شده در فاکتور- به مشتری را می‌پذیرد.
          <br>
          - پس از دریافت و پذیرش کالا توسط کاریزماکالر ، بسته به نوع محصول و مشکل اعلام شده توسط مشتری، تست و اعلام نظر
          نهایی کارشناسان خدمات پس از فروش، تا حداکثر یک هفته زمان خواهد برد.
          <br>
          - از هنگامی که وضعیت کالا در واحد خدمات پس از فروش، مشخص شود، اگر نیاز به استرداد وجه باشد، حداکثر تا ۷۲ ساعت
          کاری مبلغ به حساب مشتری واریز خواهد شد.
          <br>
          - ملاک عمل و اقدام شرکت، نظر کارشناسان پشتیبانی و خدمات پس از فروش کاریزماکالر است.
          <br>
          <strong>درخواست خود را چطور به خدمات پس از فروش اطلاع دهید؟</strong>
          <br>
          - تماس با شماره تلفن 05138473205 یا 09153221496
          <br>
          <strong>حریم خصوصی</strong>
          <br>
          کاریزماکالر ضمن احترامی که برای حریم شخصی کاربران قائل است، برای خرید، ثبت نظر یا استفاده از برخی امکانات وب
          سایت اطلاعاتی را از کاربران درخواست می‌کند تا بتواند خدماتی امن و مطمئن را به کاربران ارائه دهد. برای پردازش و
          ارسال سفارش، اطلاعاتی مانند آدرس، شماره تلفن مورد نیاز است و از آنجا که کلیه فعالیت‌های کاریزماکالر قانونی و
          مبتنی بر قوانین تجارت الکترونیک صورت می‌گیرد و طی فرایند خرید، فاکتور رسمی و بنا به درخواست مشتریان حقوقی
          گواهی ارزش افزوده صادر می‌شود، از این رو وارد کردن اطلاعاتی مانند نام و کد ملی برای اشخاص حقیقی یا کد اقتصادی
          و شناسه ملی برای خریدهای سازمانی لازم است. همچنین آدرس ایمیل و تلفن‌هایی که مشتری در پروفایل خود ثبت می‌کند،
          تنها آدرس ایمیل و تلفن‌های رسمی و مورد تایید مشتری است و تمام مکاتبات و پاسخ‌های شرکت از طریق آنها صورت
          می‌گیرد.
          <br>
          بنابراین درج آدرس، ایمیل و شماره تماس‌های همراه و ثابت توسط مشتری، به منزله مورد تایید بودن صحت آنها است و در
          صورتی که این موارد به صورت صحیح یا کامل درج نشده باشد، کاریزماکالر جهت اطمینان از صحت و قطعیت ثبت سفارش
          می‌تواند از مشتری، اطلاعات تکمیلی و بیشتری درخواست کند.
          <br>
          مشتریان می‌توانند نام، آدرس و تلفن شخص دیگری را برای تحویل گرفتن سفارش وارد کنند و کاریزماکالر تنها برای ارسال
          همان سفارش، از این اطلاعات استفاده خواهد کرد.
          <br>
          همچنین کاریزماکالر ممکن است برای ارتباط با مشتریان، بهینه سازی محتوای وب سایت و تحقیقات بازاریابی از برخی
          اطلاعات جمع آوری شده از کاربران استفاده کند. این اطلاعات علاوه بر موارد اشاره شده در بالا که پس از ایجاد حساب
          کاربری اخذ می شود شامل این موارد نیز می باشد: نوع دستگاه، زبان و نوع سیستم عامل دستگاه شما، شناسه اندروید و
          شناسه تبلیغاتی اندروید ویژه دستگاه شما، جست‌وجوهای انجام شده در وب سایت کاریزماکالر و نتایج انتخاب‌شده، مدت و
          بازه های استفاده شما از سایت، برنامه های نصب شده، اطلاعات فنی و گزارش‌های سرور شامل آدرس پروتکل اینترنت (IP)،
          نوع شبکه و نوع و نسخه مرورگر، اطلاعات مهم دستگاه هنگام وقفه‌ها (Crashes) شامل وضعیت روت بودن یا نبودن دستگاه،
          میزان کل رم و میزان فضای پر آن هنگام وقفه، روز و ساعت رخدادها، خاموش یا روشن بودن شبکه بی‌سیم، خاموش یا روشن
          بودن شبکه دیتا، خاموش یا روشن بودن وضعیت رومینگ .
          <br>
          این اطلاعات در حد ضرورت و متناسب با مقاصدی از جمله مطالعه علت گزارش خطا و بهبود اشکالات سیستمی و تجربه کاربری
          در هنگام استفاده از وبسایت کاریزماکالر و نیز تحقیقات بازاریابی و ارائه خدمات و پیشنهادات خرید مرتبط تر به
          کاربران جمع آوری می شود و به نحوی مورد استفاده قرار می‌گیرند که به طور مستقیم به یک شخص حقیقی مشخص و معین
          مرتبط نباشند.
          <br>
          برای اطلاع رسانی رویدادها و اخبار، خدمات و سرویس‌های ویژه یا پروموشن‌ها، برای اعضای وب سایت ایمیل یا پیامک
          ارسال نماید. در صورتی که کاربران تمایل به دریافت اینگونه ایمیل و پیامک‌ها نداشته باشند، می‌توانند عضویت دریافت
          خبرنامه کاریزماکالر را در پروفایل خود لغو کنند.
          <br>
          کاریزماکالر ممکن است نقد و نظرهای ارسالی کاربران را در راستای رعایت قوانین وب سایت ویرایش کند. همچنین اگر نظر
          یا پیام ارسال شده توسط کاربر، مشمول مصادیق محتوای مجرمانه باشد، کاریزماکالر می‌تواند از اطلاعات ثبت شده برای
          پیگیری قانونی استفاده کند.
          <br>
          حفظ و نگهداری رمز ورود بر عهده کاربران است و برای جلوگیری از هرگونه سوء استفاده احتمالی، کاربران نباید آن را
          برای شخص دیگری فاش کنند. کاریزماکالر هویت شخصی کاربران را محرمانه می‌داند و اطلاعات شخصی آنان را به هیچ شخص یا
          سازمان دیگری منتقل نمی‌کند، مگر اینکه با حکم قانونی مجبور باشد در اختیار مراجع ذی‌صلاح قرار دهد.
          <br>
          همچنین بدین وسیله به اطلاع کاربران رسانده می‌شود که کاریزماکالر همانند سایر وب‌سایت‌ها از جمع‌آوری IP و
          کوکی‌ها استفاده می‌کند، کوکی فایلی است که به درخواست یک سایت، توسط مرورگر ایجاد می‌شود و به سایت امکان ذخیره
          بازدید‌های شما و مناسب‌سازی آنها را فراهم می‌نماید. بسیاری از مرورگرها امکان غیرفعال کردن کوکی را فراهم
          کرده‌اند، به این منظور می‌توانید راهنمای مرورگرها را مطالعه کنید. اما پروتکل، سرور و لایه‌های امنیتی
          کاریزماکالر و روش‌های مناسب مدیریت داده‌ها اطلاعات کاربران را محافظت و از دسترسی‌های غیر قانونی جلوگیری
          می‌کند.
          <br>
          <strong>شرایط و قوانین استفاده از سرویس‌ها و خدمات کاریزماکالر</strong>
          <br>
          کاربر گرامی لطفاً موارد زیر را جهت استفاده بهینه از خدمات و برنامه‌‏های کاربردی کاریزماکالر به دقت ملاحظه
          فرمایید.
          <br>
          ورود کاربران به وب‏‌سایت کاریزماکالر هنگام استفاده از پروفایل شخصی، طرح‏‌های تشویقی وسایر خدمات ارائه شده توسط
          کاریزماکالر به معنای آگاه بودن و پذیرفتن شرایط و قوانین و همچنین نحوه استفاده از سرویس‌‏ها و خدمات کاریزماکالر
          است. توجه داشته باشید که ثبت سفارش نیز در هر زمان به معنی پذیرفتن کامل کلیه شرایط و قوانین کاریزماکالر از سوی
          کاربر است.
          <br>
          <strong>قوانین عمومی</strong>
          <br>
          توجه داشته باشید کلیه اصول و رویه‏‌های کاریزماکالر منطبق با قوانین جمهوری اسلامی ایران، قانون تجارت الکترونیک
          و قانون حمایت از حقوق مصرف کننده است و متعاقبا کاربر نیز موظف به رعایت قوانین مرتبط با کاربر است. در صورتی که
          در قوانین مندرج، رویه‏‌ها و سرویس‏‌های کاریزماکالر تغییراتی در آینده ایجاد شود، در همین صفحه منتشر و به روز
          رسانی می شود و شما توافق می‏‌کنید که استفاده مستمر شما از سایت به معنی پذیرش هرگونه تغییر است.
          <br>
          <strong>شرایط و قوانین خرید</strong>
          <br>
          در صورت خرید اعتباری (استفاده از کد تخفیف یا ووچر) پس از نهایی شدن سبد، از نظر قانونی امکان اعمال تغییر در سبد
          خرید، و تغییر مبلغ فاکتور به دلایل انصراف از خرید، حذف یا تغییر کالا یا تعداد کالا وجود ندارد و اگر مشتری
          درخواست هرگونه تغییری را داشته باشد، ووچر یا کد تخفیف باطل خواهد شد و امکان برگرداندن مبلغ آن وجود ندارد.
          <br>
          در صورتی‌که کاریزماکالر تخفیف به شرط خرید تا سقف خاصی را اعلام کرد (Discount Voucher)، میبایست سقف مبلغ خرید
          جهت استفاده ووچر رعایت شود ، در صورتی که به هردلیلی درخواست حذف کالایی از سبد خرید را داشته باشید و مبلغ سفارش
          از سقف تعیین شده پایین تر بیاید تخفیف ووچر از بین خواهد رفت.
        </p>
        <button #closebutton data-dismiss="modal" class="hidden" type="button"></button>
      </div>
    </div>
  </div>
</div>
<!-- carisma rules Modal end -->
