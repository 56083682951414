import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// caches.keys().then(function (cachesNames) {
//   console.log("Delete " + document.defaultView.location.origin + " caches");
//   return Promise.all(cachesNames.map(function (cacheName) {
//     return caches.delete(cacheName).then(function () {
//       console.log("Cache with name " + cacheName + " is deleted");
//     });
//   }))
// }).then(function () {
//   console.log("All " + document.defaultView.location.origin + " caches are deleted");
// });

document.addEventListener('DOMContentLoaded', () => {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                               platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
                                                                                                                                                                                                                                                                                                                                                                                                                                                                             });
