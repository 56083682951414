import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';
import { Status } from 'src/app/shared/interfaces/status';
import { UserAddress } from 'src/app/shared/interfaces/address';

@Injectable({
  providedIn: 'root'
})
export class AddressApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  getViewAdress() {
    return this.httpConnection.get('customer/CustomerAddress/GetViewAddresses')
  }

  getlistOfAdresses() {
    return this.httpConnection.get('customer/CustomerAddress/GetListOfAddresss')
  }

  postCreateAddress(addressInfo: UserAddress) {
    return this.httpConnection.post('customer/CustomerAddress/PostCreateAddress', addressInfo)
  }

  putUpdateAddress(addressInfo: UserAddress) {
    return this.httpConnection.post('customer/CustomerAddress/PutUpdateAddress', addressInfo)
  }

  postChangeAddressStatus(status: Status) {
    return this.httpConnection.post('customer/CustomerAddress/ChangeAddressStatus', status)
  }

}
