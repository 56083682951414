import { HttpConnectionService } from './http-connection.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  postCreateUpdateCustomerAccount(login: any) {
    return this.httpConnection.post("api/AuthCustomer/CreateUpdateCustomerAccount", login);
  }

  postVerifyByPasswordCustomerAccount(login: any) {
    return this.httpConnection.post("api/AuthCustomer/VerifyByPasswordCustomerAccount", login)
  }

  posVerifyToken(token: string) {
    return this.httpConnection.post('auth/Captcha/TokenValidate', { token: token });
  }

}
