import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateDiscount'
})
export class CalculateDiscountPipe implements PipeTransform {

  transform(value: unknown, price: number, discount: number, count: number): unknown {
    return (price - ((discount / 100) * price)) * count;
  }

}
