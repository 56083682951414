<div class="row contact-us-main-row components-main-padding">
  <div class="col-12 col-md-11 col-xl-7">
    <!-- contact us main links start -->
    <div class="row contact-us-sub-row">
      <div class="col-12 col-md-4 col-xl-4 contact-us-numbers-cols">
        <!-- <a href="https://t.me/carismacolor" target="_blank" class="contact-us-numbers-wrapper">
          <div class="labels-containers">
            <label class="pink-label">پشتیبانی تلگرام</label>
            <img class="phone-icon" src="../../../assets/images/core/telegram-pink.svg">
          </div>
          <label class="phone-number-label">برای پیام در تلگرام کلیک کنید</label>
        </a> -->
        <a href="https://www.instagram.com/carismacolor" target="_blank" class="contact-us-numbers-wrapper">
          <div class="labels-containers">
            <label class="pink-label">لینک اینستاگرام کاریزما</label>
            <img class="phone-icon" src="../../../assets/images/core/instagram-logo.svg" alt="اینستاگرام">
          </div>
          <label class="phone-number-label">برای ورورد به اینستاگرام کلیک کنید</label>
        </a>
      </div>
      <div class="col-12 col-md-4 col-xl-4 contact-us-numbers-cols">
        <a href="https://api.whatsapp.com/send?phone=+989153221496" target="_blank" class="contact-us-numbers-wrapper">
          <!-- <a href="https://wa.me/+989153221496" target="_blank" class="contact-us-numbers-wrapper"> -->
          <div class="labels-containers">
            <label class="pink-label">پشتیبانی واتساپ</label>
            <img class="phone-icon" src="../../../assets/images/core/whatsapp-pink.svg" alt="واتساپ">
          </div>
          <label class="phone-number-label">برای پیام در واتساپ کلیک کنید</label>
        </a>
      </div>
      <div class="col-12 col-md-4 col-xl-4 contact-us-numbers-cols">
        <a href="https://www.carismacolor.com" target="_blank" class="contact-us-numbers-wrapper">
          <div class="labels-containers">
            <label class="pink-label">لینک مجله کاریزما</label>
            <img class="phone-icon" src="../../../assets/images/core/world-wide-web.svg" alt="وبلاگ کاریزما کالر">
          </div>
          <label class="phone-number-label">www.CarismaColor.com</label>
        </a>
      </div>
    </div>
    <!-- contact us main links end -->
    <div class="row">
      <!-- carisma address map start -->
      <div class="col-12 map-image-col">
        <img class="map-image" src="../../../assets/images/core/carisma-address.png" alt="آدرس کاریزما">
      </div>
      <!-- carisma address map end -->
    </div>
    <div class="row justify-content-center">
      <!-- carisma address in text start -->
      <div class="col-12 col-md-12 col-xl-12 contact-us-address-col">
        <div class="contact-us-address-wrapper">
          <label class="contact-us-address">
            <img class="icons" src="../../../assets/images/core/location.svg" alt="آدرس کاریزما">
            <span>ایران ، مشهد ، خیابان احمدآباد ، خیابان راهنمایی ، راهنمایی 6 ، پلاک 4</span>
          </label>
        </div>
      </div>
      <!-- carisma address in text end -->
    </div>
  </div>
  <div class="col-12 col-md-11 col-xl-4 ">
    <div class="main-containers">
      <div class="sections-titles-wrapper sections-titles-wrapper-centered">

        <div class="title-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="30.495" height="29.883" viewBox="0 0 30.495 29.883">
            <path id="Union_1" data-name="Union 1"
              d="M29.484,29.9h-15v-.036H-1.009v0a15.815,15.815,0,0,0,15.5-15.746v.6a15.827,15.827,0,0,1,15-14.7V29.9h0Z"
              transform="translate(1.009 -0.017)" fill="#fff" />
          </svg>

          <div class="sections-title-label">
            ارسال پیام به پشتیبانی
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" width="30.747" height="29.883" viewBox="0 0 30.747 29.883">
            <path id="Union_1" data-name="Union 1"
              d="M30.493,29.883a15.772,15.772,0,0,1-15-14.689v.585A15.767,15.767,0,0,0-.252.036H15.494V0h15V29.882h0Z"
              transform="translate(30.495 29.883) rotate(180)" fill="#fff" />
          </svg>
        </div>

      </div>
      <!-- contact us form start -->
      <div class="row">
        <div class="col-12 col-md-6 col-xl-12">
          <form [formGroup]="contactUs">
            <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
              [placeholder]="'مثلا محمد محمدی'" [labelCssClass]="'form-group-custome-label'"
              [inputCssClass]="' form-control form-group-custome-input '" [control]="contactUs.get('fullName')"
              [minLength]="3" [maxLength]="2000" [id]="'fullName'" [name]="'fullName'" [formGroup]="contactUs"
              [label]="'نام و نام خانوادگی'">
            </app-form-input>

            <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
              [placeholder]="'مثلا 09358965812'" [labelCssClass]="'form-group-custome-label'"
              [inputCssClass]="' form-control form-group-custome-input '" [control]="contactUs.get('mobile')"
              [minLength]="3" [maxLength]="2000" [id]="'mobile'" [name]="'mobile'" [formGroup]="contactUs"
              [label]="'شماره موبایل'">
            </app-form-input>

            <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
              [placeholder]="'مثلا پیراهن کد 311 چه زمانی موجود میشود'" [labelCssClass]="'form-group-custome-label'"
              [inputCssClass]="' form-control form-group-custome-input '" [control]="contactUs.get('title')"
              [minLength]="3" [maxLength]="2000" [id]="'title'" [name]="'title'" [formGroup]="contactUs"
              [label]="'عنوان پیام'">
            </app-form-input>
          </form>

        </div>
        <form [formGroup]="contactUs" class="col-12 col-md-6 col-xl-12">
          <app-form-textarea [formGroupClass]="'form-group form-group-custome'" [placeholder]="'یک پیام وارد کنید'"
            [labelCssClass]="'form-group-custome-label'"
            [textareaCssClass]="' form-control form-group-custome-textarea '" [control]="contactUs.get('text')"
            [minLength]="3" [maxLength]="500" [id]="'text'" [name]="'text'" [formGroup]="contactUs" [label]="'متن پیام'"
            [cols]="'10'" [rows]="'7'">
          </app-form-textarea>
        </form>
        <div class="col-12 d-flex justify-content-center my-3">
          <div [formGroup]="recaptchaReactiveInfo">
            <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"
              siteKey="6LcZ3tUaAAAAAHTvCJlPfJT_1RizXNungVgpuSaF" required>
            </re-captcha>
            <span
              *ngIf="recaptchaReactiveInfo.controls['recaptchaReactive'].hasError('required') && recaptchaReactiveInfo.get('recaptchaReactive').touched"
              class="help-block">
              روی من ربات نیستم کلیک کنید
            </span>
          </div>
        </div>
      </div>
      <!-- contact us form end -->
      <div class="d-flex justify-content-center align-items-center mt-2">
        <button (click)="onCreate()" [disabled]="buttonLoading" class="large-button tablet-large-button" type="button">
          <span *ngIf="!buttonLoading">{{ textContact }}</span>
          <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
            alt="بارگذاری">
        </button>
      </div>

    </div>
  </div>
</div>
