import { AuthServiceApiService } from './../../core/services/api/auth.service.api.service';
import { FormValidatorsService } from './../../shared/validators/form-validators.service';
import { ContactUsApiService } from './../../core/services/api/contact-us-api.service';
import { Helper } from './../../shared/utilities/helper';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.less']
})
export class ContactUsComponent implements OnInit {
  contactUs: FormGroup;
  recaptchaReactiveInfo: FormGroup;
  buttonLoading: boolean = false;
  isCaptchaVerified: boolean = false;
  textContact: string = 'ارسال پیام به پشتیبانی';
  headerTitle: string = 'کاریزماکالر | تماس با ما'

  constructor(
    private helper: Helper,
    private contactUsApiService: ContactUsApiService,
    private formValidators: FormValidatorsService,
    private authApiService: AuthServiceApiService,
    private manageHeader: ManageHeaderService
  ) {
    this.onCreateContactUsForm();
    this.onCreateRecaptchaReactiveForm();
  }

  ngOnInit(): void {
    this.manageHeader.updateTitle(this.headerTitle);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateRecaptchaReactiveForm(): void {
    this.recaptchaReactiveInfo = new FormGroup({
      recaptchaReactive: new FormControl(null)
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateContactUsForm() {
    this.contactUs = new FormGroup({
      id: new FormControl(null),
      fullName: new FormControl(null,
        [
          this.formValidators.requiredValidator('fullName'),
          this.formValidators.minlengthValidator('fullName', 3),
          this.formValidators.maxlengthValidator('fullName', 100)
        ]),
      mobile: new FormControl(null,
        [
          this.formValidators.isMobileReg('mobile'),
          this.formValidators.onlyNumber('mobile'),
          this.formValidators.requiredValidator('mobile'),
          this.formValidators.minlengthValidator('mobile', 11),
          this.formValidators.maxlengthValidator('mobile', 11)
        ]),
      title: new FormControl(null,
        [
          this.formValidators.requiredValidator('title'),
          this.formValidators.minlengthValidator('title', 3),
          this.formValidators.maxlengthValidator('title', 200)
        ]),
      text: new FormControl(null,
        [
          this.formValidators.requiredValidator('description'),
          this.formValidators.minlengthValidator('description', 3),
          this.formValidators.maxlengthValidator('description', 500)
        ]),
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreate(): void {
    if (!this.helper.checkInvalidForm(this.contactUs)) {
      this.buttonLoading = true;
      if (!this.isCaptchaVerified) {
        this.helper.toaster('لطفا کد امنیتی را تکمیل و مجدد اقدام کنید', ToasterType.WARNING);
        this.recaptchaReactiveInfo.markAllAsTouched();
        this.buttonLoading = false;
        return;
      }
      this.contactUsApiService.postCreateContactUs(this.contactUs.value).subscribe(response => {
        this.buttonLoading = false;
        this.helper.toaster('پیام شما با موفقیت ثبت شد', 'success');
        this.onCreateContactUsForm();
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      });
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', 'error');
      this.buttonLoading = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  resolved(captchaResponse: string): void {
    this.textContact = 'در حال برسی ...';
    this.authApiService.posVerifyToken(captchaResponse).subscribe(response => {
      this.isCaptchaVerified = response['isSuccess'];
      this.textContact = 'ارسال پیام به پشتیبانی';
    }, error => {
      this.textContact = 'ارسال پیام به پشتیبانی';
      this.isCaptchaVerified = false;
      this.helper.showErrorMessage(error.error.errorMessages);
    });
  }

}
