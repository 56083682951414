import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticketStatus'
})
export class TicketStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let statusMessage = "وضعیت نامشخص";
    switch (value) {
      case 1:
        statusMessage = "در انتظار بررسی"
        break;
      case 2:
        statusMessage = "در حال بررسی"
        break;
      case 3:
        statusMessage = "پاسخ داده شده"
        break;
      case 4:
        statusMessage = "پاسخ مشتری"
        break;
      case 5:
        statusMessage = "بسته شده"
        break;
      default:
        break;
    }
    return statusMessage;
  }

}
