import { HttpConnectionService } from 'src/app/core/services/api/http-connection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlogApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  getInfoBlogHome() {
    return this.httpConnection.get('site/Blog/GetInfoBlogHome');
  }

  getBlogDetail(blogLink) {
    return this.httpConnection.get(`site/Blog/GetBlogDetail/${blogLink}`);
  }

  getSearchInBlog(SearchText: string) {
    return this.httpConnection.getParams('site/Blog/GetSearchInBlog', SearchText);
  }

  getBlogListByCategoryLink(categoryLink: string, pageNumber: number, take: number, status: number) {
    return this.httpConnection.get(`site/Blog/GetBlogListByCategoryLink/${categoryLink}/${pageNumber}/${take}/${status}`);
  }

  getSearchInBlogByPagination(searchText: string, pageNumber: number, take: number, status: number) {
    return this.httpConnection.get(`site/Blog/GetSearchInBlogByPagination/${searchText}/${pageNumber}/${take}/${status}`);
  }

  postCreateCommentByUser(commentInfo) {
    return this.httpConnection.post('site/Blog/PostCreateCommentByUser', commentInfo);
  }

  postAddLikeByUser(blogInfo) {
    return this.httpConnection.post('site/Blog/PostAddLikeByUser', blogInfo);
  }

  postAddDisLikeByUser(blogInfo) {
    return this.httpConnection.post('site/Blog/PostAddDisLikeByUser', blogInfo);
  }

  putRemoveUnLikeByUser(blogInfo) {
    return this.httpConnection.post('site/Blog/PutRemoveUnLikeByUser', blogInfo);
  }

  putRemoveUnDisLikeByUser(blogInfo) {
    return this.httpConnection.post('site/Blog/PutRemoveUnDisLikeByUser', blogInfo);
  }


}
