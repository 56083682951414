import { Profile } from './../../../shared/interfaces/profile';
import { State } from './../../../shared/interfaces/state';
import { AccountApiService } from './../../../core/services/api/account-api.service';
import { FormValidatorsService } from './../../../shared/validators/form-validators.service';
import { FileManagerApiService } from './../../../core/services/api/file-manager-api.service';
import { ImagesCompressService } from './../../../core/services/NgxImageCompress/images-compress.service';
import { Helper } from './../../../shared/utilities/helper';
import { environment } from './../../../../environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.less']
})
export class AccountComponent implements OnInit {
  accountInfo: FormGroup;
  styleInfo: FormGroup;
  sampleArray: any;
  baseUrl: any = environment.baseUrl;
  buttonLoading: boolean = false;
  sectionLoading: string = 'default';
  picture: any;
  imageFileForUpload: any;
  profile: Profile;
  listofCities: State[] = [];
  pageLoding: boolean = false;
  headerTitle: string = 'کاریزماکالر | اطلاعات کاربری'

  constructor(
    private helper: Helper,
    private imageCompressService: ImagesCompressService,
    private uplodaImagesService: FileManagerApiService,
    private formValidators: FormValidatorsService,
    private accountApiService: AccountApiService,
    private manageHeader: ManageHeaderService
  ) {
    this.onCreateAccountInfoForm();
    this.onCreateStyleInfoForm();
  }

  ngOnInit(): void {
    this.getViewProfile();
    this.manageHeader.updateTitle(this.headerTitle);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getViewProfile(): void {
    this.pageLoding = true;
    this.accountApiService.getViewProfile().subscribe(resposne => {
      this.profile = resposne['data'];
      this.accountInfo.patchValue(this.profile.userInfo);
      this.onFilterCities(true);
      this.pageLoding = false;
    }, error => {
      console.log('error', error);
      this.pageLoding = false;
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateAccountInfoForm(): void {
    this.accountInfo = new FormGroup({
      id: new FormControl(null),
      firstName: new FormControl(null,
        [
          this.formValidators.requiredValidator('firstName'),
          this.formValidators.minlengthValidator('firstName', 3),
          this.formValidators.maxlengthValidator('firstName', 100),
        ]),
      lastName: new FormControl(null,
        [
          this.formValidators.minlengthValidator('lastName', 3),
          this.formValidators.maxlengthValidator('lastName', 100),
        ]),
      day: new FormControl(null,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(31)
        ]),
      month: new FormControl(null,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(12)
        ]),
      year: new FormControl(null,
        [
          Validators.required,
          Validators.min(1330),
          Validators.max(1395)
        ]),
      gender: new FormControl(null,
        [
          Validators.required,
        ]),
      picture: new FormControl(null),
      cityId: new FormControl(null,
        [
          this.formValidators.requiredValidator('cityId'),
        ]),
      stateId: new FormControl(null,
        [
          this.formValidators.requiredValidator('stateId'),
        ]),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateStyleInfoForm(): void {
    this.styleInfo = new FormGroup({
      id: new FormControl(null,),
      height: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      weight: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      armCircumference: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      waistCircumference: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      upperHeight: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      bustHeight: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      carol: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      thighSize: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      hipCircumference: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      shoeSize: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      description: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      anatomyId: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      anatomyTitle: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      cityId: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      stateId: new FormControl(null, [this.formValidators.requiredValidator('height'), this.formValidators.onlyNumber('height')]),
      lat: new FormControl(null, [this.formValidators.requiredValidator('lat'), this.formValidators.onlyNumber('lat')]),
      long: new FormControl(null, [this.formValidators.requiredValidator('long'), this.formValidators.onlyNumber('long')]),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  async onUploadMainPicture(event: File): Promise<void> {
    this.buttonLoading = true;
    this.sectionLoading = 'picture';
    const formData = new FormData();
    this.imageFileForUpload = await this.imageCompressService.convertFileToBase64(event);
    if (this.imageFileForUpload !== null) {
      formData.append("file", this.imageFileForUpload);
      this.uplodaImagesService.postUplodaImages(formData).subscribe(response => {
        if (response['isSuccess']) {
          let model: any = response;
          this.accountInfo.get("picture").patchValue(model.data);
          this.picture = this.accountInfo.get("picture").value;
          this.buttonLoading = false;
          this.sectionLoading = 'default';
          this.helper.toaster('تصویر با موفقیت آپلود شد', ToasterType.SUCCESS);
        }
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
        this.sectionLoading = 'default';
        this.helper.toaster('از سمت سرور خطای رخ داده', ToasterType.ERROR);
      })
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onFilterCities(isUpdataed?: boolean): void {
    const stateId: string = this.accountInfo.get('stateId').value;
    if (stateId !== null) {
      (!isUpdataed) ? this.accountInfo.get('cityId').patchValue(null) : null;
      this.listofCities = this.profile.listOfStates.find(current => current.id == this.accountInfo.get('stateId').value)['listOfCities'];
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onUpdate(): void {
    if (!this.helper.checkInvalidForm(this.accountInfo)) {
      this.buttonLoading = true;
      this.sectionLoading = 'update';
      this.accountApiService.putUpdateUserProfile(this.accountInfo.value).subscribe(response => {
        this.buttonLoading = false;
        this.sectionLoading = 'default';
        window.scroll(0, 0);
        this.helper.toaster('اطلاعات شما با موفقیت ویرایش شد', ToasterType.SUCCESS);
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
        this.sectionLoading = 'default';
      })
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', ToasterType.WARNING);
      this.buttonLoading = false;
      this.sectionLoading = 'default';
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onUpdatePersonalInformation(): void {
    if (!this.helper.checkInvalidForm(this.styleInfo)) {
      this.buttonLoading = true;
      this.accountApiService.putUpdatePersonalInformation(this.styleInfo.value).subscribe(response => {
        this.buttonLoading = false;
        window.scroll(0, 0);
        this.helper.toaster('اطلاعات شما با موفقیت ویرایش شد', ToasterType.SUCCESS);
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      })
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', ToasterType.WARNING);
      this.buttonLoading = false;
    }
  }


}
