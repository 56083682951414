<div class="container-fluid main-background site-main-container"
  [ngClass]="{'home-main-container':routerLinkActive('/')}" (click)="onCloseSearch()">
  <div class="row">
    <!-- top navbar start -->
    <div class="col-12 sticky-top sticky-nav-main-col d-none d-md-none d-xl-block" (scroll)="onWindowScroll($event);"
      id="navbar" [ngClass]="{'fixed-navbar' : getRoute()}">
      <div class="row sticky-nav-main-row">
        <div class="col-11 sticky-nav-sub-col">
          <!-- navbar links start -->
          <nav class="nav-links-container">
            <ul class="navbar-links-ul">
              <li class="navbar-links-li">
                <img [routerLink]="['/']" class="logo" src="../../../../assets/images/core/logo-outline.svg"
                  alt="کاریزما لوگو">
                <a [routerLink]="['/']" [routerLinkActive]="['active-nvabar-links']"
                  [routerLinkActiveOptions]="{exact: true}" class="navbar-links-anchor">
                  کاریزما کالر
                </a>
              </li>
              <li class="navbar-links-li categories-li">
                <div class="categories-wrapper">
                  <ul class="sections-ul">

                    <li (mouseover)="onGetChildCatgories(parentCategory)"
                      *ngFor="let parentCategory of parentCategories.listOfParents" class="sections-li">
                      <a class="sections-link">
                        <img class="sections-icon" src="../../../../assets/images/core/{{parentCategory.icon}}.svg"
                          alt="{{parentCategory.name}}">
                        <span [ngClass]="{'active-section': activeLinkByHover == parentCategory.link}"
                          class="sections-text">{{parentCategory.name}}</span>
                      </a>
                    </li>

                    <li class="sections-li all-sections-li">
                      <a [routerLink]="['/product-list']" class="sections-link">
                        <img class="sections-icon" src="../../../../assets/images/core/set.svg" alt="همه موارد">
                        <span class="sections-text">همه موارد</span>
                      </a>
                    </li>

                  </ul>
                  <ul class="childs-ul">

                    <li class="childs-li child-title-li">
                      <a [routerLink]="['/category/' + childCategories.link]" class="childs-link">
                        خرید {{childCategories.name}}
                        <img class="icon" src="../../../../assets/images/core/left-arrow-grey.svg"
                          alt="{{childCategories.name}}">
                      </a>
                    </li>

                    <li *ngFor="let childCategory of childCategories.listOfChildern" class="childs-li">
                      <a [routerLink]="['/category/' + childCategory.link]" class="childs-link">
                        {{childCategory.name}}
                      </a>
                    </li>

                  </ul>
                </div>
                <a (mouseover)="onGetParentCatgeory('زنانه')" class="navbar-links-anchor categories-links">
                  دسته بندی
                </a>

              </li>
              <!-- <li *ngIf="onShowOrdersLink()" class="navbar-links-li">
                <a [routerLink]="['/user-club/user-profile/my-order']" class="navbar-links-anchor">سفارشات من</a>
              </li> -->

              <li class="navbar-links-li">
                <a [routerLink]="['/cloth-founder']" [routerLinkActive]="['active-nvabar-links']"
                  class="navbar-links-anchor">
                  لباس یاب
                </a>
              </li>

              <li class="navbar-links-li">
                <a [routerLink]="['/blog']" [routerLinkActive]="['active-nvabar-links']" class="navbar-links-anchor">
                  وبلاگ
                </a>
              </li>

              <li class="navbar-links-li">
                <a [routerLink]="['/about-us']" [routerLinkActive]="['active-nvabar-links']"
                  class="navbar-links-anchor">
                  درباره ما
                </a>
              </li>

              <!-- <li class="navbar-links-li">
                <a [routerLink]="['/contact-us']" [routerLinkActive]="['active-nvabar-links']"
                  class="navbar-links-anchor">
                  تماس با ما
                </a>
              </li> -->

            </ul>

          </nav>
          <!-- navbar links end -->
          <!-- navbar search and icons start -->
          <div class="d-flex align-items-center">
            <div class="search-wrapper">
              <div class="search-input-container">
                <form [formGroup]="searchInfo">
                  <input (input)="onSearchInProducts()" (click)="onNotCloseSearch($event)" autocomplete="off"
                    class="header-search-input" formControlName="search" name="search" type="text"
                    placeholder="نام محصول یا دسته بندی مورد نظر را وارد کنید">
                </form>
                <img class="search-icon" src="../../../../assets/images/core/search-icon.svg" alt="جستجو">
              </div>
              <div *ngIf="ShowResult" class="search-result-container">
                <a (click)="onCloseSearchResult($event, product)" *ngFor="let product of listOfProducts"
                  class="search-result-link">
                  {{product.title}}
                </a>
                <a *ngIf="listOfProducts.length <= 0" class="search-result-link d-flex justify-content-center">
                  هیچ محصولی یافت نشد
                </a>
              </div>
            </div>
            <a (click)="onGotoBasket()" [routerLinkActive]="['active-links']" class="navbar-icons-links">
              <img class="navbar-icons-image" src="../../../../assets/images/core/shopping-cart.svg" alt="سبد خرید">
            </a>
            <a (click)="onNavLinks('/user-club/user-profile/account')"
              [ngClass]="{'active-links': routerLinkActive('/user-club/user-profile')}" class="navbar-icons-links">
              <img class="navbar-icons-image" src="../../../../assets/images/core/user.svg" alt="پروفایل">
            </a>
            <a href="https://seller.carismacolor.com" target="_blank" class="navbar-icons-links seller-panel-link">
              <span class="text">پنل فروشندگان</span>
            </a>
          </div>
          <!-- navbar search and icons end -->
        </div>
      </div>
    </div>
    <!-- top navbar end -->
    <!-- responsive top navbar start -->
    <div class="col-12 sticky-top responsive-top-nav d-xl-none" [ngClass]="{'fixed-navbar' : getRoute()}"
      (scroll)="onWindowScroll($event);" id="responsiveNav">
      <div class="row responsive-nav-main-row">
        <div *ngIf="!showFilter" class="col-12 responsive-nav-sub-col">

          <a [routerLink]="['/']" [routerLinkActive]="['active-page-label-visible']"
            [routerLinkActiveOptions]="{exact: true}" class="active-page-label">
            <img class="logo-icon" src="../../../../assets/images/core/logo-outline.svg" alt="لوگو کاریزما">
            <span>صفحه نخست</span>
          </a>

          <a [routerLink]="['/product-list']" [routerLinkActive]="['active-page-label-visible']"
            class="active-page-label">
            <img class="logo-icon" src="../../../../assets/images/core/logo-outline.svg" alt="لوگو کاریزما">
            <span>لباس تک</span>
          </a>

          <a [routerLink]="['/category']" [routerLinkActive]="['active-page-label-visible']" class="active-page-label">
            <img class="logo-icon" src="../../../../assets/images/core/logo-outline.svg" alt="لوگو کاریزما">
            <span>دسته بندی</span>
          </a>

          <a [routerLink]="['/user-club/user-profile']" [routerLinkActive]="['active-page-label-visible']"
            class="active-page-label">
            <img class="logo-icon" src="../../../../assets/images/core/logo-outline.svg" alt="لوگو کاریزما">
            <span>پروفایل</span>
          </a>

          <a [routerLink]="['/basket']" [routerLinkActive]="['active-page-label-visible']" class="active-page-label">
            <img class="logo-icon" src="../../../../assets/images/core/logo-outline.svg" alt="لوگو کاریزما">
            <span>سبد خرید</span>
          </a>

          <a [routerLink]="['/about-us']" [routerLinkActive]="['active-page-label-visible']" class="active-page-label">
            <img class="logo-icon" src="../../../../assets/images/core/logo-outline.svg" alt="لوگو کاریزما">
            <span>درباره ما</span>
          </a>

          <a [routerLink]="['/contact-us']" [routerLinkActive]="['active-page-label-visible']"
            class="active-page-label">
            <img class="logo-icon" src="../../../../assets/images/core/logo-outline.svg" alt="لوگو کاریزما">
            <span>تماس با ما</span>
          </a>

          <a [routerLink]="['/product']" [routerLinkActive]="['active-page-label-visible']" class="active-page-label">
            <img class="logo-icon" src="../../../../assets/images/core/logo-outline.svg" alt="لوگو کاریزما">
            <span>محصول</span>
          </a>

          <a [routerLink]="['/menu']" [routerLinkActive]="['active-page-label-visible']" class="active-page-label">
            <img class="logo-icon" src="../../../../assets/images/core/logo-outline.svg" alt="لوگو کاریزما">
            <span>دسته بندی ها</span>
          </a>

          <a [routerLink]="['/seller']" [routerLinkActive]="['active-page-label-visible']" class="active-page-label">
            <img class="logo-icon" src="../../../../assets/images/core/logo-outline.svg" alt="لوگو کاریزما">
            <span [title]="productTitle">{{ productTitle | limitText:20 }}</span>
          </a>

          <div class="product-details-options" [class.d-flex]="router.isActive('/product', false)">
            <a (click)="toggleProductDeatilsOptions()" *ngIf="!productDeatilsOpstion"
              class="product-details-options-links">
              <img class="icon" src="../../../../assets/images/core/3-dots-vertical.svg" alt="امکانات محصول">
            </a>

            <a (click)="toggleProductDeatilsOptions()" *ngIf="productDeatilsOpstion"
              class="product-details-options-links product-details-options-close-links">
              <img class="icon" src="../../../../assets/images/core/dark-close.svg" alt="بستن امکانات">
            </a>

            <a (click)="onReturn()" class="product-details-options-links"
              [ngClass]="{'invisible': productDeatilsOpstion}">
              <img src="../../../../assets/images/core/left-arrow-dark-grey.svg" alt="بازگشت">
            </a>

            <div class="dropdown-wrapper" [ngClass]="{'d-block': productDeatilsOpstion}">

              <a (click)="copyLinkToClipboard(productLink)" class="product-details-responsive-options">
                <img class="icon" src="../../../../assets/images/core/share.svg" alt="اشتراک محصول">
                <span class="span">کپی لینک</span>
              </a>

              <a href="https://api.whatsapp.com/send?text={{productLink}}" target="_blank"
                (click)="toggleProductDeatilsOptions()" class="product-details-responsive-options">
                <img class="icon" src="../../../../assets/images/core/whatsapp.svg" alt="اشتراک در واتساپ">
                <span class="span">اشتراک در واتس اپ</span>
              </a>

              <a href="https://t.me/share/url?url={{productLink}}&text={{productTitle}}" target="_blank"
                (click)="toggleProductDeatilsOptions()" class="product-details-responsive-options">
                <img class="icon" src="../../../../assets/images/core/telegram.svg" alt="اشتراک در تلگرام">
                <span class="span">اشتراک در تلگرام</span>
              </a>


              <!-- <a class="product-details-responsive-options">
                <img class="icon" src="../../../../assets/images/core/bookmark.svg" alt="share">
                <span class="span">نشان کردن</span>
              </a> -->

            </div>

            <div *ngIf="productDeatilsOpstion" (click)="toggleProductDeatilsOptions()" class="share-backdrop"></div>

          </div>


          <a (click)="onFilter()" class="navbar-icons-links filter-icon-link"
            [ngClass]="filterRouterLinkActiveStartWith()">
            <img class="navbar-icons-image" src="../../../../assets/images/core/filter-icon.svg" alt="فیلتر">
            <span class="text">فیلتر کردن</span>
          </a>

          <a class="toggle-profile-menu" (click)="toggleProfileMenu()"
            [class.d-flex]="router.isActive('/user-club/user-profile', false)">
            <svg class="responsive-profile-menu-svg-line">
              <line class="svg-line" x1="0" y1="13" x2="17" y2="13" />
              <line [@slideInOut]="profileMenu" class="svg-line" x1="0" y1="7" x2="10" y2="7" />
              <line [@topLine]="profileMenu" class="svg-line" x1="0" y1="1" x2="12" y2="1" />
            </svg>
          </a>

          <!-- <a class="go-back-link">
            <img class="icon" src="../../../../assets/images/core/left-arrow-grey.svg" alt="برگشت">
          </a> -->

        </div>
        <div *ngIf="showFilter" class="col-12 responsive-filter-col">
          <label class="filter-title">فیلتر کردن</label>
          <a (click)="onFilter()">
            <img class="close-filter-icon" src="../../../../assets/images/core/plus.svg" alt="فیلتر">
          </a>
          <!-- <button class="filter-button" type="button">اعمال فیلتر</button> -->
        </div>
      </div>
    </div>
    <!-- responsive top navbar end -->

    <div class="col-12" [ngClass]="{'mt-0' : getRoute()}">
      <router-outlet></router-outlet>
    </div>

  </div>
  <!-- footer start -->
  <footer class="row footer-main-row" [ngClass]="getRouteFooter('/product/')">
    <div class="col-11">
      <div class="row">
        <!-- footer links start -->
        <div class="col-6 col-md-4 col-xl-2 footer-links-col">
          <ul class="footer-ul">
            <li class="footer-li">
              <a [routerLink]="['/']" class="footer-links">صفحه نخست</a>
            </li>
            <li class="footer-li">
              <a [routerLink]="['/blog']" class="footer-links">وبلاگ کاریزما</a>
            </li>
            <li class="footer-li">
              <a href="https://seller.carismacolor.com" target="_blank" class="footer-links">پنل فروشندگان</a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-4 col-xl-2 footer-links-col">
          <ul class="footer-ul">
            <!-- <li class="footer-li">
              <a class="footer-links">کاریزما کالر</a>
            </li> -->
            <li class="footer-li">
              <a (click)="onNavLinks('/user-club/user-profile/account')" class="footer-links">پروفایل من</a>
            </li>
            <li class="footer-li">
              <a (click)="onGotoBasket()" class="footer-links">سبد خرید من</a>
            </li>
            <li class="footer-li">
              <a [routerLink]="['/register-seller']" class="footer-links">ثبت نام فروشندگان</a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-4 col-xl-2 footer-links-col">
          <ul class="footer-ul">
            <!-- <li class="footer-li invisible">
              <a class="footer-links">dummy</a>
            </li> -->
            <li class="footer-li">
              <a [routerLink]="['/about-us']" class="footer-links">درباره ما</a>
            </li>
            <li class="footer-li">
              <a [routerLink]="['/contact-us']" class="footer-links">تماس با ما</a>
            </li>
          </ul>
        </div>
        <!-- footer links end -->
        <!-- footer images start -->
        <div class="col-12 col-md-12 col-xl-6 footer-image-col">
          <a [routerLink]="['/cloth-founder']" class="mr-3 mb-2" title="لباس یاب">
            <img class="cloth-founder-banner-footer" src="../../../../assets/images/site-home/cloth-founder-banner.png"
              border="0" alt="لباس یاب">
          </a>
          <a (click)="showZPTrust();" title="نماد الکترونیک اعتماد زرین پال">
            <img class="zarin-pal-footer-image" src="https://cdn.zarinpal.com/badges/trustLogo/1.svg" border="0"
              alt="نماد الکترونیک اعتماد زرین پال">
          </a>

          <!-- <div class="footer-img-container">
            <a (click)="showZPTrust();" title="نماد الکترونیک اعتماد زرین پال">
              <img class="footer-image" src="https://cdn.zarinpal.com/badges/trustLogo/1.svg" border="0"
                alt="نماد الکترونیک اعتماد زرین پال">
            </a>
          </div> -->
          <!-- <div class="footer-img-container">
            <img class="footer-image" src="../../../../assets/images/site-home/etehadie.png"
              alt="اتحادیه کسب و کارهای مجازی">
          </div> -->
          <!-- <div class="footer-img-container">
            <img class="footer-image" src="../../../../assets/images/site-home/resane.png" alt="نشان ملی ثبت">
          </div> -->
        </div>
        <!-- footer images end -->
      </div>
    </div>
    <div [class.d-block]="router.isActive('', true)" class="col-12 about-carisma-col d-none">
      <div class="row justify-content-center">
        <div class="col-11 about-carimsa-text-col">
          <h1 class="about-carisma-title">فروشگاه اینترنتی مد و پوشاک کاریزماکالر</h1>
          <div class="d-flex flex-wrap">
            <p class="about-carisma-footer-text" [ngClass]="{'unactive-about-carimsa-text': !readMore}">

              پاساژ اینترنتی کاریزماکالر فعالیت تخصصی خود را در حوزه فروش پوشاک از سال ۱۴۰۰ شروع کرده است. کاریزماکالر
              با تکیه بر سیستم هوش مصنوعی میتواند استایل، سلیقه و فرم بدن شما را شناسایی کند. فروشگاه کاریزما کالر
              براساس این معیارها لباسی متناسب با فرم بدنی و سلیقه تان به شما پیشنهاد میکند. همچنین میتوانید براساس مکان
              مورد نظرتان از سیستم کاریزماکالر کمک گرفته تا ست لباس جذابی از محصولات موجود در کاریزما کالر برای شما
              انتخاب کند.
              <br>
              مهمترین رسالت کاریزماکالر بهبود سبک پوشش و استایل بر اساس سلیقه مشتریان گرامی است. همچنین کاریزماکالر
              توانسته با صدها فروشگاه در سراسر ایران همکاری خودش را آغاز کند و همین مورد نوید بر تنوع بالای پوشاک در
              کاریزماکالر است.
              <br>
              در کاریزماکالر میتوانید تمام آیتم هایی که مرتبط با مد و پوشاک است را پیدا کنید. محصولات ارائه شده در بخش
              لباس زنانه شامل بادی زنانه، <a href="/category/تاپ-نیم_تنه-زنانه" class="home-description-links">تاپ و نیم
                تنه زنانه</a>، سرهمی، <a href="/category/سرهمی-پیراهن-سارافون-زنانه"
                class="home-description-links">پیراهن و سارافون زنانه</a>، بلوز، تونیک و شومیز زنانه، کت و شلوار، جلیقه
              و کت زنانه، <a href="/category/شلوار-شلوارک-زنانه" class="home-description-links">شلوار و شلوارک زنانه</a>
              ، دامن، لگ و ساپورت، ست مانتو، لباس مجلسی، <a href="/category/تیشرت-پولوشرت-زنانه"
                class="home-description-links">تیشرت زنانه</a>، <a href="/category/کیف-زنانه"
                class="home-description-links">کیف</a> و کفش و <a href="/category/اکسسوری-زنانه"
                class="home-description-links">اکسسوری زنانه</a> است. همچنین امکان خرید ست چرم زنانه، ست کیف و <a
                href="/category/کفش-زنانه" class="home-description-links">کفش زنانه</a> و ست اسپرت در کاریزماکالر وجود
              دارد.
            </p>
            <button (click)="onToggleReadMore()" class="read-more-button" type="button">
              {{readMore ? 'بستن' : 'بیشتر'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- carisma ownership text start -->
    <div class="col-12 social-media-links-col">
      <div class="row justify-content-center">
        <div class="col-11 py-3 d-flex justify-content-center justify-content-md-start">
          <label class="rights-label">تمامی حقوق برای کاریزما کالر محفوظ است</label>
        </div>
      </div>
    </div>
    <!-- carisma ownership text start -->
  </footer>
  <!-- footer end -->

  <!-- bottom navbar start -->
  <div *ngIf="isInRoue('product/')" class="row">
    <div class="col-12 bottom-nav d-block d-md-block d-xl-none">
      <ul class="bottom-nav-ul">
        <li class="bottom-nav-li">
          <a (click)="onNavLinks('/')" [routerLink]="['/']" [routerLinkActive]="['bottom-nav-link-active']"
            [routerLinkActiveOptions]="{exact: true}" class="bottom-nav-link">
            <img class="bottom-nav-icons" src="../../../../assets/images/core/home.svg" alt="صفحه نخست">
            <span class="bottom-nav-text">صفحه نخست</span>
          </a>
        </li>
        <li class="bottom-nav-li">
          <a (click)="onNavLinks('/menu')" [class.bottom-nav-link-active]="router.isActive('/category', false)"
            [routerLink]="['/menu']" [routerLinkActive]="['bottom-nav-link-active']" class="bottom-nav-link">
            <img class="bottom-nav-icons" src="../../../../assets/images/core/category.svg" alt="دسته بندی">
            <span class="bottom-nav-text">دسته بندی</span>
          </a>
        </li>
        <li class="bottom-nav-li">
          <a (click)="onNavLinks('/basket')" [class.bottom-nav-link-active]="router.isActive('/basket', false)"
            class="bottom-nav-link">
            <img class="bottom-nav-icons" src="../../../../assets/images/core/shopping-cart.svg" alt="سبد خرید">
            <span class="bottom-nav-text">سبد خرید</span>
          </a>
        </li>
        <li class="bottom-nav-li">
          <a (click)="onNavLinks('/cloth-founder')"
            [class.bottom-nav-link-active]="router.isActive('/cloth-founder', false)" class="bottom-nav-link">
            <img class="bottom-nav-icons" src="../../../../assets/images/core/lebas-yab-icon.svg" alt="لباس یاب">
            <span class="bottom-nav-text">لباس یاب</span>
          </a>
        </li>
        <li class="bottom-nav-li">
          <a (click)="onNavLinks('/user-club/user-profile/account')" class="bottom-nav-link"
            [class.bottom-nav-link-active]="router.isActive('/user-club/user-profile', false)">
            <img class="bottom-nav-icons" src="../../../../assets/images/core/user.svg" alt="پروفایل">
            <span class="bottom-nav-text">پروفایل</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- bottom navbar end -->

  <!-- profile responsive menu start -->
  <div [@profileSlide]="profileMenu" class="profile-responsive-menu"
    [ngClass]="{'active-profile-responsive-menu': profileMenu === 'open'}">
    <ul class="profile-ul">

      <li class="profile-li">
        <a (click)="onGoToProfilePages('/user-club/user-profile/account')"
          [ngClass]="{'active-profile-links': routerLinkActive('/user-club/user-profile/account')}"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/account.svg" alt="اطلاعات کاربری">
          <span class="profile-links-text">اطلاعات کاربری</span>
        </a>
      </li>

      <li class="profile-li">
        <a (click)="onGoToProfilePages('/user-club/user-profile/my-order')"
          [ngClass]="{'active-profile-links': routerLinkActive('/user-club/user-profile/my-order')}"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/order.svg" alt="سفارشات من">
          <span class="profile-links-text">سفارشات من</span>
        </a>
      </li>

      <li class="profile-li">
        <a (click)="onGoToProfilePages('/user-club/user-profile/transaction')"
          [ngClass]="{'active-profile-links': routerLinkActive('/user-club/user-profile/transaction')}"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/transaction.svg" alt="تراکنش های من">
          <span class="profile-links-text">تراکنش های من</span>
        </a>
      </li>

      <!-- <li class="profile-li">
        <a (click)="onGoToProfilePages('/user-club/user-profile/favorites')"
          [ngClass]="{'active-profile-links': routerLinkActive('/user-club/user-profile/favorites')}"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/favorites.svg">
          <span class="profile-links-text">علاقه مندی های من</span>
        </a>
      </li> -->

      <li class="profile-li">
        <a (click)="onGoToProfilePages('/user-club/user-profile/addresses')"
          [ngClass]="{'active-profile-links': routerLinkActive('/user-club/user-profile/addresses')}"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/address.svg" alt="آدرس های من">
          <span class="profile-links-text">آدرس های من</span>
        </a>
      </li>

      <li class="profile-li">
        <a (click)="onGoToProfilePages('/user-club/user-profile/support')"
          [ngClass]="{'active-profile-links': routerLinkActive('/user-club/user-profile/support')}"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/support.svg" alt="پشیتیبانی">
          <span class="profile-links-text">پشتیبانی</span>
        </a>
      </li>

      <li class="profile-li">
        <a data-toggle="modal" data-target="#logoutmodal" class="profile-li-links">
          <img class="profile-links-icon" src="../../../../assets/images/core/logout.svg" alt="خروج از حساب کاربری">
          <span class="profile-links-text logout-link-text">خروج از حساب کاربری</span>
        </a>
      </li>

    </ul>
  </div>
  <!-- profile responsive menu end -->

  <!-- user profile responsive open and close toggle start -->
  <div (click)="toggleProfileMenu()" [@profileToggle]="profileMenu" class="profile-toggle-wrapper">
    <div class="toggle-container">
      <a class="toggle-profile-menu" [class.d-flex]="router.isActive('/user-club/user-profile', false)">
        <svg class="responsive-profile-menu-svg-line">
          <line class="svg-line" x1="0" y1="13" x2="17" y2="13" />
          <line [@slideInOut]="profileMenu" class="svg-line" x1="0" y1="7" x2="10" y2="7" />
          <line [@topLine]="profileMenu" class="svg-line" x1="0" y1="1" x2="12" y2="1" />
        </svg>
      </a>
    </div>
  </div>
  <!-- user profile responsive open and close toggle end -->
</div>

<!-- logout modal start -->
<div class="modal" id="logoutmodal" tabindex="-1" role="dialog" aria-labelledby="logoutmodal" aria-hidden="true">
  <app-confirm-modal [ButtonClass]="'confirm-approved'" [inputTitle]="confirmTitle" [inputContent]='confirmMessage'
    [inputConfirmButtonText]="confirmbutton" (outputAccepted)="LogOut($event)" [inputButtonLoading]="buttonLoading">
  </app-confirm-modal>
</div>
<!-- logout modal end -->

<!-- responsive search start -->
<div *ngIf="showSearch" class="responsive-search-wrapper" [ngClass]="{'d-flex': getRouteSearch()}">
  <form [formGroup]="searchInfo" class="w-100">
    <input (input)="onSearchInProducts()" formControlName="search" name="search" class="responsive-search-input"
      type="text" placeholder="نام محصول یا دسته بندی مورد نظر را وارد کنید">
  </form>
  <img class="responsive-search-icon" src="../../../../assets/images/core/search-icon.svg" alt="جستجو">
  <div *ngIf="ShowResult" class="responsive-search-result-wrapper">
    <a (click)="onCloseSearchResult($event, product)" *ngFor="let product of listOfProducts"
      routerLink="/{{'product/'+ product.link}}" class="responsive-search-result">
      {{product.title}}
    </a>
    <a *ngIf="listOfProducts.length <= 0" class="responsive-search-result d-flex justify-content-center">
      هیچ محصولی یافت نشد
    </a>
  </div>
</div>
<!-- responsive search end -->

<!-- popup gift start -->

<!-- <div *ngIf="isShowGift" class="container-fluid">
  <div (click)="onCloseGift()" *ngIf="isShowGift" class="row gift-row">
    <div (click)="noClose($event)" class="col-8 col-md-4 col-xl-2 gift-col">
      <a (click)="copGiftToClipboard()" class="gift-link">
        <img class="gift-image" src="../../../../assets/images/core/giftttt.png" alt="هدیه">
      </a>
      <button (click)="onCloseGift()" class="close-gift-button">
        <img class="icon" src="../../../assets/images/core/plus.svg" alt="بستن">
      </button>
    </div>
  </div>
</div> -->

<!-- popup gift end -->