import { BehaviorSubject } from 'rxjs';
import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuestionaireService {

  private genderStatus = new BehaviorSubject<boolean>(false);

  constructor(private httpsConnection: HttpConnectionService) { }

  getInitialSetup(questionId?: string) {
    return this.httpsConnection.get(`store/Questionnaire/InitialStep/${questionId}`);
  }

  putUpdateUnverfiedUser(unverifiedUser: any) {
    return this.httpsConnection.put("store/Questionnaire/PutUpdateUnverifiedUser", unverifiedUser);
  }

  postStep(answer: any) {
    return this.httpsConnection.post("store/Questionnaire/Step", answer);
  }

  storeInLocalStorageStrigify(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getFromLocalStorageWithParse(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  getGenderStatus() {
    let unverifiedUser = this.getFromLocalStorageWithParse('unverifiedUser');
    let gender = unverifiedUser.gender;
    console.log('gender', gender);
    if (gender == 1) {
      return true;
    }
    if (gender == 2) {
      return false;
    }
  }

  removeFromGender() {
    this.genderStatus.next(false);
  }

  toggleGenderStatus(status: boolean) {
    this.genderStatus.next(status);
  }


  postSendVerifyCode(questioniareInfo: any) {
    return this.httpsConnection.post("store/Questionnaire/PostSendVerifyCode", questioniareInfo);
  }

  postVerifyCode(questioniareInfo: any) {
    return this.httpsConnection.post("store/Questionnaire/PostVerifyCode", questioniareInfo);
  }

  getIsUserConfirmed(unverifiedUserId: string) {
    return this.httpsConnection.getParams("store/Questionnaire/GetIsUserConfirmed", unverifiedUserId)
  }

}
