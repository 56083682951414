import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpConnectionService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  get(url: string) {
    return this.http.get(`${this.baseUrl}/${url}`)
  }

  getParams(url: string, params: string) {
    return this.http.get(`${this.baseUrl}/${url}/${params}`)
  }

  post(url: string, data: any) {
    return this.http.post(`${this.baseUrl}/${url}`, data);
  }

  delete(url: string, id: string) {
    return this.http.delete(`${this.baseUrl}/${url}/${id}`);
  }

  put(url: string, data: any) {
    return this.http.put(`${this.baseUrl}/${url}`, data);
  }
}
