import { Component, OnInit } from '@angular/core';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-successfull-register',
  templateUrl: './successfull-register.component.html',
  styleUrls: ['./successfull-register.component.less']
})
export class SuccessfullRegisterComponent implements OnInit {

  headerTitle: string = 'کاریزماکالر | ثبت نام موفق'

  constructor(private manageHeader: ManageHeaderService) { }

  ngOnInit(): void {
    this.manageHeader.updateTitle(this.headerTitle);
  }

}
