import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productGender'
})
export class ProductGenderPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let statusMessage = "وضعیت نامشخص";
    switch (value) {
      case 1:
        statusMessage = "آقایان"
        break;
      case 2:
        statusMessage = "خانم ها"
        break;
      default:
        break;
    }
    return statusMessage;
  }

}
