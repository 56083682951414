import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';
import { Status } from 'src/app/shared/interfaces/status';

@Injectable({
  providedIn: 'root'
})
export class BasketApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  postCreateUpdateCart(cartInfo: any) {
    return this.httpConnection.post('store/Basket/PostCreateUpdateCart', cartInfo)
  }

  getViewBasket() {
    return this.httpConnection.get('store/Basket/GetViewBasket');
  }

  postChangeCartItemQuantity(quantityInfo: any) {
    return this.httpConnection.post('store/Basket/PostChangeCartItemQuantity', quantityInfo)
  }

  postChangeCartItemStatus(status: Status) {
    return this.httpConnection.post('store/Basket/PostChangeCartItemStatus', status)
  }

  postCreateFactor(basketInfo: any) {
    return this.httpConnection.post('store/Payment/PostCreateFactor', basketInfo)
  }

  postUseDiscountCode(factoryItems: any) {
    return this.httpConnection.post('store/Payment/PostUseDiscountCode', factoryItems);
  }

}
