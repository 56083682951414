import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Observable } from 'src/app/core/services/observable/observable';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.less']
})
export class ConfirmModalComponent implements OnInit {
  @Input() inputContent: string;
  @Input() inputTitle: string;
  @Input() inputConfirmButtonText: string;
  @Input() ButtonClass: string | string[] = '';
  @Input() inputButtonLoading: boolean;
  @Output() outputAccepted = new EventEmitter<boolean>();
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  @ViewChild('closeDeleteMenu') closeDeleteMenu: any;
  constructor(
    private observable: Observable
  ) { }

  ngOnInit(): void {
    this.subscription1 = this.observable.get().subscribe(response => {
      if (Object.keys(response).length !== 0) {
        if (response.eventModal) {
          this.closeDeleteMenu?.nativeElement.click();
        }
      }
    });
    this.subscription2 = this.observable.getDeleted().subscribe(response => {
      this.closeDeleteMenu?.nativeElement.click();
    });
  }

  onAccepted(deleted: boolean): void {
    this.outputAccepted.emit(deleted);
  }

}
