<div class="row successfull-payment-main-row vh-100">
  <div class="col-11 payment-result-main-col">
    <img class="payment-result-image" src="../../../../assets/images/core/successfull-payment.svg" alt="پرداخت موفق">
    <label class="payment-result-text">ثبت نام موفق</label>
    <div class="peyment-result-number-container">
      <label class="payment-result-sub-text">ثبت نام شما موفقیت آمیز بود</label>
    </div>
    <label class="go-to-orders-results-text">
      سپاس از ثبت نام شما در فروشگاه کاریزما کالر، پس از برسی درخواست تیم پشتیبانی با شما تماس خواهد گرفت
    </label>
    <button [routerLink]="['/']" class="medium-button" type="button">صفحه اصلی</button>
  </div>
</div>
