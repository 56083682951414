import { FormGroup, FormControl } from '@angular/forms';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AuthServiceApiService } from 'src/app/core/services/api/auth.service.api.service';
import { FormValidatorsService } from 'src/app/shared/validators/form-validators.service';
import { Helper } from 'src/app/shared/utilities/helper';
import { LocalStorageService } from 'src/app/core/services/storage/local-storage.service';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { Location } from '@angular/common';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.less']
})
export class SignInComponent implements OnInit {

  mobileInfo: FormGroup;
  passwordInfo: FormGroup;
  authenticationSteps = 'mobile';
  buttonLoading: boolean = false;
  sectionLoading: string = 'default';
  hoverShow: boolean = false;
  isCaptchaVerified: boolean = false;
  textSign: string = 'ورود';
  isShowTimer: boolean = true;
  @ViewChild('closebutton') closebutton: any;
  recaptchaReactiveInfo: FormGroup;
  headerTitle: string = 'کاریزماکالر | ورود'

  constructor(
    private authApiService: AuthServiceApiService,
    private formValidators: FormValidatorsService,
    private helper: Helper,
    private localStorageService: LocalStorageService,
    private location: Location,
    private manageHeader: ManageHeaderService
  ) {
    this.onCreateMobileInfoForm();
    this.onCreatePasswordInfoForm();
    this.onCreateRecaptchaReactiveForm();
  }

  ngOnInit(): void {
    this.manageHeader.updateTitle(this.headerTitle);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  timeR(): void {
    setTimeout(() => {
      this.isShowTimer = false;
    }, 60000);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateRecaptchaReactiveForm(): void {
    this.recaptchaReactiveInfo = new FormGroup({
      recaptchaReactive: new FormControl(null)
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateMobileInfoForm() {
    this.mobileInfo = new FormGroup({
      id: new FormControl(null),
      mobile: new FormControl(null,
        [
          this.formValidators.isMobileReg("mobile"),
          this.formValidators.onlyNumber("mobile"),
          this.formValidators.requiredValidator("mobile"),
          this.formValidators.maxlengthValidator("mobile", 11),
          this.formValidators.minlengthValidator("mobile", 11)
        ]),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreatePasswordInfoForm(): void {
    this.passwordInfo = new FormGroup({
      id: new FormControl(null),
      mobile: new FormControl(null,
        [
          this.formValidators.onlyNumber("mobile"),
          this.formValidators.requiredValidator("mobile"),
          this.formValidators.maxlengthValidator("mobile", 11),
          this.formValidators.minlengthValidator("mobile", 11)
        ]),
      password: new FormControl(null,
        [
          this.formValidators.onlyNumber("password"),
          this.formValidators.requiredValidator("password"),
          this.formValidators.maxlengthValidator("password", 4),
          this.formValidators.minlengthValidator("password", 4)
        ])
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreate(): void {
    if (!this.helper.checkInvalidForm(this.mobileInfo)) {
      this.buttonLoading = true;
      if (!this.isCaptchaVerified) {
        this.helper.toaster('لطفا کد امنیتی را تکمیل و مجدد اقدام کنید', ToasterType.WARNING);
        this.recaptchaReactiveInfo.markAllAsTouched();
        this.buttonLoading = false;
        return;
      }
      this.authApiService.postCreateUpdateCustomerAccount(this.mobileInfo.value).subscribe(response => {
        this.buttonLoading = false;
        this.authenticationSteps = 'password';
        this.helper.toaster('پیام با موفقیت ارسال شد', 'success');
        this.passwordInfo.get('mobile').patchValue(this.mobileInfo.get('mobile').value);
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      });
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', 'error');
      this.buttonLoading = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onVerify(): void {
    this.passwordInfo.get('mobile').patchValue(this.mobileInfo.get('mobile').value);
    if (!this.helper.checkInvalidForm(this.passwordInfo)) {
      this.buttonLoading = true;
      this.sectionLoading = 'verifyCode';
      this.authApiService.postVerifyByPasswordCustomerAccount(this.passwordInfo.value).subscribe(response => {
        this.buttonLoading = false;
        this.sectionLoading = 'default';
        this.localStorageService.create('userInfo', response['data']['userAuthInfo']);
        let url: any = this.localStorageService.get('url');
        if (url) {
          this.helper.redirectTo(url);
          this.localStorageService.remove('url');
        } else {
          this.helper.redirectTo('/');
        }
        this.helper.toaster('لاگین با موفقیت انجام شد', 'success');
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
        this.sectionLoading = 'default';
      });
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', 'error');
      this.buttonLoading = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  toggleHover(): void {
    this.hoverShow == true ? this.hoverShow = false : this.hoverShow = true;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCheckLength(field: string): void {
    if (field === 'password') {
      if (!this.passwordInfo.invalid) {
        this.onVerify();
      }
    }
  }
  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCorrectNumber(): void {
    this.authenticationSteps = 'mobile';
    this.onCreateMobileInfoForm();
    this.onCreatePasswordInfoForm();
    this.isCaptchaVerified = false;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onOpenModal(): void {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  @HostListener('window:popstate', ['$event'])
  dismissModal(): void {
    this.closebutton?.nativeElement.click();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  resolved(captchaResponse: string): void {
    this.textSign = 'در حال برسی ...';
    this.authApiService.posVerifyToken(captchaResponse).subscribe(response => {
      this.isCaptchaVerified = response['isSuccess'];
      this.textSign = 'ورود';
    }, error => {
      this.textSign = 'ورود';
      this.isCaptchaVerified = false;
      this.helper.showErrorMessage(error.error.errorMessages);
    });
  }

  onGoBack(): void {
    if (history.length > 2) {
      this.location.back();
    } else {
      this.helper.redirectTo('/');
    }
  }

}
