import { Vendor } from './../../shared/interfaces/vendor';
import { VendorPageApiService } from './../../core/services/api/vendor-page-api.service';
import { Helper } from './../../shared/utilities/helper';
import { environment } from './../../../environments/environment';
import { ProductApiService } from './../../core/services/api/product-api.service';
import { ProductListApiService } from '../../core/services/api/product-list-api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Component, HostListener, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { Category } from 'src/app/shared/interfaces/category';
import { Brand } from 'src/app/shared/interfaces/brand';
import { Paginate } from 'src/app/shared/interfaces/paginate';
import { CategoryOrderSort } from 'src/app/core/enums/category-order.enum';
import { Observable } from 'src/app/core/services/observable/observable';
import { QueryParam } from 'src/app/core/enums/query-params.enum';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';


@Component({
  selector: 'app-vendor-page',
  templateUrl: './vendor-page.component.html',
  styleUrls: ['./vendor-page.component.less']
})
export class VendorPageComponent implements OnInit {

  pages: number[] = [];
  currentPage: number = 1;
  pageSize: number = 20;
  totalPage: number;
  buttonLoading: boolean = false;
  actvieCategorySort: number = 1;
  orderName: string = 'مرتب سازی';

  filterInfo: FormGroup;
  pageLoading: boolean = false;
  showFilter: boolean = false;
  filtersToggle: number;
  queryParams: string = "";
  latestQueryParams: string = "initialFilter=true&orderBy=1&pageNumber=1";
  filterName: string = ""
  isShowFilters: boolean = false;
  isFilterPrice: boolean = false;
  productList: any[] = [];
  customeQueryParams: any = {};
  // @HostListener('window:resize', ['$event'])
  screenHeight: number;
  screenWidth: number;
  pagination: any;
  listOfCategories: Category[] = [];
  listOfColorLabels: any[] = [];
  listOfBrands: Brand[] = [];
  listOfFilterColors: any[] = [];
  initialFilter: boolean = true;
  vendorInfo: Vendor;
  baseUrl: string = environment.baseUrl;
  // price range start
  lowValue: number;
  hightValue: number;
  vendorLink: string;
  tempArray: any = [];
  options: Options = {
    floor: 10000,
    ceil: 1000000,
    step: 100000,
    rightToLeft: true,
  };
  constructor(
    private router: Router,
    private productListApiService: ProductListApiService,
    private activeRoute: ActivatedRoute,
    private productApiService: ProductApiService,
    private helper: Helper,
    private vendorPageApiService: VendorPageApiService,
    private observable: Observable,
    private manageHeader: ManageHeaderService
  ) {
    this.onCreateProductInfoFrom();
    this.productListApiService.getFilterStatus().subscribe(res => {
      this.isShowFilters = res;
    });
    this.getScreenSize();
    this.observable.getFilter().subscribe(response => {
      this.isShowFilters = response;
      this.showFilter = response;
    });
  }

  async ngOnInit(): Promise<void> {
    this.vendorLink = this.activeRoute.snapshot.params["link"];
    if (!this.initQueryParamsInReloadPage(this.activeRoute.snapshot.queryParams)) {
      (this.vendorLink) ? await this.getInitFilterCloth(this.vendorLink, "initialFilter=true&orderBy=1&pageNumber=1") : console.warn("undefined vendor link");
    }
    this.router.events.subscribe(async response => {
      if (response instanceof NavigationEnd) {
        let vendorLink2: string = this.activeRoute.snapshot.params["link"];
        if (this.vendorLink !== vendorLink2) {
          this.vendorLink = vendorLink2;
          if (!this.initQueryParamsInReloadPage(this.activeRoute.snapshot.queryParams)) {
            (this.vendorLink) ? await this.getInitFilterCloth(this.vendorLink, "initialFilter=true&orderBy=1&pageNumber=1") : console.warn("undefined vendor link");
          }
        }
      }
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateProductInfoFrom(): void {
    this.filterInfo = new FormGroup({
      id: new FormControl(null),
      size: new FormControl(null),
      color: new FormControl(null),
      gender: new FormControl(2),
      search: new FormControl(null),
      category: new FormControl(null),
      brand: new FormControl(null),
    })
  }

  /**
 * Returns x raised to the n-th power.
 *
 * @param {number} x The number to raise.
 * @param {number} n The power, must be a natural number.
 * @return {number} x raised to the n-th power.
 */
  initQueryParamsInReloadPage(queyrPararms: any): boolean {

    let isQueryParams: boolean = false;
    let queryValue: any;
    let checkedFlagsQueryParams: any = {
      page: "",
      orderBy: ""
    }
    if (!this.helper.isEmptyObject(queyrPararms)) {
      isQueryParams = true;
      for (let index in queyrPararms) {
        switch (index) {
          case QueryParam.PAGE:
            queryValue = Number(queyrPararms[index]);
            checkedFlagsQueryParams.page = queryValue;
            this.customeQueryParams.page = queryValue;
            this.queryParams += `pageNumber=${queryValue}&`;
            break;
          case QueryParam.ORDERBY:
            queryValue = Number(queyrPararms[index]);
            checkedFlagsQueryParams.orderBy = queryValue;
            this.customeQueryParams.orderBy = queryValue;
            this.actvieCategorySort = queryValue;
            this.queryParams += `orderBy=${queryValue}&`;
            break;
        }
      }
      for (let index = 0; index <= 5; index++) {
        for (let key in queyrPararms) {
          if (key === `colorLabels_${index}`) {
            queryValue = queyrPararms[key];
            this.customeQueryParams[`colorLabels_${index}`] = queryValue;
            checkedFlagsQueryParams[`colorLabels_${index}`] = { value: queryValue, falg: true };
            this.queryParams += `&colorLabels_${index}=${queryValue}`;
          }
          if (key === `brandTitles_${index}`) {
            queryValue = queyrPararms[key];
            this.customeQueryParams[`brandTitles_${index}`] = queryValue;
            checkedFlagsQueryParams[`brandTitles_${index}`] = { value: queryValue, falg: true };
            this.queryParams += `&brandTitles_${index}=${queryValue}`;
          }
        }
      }
      if (this.actvieCategorySort === CategoryOrderSort.NEWEST) {
        this.orderName = 'جدید ترین';
      }
      if (this.actvieCategorySort === CategoryOrderSort.EXPENSIVE) {
        this.orderName = 'گران ترین';
      }
      if (this.actvieCategorySort === CategoryOrderSort.CHEAPEST) {
        this.orderName = 'ارزان ترین';
      }
      this.queryParams = "initialFilter=true&".concat(this.queryParams);
      this.onGoToPage(checkedFlagsQueryParams.page, this.queryParams, true)
    }
    return isQueryParams;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  async getInitFilterCloth(vendorLink?: string, queryPararms?: string): Promise<void> {
    this.pageLoading = true;
    this.vendorPageApiService.getVendorFilterCloth(vendorLink, queryPararms).subscribe(async response => {
      this.pageLoading = false;
      this.productList = response['data']['listOfProducts'];;
      this.listOfCategories = response['data']['listOfCategories'];
      this.listOfColorLabels = response['data']['listOfColorLabels'];
      this.listOfBrands = response['data']['listOfBrands'];
      this.vendorInfo = response['data']['vendorInfo'];
      this.manageHeader.updateTitle(this.vendorInfo.shopName);
      this.observable.add(this.vendorInfo.shopName);
      for (let index = 0; index < this.productList.length; index++) {
        this.productList[index].realPrice = this.helper.calculateDiscount(this.productList[index].price, this.productList[index].discount, 1);
      }
      this.filterDuplicateColorSizes(this.listOfColorLabels);
      this.options.ceil = response['data'].maxPrice;
      this.options.floor = response['data'].minPrice;
      this.hightValue = response['data'].maxPrice;
      this.lowValue = response['data'].minPrice;
      this.pagination = await this.helper.paginate(response['data'].count, this.currentPage, this.pageSize);
      this.setPaginateValue(this.pagination);
    }, error => {
      this.pageLoading = false;
      this.helper.toaster('از سمت سرور خطای رخ داده', ToasterType.ERROR);
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getInitFilterClothByPagination(vendorLink?: string, queryPararms?: string): void {
    this.sortDropDown = false;
    this.vendorPageApiService.getVendorFilterCloth(vendorLink, queryPararms).subscribe(async response => {
      this.buttonLoading = false;
      this.pageLoading = false;
      this.isShowFilters = false;
      this.observable.addFilter(false);
      this.productList = response['data']['listOfProducts'];;
      this.vendorInfo = response['data']['vendorInfo'];
      for (let index = 0; index < this.productList.length; index++) {
        this.productList[index].realPrice = this.helper.calculateDiscount(this.productList[index].price, this.productList[index].discount, 1);
      }
      this.filterDuplicateColorSizes(this.listOfColorLabels);
      this.pagination = await this.helper.paginate(response['data'].count, this.currentPage, this.pageSize);
      this.setPaginateValue(this.pagination);
    }, error => {
      this.isShowFilters = false;
      this.pageLoading = false;
      this.helper.toaster('از سمت سرور خطای رخ داده', ToasterType.ERROR);
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  setPaginateValue(pagination: Paginate): void {
    this.totalPage = pagination.totalPages;
    this.pages = pagination.pages;
    this.currentPage = pagination.currentPage;
  }
  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onGoToPage(currentPage: number, queryPararms?: string, isReloadPage?: boolean): void {
    this.currentPage = currentPage;
    if (isReloadPage) {
      this.getInitFilterCloth(this.vendorLink, queryPararms);
    } else {
      this.customeQueryParams.page = currentPage;
      this.customeQueryParams.orderBy = this.actvieCategorySort;
      this.queryParams = `initialFilter=false&orderBy=${this.actvieCategorySort}&pageNumber=${currentPage}`;
      this.latestQueryParams = `initialFilter=false&orderBy=${this.actvieCategorySort}&pageNumber=${currentPage}`;
      this.getInitFilterClothByPagination(this.vendorLink, this.queryParams);
    }
    this.router.navigate(['vendor/' + this.vendorLink], { queryParams: this.customeQueryParams });
    this.helper.scrollTopByElementId('top');
  }
  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onNextPage(): void {
    if (this.currentPage < this.pagination.totalPages) {
      this.currentPage++;
      this.customeQueryParams.page = this.currentPage;
      this.customeQueryParams.orderBy = CategoryOrderSort.NEWEST;
      this.queryParams = `initialFilter=false&orderBy=${this.actvieCategorySort}&pageNumber=${this.currentPage}`;
      this.latestQueryParams = `initialFilter=fals&orderBy=${this.actvieCategorySort}&pageNumber=${this.currentPage}`;
      this.router.navigate(['vendor/' + this.vendorLink], { queryParams: this.customeQueryParams });
      this.getInitFilterClothByPagination(this.vendorLink, this.queryParams);
      this.helper.scrollTopByElementId('top');
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onPreviousPage(): void {
    if (this.currentPage <= this.pagination.endPage && this.currentPage > this.pagination.startPage) {
      this.currentPage--;
      this.customeQueryParams.page = this.currentPage;
      this.customeQueryParams.orderBy = this.actvieCategorySort;
      this.queryParams = `initialFilter=false&orderBy=${this.actvieCategorySort}&pageNumber=${this.currentPage}`;
      this.latestQueryParams = `initialFilter=false&orderBy=${this.actvieCategorySort}&pageNumber=${this.currentPage}`;
      this.router.navigate(['vendor/' + this.vendorLink], { queryParams: this.customeQueryParams });
      this.getInitFilterClothByPagination(this.vendorLink, this.queryParams);
      this.helper.scrollTopByElementId('top');
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  filterDuplicateColorSizes(listOfColorLabels: any[]): void {
    for (let index = 0; index < listOfColorLabels.length; index++) {
      if (this.listOfFilterColors.length === 0) {
        this.listOfFilterColors.push(listOfColorLabels[index]);
      } else {
        let color = this.listOfFilterColors.find(x => x.title === listOfColorLabels[index].title);
        if (!color) {
          this.listOfFilterColors.push(listOfColorLabels[index]);
        }
      }
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onFinalFilter(): void {
    if (this.queryParams) {
      this.customeQueryParams.page = 1;
      this.customeQueryParams.orderBy = 1;
      this.buttonLoading = true;
      this.router.navigate(['vendor/' + this.vendorLink], { queryParams: this.customeQueryParams });
      this.getInitFilterClothByPagination(this.vendorLink, this.queryParams);
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getSimilarProductByFilter(): void {
    this.productApiService.getFilterCloth(this.queryParams).subscribe(response => {
      this.productList = response['data']['listOfProducts'];
      for (let index = 0; index < this.productList.length; index++) {
        this.productList[index].realPrice = this.helper.calculateDiscount(this.productList[index].price, this.productList[index].discount, 1);
      }
    }, error => {
      this.helper.toaster('از سمت سرور خطای رخ داده', ToasterType.ERROR);
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getProductListBycategoryOrder(order: number): void {

    if (order === CategoryOrderSort.NEWEST) {
      this.orderName = 'جدید ترین';
      this.customeQueryParams.orderBy = CategoryOrderSort.NEWEST;
    }
    if (order === CategoryOrderSort.EXPENSIVE) {
      this.orderName = 'گران ترین';
      this.customeQueryParams.orderBy = CategoryOrderSort.EXPENSIVE;
    }
    if (order === CategoryOrderSort.CHEAPEST) {
      this.orderName = 'ارزان ترین';
      this.customeQueryParams.orderBy = CategoryOrderSort.CHEAPEST;
    }
    this.actvieCategorySort = order;
    this.customeQueryParams.page = this.currentPage;
    this.queryParams = `initialFilter=false&orderBy=${this.actvieCategorySort}&pageNumber=${this.currentPage}`;
    this.latestQueryParams = `initialFilter=false&orderBy=${this.actvieCategorySort}&pageNumber=${this.currentPage}`;
    this.router.navigate(['vendor/' + this.vendorLink], { queryParams: this.customeQueryParams });
    this.getInitFilterClothByPagination(this.vendorLink, this.queryParams);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  gender: number = 0;
  filterByGender(genderValue: number): void {
    this.gender = genderValue;
    this.parseQueryParam();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  listOfFilterCategories: Category[] = [];
  filterByCategories(category: Category, index: number): void {
    if (this.listOfFilterCategories.length === 0) {
      this.listOfFilterCategories.push(category);
      this.parseQueryParam();
      return;
    }
    let categoryIndex: number = this.listOfFilterCategories.findIndex(x => x.id === category.id);
    if (categoryIndex === -1) {
      this.listOfFilterCategories.push(category);
    } else {
      this.listOfFilterCategories.splice(categoryIndex, 1);
    }
    this.parseQueryParam();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  listOfFilterBrands: Brand[] = [];
  filterByBrands(brand: Brand, index: number): void {
    if (this.listOfFilterBrands.length === 0) {
      this.listOfFilterBrands.push(brand);
      this.parseQueryParam();
      return;
    }
    let brandIndex: number = this.listOfFilterBrands.findIndex(x => x.id === brand.id);
    if (brandIndex === -1) {
      this.listOfFilterBrands.push(brand);
    } else {
      this.listOfFilterBrands.splice(brandIndex, 1);
    }
    this.parseQueryParam();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  listOfFilterColorLabels: any[] = [];
  filtrColorLabels(color: any): void {
    if (this.listOfFilterColorLabels.length === 0) {
      this.listOfFilterColorLabels.push(color);
      this.parseQueryParam();
      return;
    }
    let colorLabelIndex: number = this.listOfFilterColorLabels.findIndex(x => x.id === color.id);
    if (colorLabelIndex === -1) {
      this.listOfFilterColorLabels.push(color);
    } else {
      this.listOfFilterColorLabels.splice(colorLabelIndex, 1);
    }
    this.parseQueryParam();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  parseQueryParam(): void {

    let customeQueryParams: string = "";
    this.queryParams = this.latestQueryParams;

    if (this.gender !== 0) {
      this.queryParams += '&gender=' + this.gender + "&";
      this.customeQueryParams.gender = this.gender;
    }

    for (let index in this.listOfFilterCategories) {
      customeQueryParams += '&categoryLinks=' + this.listOfFilterCategories[index].title;
      this.customeQueryParams['categoryLinks_' + index] = this.listOfFilterCategories[index].title;
    }

    this.cleanCustomeQueryPararms('brandTitles');
    for (let index in this.listOfFilterBrands) {
      customeQueryParams += '&brandTitles=' + this.listOfFilterBrands[index].title;
      this.customeQueryParams['brandTitles_' + index] = this.listOfFilterBrands[index].title;
    }

    this.cleanCustomeQueryPararms('colorLabels');
    for (let index in this.listOfFilterColorLabels) {
      customeQueryParams += '&colorLabels=' + this.listOfFilterColorLabels[index].title;
      this.customeQueryParams['colorLabels_' + index] = this.listOfFilterColorLabels[index].title;
    }

    if (this.isFilterPrice) {
      customeQueryParams += `&minPrice=${this.lowValue}&maxPrice=${this.hightValue}&`;
      this.customeQueryParams.minPrice = this.lowValue;
      this.customeQueryParams.maxPrice = this.hightValue;
    }

    this.queryParams += customeQueryParams;
    this.router.navigate(['vendor/' + this.vendorLink], { queryParams: this.customeQueryParams });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  cleanCustomeQueryPararms(key: string): void {
    for (let index = 0; index < 20; index++) {
      if (this.customeQueryParams[`${key}_` + index] !== undefined) {
        delete this.customeQueryParams[`${key}_` + index];
      }
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  filterByPrice(): void {
    this.isFilterPrice = true;
    this.parseQueryParam();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  toggleFilters(index: number, filter: string): void {
    this.filtersToggle = index;
    this.filterName === filter ? this.filterName = "" : this.filterName = filter;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getScreenSize(event?: any): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1200) {
      this.showFilter = true;
    } else {
      this.showFilter = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onFilters(formControlName: string, id: string): void {
    this.tempArray.push(id);
    this.filterInfo.get(formControlName).patchValue(this.tempArray);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e: Event): void {
  //   if (this.screenWidth > 1200) {
  //     if (window.pageYOffset > 0) {
  //       document.getElementById('filterForm').classList.add('sticky-filter');
  //     }
  //   } else {
  //     return;
  //   }
  // }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  sortDropDown: boolean = false;
  toggleSortDropDown() {
    this.sortDropDown == true ? this.sortDropDown = false : this.sortDropDown = true;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  activeProductView: string = 'vertical';
  onChangeProductView(state: string) {
    if (state === 'vertical') {
      this.activeProductView = 'vertical';
    }
    if (state === 'horizental') {
      this.activeProductView = 'horizental';
    }
  }

}
