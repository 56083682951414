<div class="row">
  <div class="col-12 col-md-12 col-xl-3 d-none d-xl-block">
    <!-- user profile tab links start -->
    <ul class="profile-ul">

      <li class="profile-li">
        <a [routerLink]="['/user-club/user-profile/account']" [routerLinkActive]="['active-profile-links']"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/account.svg" alt="اطلاعات کاربری">
          <span class="profile-links-text">اطلاعات کاربری</span>
        </a>
      </li>

      <li class="profile-li">
        <a [routerLink]="['/user-club/user-profile/my-order']" [routerLinkActive]="['active-profile-links']"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/order.svg" alt="سفارشات من">
          <span class="profile-links-text">سفارشات من</span>
        </a>
      </li>

      <li class="profile-li">
        <a [routerLink]="['/user-club/user-profile/transaction']" [routerLinkActive]="['active-profile-links']"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/transaction.svg" alt="تراکنش های من">
          <span class="profile-links-text">تراکنش های من</span>
        </a>
      </li>

      <!-- <li class="profile-li">
        <a [routerLink]="['/user-club/user-profile/favorites']" [routerLinkActive]="['active-profile-links']"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/favorites.svg">
          <span class="profile-links-text">علاقه مندی های من</span>
        </a>
      </li> -->

      <li class="profile-li">
        <a [routerLink]="['/user-club/user-profile/addresses']" [routerLinkActive]="['active-profile-links']"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/address.svg" alt="آدرس های من">
          <span class="profile-links-text">آدرس های من</span>
        </a>
      </li>

      <li class="profile-li">
        <a [routerLink]="['/user-club/user-profile/support']" [routerLinkActive]="['active-profile-links']"
          class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/support.svg" alt="پشتیبانی">
          <span class="profile-links-text">پشتیبانی</span>
        </a>
      </li>

      <li class="profile-li">
        <a data-toggle="modal" data-target="#logOutModal" class="profile-li-links">
          <img class="profile-links-icon" src="../../../assets/images/core/logout.svg" alt="خروج از حساب کاربری">
          <span class="profile-links-text logout-link-text">خروج از حساب کاربری</span>
        </a>
      </li>

    </ul>
    <!-- user profile tab links end -->
  </div>
  <div class="col-12 col-md-12 col-xl-9">
    <router-outlet></router-outlet>
  </div>
</div>


<div class="modal" id="logOutModal" tabindex="-1" role="dialog" aria-labelledby="logOutModal" aria-hidden="true">
  <app-confirm-modal [ButtonClass]="'confirm-approved'" [inputTitle]="confirmTitle" [inputContent]='confirmMessage'
    [inputConfirmButtonText]="confirmbutton" (outputAccepted)="onLogOut($event)" [inputButtonLoading]="buttonLoading">
  </app-confirm-modal>
</div>
