import { Component, OnInit } from '@angular/core';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-unsuccessfull-payment',
  templateUrl: './unsuccessfull-payment.component.html',
  styleUrls: ['./unsuccessfull-payment.component.less']
})
export class UnsuccessfullPaymentComponent implements OnInit {
  headerTitle: string = 'کاریزماکالر | پرداخت ناموفق'

  constructor(
    private manageHeader: ManageHeaderService
  ) { }

  ngOnInit(): void {
    this.manageHeader.updateTitle(this.headerTitle);

  }

}
