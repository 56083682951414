<div class="container">
  <div class="control">
    <!-- auth header start -->
    <div class="row sign-in-header">
      <div class="col go-back-col">
        <button (click)="onGoBack()" class="btn" type="button">
          <span class="button-span">بازگشت به سایت</span>
          <span class="back-arrow-wrapper">
            <span class="arrows"></span>
            <span class="arrow-line"></span>
            <span class="arrows"></span>
          </span>
        </button>
      </div>
      <div class="carisma-logo-col col">
        <img class="carisma-logo-icon" src="../../../../assets/images/core/Carisma-Logo-Nav.svg" alt="">
      </div>
    </div>
    <!-- auth header end -->
    <div class="body-control row seller-body-control justify-content-between">
      <!-- auth get mobile number start -->
      <div class="form col-12 col-md-4 col-xl-3 align-self-start">
        <form [formGroup]="sellerInfo">
          <div class="form-group form-group-custome mt-0">
            <h1 class="form-group-custome-label sign-in-label seller-label">
              ثبت نام در باشگاه
              <span class="sign-in-label-span">فروشندگان کاریزماکالر</span>
            </h1>
            <div class="sign-in-button-container ml-2">
              <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome seller-form-group'"
                [placeholder]="'تلفن همراه'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input'" [control]="sellerInfo.get('mobile')"
                [minLength]="3" [maxLength]="2000" [id]="'mobile'" [name]="'mobile'" [formGroup]="sellerInfo"
                [label]="'تلفن همراه'">
              </app-form-input>
              <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome seller-form-group'"
                [placeholder]="'نام و نام خانوادگی'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input'" [control]="sellerInfo.get('FullName')"
                [minLength]="3" [maxLength]="2000" [id]="'FullName'" [name]="'FullName'" [formGroup]="sellerInfo"
                [label]="'نام و نام خانوادگی'">
              </app-form-input>
              <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome seller-form-group'"
                [placeholder]="'عنوان دسته بندی فعالیت'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input'" [control]="sellerInfo.get('productType')"
                [minLength]="3" [maxLength]="2000" [id]="'productType'" [name]="'productType'" [formGroup]="sellerInfo"
                [label]="'عنوان دسته بندی فعالیت'">
              </app-form-input>
              <div class="sign-in-mobile mt-2" [formGroup]="recaptchaReactiveInfo">
                <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"
                  siteKey="6LcZ3tUaAAAAAHTvCJlPfJT_1RizXNungVgpuSaF" required>
                </re-captcha>
                <span
                  *ngIf="recaptchaReactiveInfo.controls['recaptchaReactive'].hasError('required') && recaptchaReactiveInfo.get('recaptchaReactive').touched"
                  class="help-block">
                  روی من ربات نیستم کلیک کنید
                </span>
              </div>
              <button (click)="onCreate()" [disabled]="buttonLoading" class="carisma-button medium-button">
                <span *ngIf="!buttonLoading" class="button-text-span" id="sign-button">{{ textSign }}</span>
                <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core//loading.gif"
                  alt="بارگذاری">
              </button>
            </div>
          </div>
        </form>
      </div>
      <!-- auth get mobile number end -->
      <!-- auth image container start -->
        <div class="col-12 col-md-8 col-xl-4">
          <h2 class="seller-advantages-header">مزایا فروش در کاریزما کالر</h2>
          <ul class="seller-advantages">
            <li class="seller-advantages-li">تسویه حساب آنی</li>
            <li class="seller-advantages-li">عدم دریافت کمیسیون تا ۶ ماه</li>
            <li class="seller-advantages-li">پنل حسابداری برای هر فروشنده</li>
            <li class="seller-advantages-li">طرح های تخفیفی کاریزماکالر برای مشتریان شما</li>
          </ul>
        </div>
      <div class="seller-text-col image justify-content-md-center  col-10 offset-1 col-sm-12 col-md-12 offset-md-0 col-xl-5">
        <img class="img" src="../../../assets/images/core/shopPic.png" alt="فروشنده">
      </div>
      <!-- auth image container end -->
    </div>
  </div>
</div>
