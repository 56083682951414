import { BlogPost } from './../../shared/interfaces/blog-post';
import { Category } from './../../shared/interfaces/category';
import { Slider } from './../../shared/interfaces/slider';
import { Product } from './../../shared/interfaces/product';
import { Helper } from './../../shared/utilities/helper';
import { environment } from './../../../environments/environment';
import { HomeApiService } from './../../core/services/api/home-api.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { staticParentCategory } from './../../shared/statics/data';
import { Promotion } from 'src/app/shared/interfaces/promotion';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { ViewPart } from 'src/app/core/enums/view-part.enum';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  productList: Product[];
  listOfSliders: Slider[];
  listOfCategories: Category[] = [];
  listOfPopularProducts: Product[] = [];
  listOfSpecialProducts: Product[] = [];
  listOfPromotions: Promotion[] = [];
  listOfBlogPost: BlogPost[];
  staticParentCategory: any[] = staticParentCategory;
  baseUrl: any = environment.baseUrl;
  pageLoading: boolean = false;
  ravandTimeout: any;
  specialIntervalId: any;
  popularIntervalId: any;
  specialTotalSlideBox: number = 0;
  popularTotalSlideBox: number = 0;
  activeSpecialIndex: number = 0;
  activePopularIndex: number = 0;
  mainBoxSpecialSlider: any;
  mainBoxPopularSlider: any;
  specialCategoryId: string = "2c83cfd3-e84d-4c86-9930-8dc863f84293";
  popularCategoryId: string = "2c83cfd3-e84d-4c86-9930-8dc863f84293"
  specialCategoryName: string = 'لباس زنانه';
  popularCategoryName: string = 'لباس زنانه';

  @ViewChild("specialSlider", { static: true }) public specialSlider: ElementRef;
  @ViewChild("popularSlider", { static: true }) public popularSlider: ElementRef;
  @ViewChild('productCard') productCard: any;

  subscription: Subscription = new Subscription();
  headerTitle: string = 'فروشگاه اینترنتی لباس کاریزماکالر'

  constructor(
    private homeApiService: HomeApiService,
    private helper: Helper,
    private manageHeader: ManageHeaderService
  ) { }

  ngOnInit(): void {
    this.getviewHome();
    this.manageHeader.updateTitle(this.headerTitle);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  initalParamterSlider(): void {
    this.mainBoxSpecialSlider = $(this.specialSlider.nativeElement);
    this.mainBoxPopularSlider = $(this.popularSlider.nativeElement);
    this.mainBoxSpecialSlider.scrollLeft(0);
    this.mainBoxPopularSlider.scrollLeft(0);
    this.initAutoSpecialSlider();
    this.initAutPopularSlider();
  }

  ngOnDestroy(): void {
    if (this.specialIntervalId) {
      clearInterval(this.specialIntervalId);
    }
    if (this.popularIntervalId) {
      clearInterval(this.popularIntervalId);
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getviewHome(): void {
    this.pageLoading = true;
    this.homeApiService.getViewNewHome().subscribe(reponse => {
      this.productList = reponse['data'].listOfProducts;
      this.listOfSliders = reponse['data'].listOfSliders;
      this.listOfPromotions = reponse['data'].listOfPromotions;
      this.listOfPopularProducts = reponse['data'].listOfPopularProducts;
      this.listOfSpecialProducts = reponse['data'].listOfSpecialProducts;
      this.listOfCategories = reponse['data'].listOfCategories;
      this.listOfBlogPost = reponse['data'].listOfBlogPosts;
      this.initalParamterSlider();
      this.pageLoading = false;
    }, error => {
      this.pageLoading = false;
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getProductListSpecialByCategoryId(categoryId: string, viewPart: number, categoryName?: string): void {
    this.dropDownCategories = false;
    if (this.specialCategoryId === categoryId) {
      return;
    }
    if (categoryName) {
      this.specialCategoryName = categoryName;
    }
    this.specialCategoryId = categoryId;
    this.homeApiService.getProductByCategoryViewPart(categoryId, viewPart).subscribe(response => {
      this.listOfSpecialProducts = response['data'];
      if (this.listOfSpecialProducts.length >= 8) {
        this.clearTimeout(2);
        this.initAutoSpecialSlider();
      }
    }, error => {
      this.helper.toaster('از سمت سرور خطای رخ داده است', ToasterType.ERROR);
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getProductListPopularByCategoryId(categoryId: string, viewPart: number, categoryName?: string): void {
    this.dropDownCategories = false;
    if (this.popularCategoryId === categoryId) {
      return;
    }
    if (categoryName) {
      this.popularCategoryName = categoryName;
    }
    this.popularCategoryId = categoryId;
    this.homeApiService.getProductByCategoryViewPart(categoryId, viewPart).subscribe(response => {
      this.listOfPopularProducts = response['data'];
      if (this.listOfPopularProducts.length >= 8) {
        this.clearTimeout(3);
        this.initAutPopularSlider();
      }
    }, error => {
      this.helper.toaster('از سمت سرور خطای رخ داده است', ToasterType.ERROR);
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  clearTimeout(viewPart: number): void {
    if (viewPart === ViewPart.SPECIAL) {
      clearTimeout(this.specialIntervalId);
    }
    if (viewPart === ViewPart.POPULAR) {
      clearTimeout(this.popularIntervalId);
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  stopSlider(viewPart: number): void {
    // Avoid any further animation to be added.
    if (viewPart === ViewPart.SPECIAL) {
      // Stop the currently running animations.
      clearInterval(this.specialIntervalId);
      this.clearTimeout(viewPart);
      this.mainBoxSpecialSlider.stop(true);
    }
    if (viewPart === ViewPart.POPULAR) {
      // Stop the currently running animations.
      clearInterval(this.popularIntervalId);
      this.clearTimeout(viewPart);
      this.mainBoxPopularSlider.stop(true);
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  initAutoSpecialSlider(): void {
    // default box width 200px
    this.mainBoxSpecialSlider.css('width', (200 * this.listOfSpecialProducts.length));
    this.specialTotalSlideBox = (this.listOfSpecialProducts.length / 2);
    this.specialIntervalId = setInterval(() => {
      this.autoSpecialSlider();
    }, 10000);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  initAutPopularSlider(): void {
    // default box width 200px
    this.mainBoxPopularSlider.css('width', (200 * this.listOfPopularProducts.length));
    this.popularTotalSlideBox = (this.listOfPopularProducts.length / 2);
    this.popularIntervalId = setInterval(() => {
      this.autoPopularSlider();
    }, 10000);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  autoSpecialSlider(): void {
    if (this.activeSpecialIndex >= this.specialTotalSlideBox) {
      this.activeSpecialIndex = 0;
    } else {
      this.activeSpecialIndex++;
    }
    this.mainBoxSpecialSlider.animate({ scrollLeft: this.activeSpecialIndex * -260 + 'px' }, 800);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  autoPopularSlider(): void {
    if (this.activePopularIndex >= this.popularTotalSlideBox) {
      this.activePopularIndex = 0;
    } else {
      this.activePopularIndex++;
    }
    this.mainBoxPopularSlider.animate({ scrollLeft: this.activePopularIndex * -240 + 'px' }, 800);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  dropDownCategories: boolean = false;
  onOpenDropDownCategories(): void {
    this.dropDownCategories == true ? this.dropDownCategories = false : this.dropDownCategories = true;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onScrollProductSlider(state: string, viewPart: number): void {
    this.stopSlider(viewPart);
    if (state === 'next' && viewPart === ViewPart.SPECIAL) {
      this.mainBoxSpecialSlider.animate({ scrollLeft: 0 * -230 + 'px' }, 800);
    }
    if (state === 'perv' && viewPart === ViewPart.SPECIAL) {
      this.mainBoxSpecialSlider.animate({ scrollLeft: 4 * -230 + 'px' }, 800);
    }
    if (state === 'next' && viewPart === ViewPart.POPULAR) {
      this.mainBoxPopularSlider.animate({ scrollLeft: 0 * -230 + 'px' }, 800);
    }
    if (state === 'perv' && viewPart === ViewPart.POPULAR) {
      this.mainBoxPopularSlider.animate({ scrollLeft: 4 * -230 + 'px' }, 800);
    }
  }
}

  // initialClinetX: number;
  // newClinetX: number;
  // @HostListener('mousedown', ['$event'])
  // onMouseDown(event) {
  //   this.initialClinetX = event.offsetX;
  //   console.log('initialClinetX', this.initialClinetX);
  // }
  // @HostListener('mouseup', ['$event'])
  // onMouseUp(event) {
  //   this.newClinetX = event.offsetX;
  //   console.log('newClinetX', this.initialClinetX);
  //   let dragLength = this.initialClinetX - this.newClinetX;
  //   console.log('dragLength', dragLength);
  //   this.mainBoxSlider.animate({ scrollLeft: dragLength * -330 + 'px' }, 800);
  // }
