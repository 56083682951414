export interface Recommend {
  id: number,
  gender: number,
  character: string,
  celebrity: {
    picture: string,
    name: string,
    text: string
  },
  brands: object[],
  pictures: string[],
}


export const recommendData: Recommend[] = [
  {
    "gender": 0,
    "id": 1,
    "character": "خلاق",
    "celebrity": {
      "picture": "ethics-lady-gaga.jpeg", "name": "لیدی گاگا",
      "text": "افرادی هستن که آرامش و مدیتیشن و تنهایی رو ترجیح میدن و علاقه به استایلهای هنری دارن"
    },
    "brands": [{ "logo": "missoni-logo.jpg", "title": "missoni" }, { "logo": "gucci-logo.jpg", "title": "gucci" }],
    "pictures": ["ethics-mmissoni-1.jpg", "ethics-mmissoni-2.jpg", "ethics-mmissoni-3.jpg", "ethics-gucci-1.jpg", "ethics-gucci-2.jpg", "ethics-gucci-3.jpg"]
  },
  {
    "gender": 1,
    "id": 1,
    "character": "خلاق",
    "celebrity": {
      "picture": "ethics-jonny-depp.jpg", "name": "جانی دپ",
      "text": "افرادی هستن که آرامش و مدیتیشن و تنهایی رو ترجیح میدن و علاقه به استایلهای هنری دارن"
    },
    "brands": [{ "logo": "missoni-logo.jpg", "title": "missoni" }, { "logo": "gucci-logo.jpg", "title": "gucci" }],
    "pictures": ["ethics-men-more1.jpg", "ethics-men-more2.jpg", "ethics-men-more3.jpg", "ethics-men-more4.jpg", "ethics-men-more5.jpg", "ethics-men-more6.jpg"]
  },
  {
    "gender": 0,
    "id": 2,
    "character": "راحت پوش",
    "celebrity": {
      "picture": "conftable-hailey-bieber.jpg", "name": "هیلی بیبر",
      "text": "همینطور که از اسمش معلومه برای راحت پوش ها، راحتی و البته شیک بودن در درجه اول اهمیته. راحت پوش ها همیشه پایه و آماده به فعالیت هستند."
    },
    "brands": [{ "logo": "lascoste-logo.jpeg", "title": "lascoste" }, { "logo": "levis-logo.png", "title": "levis" }],
    "pictures": ["conftable-lacoste-2.jpg", "conftable-lacoste-3.jpg", "conftable-levis-3.jpg"]
  },
  {
    "gender": 1,
    "id": 2,
    "character": "راحت پوش",
    "celebrity": {
      "picture": "comftable-ajavd-ezatti.jpg", "name": "جواد عزتی",
      "text": "همینطور که از اسمش معلومه برای راحت پوش ها، راحتی و البته شیک بودن در درجه اول اهمیته. راحت پوش ها همیشه پایه و آماده به فعالیت هستند."
    },
    "brands": [{ "logo": "lascoste-logo.jpeg", "title": "lascoste" }, { "logo": "levis-logo.png", "title": "levis" }],
    "pictures": ["conftable-lacoste-1.jpg"]
  },
  {
    "gender": 0,
    "id": 3,
    "character": "طبیعی",
    "celebrity": {
      "picture": "natural-jennider-aniston.jpg", "name": "جنیفر انیستون",
      "text": "هیچی چیزی برای یک شخصیت طبیعی مطلوب تر از این نیست که همونی که هست به چشم بیاد.بدون ذره ای اغراق و دست بردن در هارمونی طبیعت."
    },
    "brands": [{ "logo": "lagarconne-logo.jpg", "title": "la garçonne" }, { "logo": "balck-grane-logo.png", "title": "black crane" }],
    "pictures": ["natural-black_crane-1.jpg", "natural-black_crane-2.jpg", "natural-black_crane-3.jpg", "natural-lagarconne-1.jpg", "natural-lagarconne-2.jpg", "natural-lagarconne-3.jpg"]
  },
  {
    "gender": 1,
    "id": 3,
    "character": "طبیعی",
    "celebrity": {
      "picture": "natural-farhad-aeish.jpg", "name": "فرهاد آییش",
      "text": "هیچی چیزی برای یک شخصیت طبیعی مطلوب تر از این نیست که همونی که هست به چشم بیاد.بدون ذره ای اغراق و دست بردن در هارمونی طبیعت."
    },
    "brands": [{ "logo": "lagarconne-logo.jpg", "title": "la garçonne" }, { "logo": "balck-grane-logo.png", "title": "black crane" }],
    "pictures": ["natural-men-more-1.jpg", "natural-men-more-2.jpg", "natural-men-more-3.jpg"]
  },
  {
    "gender": 0,
    "id": 4,
    "character": "عاطفی",
    "celebrity": {
      "picture": "emotional-ariana-grande.jpg", "name": "آریانا گرانده",
      "text": "ک شخصیت عاطفی حتی با لباسش هم داره پیام قدرتمند احساسات و عشق رو انتقال میده. پوشش یک شخصیت عاطفی نمایانگر لطافت احساس عشقه."
    },
    "brands": [{ "logo": "eliesaab-logo.jpg", "title": "elie saab" }, { "logo": "marchesa.jpg", "title": "marchesa" }],
    "pictures": ["emotional-eliesaabworld-1.jpg", "emotional-eliesaabworld-2.jpg", "emotional-eliesaabworld-3.jpg", "emotional-marchesafashion-1.jpg", "emotional-marchesafashion-2.jpg", "emotional-marchesafashion-3.jpg"]
  },
  {
    "gender": 1,
    "id": 4,
    "character": "عاطفی",
    "celebrity": {
      "picture": "emotional-parsa-piruzfar.jpg", "name": "پارسا پیروزفر",
      "text": "ک شخصیت عاطفی حتی با لباسش هم داره پیام قدرتمند احساسات و عشق رو انتقال میده. پوشش یک شخصیت عاطفی نمایانگر لطافت احساس عشقه."
    },
    "brands": [{ "logo": "eliesaab-logo.jpg", "title": "elie saab" }, { "logo": "marchesa.jpg", "title": "marchesa" }],
    "pictures": ["romantic-men-1.jpg", "romantic-men-2.jpg", "romantic-men-3.jpg"]
  },
  {
    "gender": 0,
    "id": 5,
    "character": "نمایشی",
    "celebrity": {
      "picture": "dramatic-billie-elish.jpg", "name": "بیلی ایلیش",
      "text": "اینکه همه توجه ها بهشون جلب بشه از مهم ترین خصوصیات اخلاقی شخصیت های نمایشیه."
    },
    "brands": [{ "logo": "versace.png", "title": "versace" }, { "logo": "dolceandgabbana.jpg", "title": "Dolce & Gabbana" }],
    "pictures": ["dramatic-versace-1.jpg", "dramatic-versace-2.jpg"]
  },
  {
    "gender": 1,
    "id": 5,
    "character": "نمایشی",
    "celebrity": {
      "picture": "dramatic-zayn-malik.jpg", "name": "زین مالک",
      "text": "اینکه همه توجه ها بهشون جلب بشه از مهم ترین خصوصیات اخلاقی شخصیت های نمایشیه."
    },
    "brands": [{ "logo": "versace.png", "title": "versace" }, { "logo": "dolceandgabbana.jpg", "title": "Dolce & Gabbana" }],
    "pictures": ["dramatic-versace-3.jpg", "dramatic-dolcegabbana-1.jpg", "dramatic-dolcegabbana-2.jpg", "dramatic-dolcegabbana-3.jpg"]
  },
  {
    "gender": 0,
    "id": 6,
    "character": "کلاسیک",
    "celebrity": {
      "picture": "classic-victoria-beckham.jpg", "name": "ویکتوریا بکام",
      "text": "استایل غالب شما کلاسیک است کلاسیک ها همیشه دوست دارند نمادی از نظم و هارمونی و وقار باشند"
    },
    "brands": [{ "logo": "alexandermaqueen-logo.jpg", "title": "alexander mcqueen" }, { "logo": "massimodutti-logo.jpg", "title": "massimo dutti" }],
    "pictures": ["classic-alexandermcqueen-3.jpg", "classic-massimodutti-1.jpg", "classic-woman-more-1.jpg", "classic-woman-more-2.jpg", "classic-woman-more-3.jpg"]
  },
  {
    "gender": 1,
    "id": 6,
    "character": "کلاسیک",
    "celebrity": {
      "picture": "classic-amirhoseinarman.jpg", "name": "امیر حسین آرمان",
      "text": "استایل غالب شما کلاسیک است کلاسیک ها همیشه دوست دارند نمادی از نظم و هارمونی و وقار باشند"
    },
    "brands": [{ "logo": "alexandermaqueen-logo.jpg", "title": "alexander mcqueen" }, { "logo": "massimodutti-logo.jpg", "title": "massimo dutti" }],
    "pictures": ["classic-alexandermcqueen-1.jpg", "classic-alexandermcqueen-2.jpg", "classic-massimodutti-2.jpg", "classic-massimodutti-3.jpg"]
  },
];
