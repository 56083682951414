import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from './core/services/storage/local-storage.service';
import { Helper } from './shared/utilities/helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  title = 'user-panel';

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private helper: Helper
  ) { }

  async ngOnInit(): Promise<void> {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (evt.url.indexOf("?") === -1) {
        // window.scroll(0, 0);
      }
      this.helper.createLinkForCanonicalURL();
    });
    await this.localStorageService.checkVersion(1);
  }
}




