import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  getViewProfile() {
    return this.httpConnection.get('customer/CustomerPersonalInformations/GetViewProfile');
  }

  putUpdateUserProfile(accountInfo: any) {
    return this.httpConnection.put('customer/CustomerPersonalInformations/PutUpdateUserProfile', accountInfo);
  }

  putUpdatePersonalInformation(styleInfo: any) {
    return this.httpConnection.put('customer/CustomerPersonalInformations/PutUpdatePersonalInformation', styleInfo);
  }


}
