<div [class]="formGroupClass" [formGroup]="formGroup" [ngClass]="extraClass"
  [class.invalid]="control.touched && control.invalid">
  <label *ngIf="label" [class]="labelCssClass">{{label}}</label>
  <a *ngIf="guidesLinks" [class]="guidesLinksCssClass" (click)="onClickGuides">
    [
    <span>{{guidesLinks}}</span>
    ]
  </a>
  <input [type]="type" [placeholder]="placeholder" [attr.name]="name" [attr.id]="id" [formControl]="control"
    [maxlength]="maxLength" [minLength]="minLength" [class]="inputCssClass" autocomplete="off" (blur)="blur()"
    (focusout)="onChangeToNumber(control.value)" />
  <div class="error-msg" *ngIf="control.errors && control.touched">
    <div>
      <!-- {{ '[' + label + ' ' + control.errors[objectFn.keys(control.errors)[0]] + ']' }} -->
      {{ '[' + ' ' + control.errors[objectFn.keys(control.errors)[0]] + ']' }}
    </div>
  </div>
</div>
