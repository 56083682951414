import { Observable } from 'src/app/core/services/observable/observable';
import { Helper } from './../../shared/utilities/helper';
import { LocalStorageService } from './../../core/services/storage/local-storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.less']
})
export class UserProfileComponent implements OnInit {

  buttonLoading: boolean = false;
  confirmTitle: string = 'آیا میخواهید خارج شوید؟';
  confirmMessage: string = 'با خروج به حساب کاربری و سبد خرید دسترسی نخواهید داشت.';
  confirmbutton: string = 'بله، خارج می شوم';

  constructor(
    private localStorageService: LocalStorageService,
    private helper: Helper,
    private observable: Observable
  ) { }

  ngOnInit(): void {
  }

 /**
 * Returns x raised to the n-th power.
 *
 * @param {number} x The number to raise.
 * @param {number} n The power, must be a natural number.
 * @return {number} x raised to the n-th power.
 */
  onLogOut(eventDeleted: boolean): void {
    if (eventDeleted) {
      this.localStorageService.remove('userInfo');
      this.helper.redirectTo('/');
      this.buttonLoading = eventDeleted;
      this.observable.add({ eventModal: eventDeleted });
    }
  }
}
