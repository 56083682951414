<div *ngIf="questionnaireState < 58" class="container-fluid">
  <div class="row questionnaire-header-row">
    <div class="col-12 d-flex justify-content-between my-2 align-items-center">
      <label [ngClass]="{'invisible': questionnaireState === 1, 'section-title-long': questionnaireState === 56}"
        class="section-title">
        <span *ngIf="questionnaireState < 4">بخش اول</span>
        <span *ngIf="questionnaireState > 3 && questionnaireState < 45">بخش دوم</span>
        <span *ngIf="questionnaireState > 44 &&  questionnaireState <= 55">بخش سوم</span>
        <span *ngIf="questionnaireState == 57">بخش آناتومی بدن</span>
        <span class="ml-1">
          (
          مرحله
          {{ questionnaireState - 1 }}
          از
          56
          )
        </span>
      </label>
      <img class="questionnaire-logo" src="../../../assets/images/core/carisma-logo-top-with-typo.png"
        alt="کاریزماکالر">
    </div>
  </div>
  <div class="row questionnaire-body-row" [ngSwitch]="questionnaireState">
    <div *ngSwitchCase="1" class="col-12 height-100">
      <div class="row height-100">
        <div *ngIf="authState === 'mobile'" class="col-12 col-xl-4">
          <label class="auth-welcome-text">
            سلام دوست عزیز !
            <br>
            پیشاپیش از وقتی که میگذاری تشکر میکنیم.
          </label>
          <p class="auth-wecolme-long-text">
            این پرسشنامه، شامل سه بخش هست که در صورت تکمیل هر بخش، یک امتیاز قرعه کشی به شما تعلق می گیره.برای شروع اسم
            و شماره همراهت را وارد کن تا امتیاز هات به نامت ثبت بشه
          </p>
          <form [formGroup]="authInfo">

            <!-- <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
              [placeholder]="'نام را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
              [inputCssClass]="' form-control form-group-custome-input '" [control]="authInfo.get('name')"
              [minLength]="3" [maxLength]="2000" [id]="'name'" [name]="'name'" [formGroup]="authInfo" [label]="'نام'">
            </app-form-input>

            <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
              [placeholder]="'نام خانوادگی را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
              [inputCssClass]="' form-control form-group-custome-input '" [control]="authInfo.get('lastName')"
              [minLength]="3" [maxLength]="2000" [id]="'lastName'" [name]="'lastName'" [formGroup]="authInfo"
              [label]="'نام خانوادگی'">
            </app-form-input> -->

            <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
              [placeholder]="'شماره تماس را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
              [inputCssClass]="' form-control form-group-custome-input '" [control]="authInfo.get('phoneNumber')"
              [minLength]="3" [maxLength]="2000" [id]="'phoneNumber'" [name]="'phoneNumber'" [formGroup]="authInfo"
              [label]="'شماره تماس'">
            </app-form-input>

            <!-- <div class="d-flex">

              <label class="custome-checkbox radio-large radio-large-with-text ml-2">
                <input class="custome-checkbox-input" type="radio" name="gender" [value]="0" [id]="'gender'"
                  formControlName="gender">
                <span class="custome-checkbox-span questionnaire-radio-span">آقا</span>
              </label>

              <label class="custome-checkbox radio-large radio-large-with-text ml-3">
                <input class="custome-checkbox-input" type="radio" name="gender" [value]="1" [id]="'gender'"
                  formControlName="gender">
                <span class="custome-checkbox-span questionnaire-radio-span">خانم</span>
              </label>
            </div> -->

          </form>
          <div class="sign-in-mobile" [formGroup]="recaptchaReactiveInfo">
            <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"
              siteKey="6LcZ3tUaAAAAAHTvCJlPfJT_1RizXNungVgpuSaF" required>
            </re-captcha>
            <span
              *ngIf="recaptchaReactiveInfo.controls['recaptchaReactive'].hasError('required') && recaptchaReactiveInfo.get('recaptchaReactive').touched"
              class="help-block">
              بر روی من ربات نیستم کلیک کنید
            </span>
          </div>
          <div class="d-flex justify-content-end">
            <button (click)="onRegister()" class="medium-button" type="button">
              <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
                alt="بارگذاری">
              <span *ngIf="!buttonLoading">{{ textSign }}</span>
            </button>
          </div>
        </div>
        <div *ngIf="authState === 'password'" class="col-12 col-xl-4">
          <label class="auth-welcome-text">
            سلام دوست عزیز !
            <br>
            پیشاپیش از وقتی که میگذاری تشکر میکنیم.
          </label>
          <p class="auth-wecolme-long-text">
            کلمه عبور از طریق پیامک به شماره وارد شده ارسال شد، لطفا کلمه عبور را در باکس زیر وارد نمایید.
          </p>
          <form [formGroup]="authInfo">

            <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
              [placeholder]="'کلمه عبور را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
              [inputCssClass]="' form-control form-group-custome-input '" [control]="authInfo.get('password')"
              [minLength]="3" [maxLength]="2000" [id]="'password'" [name]="'password'" [formGroup]="authInfo"
              [label]="'کلمه عبور'">
            </app-form-input>

          </form>
          <div class="d-flex justify-content-end">
            <button (click)="onLogin()" class="medium-button" type="button" [disabled]="buttonLoading">
              <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
                alt="بارگذاری">
              <span *ngIf="!buttonLoading">شروع</span>
            </button>
          </div>
        </div>
        <div class="col-12 col-xl-8 questionnaire-image-col">
          <img class="image" src="../../../assets/images/questionnaire/woman.png" alt="پرسشنامه">
        </div>
      </div>
    </div>
    <div *ngSwitchCase="2" class="col-12 height-100">
      <div class="row height-100">
        <div class="col-12 col-xl-4">
          <form [formGroup]="personalInfo">
            <!-- <div class="my-2">
              <label class="questions">1-قد؟ (سانتیمتر)</label>
              <div class="questionnire-radio-wrapper">

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="height" [value]="'150'" [id]="'height'"
                    formControlName="height">
                  <span class="custome-checkbox-span questionnaire-radio-span">کمتر از 150</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="height" [value]="'150-160'" [id]="'height'"
                    formControlName="height">
                  <span class="custome-checkbox-span questionnaire-radio-span">150-160</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="height" [value]="'160-170'" [id]="'height'"
                    formControlName="height">
                  <span class="custome-checkbox-span questionnaire-radio-span">160-170</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="height" [value]="'170-180'" [id]="'height'"
                    formControlName="height">
                  <span class="custome-checkbox-span questionnaire-radio-span">170-180</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="height" [value]="'180-190'" [id]="'height'"
                    formControlName="height">
                  <span class="custome-checkbox-span questionnaire-radio-span">180-190</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="height" [value]="'190'" [id]="'height'"
                    formControlName="height">
                  <span class="custome-checkbox-span questionnaire-radio-span">190 به بالا</span>
                </label>

              </div>
            </div>
            <div class="my-2">
              <label class="questions">1-وزن؟ (کیلوگرم)</label>
              <div class="questionnire-radio-wrapper">

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="weight" [value]="'45'" [id]="'weight'"
                    formControlName="weight">
                  <span class="custome-checkbox-span questionnaire-radio-span">زیر 45</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="weight" [value]="'45-60'" [id]="'weight'"
                    formControlName="weight">
                  <span class="custome-checkbox-span questionnaire-radio-span">45-60</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="weight" [value]="'60-75'" [id]="'weight'"
                    formControlName="weight">
                  <span class="custome-checkbox-span questionnaire-radio-span">60-75</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="weight" [value]="'75-90'" [id]="'weight'"
                    formControlName="weight">
                  <span class="custome-checkbox-span questionnaire-radio-span">75-90</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="weight" [value]="'90-105'" [id]="'weight'"
                    formControlName="weight">
                  <span class="custome-checkbox-span questionnaire-radio-span">90-105</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="weight" [value]="'105'" [id]="'weight'"
                    formControlName="weight">
                  <span class="custome-checkbox-span questionnaire-radio-span">105 به بالا</span>
                </label>

              </div>
            </div>
            <div class="my-2">
              <label class="questions">1-سن؟</label>
              <div class="questionnire-radio-wrapper">

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="age" [value]="'15'" [id]="'age'"
                    formControlName="age">
                  <span class="custome-checkbox-span questionnaire-radio-span">زیر 15 سال</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="age" [value]="'15-20'" [id]="'age'"
                    formControlName="age">
                  <span class="custome-checkbox-span questionnaire-radio-span">15-20</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="age" [value]="'20-30'" [id]="'age'"
                    formControlName="age">
                  <span class="custome-checkbox-span questionnaire-radio-span">20-30</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="age" [value]="'30-40'" [id]="'age'"
                    formControlName="age">
                  <span class="custome-checkbox-span questionnaire-radio-span">30-40</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="age" [value]="'40-50'" [id]="'age'"
                    formControlName="age">
                  <span class="custome-checkbox-span questionnaire-radio-span">40-50</span>
                </label>

                <label class="custome-checkbox radio-large">
                  <input class="custome-checkbox-input" type="radio" name="age" [value]="'50'" [id]="'age'"
                    formControlName="age">
                  <span class="custome-checkbox-span questionnaire-radio-span">50 سال به بالا</span>
                </label>

              </div>
            </div> -->
            <app-form-input [type]="'number'" [formGroupClass]="'form-group form-group-custome'"
              [placeholder]="'قد را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
              [inputCssClass]="' form-control form-group-custome-input '" [control]="personalInfo.get('height')"
              [id]="'height'" [name]="'height'" [formGroup]="personalInfo" [label]="'قد'">
            </app-form-input>
            <app-form-input [type]="'number'" [formGroupClass]="'form-group form-group-custome'"
              [placeholder]="'وزن را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
              [inputCssClass]="' form-control form-group-custome-input '" [control]="personalInfo.get('weight')"
              [id]="'weight'" [name]="'weight'" [formGroup]="personalInfo" [label]="'وزن'">
            </app-form-input>
            <app-form-input [type]="'number'" [formGroupClass]="'form-group form-group-custome'"
              [placeholder]="'سن را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
              [inputCssClass]="' form-control form-group-custome-input '" [control]="personalInfo.get('age')"
              [id]="'age'" [name]="'age'" [formGroup]="personalInfo" [label]="'سن'">
            </app-form-input>
          </form>
          <div class="d-flex justify-content-between justify-content-xl-end">
            <button (click)="perv(2)" class="medium-button grey-button" type="button">قبلی</button>
            <button (click)="next(2)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
              <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
                alt="بارگذاری">
              <span *ngIf="!buttonLoading">بعدی</span>
            </button>
          </div>
        </div>
        <div class="col-12 col-xl-8 questionnaire-image-col">
          <img class="image" src="../../../assets/images/questionnaire/pink-girl.png" alt="پرسشنامه">
        </div>
      </div>
    </div>
    <div *ngSwitchCase="3" class="col-12 height-100">
      <div class="row height-100">
        <div class="col-12 col-xl-4 height-100 d-flex flex-column justify-content-between">
          <label class="questions">رنگ پوست خود را انتخاب کنید</label>
          <form [formGroup]="personalInfo">
            <div class="colors-wrapper">

              <div class="questionnaire-color-wrapper">
                <input class="hidden colorInput" type="radio" name="skin" id="color1" [value]="'#3b1f1b'"
                  formControlName="skin">
                <label style="background-color: #3b1f1b;" class="questionnaire-color-labels" for="color1"></label>
              </div>

              <div class="questionnaire-color-wrapper">
                <input class="hidden colorInput" type="radio" name="skin" id="color2" [value]="'#a65f2b'"
                  formControlName="skin">
                <label style="background-color: #a65f2b;" class="questionnaire-color-labels" for="color2"></label>
              </div>

              <div class="questionnaire-color-wrapper">
                <input class="hidden colorInput" type="radio" name="skin" id="color3" [value]="'#bd7952'"
                  formControlName="skin">
                <label style="background-color: #bd7952;" class="questionnaire-color-labels" for="color3"></label>
              </div>

              <div class="questionnaire-color-wrapper">
                <input class="hidden colorInput" type="radio" name="skin" id="color4" [value]="'#d19f7c'"
                  formControlName="skin">
                <label style="background-color: #d19f7c;" class="questionnaire-color-labels" for="color4"></label>
              </div>

              <div class="questionnaire-color-wrapper">
                <input class="hidden colorInput" type="radio" name="skin" id="color5" [value]="'#e9b58e'"
                  formControlName="skin">
                <label style="background-color: #e9b58e;" class="questionnaire-color-labels" for="color5"></label>
              </div>

              <div class="questionnaire-color-wrapper">
                <input class="hidden colorInput" type="radio" name="skin" id="color6" [value]="'#f7d0b1'"
                  formControlName="skin">
                <label style="background-color: #f7d0b1;" class="questionnaire-color-labels" for="color6"></label>
              </div>

            </div>
          </form>
          <div class="d-flex justify-content-between justify-content-xl-end my-3">
            <button (click)="perv(3)" class="medium-button grey-button" type="button">قبلی</button>
            <button (click)="next(3)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
              <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
                alt="بارگذاری">
              <span *ngIf="!buttonLoading">بعدی</span>
            </button>
          </div>
        </div>
        <div class="col-12 col-xl-8 questionnaire-image-col">
          <img class="image" src="../../../assets/images/questionnaire/pink-girl.png" alt="پرسشنامه">
        </div>
      </div>
    </div>
    <div *ngSwitchCase="4" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture1.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture1.jpg">
                    <span class="price-span">
                      قیمت :
                      791,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture1.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture2.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture2.jpg">
                    <span class="price-span">
                      قیمت :
                      871,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture2.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture3.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture3.jpg">
                    <span class="price-span">
                      قیمت :
                      533,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture3.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture4.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture4.jpg">
                    <span class="price-span">
                      قیمت :
                      654,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture4.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(4)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(4)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="5" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture5.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture5.jpg">
                    <span class="price-span">
                      قیمت :
                      889,300
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture5.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture6.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture6.jpg">
                    <span class="price-span">
                      قیمت :
                      2,650,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture6.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture7.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture7.jpg">
                    <span class="price-span">
                      قیمت :
                      3,020,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture7.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture8.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture8.jpg">
                    <span class="price-span">
                      قیمت :
                      937,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture8.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(5)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(5)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="6" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture9.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture9.jpg">
                    <span class="price-span">
                      قیمت :
                      755,300
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture9.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture10.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture10.jpg">
                    <span class="price-span">
                      قیمت :
                      839,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture10.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture11.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture11.jpg">
                    <span class="price-span">
                      قیمت :
                      979,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture11.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture12.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture12.jpg">
                    <span class="price-span">
                      قیمت :
                      2,500,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture12.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(6)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(6)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="7" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture13.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture13.jpg">
                    <span class="price-span">
                      قیمت :
                      959,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture13.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture14.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture14.jpg">
                    <span class="price-span">
                      قیمت :
                      959,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture14.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture15.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture15.jpg">
                    <span class="price-span">
                      قیمت :
                      3,760,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture15.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture16.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture16.jpg">
                    <span class="price-span">
                      قیمت :
                      759,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture16.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(7)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(7)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="8" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture17.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture17.jpg">
                    <span class="price-span">
                      قیمت :
                      3,790,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture17.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture18.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture18.jpg">
                    <span class="price-span">
                      قیمت :
                      1,599,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture18.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture19.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture19.jpg">
                    <span class="price-span">
                      قیمت :
                      1,511,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture19.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture20.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture20.jpg">
                    <span class="price-span">
                      قیمت :
                      2,908,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture20.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(8)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(8)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="9" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture21.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture21.jpg">
                    <span class="price-span">
                      قیمت :
                      1,715,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture21.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture22.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture22.jpg">
                    <span class="price-span">
                      قیمت :
                      961,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture22.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture23.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture23.jpg">
                    <span class="price-span">
                      قیمت :
                      1,990,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture23.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture24.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture24.jpg">
                    <span class="price-span">
                      قیمت :
                      1,240,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture24.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(9)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(9)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="10" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture25.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture25.jpg">
                    <span class="price-span">
                      قیمت :
                      1,240,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture25.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture26.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture26.jpg">
                    <span class="price-span">
                      قیمت :
                      839,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture26.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture27.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture27.jpg">
                    <span class="price-span">
                      قیمت :
                      999,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture27.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture28.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture28.jpg">
                    <span class="price-span">
                      قیمت :
                      3,980,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture28.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(10)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(10)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="11" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture29.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture29.jpg">
                    <span class="price-span">
                      قیمت :
                      1,299,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture29.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture30.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture30.jpg">
                    <span class="price-span">
                      قیمت :
                      747,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture30.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture31.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture31.jpg">
                    <span class="price-span">
                      قیمت :
                      1,240,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture31.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture32.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture32.jpg">
                    <span class="price-span">
                      قیمت :
                      1,135,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture32.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(11)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(11)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="12" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture33.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture33.jpg">
                    <span class="price-span">
                      قیمت :
                      1,299,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture33.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture34.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture34.jpg">
                    <span class="price-span">
                      قیمت :
                      971,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture34.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture35.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture35.jpg">
                    <span class="price-span">
                      قیمت :
                      884,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture35.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture36.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture36.jpg">
                    <span class="price-span">
                      قیمت :
                      495,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture36.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(12)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(12)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="13" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture37.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture37.jpg">
                    <span class="price-span">
                      قیمت :
                      739,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture37.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture38.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture38.jpg">
                    <span class="price-span">
                      قیمت :
                      1,096,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture38.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture39.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture39.jpg">
                    <span class="price-span">
                      قیمت :
                      611,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture39.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture40.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture40.jpg">
                    <span class="price-span">
                      قیمت :
                      971,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture40.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(13)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(13)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="14" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture41.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture41.jpg">
                    <span class="price-span">
                      قیمت :
                      424,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture41.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture42.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture42.jpg">
                    <span class="price-span">
                      قیمت :
                      890,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture42.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture43.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture43.jpg">
                    <span class="price-span">
                      قیمت :
                      554,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture43.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture44.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture44.jpg">
                    <span class="price-span">
                      قیمت :
                      705,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture44.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(14)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(14)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="15" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture45.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture45.jpg">
                    <span class="price-span">
                      قیمت :
                      285,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture45.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture46.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture46.jpg">
                    <span class="price-span">
                      قیمت :
                      747,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture46.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture47.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture47.jpg">
                    <span class="price-span">
                      قیمت :
                      811,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture47.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture48.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture48.jpg">
                    <span class="price-span">
                      قیمت :
                      424,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture48.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(15)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(15)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="16" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture49.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture49.jpg">
                    <span class="price-span">
                      قیمت :
                      385,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture49.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture50.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture50.jpg">
                    <span class="price-span">
                      قیمت :
                      696,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture50.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture51.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture51.jpg">
                    <span class="price-span">
                      قیمت :
                      424,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture51.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture52.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture52.jpg">
                    <span class="price-span">
                      قیمت :
                      816,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture52.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(16)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(16)" class="medium-button ml-2" type="button">بعدی</button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="17" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture53.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture53.jpg">
                    <span class="price-span">
                      قیمت :
                      423,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture53.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture54.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture54.jpg">
                    <span class="price-span">
                      قیمت :
                      217,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture54.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture55.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture55.jpg">
                    <span class="price-span">
                      قیمت :
                      1,328,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture55.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture56.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture56.jpg">
                    <span class="price-span">
                      قیمت :
                      816,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture56.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(17)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(17)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="18" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture57.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture57.jpg">
                    <span class="price-span">
                      قیمت :
                      522,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture57.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture58.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture58.jpg">
                    <span class="price-span">
                      قیمت :
                      851,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture58.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture59.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture59.jpg">
                    <span class="price-span">
                      قیمت :
                      657,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture59.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture60.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture60.jpg">
                    <span class="price-span">
                      قیمت :
                      1,016,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture60.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(18)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(18)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="19" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture61.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture61.jpg">
                    <span class="price-span">
                      قیمت :
                      685,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture61.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture62.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture62.jpg">
                    <span class="price-span">
                      قیمت :
                      1,103,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture62.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture63.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture63.jpg">
                    <span class="price-span">
                      قیمت :
                      995,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture63.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture64.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture64.jpg">
                    <span class="price-span">
                      قیمت :
                      1,103,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture64.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(19)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(19)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="20" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture65.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture65.jpg">
                    <span class="price-span">
                      قیمت :
                      1,290,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture65.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture66.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture66.jpg">
                    <span class="price-span">
                      قیمت :
                      895,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture66.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture67.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture67.jpg">
                    <span class="price-span">
                      قیمت :
                      622,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture67.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture68.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture68.jpg">
                    <span class="price-span">
                      قیمت :
                      795,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture68.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(20)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(20)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="21" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture69.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture69.jpg">
                    <span class="price-span">
                      قیمت :
                      575,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture69.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture70.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture70.jpg">
                    <span class="price-span">
                      قیمت :
                      503,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture70.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture71.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture71.jpg">
                    <span class="price-span">
                      قیمت :
                      419,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture71.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture72.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture72.jpg">
                    <span class="price-span">
                      قیمت :
                      947,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture72.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(21)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(21)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="22" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture73.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture73.jpg">
                    <span class="price-span">
                      قیمت :
                      489,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture73.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture74.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture74.jpg">
                    <span class="price-span">
                      قیمت :
                      380,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture74.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture75.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture75.jpg">
                    <span class="price-span">
                      قیمت :
                      287,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture75.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture76.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture76.jpg">
                    <span class="price-span">
                      قیمت :
                      485,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture76.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(22)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(22)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="23" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture77.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture77.jpg">
                    <span class="price-span">
                      قیمت :
                      320,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture77.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture78.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture78.jpg">
                    <span class="price-span">
                      قیمت :
                      249,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture78.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture79.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture79.jpg">
                    <span class="price-span">
                      قیمت :
                      189,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture79.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture80.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture80.jpg">
                    <span class="price-span">
                      قیمت :
                      249,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture80.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(23)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(23)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="24" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture81.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture81.jpg">
                    <span class="price-span">
                      قیمت :
                      350,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture81.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture82.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture82.jpg">
                    <span class="price-span">
                      قیمت :
                      1,071,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture82.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture83.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture83.jpg">
                    <span class="price-span">
                      قیمت :
                      360,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture83.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture84.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture84.jpg">
                    <span class="price-span">
                      قیمت :
                      189,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture84.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(24)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(24)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="25" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture85.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture85.jpg">
                    <span class="price-span">
                      قیمت :
                      435,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture85.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture86.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture86.jpg">
                    <span class="price-span">
                      قیمت :
                      398,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture86.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture87.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture87.jpg">
                    <span class="price-span">
                      قیمت :
                      240,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture87.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture88.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture88.jpg">
                    <span class="price-span">
                      قیمت :
                      540,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture88.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(25)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(25)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="26" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture89.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture89.jpg">
                    <span class="price-span">
                      قیمت :
                      609,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture89.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture90.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture90.jpg">
                    <span class="price-span">
                      قیمت :
                      912,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture90.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture91.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture91.jpg">
                    <span class="price-span">
                      قیمت :
                      346,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture91.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture92.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture92.jpg">
                    <span class="price-span">
                      قیمت :
                      422,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture92.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(26)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(26)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="27" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture93.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture93.jpg">
                    <span class="price-span">
                      قیمت :
                      271,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture93.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture94.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture94.jpg">
                    <span class="price-span">
                      قیمت :
                      493,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture94.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture95.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture95.jpg">
                    <span class="price-span">
                      قیمت :
                      551,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture95.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture96.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture96.jpg">
                    <span class="price-span">
                      قیمت :
                      326,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture96.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(27)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(27)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="28" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture97.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture97.jpg">
                    <span class="price-span">
                      قیمت :
                      548,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture97.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture98.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture98.jpg">
                    <span class="price-span">
                      قیمت :
                      502,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture98.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture99.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture99.jpg">
                    <span class="price-span">
                      قیمت :
                      271,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture99.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture100.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture100.jpg">
                    <span class="price-span">
                      قیمت :
                      859,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture100.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(28)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(28)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="29" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture101.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture101.jpg">
                    <span class="price-span">
                      قیمت :
                      269,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture101.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture102.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture102.jpg">
                    <span class="price-span">
                      قیمت :
                      1,099,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture102.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture103.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture103.jpg">
                    <span class="price-span">
                      قیمت :
                      843,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture103.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture104.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture104.jpg">
                    <span class="price-span">
                      قیمت :
                      979,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture104.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(29)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(29)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="30" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture105.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture105.jpg">
                    <span class="price-span">
                      قیمت :
                      391,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture105.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture106.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture106.jpg">
                    <span class="price-span">
                      قیمت :
                      285,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture106.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture107.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture107.jpg">
                    <span class="price-span">
                      قیمت :
                      353,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture107.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture108.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture108.jpg">
                    <span class="price-span">
                      قیمت :
                      892,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture108.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(30)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(30)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="31" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture109.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture109.jpg">
                    <span class="price-span">
                      قیمت :
                      1,145,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture109.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture110.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture110.jpg">
                    <span class="price-span">
                      قیمت :
                      984,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture110.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture111.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture111.jpg">
                    <span class="price-span">
                      قیمت :
                      539,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture111.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture112.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture112.jpg">
                    <span class="price-span">
                      قیمت :
                      423,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture112.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(31)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(31)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="32" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture113.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture113.jpg">
                    <span class="price-span">
                      قیمت :
                      1,067,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture113.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture114.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture114.jpg">
                    <span class="price-span">
                      قیمت :
                      354,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture114.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture115.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture115.jpg">
                    <span class="price-span">
                      قیمت :
                      585,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture115.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture116.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture116.jpg">
                    <span class="price-span">
                      قیمت :
                      704,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture116.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(32)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(32)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="33" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture117.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture117.jpg">
                    <span class="price-span">
                      قیمت :
                      844,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture117.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture118.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture118.jpg">
                    <span class="price-span">
                      قیمت :
                      970,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture118.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture119.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture119.jpg">
                    <span class="price-span">
                      قیمت :
                      741,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture119.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture120.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture120.jpg">
                    <span class="price-span">
                      قیمت :
                      1,522,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture120.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(33)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(33)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="34" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture121.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture121.jpg">
                    <span class="price-span">
                      قیمت :
                      744,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture121.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture122.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture122.jpg">
                    <span class="price-span">
                      قیمت :
                      587,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture122.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture123.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture123.jpg">
                    <span class="price-span">
                      قیمت :
                      799,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture123.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture124.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture124.jpg">
                    <span class="price-span">
                      قیمت :
                      1,454,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture124.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(34)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(34)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="35" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture125.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture125.jpg">
                    <span class="price-span">
                      قیمت :
                      550,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture125.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture126.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture126.jpg">
                    <span class="price-span">
                      قیمت :
                      491,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture126.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture127.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture127.jpg">
                    <span class="price-span">
                      قیمت :
                      189,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture127.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture128.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture128.jpg">
                    <span class="price-span">
                      قیمت :
                      233,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture128.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(35)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(35)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="36" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture129.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture129.jpg">
                    <span class="price-span">
                      قیمت :
                      411,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture129.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture130.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture130.jpg">
                    <span class="price-span">
                      قیمت :
                      655,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture130.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture131.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture131.jpg">
                    <span class="price-span">
                      قیمت :
                      312,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture131.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture132.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture132.jpg">
                    <span class="price-span">
                      قیمت :
                      263,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture132.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(36)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(36)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="37" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture133.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture133.jpg">
                    <span class="price-span">
                      قیمت :
                      272,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture133.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture134.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture134.jpg">
                    <span class="price-span">
                      قیمت :
                      398,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture134.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture135.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture135.jpg">
                    <span class="price-span">
                      قیمت :
                      634,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture135.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture136.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture136.jpg">
                    <span class="price-span">
                      قیمت :
                      592,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture136.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(37)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(37)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="38" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture137.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture137.jpg">
                    <span class="price-span">
                      قیمت :
                      641,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture137.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture138.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture138.png">
                    <span class="price-span">
                      قیمت :
                      387,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture138.png')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture139.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture139.jpg">
                    <span class="price-span">
                      قیمت :
                      450,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture139.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture140.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture140.jpg">
                    <span class="price-span">
                      قیمت :
                      248,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture140.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(38)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(38)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="39" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture141.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture141.jpg">
                    <span class="price-span">
                      قیمت :
                      570,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture141.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture142.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture142.jpg">
                    <span class="price-span">
                      قیمت :
                      633,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture142.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture143.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture143.jpg">
                    <span class="price-span">
                      قیمت :
                      449,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture143.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture144.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture144.jpg">
                    <span class="price-span">
                      قیمت :
                      700,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture144.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(39)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(39)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="40" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture145.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture145.jpg">
                    <span class="price-span">
                      قیمت :
                      386,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture145.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture146.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture146.jpg">
                    <span class="price-span">
                      قیمت :
                      803,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture146.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture147.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture147.jpg">
                    <span class="price-span">
                      قیمت :
                      426,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture147.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture148.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture148.jpg">
                    <span class="price-span">
                      قیمت :
                      520,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture148.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(40)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(40)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="41" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture149.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture149.png">
                    <span class="price-span">
                      قیمت :
                      499,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture149.png')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture150.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture150.jpg">
                    <span class="price-span">
                      قیمت :
                      329,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture150.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture151.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture151.jpg">
                    <span class="price-span">
                      قیمت :
                      924,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture151.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture152.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture152.jpg">
                    <span class="price-span">
                      قیمت :
                      799,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture152.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(41)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(41)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="42" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture153.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture153.jpg">
                    <span class="price-span">
                      قیمت :
                      563,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture153.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture154.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture154.jpg">
                    <span class="price-span">
                      قیمت :
                      689,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture154.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture155.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture155.jpg">
                    <span class="price-span">
                      قیمت :
                      350,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture155.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture156.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture156.jpg">
                    <span class="price-span">
                      قیمت :
                      451,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture156.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(42)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(42)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="43" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture157.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture157.jpg">
                    <span class="price-span">
                      قیمت :
                      573,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture157.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture158.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture158.jpg">
                    <span class="price-span">
                      قیمت :
                      494,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture158.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture159.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture159.jpg">
                    <span class="price-span">
                      قیمت :
                      650,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture159.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture160.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture160.jpg">
                    <span class="price-span">
                      قیمت :
                      1,103,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture160.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(43)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(43)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="44" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture161.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture161.jpg">
                    <span class="price-span">
                      قیمت :
                      463,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture161.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture162.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture162.jpg">
                    <span class="price-span">
                      قیمت :
                      788,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture162.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture163.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture163.jpg">
                    <span class="price-span">
                      قیمت :
                      551,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture163.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture164.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture164.jpg">
                    <span class="price-span">
                      قیمت :
                      679,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture164.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(44)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(44)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="45" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدوم رو برای پوشیدن انتخاب میکنی؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture165.jpg'" name="image" id="image1"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/Picture165.jpg">
                    <span class="price-span">
                      قیمت :
                      402,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture165.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture166.jpg'" name="image" id="image2"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/Picture166.jpg">
                    <span class="price-span">
                      قیمت :
                      824,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture166.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture167.jpg'" name="image" id="image3"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/Picture167.jpg">
                    <span class="price-span">
                      قیمت :
                      620,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture167.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="'Picture168.jpg'" name="image" id="image4"
                    formControlName="image">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/Picture168.jpg">
                    <span class="price-span">
                      قیمت :
                      519,000
                      تومان
                    </span>
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/Picture168.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="questionnaire-rating">
            <label class="rating-title">از انتخاب خودتون چقد رضایت دارید؟ (از 1 تا 10 نمره بدید)</label>
            <ngx-slider [(highValue)]="answerRate" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(45)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(45)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="46" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">شخصیت خود را چگونه می بینید؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-12 d-flex flex-column">

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="1" [id]="'answer1'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    عاشق پیشه، محبوب مردان، احساسی، زنانه، مهربان، اهل خانواده و روابط
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="2" [id]="'answer2'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    غیررسمی، بیخیال، خاکی، مستقل، اهل طبیعت، ساده پسند، طرفدار حقوق انسان و حیوان
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="3" [id]="'answer3'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    قابل توجه، مدگرا، بااعتماد به نفس، سرشار از زندگی، پیچیده، افراطی
                  </span>
                </label>


                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="4" [id]="'answer4'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    کژوال و راحت، ورزشی، در دسترس، ماجراجو، ورزشکار، قابل اعتماد، کارآمد
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="5" [id]="'answer5'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    باوقار، هماهنگ و موزون، رسمی، متین، سازمان یافته، اثرگذار، عمل گرا
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="6" [id]="'answer6'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    تعلق به طبیعت، خیال باف، معنوی، معناگرا، اهل هنر، اهل تنهایی، مهربان
                  </span>
                </label>

              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(46)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(46)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="47" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">آیا خود را فردی لوکس پسند می بینید؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-12 d-flex flex-column">

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="true" [id]="'answer1'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    بله
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="false" [id]="'answer2'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    خیر
                  </span>
                </label>

              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(47)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(47)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="48" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">علاقه به طبیعت و طبیعت گردی دارین؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-12 d-flex flex-column">

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="true" [id]="'answer1'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    بله
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="false" [id]="'answer2'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    خیر
                  </span>
                </label>

              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(48)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(48)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="49" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">علاقه به تتو، پیرسینگ و رنگ موی فانتزی دارین؟
            <br>
            (حتی 1گزینه هم باشه درسته)
          </label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-12 d-flex flex-column">

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="true" [id]="'answer1'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    بله
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="false" [id]="'answer2'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    خیر
                  </span>
                </label>

              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(49)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(49)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="50" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">سبک زندگیتون چه جوریه؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-12 d-flex flex-column">

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="1" [id]="'answer1'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    مهمان نواز، آرایشی و بهداشتی، حوزه سلامت، آشپزی، عاشقانه
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="2" [id]="'answer2'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    مددکار، هنرمند، دانشمند، فعال، عکاس،طبیعت گرد
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="3" [id]="'answer3'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    مهمانی و پارتی، تفریح، محرک، کارآفرین، متفاوت با بقیه
                  </span>
                </label>


                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="4" [id]="'answer4'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    دارای تحصیلات عالی، ذهن منطقی، ورزش، مهمانی
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="5" [id]="'answer5'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    کار با قوانین، ساختاریافته، سازمانی، کارمندی و جدی
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="6" [id]="'answer6'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    هنری، اهل موسیقی، طراحی، خلق کردن، مراقبه و مدیتیشن، نویسندگی، کتاب
                  </span>
                </label>

              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(50)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(50)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="51" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">علاقه به پوشیدن لباسهای ترند و مدروز دارین؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-12 d-flex flex-column">

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="true" [id]="'answer1'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    بله
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="false" [id]="'answer2'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    خیر
                  </span>
                </label>

              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(51)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(51)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="52" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدام مورد به استایل شما نزدیکتره؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-12 d-flex flex-column">

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="1" [id]="'answer1'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    ساده، خوش دوخت، هماهنگ، کیفیت بالا، علاقه چرم طبیعی
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="2" [id]="'answer2'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    مد روز، جدیدترین آیتم فشن شو، ترکیب رنگی و پر انرژی، لباس اکلیلی، همه سبک ها
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="3" [id]="'answer3'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    لباس هایی که نماد زنانگی و اغواگری هستند، پترن گلدار، پارچه های ظریف، کفش پاشنه بلند اکثرا
                  </span>
                </label>


                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="4" [id]="'answer4'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    کژوال و راحت، لباس های قیمت مناسب، پارچه های طبیعی مثل نخ پنبه و...، طرح های سنتی، مخالف چرم طبیعی
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="5" [id]="'answer5'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    کژوال، ورزشی، راحت، پترن راه راه و اشکال هندسی و چهارخانه، استفاده زیاد از جین
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="6" [id]="'answer6'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    لباس های خلاق و غیرمعمول، لباس های قدیمی و چین دار، جواهرات عجیب و پیچیده، پترن های شاد و راحت
                  </span>
                </label>

              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(52)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(52)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="53" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">علاقه به استایل های غیرمعمول دارین؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-12 d-flex flex-column">

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="true" [id]="'answer1'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    بله
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="false" [id]="'answer2'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    خیر
                  </span>
                </label>

              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(53)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(53)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="54" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">اکثرا مدل موی انتخابی شما چگونه است؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-12 d-flex flex-column">

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="1" [id]="'answer1'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    موهای یکدست، همیشه مرتب و درست شده، مدل های ساده و شیک، به ندرت تغییر می کند
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="2" [id]="'answer2'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    موهای فیکس شده، اغلب کوتاه و تیز، اکسسوری برای موها، رنگ موی فانتزی
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="3" [id]="'answer3'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    موهای رها و بلند، روبان و نوار برای بستن مو، اکسسوری برای مو مثل گل و مهره
                  </span>
                </label>


                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="4" [id]="'answer4'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    کژوال، بدون استایل، استفاده از هدبند کژوال، خیلی کوتاه یا خیلی بلند، نرم کم
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="5" [id]="'answer5'"
                    formControlName="answer">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    آزاد، کژوال و بدون استایل، خیلی کوتاه یا خیلی بلند، بافته شده، مجعد، اکسسوری مهره ای و توری
                  </span>
                </label>

              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(54)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(54)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="55" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدام استایل رو بیشتر میپسندین؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="1" name="answer" id="image1" formControlName="answer">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/w-s-1.jpg">
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/w-s-1.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="2" name="answer" id="image2" formControlName="answer">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/w-s-2.jpg">
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/w-s-2.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="3" name="answer" id="image3" formControlName="answer">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/w-s-3.jpg">
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/w-s-3.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="4" name="answer" id="image4" formControlName="answer">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/w-s-4.jpg">
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/w-s-4.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="5" name="answer" id="image5" formControlName="answer">
                  <label class="questionnaire-picture-labels" for="image5">
                    <img class="image" src="../../../assets/images/questionnaire/w-s-5.jpg">
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/w-s-5.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="6" name="answer" id="image6" formControlName="answer">
                  <label class="questionnaire-picture-labels" for="image6">
                    <img class="image" src="../../../assets/images/questionnaire/w-s-6.jpg">
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/w-s-6.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(55)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(55)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="56" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">کدام میکاپ رو بیشتر میپسندین؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
          <form [formGroup]="answerInfo">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="1" name="answer" id="image1" formControlName="answer">
                  <label class="questionnaire-picture-labels" for="image1">
                    <img class="image" src="../../../assets/images/questionnaire/w-m-1.jpg">
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/w-m-1.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="2" name="answer" id="image2" formControlName="answer">
                  <label class="questionnaire-picture-labels" for="image2">
                    <img class="image" src="../../../assets/images/questionnaire/w-m-2.jpg">
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/w-m-2.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="3" name="answer" id="image3" formControlName="answer">
                  <label class="questionnaire-picture-labels" for="image3">
                    <img class="image" src="../../../assets/images/questionnaire/w-m-3.jpg">
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/w-m-3.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
              <div class="col-6 col-md-3 col-xl-3 p-0">
                <div class="image-answers-wrapper">
                  <input class="hidden" type="radio" [value]="4" name="answer" id="image4" formControlName="answer">
                  <label class="questionnaire-picture-labels" for="image4">
                    <img class="image" src="../../../assets/images/questionnaire/w-m-4.jpg">
                  </label>
                  <img (click)="onShowImage('../../../assets/images/questionnaire/w-m-4.jpg')" data-toggle="modal"
                    data-target="#answeImageModal" class="expand-image" src="../../../assets/images/core/expand.svg">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(56)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(56)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="57" class="col-12">
      <div class="row">
        <div class="col-12">
          <label class="questions">نسبت اندازه شانه و باسن شما چگونه است ؟</label>
        </div>
        <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
            <form [formGroup]="answerInfo">

            <div class="row">
              <div class="col-12 d-flex flex-column">

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="1" [id]="'answer1'"
                    (click)="checkAnatomyAnswer(1);">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    برابر هستند
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="2" [id]="'answer2'"
                    (click)="checkAnatomyAnswer(2);">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    باسن باریک تر است
                  </span>
                </label>

                <label class="custome-checkbox radio-large radio-large-with-text my-3">
                  <input class="custome-checkbox-input" type="radio" name="answer" [value]="3" [id]="'answer3'"
                    (click)="checkAnatomyAnswer(3);">
                  <span class="custome-checkbox-span questionnaire-radio-span">
                    شانه ها باریک تر است
                  </span>
                </label>

              </div>
            </div>
            </form>
            <div *ngIf="anatomyStep1 == 2" class="row">
              <div class="col-12">
                <label class="questions">آیا کمر شما باریک است ؟</label>
              </div>
              <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
                <form [formGroup]="answerInfo">
                  <div class="row">
                    <div class="col-12 d-flex flex-column">

                      <label class="custome-checkbox radio-large radio-large-with-text my-3">
                        <input class="custome-checkbox-input" type="radio" name="answer" [value]="4" [id]="'answer4'"
                          (click)="checkAnatomyAnswer(4);">
                        <span class="custome-checkbox-span questionnaire-radio-span">
                          بله
                        </span>
                      </label>

                      <label class="custome-checkbox radio-large radio-large-with-text my-3">
                        <input class="custome-checkbox-input" type="radio" name="answer" [value]="5" [id]="'answer5'"
                          (click)="checkAnatomyAnswer(5);">
                        <span class="custome-checkbox-span questionnaire-radio-span">
                          خیر
                        </span>
                      </label>

                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div *ngIf="anatomyStep2 == 3" class="row">
              <div class="col-12">
                <label class="questions">آیا قفسه سینه توپر و شکم گردی دارید ؟</label>
              </div>
              <div class="col-12 col-xl-10 d-flex flex-column justify-content-between">
                <form [formGroup]="answerInfo">
                  <div class="row">
                    <div class="col-12 d-flex flex-column">

                      <label class="custome-checkbox radio-large radio-large-with-text my-3">
                        <input class="custome-checkbox-input" type="radio" name="answer" [value]="6" [id]="'answer6'"
                          (click)="checkAnatomyAnswer(6);">
                        <span class="custome-checkbox-span questionnaire-radio-span">
                          بله
                        </span>
                      </label>

                      <label class="custome-checkbox radio-large radio-large-with-text my-3">
                        <input class="custome-checkbox-input" type="radio" name="answer" [value]="7" [id]="'answer7'"
                          (click)="checkAnatomyAnswer(7);">
                        <span class="custome-checkbox-span questionnaire-radio-span">
                          خیر
                        </span>
                      </label>

                    </div>
                  </div>
                </form>
              </div>
            </div>

            <!-- Finaly Result -->
            <div class="row">
              <div class="col-12" *ngIf="anatomy">
                <span class="questions">فرم اندام شما {{ anatomy }} می باشد</span>
              </div>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-between justify-content-xl-start  my-3">
          <button (click)="perv(57)" class="medium-button grey-button" type="button">قبلی</button>
          <button (click)="next(57)" class="medium-button ml-2" type="button" [disabled]="buttonLoading">
            <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
              alt="بارگذاری">
            <span *ngIf="!buttonLoading">بعدی</span>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>

<div *ngIf="questionnaireState === 58" class="container-fluid main-background">
  <div class="row not-found-main-row">
    <div class="col-12 not-found-main-col">
      <div class="main-containers-no-title not-found-main-container">
        <img class="not-found-icon" src="../../../assets/images/core/successfull-payment.svg">
        <label class="not-found-title">با تشکر از مشارکت شما</label>
        <label class="not-found-text">
          با تشکر از مشارکت شما
          <br>
          نام و شماره تماس شما در لیست قرعه کشی قرار گرفت
          <br>
          قرعه کشی در تاریخ 1400/8/23 برگزار میشود.
        </label>
        <button [routerLink]="['/']" class="medium-button" type="button">صفحه اصلی</button>
      </div>
    </div>
  </div>
</div>

<img *ngIf="questionnaireState === 1" class="questionnaire-background-image"
  src="../../../assets/images/questionnaire/background-shape.png" alt="پرسشنامه">
<img *ngIf="questionnaireState > 1  && questionnaireState < 4" class="questionnaire-background-image"
  src="../../../assets/images/questionnaire/blue-back.png" alt="پرسشنامه">
<img *ngIf="questionnaireState > 3 && questionnaireState < 58" class="questionnaire-background-image"
  src="../../../assets/images/questionnaire/Layer-6.png" alt="پرسشنامه">



<div class="modal" id="answeImageModal" tabindex="-1" role="dialog" aria-labelledby="answeImageModal"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content questionaire-modal-content">
      <img [src]="imageSrc" alt="">
    </div>
  </div>
</div>



<!-- <div class="col-12 height-100">
  <div class="row height-100">
    <div class="col-12 col-xl-5">
      <form [formGroup]="personalInfo">
        <div class="my-2">
          <label class="questions">1- سایز شومیز</label>
          <div class="questionnire-radio-wrapper questionnire-radio-wrapper-w-auto">

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="shoomiz" [value]="'32'" [id]="'shoomiz'"
                formControlName="shoomiz">
              <span class="custome-checkbox-span questionnaire-radio-span">کمتر از 32</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="shoomiz" [value]="'32-38'" [id]="'shoomiz'"
                formControlName="shoomiz">
              <span class="custome-checkbox-span questionnaire-radio-span">32-38</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="shoomiz" [value]="'160-170'" [id]="'shoomiz'"
                formControlName="shoomiz">
              <span class="custome-checkbox-span questionnaire-radio-span">160-170</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="shoomiz" [value]="'170-180'" [id]="'shoomiz'"
                formControlName="shoomiz">
              <span class="custome-checkbox-span questionnaire-radio-span">170-180</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="shoomiz" [value]="'180-190'" [id]="'shoomiz'"
                formControlName="shoomiz">
              <span class="custome-checkbox-span questionnaire-radio-span">180-190</span>
            </label>

          </div>
        </div>
        <div class="my-2">
          <label class="questions">2- سایز مانتو</label>
          <div class="questionnire-radio-wrapper questionnire-radio-wrapper-w-auto">

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="manto" [value]="'45'" [id]="'manto'"
                formControlName="manto">
              <span class="custome-checkbox-span questionnaire-radio-span">زیر 45</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="manto" [value]="'45-60'" [id]="'manto'"
                formControlName="manto">
              <span class="custome-checkbox-span questionnaire-radio-span">45-60</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="manto" [value]="'60-75'" [id]="'manto'"
                formControlName="manto">
              <span class="custome-checkbox-span questionnaire-radio-span">60-75</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="manto" [value]="'75-90'" [id]="'manto'"
                formControlName="manto">
              <span class="custome-checkbox-span questionnaire-radio-span">75-90</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="manto" [value]="'90-105'" [id]="'manto'"
                formControlName="manto">
              <span class="custome-checkbox-span questionnaire-radio-span">90-105</span>
            </label>

          </div>
        </div>
        <div class="my-2">
          <label class="questions">3- سایز شلوار</label>
          <div class="questionnire-radio-wrapper questionnire-radio-wrapper-w-auto">

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="shalvar" [value]="'15'" [id]="'shalvar'"
                formControlName="shalvar">
              <span class="custome-checkbox-span questionnaire-radio-span">زیر 15 سال</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="shalvar" [value]="'15-20'" [id]="'shalvar'"
                formControlName="shalvar">
              <span class="custome-checkbox-span questionnaire-radio-span">15-20</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="shalvar" [value]="'20-30'" [id]="'shalvar'"
                formControlName="shalvar">
              <span class="custome-checkbox-span questionnaire-radio-span">20-30</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="shalvar" [value]="'30-40'" [id]="'shalvar'"
                formControlName="shalvar">
              <span class="custome-checkbox-span questionnaire-radio-span">30-40</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="shalvar" [value]="'40-50'" [id]="'shalvar'"
                formControlName="shalvar">
              <span class="custome-checkbox-span questionnaire-radio-span">40-50</span>
            </label>

          </div>
        </div>
        <div class="my-2">
          <label class="questions">4- سایز کفش</label>
          <div class="questionnire-radio-wrapper questionnire-radio-wrapper-w-auto">

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="kafsh" [value]="'15'" [id]="'kafsh'"
                formControlName="kafsh">
              <span class="custome-checkbox-span questionnaire-radio-span">زیر 15 سال</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="kafsh" [value]="'15-20'" [id]="'kafsh'"
                formControlName="kafsh">
              <span class="custome-checkbox-span questionnaire-radio-span">15-20</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="kafsh" [value]="'20-30'" [id]="'kafsh'"
                formControlName="kafsh">
              <span class="custome-checkbox-span questionnaire-radio-span">20-30</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="kafsh" [value]="'30-40'" [id]="'kafsh'"
                formControlName="kafsh">
              <span class="custome-checkbox-span questionnaire-radio-span">30-40</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="kafsh" [value]="'30-40'" [id]="'kafsh'"
                formControlName="kafsh">
              <span class="custome-checkbox-span questionnaire-radio-span">30-40</span>
            </label>

          </div>
        </div>
        <div class="my-2">
          <label class="questions">5- سایز پالتو</label>
          <div class="questionnire-radio-wrapper questionnire-radio-wrapper-w-auto">

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="palto" [value]="'15'" [id]="'palto'"
                formControlName="palto">
              <span class="custome-checkbox-span questionnaire-radio-span">زیر 15 سال</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="palto" [value]="'15-20'" [id]="'palto'"
                formControlName="palto">
              <span class="custome-checkbox-span questionnaire-radio-span">15-20</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="palto" [value]="'20-30'" [id]="'palto'"
                formControlName="palto">
              <span class="custome-checkbox-span questionnaire-radio-span">20-30</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="palto" [value]="'30-40'" [id]="'palto'"
                formControlName="palto">
              <span class="custome-checkbox-span questionnaire-radio-span">30-40</span>
            </label>

            <label class="custome-checkbox radio-large">
              <input class="custome-checkbox-input" type="radio" name="palto" [value]="'30-40'" [id]="'palto'"
                formControlName="palto">
              <span class="custome-checkbox-span questionnaire-radio-span">30-40</span>
            </label>

          </div>
        </div>
      </form>
      <div class="d-flex justify-content-between justify-content-xl-end">
        <button (click)="perv(12)" class="medium-button grey-button" type="button">قبلی</button>
        <button (click)="next(12)" class="medium-button ml-2" type="button">بعدی</button>
      </div>
    </div>
    <div class="col-12 col-xl-7 questionnaire-image-col">
      <img class="image" src="../../../assets/images/questionnaire/pink-girl.png" alt="پرسشنامه">
    </div>
  </div>
</div> -->
