import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'successful'
})
export class SuccessfulPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let statusMessage = "وضعیت نامشخص";
    switch (value) {
      case true:
        statusMessage = "موفق"
        break;
      case false:
        statusMessage = "ناموفق"
        break;
      default:
        break;
    }
    return statusMessage;
  }

}
