import { ColorLabel } from './../../shared/interfaces/color-label';
import { MoreSize, Size } from './../../shared/interfaces/size';
import { Observable } from './../../core/services/observable/observable';
import { BasketApiService } from './../../core/services/api/basket-api.service';
import { FormValidatorsService } from './../../shared/validators/form-validators.service';
import { LocalStorageService } from './../../core/services/storage/local-storage.service';
import { Helper } from './../../shared/utilities/helper';
import { environment } from './../../../environments/environment';
import { ProductApiService } from './../../core/services/api/product-api.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, HostListener, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OrderSort } from 'src/app/core/enums/order-sort.enum';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { SimilarProducts } from 'src/app/shared/interfaces/similar-products';
import { SizeGroupType } from 'src/app/core/enums/size-group-type';
import { Subscription } from 'rxjs/internal/Subscription';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthServiceApiService } from 'src/app/core/services/api/auth.service.api.service';
import { PlatformLocation } from '@angular/common';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';
import KeenSlider, { KeenSliderInstance } from "keen-slider"

@Component({
  selector: 'app-set-recommendation',
  templateUrl: './set-recommendation.component.html',
  styleUrls: ['./set-recommendation.component.less']
})
export class SetRecommendationComponent implements OnInit {

  productDetails: FormGroup;
  commentInfo: FormGroup;
  similarProductInfo: FormGroup;
  customerSizeInfo: FormGroup;
  recaptchaReactiveInfo: FormGroup;
  productMoreInfoTabs: number = 1;
  product: any = {};
  customePicture: string;
  baseUrl = environment.baseUrl;
  buttonLoading: boolean = false;
  isCaptchaVerified: boolean = false;
  buttonLoadingIsBalance: boolean = true;
  pageLoading: boolean = false;
  textComment: string = 'ارسال نظر';
  @ViewChild('openbutton') openbutton: any;
  @ViewChild('closeButton') closeButton: any;
  FIELDS_MORE_SIZES: string[] = [];
  colorLabelId: string = null;
  sizeId: string;
  listOfFilterSizes: Size[] = [];
  similarProduct: SimilarProducts;
  listOfSimilarProducts;
  countSimilarProducts: number | string = 0;
  listOfFilterColors: ColorLabel[] = [];
  listOfSizes: any = [];
  listOfColorSizes: any = [];
  moreSizes: any = [];
  totalEvaluationsComment: any = {
    matchThePhotoWithTheProduct: 0 as number,
    matchThePhotoWithTheProductRating: 0 as number,
    qualityOfClothingDesign: 0 as number,
    qualityOfClothingDesignRating: 0 as number,
    qualityOfFabric: 0 as number,
    qualityOfFabricRating: 0 as number,
    innovation: 0 as number,
    innovationRating: 0 as number
  }

  // price range start
  qualityOfFabric: number = 0;
  innovation: number = 0;
  qualityOfClothingDesign: number = 0;
  matchThePhotoWithTheProduct: number = 0;
  options: Options = {
    floor: 0,
    ceil: 5,
    step: 1,
    rightToLeft: true,
    showOuterSelectionBars: true
  };
  // price range end
  showImageGallery: boolean = false;
  gallerySelectedImage: string;
  gallerylength: number;
  currentImageIndex: number;
  gallery: string[] = [];
  disablePreviousButton: boolean = false;
  disableNextButton: boolean = false;
  subscription: Subscription = new Subscription();
  productFullLink: string;
  @ViewChild('productInfo') productInfoTab: ElementRef;
  @ViewChild('productDescription') productDescriptionTab: ElementRef;
  @ViewChild('productComments') productCommentsTab: ElementRef;
  @ViewChild('productSize') productSizeTab: ElementRef;



  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>;
  @ViewChild("sliderHeadToWaistRef") sliderHeadToWaistRef: ElementRef<HTMLElement>;
  @ViewChild("sliderWaistToAnkleRef") sliderWaistToAnkleRef: ElementRef<HTMLElement>;
  @ViewChild("sliderAnkleTofeetRef") sliderAnkleTofeetRef: ElementRef<HTMLElement>;
  slider: KeenSliderInstance = null;
  sliderHeadToWaist: KeenSliderInstance = null;
  sliderWaistToAnkle: KeenSliderInstance = null;
  sliderAnkleTofeet: KeenSliderInstance = null;
  currentSlide: number = 1;
  selectedSetItemState: boolean = false;
  productId: string = '';
  dressCode: string = '';
  listOfTopProducts: any = [];
  listOfBottomProducts: any = [];
  dataLoaded: boolean = false;
  selectedTop: any = {}
  selectedBottom: any = {};

  constructor(
    private productApiService: ProductApiService,
    private authApiService: AuthServiceApiService,
    private activeRoute: ActivatedRoute,
    private helper: Helper,
    private localStorageService: LocalStorageService,
    private formValidators: FormValidatorsService,
    private basketApiService: BasketApiService,
    private observable: Observable,
    private router: Router,
    private clipboard: Clipboard,
    private location: PlatformLocation,
    private manageHeader: ManageHeaderService
  ) {
    this.onCreateProductDetailsForm();
    this.onCreateCommentInfoForm();
    this.OnCreateSimilarProductForm();
    this.onCreateCustomerSizeInfoForm();
    this.onCreateRecaptchaReactiveForm();
  }

  async ngOnInit(): Promise<void> {
    this.productId = this.activeRoute.snapshot.params["productId"];
    this.dressCode = this.activeRoute.snapshot.params["dressCode"];
    await this.getviewProducts(this.productId, this.dressCode);
    await this.onAddTags();
    this.router.events.subscribe(response => {
      if (response instanceof NavigationEnd) {


        let productId: string = this.activeRoute.snapshot.params["productId"];
        let dressCode: string = this.activeRoute.snapshot.params["dressCode"];

        if (this.productId != productId) {
          this.resetAllListOfSizes();
          this.getviewProducts(this.productId, this.dressCode);
          this.onAddTags();
        }
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // this.slider = new KeenSlider(this.sliderRef?.nativeElement, {
      //   initial: this.currentSlide,
      //   loop: true,
      //   slideChanged: (s) => {
      //     this.currentSlide = s.track.details.rel
      //   },
      // })
      this.sliderHeadToWaist = new KeenSlider(this.sliderHeadToWaistRef?.nativeElement, {
        initial: this.currentSlide,
        loop: false,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
          this.selectedTop = this.listOfTopProducts[s.track.details.rel];
          this.product = this.listOfTopProducts[s.track.details.rel];
          this.onSelectSetItem('headToWaist', this.product);
        },
      })

      this.sliderWaistToAnkle = new KeenSlider(this.sliderWaistToAnkleRef?.nativeElement, {
        initial: this.currentSlide,
        loop: false,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
          this.selectedBottom = this.listOfBottomProducts[s.track.details.rel];
          this.product = this.listOfBottomProducts[s.track.details.rel];
          this.onSelectSetItem('waistToAnkle', this.product);
        },
      })
      // this.sliderAnkleTofeet = new KeenSlider(this.sliderAnkleTofeetRef?.nativeElement, {
      //   initial: this.currentSlide,
      //   loop: true,
      //   slideChanged: (s) => {
      //     this.currentSlide = s.track.details.rel
      //   },
      // })
    })
  }

  ngAfterViewChecked(): void {
    if (this.listOfTopProducts.length > 0 && this.dataLoaded) {
      this.sliderHeadToWaist.update();
      // console.log('top updated init');
    }
    if (this.listOfBottomProducts.length > 0 && this.dataLoaded) {
      this.sliderWaistToAnkle.update();
      // console.log('bottom updated init');
      this.dataLoaded = false;
    }
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.back();
    }
    this.subscription.unsubscribe();
    this.observable.remove();
    if (this.sliderHeadToWaist) this.sliderHeadToWaist.destroy();
    if (this.sliderWaistToAnkle) this.sliderWaistToAnkle.destroy();
  }

  async onAddTags(): Promise<void> {
    let tags: any = [
      {
        name: 'twitter:card',
        content: 'product',
      },
      // {
      //   name: 'twitter:site',
      //   content: 'لینک توییتر',
      // },
      {
        name: 'twitter:creator',
        content: 'کاریزماکالر',
      },
      {
        name: 'twitter:title',
        content: this.product.title,
      },
      {
        name: 'twitter:image',
        content: this.baseUrl + '/image/' + this.customePicture,
      },
      {
        property: 'og:type',
        content: 'product',
      },
      {
        property: 'og:title',
        content: this.product.title,
      },
      {
        property: 'og:url',
        content: this.baseUrl + '/product/' + this.product.link,
      },
      {
        property: 'og:site_name',
        content: 'کاریزماکالر',
      },
      {
        property: 'og:image',
        content: this.baseUrl + '/image/' + this.customePicture,
      },
      {
        property: 'og:image:width',
        content: '500',
      },
      {
        property: 'og:image:height',
        content: '500',
      },
    ]
    this.helper.addMetaTags(tags);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateRecaptchaReactiveForm(): void {
    this.recaptchaReactiveInfo = new FormGroup({
      recaptchaReactive: new FormControl(null)
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  evaluateComments(): void {
    for (let index in this.product.listOfComments) {
      this.totalEvaluationsComment.matchThePhotoWithTheProduct += this.product.listOfComments[index].matchThePhotoWithTheProduct;
      this.totalEvaluationsComment.qualityOfClothingDesign += this.product.listOfComments[index].qualityOfClothingDesign;
      this.totalEvaluationsComment.qualityOfFabric += this.product.listOfComments[index].qualityOfClothingDesign;
      this.totalEvaluationsComment.innovation += this.product.listOfComments[index].innovation;
    }
    this.totalEvaluationsComment.matchThePhotoWithTheProduct = (this.totalEvaluationsComment.matchThePhotoWithTheProduct / 5);
    this.totalEvaluationsComment.qualityOfClothingDesign = (this.totalEvaluationsComment.qualityOfClothingDesign / 5);
    this.totalEvaluationsComment.qualityOfFabric = (this.totalEvaluationsComment.qualityOfFabric / 5);
    this.totalEvaluationsComment.innovation = (this.totalEvaluationsComment.innovation / 5);

    this.calculateMatchThePhotoWithTheProductRating();
    this.calculateQualityOfClothingDesignRating();
    this.calculateQualityOfFabricRating();
    this.calculateInnovation();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  calculateInnovation(): void {
    if (this.totalEvaluationsComment.innovation < 0 && this.totalEvaluationsComment.innovation > 1) {
      this.totalEvaluationsComment.innovationRating = 0;
    }
    if (this.totalEvaluationsComment.innovation >= 1 && this.totalEvaluationsComment.innovation < 2) {
      this.totalEvaluationsComment.innovationRating = 1;
    }
    if (this.totalEvaluationsComment.innovation >= 2 && this.totalEvaluationsComment.innovation < 3) {
      this.totalEvaluationsComment.innovationRating = 2;
    }
    if (this.totalEvaluationsComment.innovation >= 3 && this.totalEvaluationsComment.innovation < 4) {
      this.totalEvaluationsComment.innovationRating = 3;
    }
    if (this.totalEvaluationsComment.innovation >= 4 && this.totalEvaluationsComment.innovation < 5) {
      this.totalEvaluationsComment.innovationRating = 4;
    }
    if (this.totalEvaluationsComment.innovation === 5) {
      this.totalEvaluationsComment.innovationRating = 5;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  calculateQualityOfFabricRating(): void {
    if (this.totalEvaluationsComment.qualityOfFabric < 0 && this.totalEvaluationsComment.qualityOfFabric > 1) {
      this.totalEvaluationsComment.qualityOfFabricRating = 0;
    }
    if (this.totalEvaluationsComment.qualityOfFabric >= 1 && this.totalEvaluationsComment.qualityOfFabric < 2) {
      this.totalEvaluationsComment.qualityOfFabricRating = 1;
    }
    if (this.totalEvaluationsComment.qualityOfClothingDesign >= 2 && this.totalEvaluationsComment.qualityOfFabric < 3) {
      this.totalEvaluationsComment.qualityOfFabricRating = 2;
    }
    if (this.totalEvaluationsComment.qualityOfFabric >= 3 && this.totalEvaluationsComment.qualityOfFabric < 4) {
      this.totalEvaluationsComment.qualityOfFabricRating = 3;
    }
    if (this.totalEvaluationsComment.qualityOfFabric >= 4 && this.totalEvaluationsComment.qualityOfFabric < 5) {
      this.totalEvaluationsComment.qualityOfFabricRating = 4;
    }
    if (this.totalEvaluationsComment.qualityOfFabric === 5) {
      this.totalEvaluationsComment.qualityOfFabricRating = 5;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  calculateQualityOfClothingDesignRating(): void {
    if (this.totalEvaluationsComment.qualityOfClothingDesign < 0 && this.totalEvaluationsComment.qualityOfClothingDesign > 1) {
      this.totalEvaluationsComment.qualityOfClothingDesignRating = 0;
    }
    if (this.totalEvaluationsComment.qualityOfClothingDesign >= 1 && this.totalEvaluationsComment.qualityOfClothingDesign < 2) {
      this.totalEvaluationsComment.qualityOfClothingDesignRating = 1;
    }
    if (this.totalEvaluationsComment.qualityOfClothingDesign >= 2 && this.totalEvaluationsComment.qualityOfClothingDesign < 3) {
      this.totalEvaluationsComment.qualityOfClothingDesignRating = 2;
    }
    if (this.totalEvaluationsComment.qualityOfClothingDesign >= 3 && this.totalEvaluationsComment.qualityOfClothingDesign < 4) {
      this.totalEvaluationsComment.matchThePhotoWithTheProductRating = 3;
    }
    if (this.totalEvaluationsComment.qualityOfClothingDesign >= 4 && this.totalEvaluationsComment.qualityOfClothingDesign < 5) {
      this.totalEvaluationsComment.qualityOfClothingDesignRating = 4;
    }
    if (this.totalEvaluationsComment.qualityOfClothingDesign === 5) {
      this.totalEvaluationsComment.qualityOfClothingDesignRating = 5;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  calculateMatchThePhotoWithTheProductRating(): void {
    if (this.totalEvaluationsComment.matchThePhotoWithTheProduct < 0 && this.totalEvaluationsComment.matchThePhotoWithTheProduct > 1) {
      this.totalEvaluationsComment.matchThePhotoWithTheProductRating = 0;
    }
    if (this.totalEvaluationsComment.matchThePhotoWithTheProduct >= 1 && this.totalEvaluationsComment.matchThePhotoWithTheProduct < 2) {
      this.totalEvaluationsComment.matchThePhotoWithTheProductRating = 1;
    }
    if (this.totalEvaluationsComment.matchThePhotoWithTheProduct >= 2 && this.totalEvaluationsComment.matchThePhotoWithTheProduct < 3) {
      this.totalEvaluationsComment.matchThePhotoWithTheProductRating = 2;
    }
    if (this.totalEvaluationsComment.matchThePhotoWithTheProduct >= 3 && this.totalEvaluationsComment.matchThePhotoWithTheProduct < 4) {
      this.totalEvaluationsComment.matchThePhotoWithTheProductRating = 3;
    }
    if (this.totalEvaluationsComment.matchThePhotoWithTheProduct >= 4 && this.totalEvaluationsComment.matchThePhotoWithTheProduct < 5) {
      this.totalEvaluationsComment.matchThePhotoWithTheProductRating = 4;
    }
    if (this.totalEvaluationsComment.matchThePhotoWithTheProduct === 5) {
      this.totalEvaluationsComment.matchThePhotoWithTheProductRating = 5;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateProductDetailsForm(): void {
    this.productDetails = new FormGroup({
      id: new FormControl(null),
      size: new FormControl(null),
      color: new FormControl(null),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateCustomerSizeInfoForm(): void {
    this.customerSizeInfo = new FormGroup({
      id: new FormControl(null),
      chestWidth: new FormControl(null),
      waistWidth: new FormControl(null),
      shoulderWidth: new FormControl(null),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateCommentInfoForm(): void {
    this.commentInfo = new FormGroup({
      id: new FormControl(null),
      description: new FormControl(null, [this.formValidators.requiredValidator('description'), this.formValidators.minlengthValidator('description', 3), this.formValidators.maxlengthValidator('description', 500)]),
      innovation: new FormControl(null),
      qualityOfFabric: new FormControl(null),
      qualityOfClothingDesign: new FormControl(null),
      matchThePhotoWithTheProduct: new FormControl(null),
      doYouRecommendBuyingThisProduct: new FormControl('true', [this.formValidators.requiredValidator('doYouRecommendBuyingThisProduct')]),
      productId: new FormControl(null),
      userIdentityId: new FormControl(null),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  OnCreateSimilarProductForm(): void {
    this.similarProductInfo = new FormGroup({
      'id': new FormControl(null),
      'similarProductId': new FormControl(null),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onChangeTabs(i: number): void {
    this.productMoreInfoTabs = i;
    let selectedTab: HTMLElement;
    if (this.productMoreInfoTabs === 1) {
      selectedTab = this.productInfoTab.nativeElement;
    }
    if (this.productMoreInfoTabs === 2) {
      selectedTab = this.productDescriptionTab.nativeElement;
    }
    if (this.productMoreInfoTabs === 3) {
      selectedTab = this.productCommentsTab.nativeElement;
    }
    if (this.productMoreInfoTabs === 4) {
      selectedTab = this.productSizeTab.nativeElement;
    }
    const scrollAmount = selectedTab.getBoundingClientRect().top + window.pageYOffset - 200;
    window.scrollTo({ top: scrollAmount, behavior: 'smooth' });
  }

  /**
* Returns x raised to the n-th power.
*
* @param {number} x The number to raise.
* @param {number} n The power, must be a natural number.
* @return {number} x raised to the n-th power.
*/
  @HostListener('window:scroll', ['$event'])
  onProdutTabsScroll(): void {
    if (this.productInfoTab?.nativeElement.getBoundingClientRect().top < 200) {
      this.productMoreInfoTabs = 1;
    }
    if (this.productDescriptionTab?.nativeElement.getBoundingClientRect().top < 200) {
      this.productMoreInfoTabs = 2;
    }
    if (this.productCommentsTab?.nativeElement.getBoundingClientRect().top < 200) {
      this.productMoreInfoTabs = 3;
    }
    if (this.productSizeTab?.nativeElement.getBoundingClientRect().top < 200) {
      this.productMoreInfoTabs = 4;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onGoToCalcSize(): void {
    this.productMoreInfoTabs = 4;
    window.scrollBy({
      top: 300,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  async getviewProducts(productId: string, dressCode: string): Promise<void> {
    this.pageLoading = true;
    this.productApiService.findMatch(productId, dressCode).subscribe(response => {
      console.log(response['data']);

      if (!response['data']) {
        this.router.navigateByUrl('**', { skipLocationChange: true });
        this.pageLoading = false;
        return;
      }
      // this.product = response['data'];
      this.listOfTopProducts = response['data']['listOfTopProducts'];
      this.listOfBottomProducts = response['data']['listOfBottomProducts'];
      this.selectedTop = this.listOfTopProducts[0];
      this.selectedBottom = this.listOfBottomProducts[0];
      this.dataLoaded = true;
      this.manageHeader.updateTitle("پیشنهاد ست");
      // if (window.location.hostname.indexOf('localhost') !== -1) {
      //   this.productFullLink = window.location.hostname + ":" + this.location.port + "/product/" + productId;
      // } else {
      //   this.productFullLink = window.location.hostname + "/product/" + productId;
      // }
      // this.customePicture = this.product.picture;
      // this.observable.remove();
      // this.observable.add(this.product.title);
      // this.onSelect(this.product);
      // this.product.realPrice = this.helper.calculateDiscount(this.product.price, this.product.discount, 1);

      this.pageLoading = false;
      // this.evaluateComments();
      // this.onFillgallery();
    }, error => {
      this.pageLoading = false;
    })
  }
  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onAddToBasket(): void {

    if (!this.localStorageService.isLogIn()) {
      this.localStorageService.create('url', this.router.url);
      this.helper.redirectTo('/auth');
      this.helper.toaster('لطفا اول ثبت نام کنید', ToasterType.SUCCESS);
      return;
    }
    if (!this.productDetails.get('color').value) {
      this.helper.toaster('رنگ را انتخاب کنید', ToasterType.WARNING);
      return;
    }
    if (!this.productDetails.get('size').value) {
      this.helper.toaster('سایز را انتخاب کنید', ToasterType.WARNING);
      return;
    }
    if (this.countSimilarProducts === 0) {
      this.helper.toaster('محصول موجود نمی باشد', ToasterType.WARNING);
      return;
    }
    if (!this.similarProduct.id) {
      this.helper.toaster('محصول موجود نمی باشد', ToasterType.WARNING);
      return;
    }

    this.similarProductInfo.get('similarProductId').patchValue(this.similarProduct.id);
    this.buttonLoading = true;
    this.basketApiService.postCreateUpdateCart(this.similarProductInfo.value).subscribe(response => {
      this.buttonLoading = false;
      this.helper.toaster(' محصول به سبد خرید اضافه شد', ToasterType.SUCCESS);
      this.helper.redirectTo('/basket');
    }, error => {
      this.helper.showErrorMessage(error.error.errorMessages);
      this.buttonLoading = false;
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onAddCommnet(): void {
    if (this.localStorageService.checkAuthUrl('', false)) {
      this.openbutton.nativeElement.click();
    }
    this.onOpenModal();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateComment(productId: string): void {
    if (!this.helper.checkInvalidForm(this.commentInfo)) {
      this.buttonLoading = true;
      if (!this.isCaptchaVerified) {
        this.helper.toaster('لطفا کد امنیتی را تکمیل و مجدد اقدام کنید', ToasterType.WARNING);
        this.recaptchaReactiveInfo.markAllAsTouched();
        this.buttonLoading = false;
        return;
      }
      this.commentInfo.get('qualityOfFabric').patchValue(this.qualityOfFabric);
      this.commentInfo.get('innovation').patchValue(this.innovation);
      this.commentInfo.get('qualityOfClothingDesign').patchValue(this.qualityOfClothingDesign);
      this.commentInfo.get('matchThePhotoWithTheProduct').patchValue(this.matchThePhotoWithTheProduct);
      this.commentInfo.get('productId').patchValue(productId);
      this.productApiService.postCreateComment(this.commentInfo.value).subscribe(response => {
        this.buttonLoading = false;
        this.helper.toaster('نظر شما با موفقیت ثبت شد', ToasterType.WARNING);
        this.openbutton.nativeElement.click();
        this.onCreateCommentInfoForm();
        this.qualityOfFabric = 0;
        this.innovation = 0;
        this.qualityOfClothingDesign = 0;
        this.matchThePhotoWithTheProduct = 0;
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      });
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', ToasterType.WARNING);
      this.buttonLoading = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  filterSize(colorLabelId: string): void {

    this.listOfFilterSizes = [];
    if (colorLabelId !== null) {
      this.listOfFilterSizes = this.helper.uniqe(this.helper.sort(this.listOfSizes.filter(x => x.colorLabelId === colorLabelId), 'sizeTitle', OrderSort.DESCENDING), 'sizeTitle');
    }
    if (this.listOfFilterSizes.length === 0) {
      this.listOfFilterSizes = this.helper.uniqe(this.helper.sort(this.listOfFilterColors.filter(x => x.colorLabelId === colorLabelId), 'sizeTitle', OrderSort.DESCENDING), 'sizeTitle');
    }

    this.productDetails.get('size').patchValue(null);
    this.colorLabelId = colorLabelId;
    this.similarProduct = this.listOfSimilarProducts.find(x => x.colorLabelId === this.colorLabelId);
    if (this.similarProduct) {
      this.customePicture = this.similarProduct.picture;
    }
    this.findColorSizeCount();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  findColorSizeCount(sizeId?: string): void {
    if (this.colorLabelId === null) {
      this.helper.toaster('لطفا رنگ مورد نظر را انتخاب کنید', ToasterType.ERROR);
      return;
    }
    if (sizeId !== null && this.colorLabelId !== null) {
      this.similarProduct = this.listOfSimilarProducts.find(x => x.sizeId === sizeId && x.colorLabelId === this.colorLabelId);
      this.countSimilarProducts = (this.similarProduct === undefined || this.similarProduct === null) ? 'لطفا سایز را انتخاب کنید' : this.similarProduct.balance;
      this.sizeId = sizeId;
      if (this.similarProduct) {
        this.customePicture = this.similarProduct.picture;
      }
      this.onCalcPriceAndDiscount();
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  filterDuplicateColorSizes(listOfColorSizes: any[]): void {
    this.resetAllListOfSizes();
    for (let index = 0; index < listOfColorSizes.length; index++) {
      if (this.listOfFilterColors.length === 0 && this.listOfSizes.length === 0) {
        this.listOfFilterColors.push(listOfColorSizes[index]);
        this.listOfSizes.push(listOfColorSizes[index]);
      } else {
        let color = this.listOfFilterColors.find(x => x.colorLabelId === listOfColorSizes[index].colorLabelId);
        if (listOfColorSizes[index].type === SizeGroupType.FREE) {
          this.listOfSizes.push(listOfColorSizes[index]);
        }
        let size = this.listOfSizes.filter(y => y.sizeId !== listOfColorSizes[index].sizeId);
        if (!color) {
          this.listOfFilterColors.push(listOfColorSizes[index]);
        }
        if (size) {
          this.listOfSizes.push(listOfColorSizes[index]);
        }
      }
    }
    this.listOfFilterSizes = this.listOfSizes;
    this.listOfFilterSizes = this.helper.sort(this.helper.uniqe(this.listOfFilterSizes, 'sizeTitle'), 'sizeTitle', OrderSort.DESCENDING);
    // this.checkListOfColorSizesByBalance();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  checkListOfColorSizesByBalance(): void {
    let filterlistOfSizeByBalance: any[] = [];
    for (let x in this.listOfColorSizes) {
      for (let y in this.listOfFilterSizes) {
        if (this.listOfColorSizes[x].sizeId === this.listOfFilterSizes[y]['sizeId']) {
          if (this.listOfFilterSizes[y]['balance'] === 0) {
            if (this.listOfColorSizes[x].balance !== 0) {
              filterlistOfSizeByBalance = this.listOfColorSizes[x];
            }
          }
        }
      }
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  resetAllListOfSizes(): void {
    this.colorLabelId = null;
    this.listOfFilterColors = [];
    this.listOfSizes = [];
    this.listOfFilterSizes = [];
    this.moreSizes = [];
    this.onCreateProductDetailsForm();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onSelect(product: any): void {
    this.listOfSimilarProducts = product['listOfSimilarProducts'];
    this.listOfColorSizes = product['listOfColorSizes'];
    this.filterDuplicateColorSizes(this.listOfColorSizes);
    this.createAutoFieldsMoreSizes();
    for (let index in this.listOfFilterSizes) {
      let size: MoreSize = (this.listOfFilterSizes[index].moreSizes !== null) ? JSON.parse(this.listOfFilterSizes[index].moreSizes) : {};
      size.sizeTitle = this.listOfFilterSizes[index]?.sizeTitle;
      this.moreSizes.push(size);
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  createAutoFieldsMoreSizes(): void {
    switch (this.product.bodyPosition) {
      case 1:
        break;
      case 2:
        this.FIELDS_MORE_SIZES = ['عرض سینه', 'عرض کمر', 'طول لباس', 'طول آستین', 'دور باسن'];
        break;
      case 3:
        this.FIELDS_MORE_SIZES = ['عرض کمر', 'عرض ران', 'دور باسن'];
        break;
      case 4:
        this.FIELDS_MORE_SIZES = ['طول کف پا'];
        break;
      case 5:
        this.FIELDS_MORE_SIZES = ['اندازه کلی'];
        break;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCalcPriceAndDiscount(): void {
    if (this.similarProduct) {
      this.product.discount = this.similarProduct.discount;
      this.product.price = this.similarProduct.sellPrice;
      this.product.realPrice = this.helper.calculateDiscount(this.similarProduct.sellPrice, this.similarProduct.discount, 1);
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onOpenModal(): void {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  @HostListener('window:popstate', ['$event'])
  dismissModal(): void {
    this.closeButton?.nativeElement.click();
    this.showImageGallery = false;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onFillgallery(): void {
    this.gallery = [];
    for (let index = 0; index < this.product.galleries.length; index++) {
      this.gallery.push(this.product.galleries[index])
    }
    this.gallery.unshift(this.customePicture);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onOpenImageGallery(image: string): void {
    this.showImageGallery = true;
    this.gallerySelectedImage = image;
    this.gallerylength = this.gallery.length;
    this.currentImageIndex = this.gallery.findIndex(x => x === this.gallerySelectedImage) + 1;
    if (this.gallery.findIndex(x => x === this.gallerySelectedImage) === 0) {
      this.disablePreviousButton = true;
    }
    if (this.gallery.findIndex(x => x === this.gallerySelectedImage) == this.gallery.length - 1) {
      this.disableNextButton = true;
    }
    this.onOpenModal();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCloseImageGallery(): void {
    this.showImageGallery = false;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onChangeGalleryImage(event: Event, state: string): void {
    event.stopPropagation();
    if (state === 'next') {
      let nextImageIndex = this.gallery.findIndex(x => x === this.gallerySelectedImage) + 1;
      if (nextImageIndex < this.gallery.length) {
        this.currentImageIndex = nextImageIndex + 1;
        this.gallerySelectedImage = this.gallery[nextImageIndex];
        this.disablePreviousButton = false;
      }
      if (this.gallery.findIndex(x => x === this.gallerySelectedImage) === this.gallery.length - 1) {
        this.disableNextButton = true;
      }
    }
    if (state === 'previous') {
      let previousImageIndex = this.gallery.findIndex(x => x === this.gallerySelectedImage) - 1;
      if (previousImageIndex >= 0) {
        this.currentImageIndex = this.gallery.findIndex(x => x === this.gallerySelectedImage);
        this.gallerySelectedImage = this.gallery[previousImageIndex];
        this.disableNextButton = false;
      }
      if (this.gallery.findIndex(x => x === this.gallerySelectedImage) === 0) {
        this.disablePreviousButton = true;
      }
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onSelectedImage(event: Event): void {
    event.stopPropagation();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onZoomPinch(event: Event): void {
    event.stopPropagation();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  resolved(captchaResponse: string): void {
    this.textComment = 'در حال برسی ...';
    this.authApiService.posVerifyToken(captchaResponse).subscribe(response => {
      this.isCaptchaVerified = response['isSuccess'];
      this.textComment = 'ارسال نظر';
    }, error => {
      this.textComment = 'ارسال نظر';
      this.isCaptchaVerified = false;
      this.helper.showErrorMessage(error.error.errorMessages);
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  bookmarked: boolean = false;
  onToggleBookmark(): void {
    this.bookmarked = this.bookmarked === false ? true : false;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  productDeatilsOpstion: boolean = false;
  toggleProductDeatilsOptions(): void {
    this.productDeatilsOpstion == true ? this.productDeatilsOpstion = false : this.productDeatilsOpstion = true;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  copyLinkToClipboard(): void {
    this.clipboard.copy(this.productFullLink);
    this.helper.toaster(`لینک با موفقیت کپی شد`, ToasterType.SUCCESS);
    this.toggleProductDeatilsOptions();
  }

  activeBodyType: string = '';

  onSelectSetItem(activeBodyType: string, similarProduct: any): void {
    // console.log('seleted item', activeBodyType);
    // console.log('similarProduct', similarProduct);

    if (window.location.hostname.indexOf('localhost') !== -1) {
      this.productFullLink = window.location.hostname + ":" + this.location.port + "/product/" + similarProduct?.product?.link;
    } else {
      this.productFullLink = window.location.hostname + "/product/" + similarProduct?.product?.link;
    }

    this.product = similarProduct.product;
    this.activeBodyType = activeBodyType;
    this.selectedSetItemState = true;
    this.productDetails.reset();
  }



  onPrev(position: string, indexx?: number): void {
    // console.log('prev');
    switch (position) {
      case 'headToWaist':
        // this.listOfTopProducts.forEach((element, index) => {
        //   if (index === indexx - 1) {
        //     this.selectedTop = element;
        //   }
        // });
        this.sliderHeadToWaist.prev();
        break;
      case 'waistToAnkle':
        // this.listOfBottomProducts.forEach((element, index) => {
        //   if (index === indexx - 1) {
        //     this.selectedBottom = element;
        //   }
        // });
        this.sliderWaistToAnkle.prev();
        break;
      default:
        break;
    }

  }

  onNext(position: string, indexx?: number): void {
    // console.log('next');
    switch (position) {
      case 'headToWaist':
        // this.listOfTopProducts.forEach((element, index) => {
        //   if (index === indexx + 1) {
        //     this.selectedTop = element;
        //   }
        // });
        this.sliderHeadToWaist.next();
        break;
      case 'waistToAnkle':
        // this.listOfBottomProducts.forEach((element, index) => {
        //   if (index === indexx + 1) {
        //     this.selectedBottom = element;
        //   }
        // });
        this.sliderWaistToAnkle.next();
        break;
      default:
        break;
    }

  }

  onSelectSize(size: any): void {
    this.productDetails.get('size').patchValue(size.sizeId);
    this.similarProductInfo.get('similarProductId').patchValue(size.similarProductId);
  }

  addToCart(): void {

    if (!this.localStorageService.isLogIn()) {
      this.localStorageService.create('url', this.router.url);
      this.helper.redirectTo('/auth');
      this.helper.toaster('لطفا اول ثبت نام کنید', ToasterType.SUCCESS);
      return;
    }

    if (!this.productDetails.get('size').value) {
      this.helper.toaster('لطفا سایز را مشخص کنید', ToasterType.WARNING);
      return;
    }

    this.buttonLoading = true;
    this.basketApiService.postCreateUpdateCart(this.similarProductInfo.value).subscribe(response => {
      this.buttonLoading = false;
      this.helper.toaster(' محصول به سبد خرید اضافه شد', ToasterType.SUCCESS);
      this.helper.redirectTo('/basket');
    }, error => {
      this.helper.showErrorMessage(error.error.errorMessages);
      this.buttonLoading = false;
    });

  }


}
