<div class="modal-dialog modal-sm modal-dialog-centered" role="document">
  <div class="modal-content sign-in-modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-12 d-flex flex-column">
          <label class="confirm-modal-title">{{inputTitle}}</label>
          <svg class="confirm-Modal-svg-line">
            <line class="svg-line" x1="0" y1="0" x2="20000" y2="0" />
          </svg>
        </div>
        <div class="col-12 confirm-modal-text-col">
          <label class="confirm-text">{{ inputContent }}</label>
        </div>
        <div class="col-12 confirm-modal-button-col">
          <button data-dismiss="modal" (click)="onAccepted(false)" [disabled]="inputButtonLoading"
            class="medium-button grey-button">انصراف</button>

          <button type="button" #closeDeleteMenu class="hidden" data-dismiss="modal" aria-label="Close">

          </button>
          <button [class]="ButtonClass" [disabled]="inputButtonLoading" (click)="onAccepted(true)"
            class="medium-button">
            <img *ngIf="inputButtonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif">
            <span *ngIf="!inputButtonLoading">{{inputConfirmButtonText}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
