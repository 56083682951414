import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'latestModifiedFile'
})
export class LatestModifiedFilePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value.toString() + '?' + Math.round(new Date().getTime() / 1000);
  }

}
