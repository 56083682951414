import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ManageHeaderService {

  constructor(private title: Title, private meta: Meta) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  addMetaTag(name: string, content: string) {
    this.meta.addTag({ name: name, content: content });
  }

  addMetaTags(metaTags) {
    this.meta.addTags(metaTags);
  }

  addMetaTagProperty(property: string, content: string) {
    this.meta.addTag({ property: property, content: content });
  }


  getMetaTag(name: string) {
    return this.meta.getTag(name);
  }

  getMetaTags(metaTags: string) {
    return this.meta.getTags(metaTags);
  }

}
