<div *ngIf="!tableLoading" class="row orders-main-row">
  <!-- list of addresses start -->
  <div class="col-12 tables-main-col">
    <table *ngIf="!tableLoading && listOfAdresses.length != 0" class="table charisma-table">
      <thead class="table-head">
        <tr class="tr">
          <th class="th" scope="col">#</th>
          <th class="th" scope="col">نام گیرنده</th>
          <th class="th" scope="col">استان و شهر</th>
          <th class="th" scope="col">آدرس گیرنده</th>
          <th class="th" scope="col">ویرایش</th>
          <th class="th" scope="col">حذف</th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr *ngFor="let address of listOfAdresses; let i = index;" class="tr">
          <th class="th" scope="row">{{i + 1}}</th>
          <td class="td">{{address.recipientName}}</td>
          <td class="td">{{address.stateName}} / {{address.cityName}}</td>
          <td class="td">{{address.addressDetails}}</td>
          <td class="td">
            <a (click)="onToggleUpdate(address, i)" data-toggle="modal" data-target="#addressModal"
              class="actions-link">
              <img class="actions-icon" src="../../../../assets/images/core/grey-pen.svg" alt="ویرایش">
            </a>
          </td>
          <td class="td">
            <a (click)="onToggleDelete(address)" data-toggle="modal" data-target="#deleteModal" class="actions-link">
              <img class="actions-icon" src="../../../../assets/images/core/red-trash.svg" alt="حذف">
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- list of addresses end -->
  <div class="col-12">
    <button data-toggle="modal" data-target="#addressModal" (click)="onToggleAddAddress()"
      class="add-new-address-button" type="button">
      <span>افزودن آدرس جدید</span>
      <img class="add-icon" src="../../../../assets/images/core/plus.svg" alt="اضافه کردن آدرس">
    </button>
  </div>
</div>


<div *ngIf="tableLoading" class="profile-loading">
  <img class="profile-loading-gif" src="../../../../assets/images/core/carisma-logo-top-with-typo.png" alt="بارگذاری">
</div>


<!-- add address modal start -->
<div class="modal" id="addressModal" tabindex="-1" role="dialog" aria-labelledby="addressModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body main-containers">
        <form [formGroup]="addressInfo">
          <div class="row">
            <div class="col-12 add-new-address-title-col">
              <label class="add-new-address-modal-title">
                افزودن آدرس جدید
              </label>
              <svg class="address-svg-line">
                <line class="svg-line" x1="0" y1="0" x2="1000" y2="0" />
              </svg>
            </div>
            <div class="co-12 col-md-12 col-xl-6">

              <app-form-ng-select (click)="onFilterCities()"
                [formGroupClass]="'form-group form-group-custome basket-form-group-custome'"
                [placeholder]="'لطفا استان مورد نظر خود را انتخاب کنید'" [label]="'انتخاب استان'"
                [labelCssClass]="'form-group-custome-label'" [formGroup]="addressInfo"
                [control]="addressInfo.get('state')" [multiple]="false" [name]="'state'" [id]="'state'"
                [bindLabel]="'name'" [bindValue]="" [items]="listOfStates" [closeOnSelect]="true">
              </app-form-ng-select>

              <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'مثلا محمد محمدی'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input '" [control]="addressInfo.get('recipientName')"
                [minLength]="3" [maxLength]="2000" [id]="'recipientName'" [name]="'recipientName'"
                [formGroup]="addressInfo" [label]="'نام و نام خانوادگی گیرنده'">
              </app-form-input>

              <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'کد پستی را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input '" [control]="addressInfo.get('postalCode')"
                [minLength]="3" [maxLength]="2000" [id]="'postalCode'" [name]="'postalCode'" [formGroup]="addressInfo"
                [label]="'کد پستی'">
              </app-form-input>

            </div>
            <div class="co-12 col-md-12 col-xl-6">

              <app-form-ng-select [formGroupClass]="'form-group form-group-custome basket-form-group-custome'"
                [placeholder]="'لطفا شهر مورد نظر خود را انتخاب کنید'" [label]="'انتخاب شهر'"
                [labelCssClass]="'form-group-custome-label'" [formGroup]="addressInfo"
                [control]="addressInfo.get('city')" [multiple]="false" [name]="'city'" [id]="'city'"
                [bindLabel]="'name'" [bindValue]="" [items]="listofCities" [closeOnSelect]="true">
              </app-form-ng-select>

              <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'شماره تماس گیرنده را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input '" [control]="addressInfo.get('phoneReciver')"
                [minLength]="3" [maxLength]="2000" [id]="'phoneReciver'" [name]="'phoneReciver'"
                [formGroup]="addressInfo" [label]="'شماره تماس گیرنده'">
              </app-form-input>

              <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'پلاک را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input '" [control]="addressInfo.get('houseNumber')"
                [minLength]="3" [maxLength]="2000" [id]="'houseNumber'" [name]="'houseNumber'" [formGroup]="addressInfo"
                [label]="'شماره پلاک'">
              </app-form-input>

            </div>
            <div class="col-12">

              <app-form-textarea [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'آدرس کامل گیرنده را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
                [textareaCssClass]="' form-control form-group-custome-textarea '"
                [control]="addressInfo.get('addressDetails')" [minLength]="0" [maxLength]="1000000"
                [id]="'addressDetails'" [name]="'addressDetails'" [formGroup]="addressInfo" [cols]="'30'" [rows]="'7'"
                [label]="'آدرس کامل گیرنده'">
              </app-form-textarea>

            </div>
            <!-- <div class="col-12">
              <img class="address-map" src="../../../assets/images/contact-us/map.png" alt="map">
            </div> -->


            <div class="col-12">
              <button *ngIf="!addressInfo.value.id" (click)="onCreate()" [disabled]="buttonLoading" class="large-button"
                type="button">
                <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
                  alt="بارگذاری">
                <span *ngIf="!buttonLoading">ذخیره آدرس</span>
              </button>
              <button *ngIf="addressInfo.value.id" (click)="onUpdate()" [disabled]="buttonLoading" class="large-button"
                type="button">
                <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif"
                  alt="بارگذاری">
                <span *ngIf="!buttonLoading">ویرایش آدرس</span>
              </button>
            </div>

            <button class="hidden" type="button" #closebutton data-dismiss="modal"></button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- add address modal end -->



<div class="modal" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModal" aria-hidden="true">
  <app-confirm-modal [ButtonClass]="'confirm-delete'" [inputTitle]="confirmTitle" [inputContent]='confirmMessage'
    [inputConfirmButtonText]="confirmbutton" (outputAccepted)="onDelete($event)" [inputButtonLoading]="buttonLoading">
  </app-confirm-modal>
</div>
