<div *ngIf="!tableLoading" class="row chat-main-row">
  <!-- chat header start -->
  <div class="col-12 chat-header-col">
    <div class="ticket-code-and-title-container">
      <!-- <label class="ticket-code">474747</label> -->
      <label class="ticket-title">{{ticket.title}}</label>
    </div>
    <div class="badge-and-date-container">
      <!-- <label class="ticket-date">12 اردیبهشت 99</label> -->
      <label class="ticket-date">{{ticket.createAt | jalali}}</label>
      <label
        [ngClass]="{'pendding-for-check-status': ticket.status == 1, 'pendding-for-answer-status': ticket.status == 2, 'answered-status': ticket.status == 3, 'customer-answer-status': ticket.status == 4, 'closed-status': ticket.status == 5}">
        {{ticket.status | ticketStatus}}
      </label>
    </div>
  </div>
  <!-- chat header end -->
  <!-- chat texts start -->
  <div class="col-12 chat-messages-col">
    <div class="chat-scrollable-wrapper">
      <div *ngFor="let message of ticket.listOfMessages"
        [ngClass]="{'customer-message-container': message.senderId === userId}" class="massage-containers">
        <p class="message-text">{{message.text}}</p>
        <label class="chat-time">
          <span>{{message.createAt | date:"hh:mm:ss"}}</span>
          <span class="chat-online-status"></span>
        </label>
      </div>
    </div>
    <!-- <span class="no-background"><span>12 اردیبهشت 99</span></span> -->
  </div>
  <!-- chat texts end -->
</div>
<!-- chat text input start -->
<div *ngIf="!tableLoading" class="row send-message-main-row">
  <form [formGroup]="ticketInfo" class="w-100">
    <div class="send-message-input-wrapper">
      <input placeholder="متن پیام خود را وارد کنید" autocomplete="off" class="send-message-input" type="text" id="text"
        name="text" formControlName="text">
      <button (click)="onCreate()" [disabled]="buttonLoading" class="send-message-button" type="button">
        <img *ngIf="buttonLoading" class="button-loading" src="../../../../../assets/images/core/pink-loading.gif"
          alt="بارگذاری">
        <img *ngIf="!buttonLoading" class="send-icon" src="../../../../../assets/images/core/send.svg" alt="ارسال پیام">
      </button>
    </div>
  </form>
</div>
<!-- chat text input end -->

<div *ngIf="tableLoading" class="profile-loading">
  <img class="profile-loading-gif" src="../../../../assets/images/core/carisma-logo-top-with-typo.png" alt="بارگذاری">
</div>
