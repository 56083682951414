import { QuestionnaireComponent } from './questionnaire/questionnaire/questionnaire.component';
import { WatingComponent } from './site/wating/wating.component';
import { BlogSearchComponent } from './site/blog/blog-search/blog-search.component';
import { BlogCategoryComponent } from './site/blog/blog-category/blog-category.component';
import { ArticleComponent } from './site/blog/article/article.component';
import { BlogComponent } from './site/blog/blog.component';
import { BlogLayoutComponent } from './shared/layout/blog-layout/blog-layout.component';
import { SellerComponent } from './site/seller/seller.component';
import { ChildMenuComponent } from './site/menu/child-menu/child-menu.component';
import { MenuComponent } from './site/menu/menu.component';
import { VendorPageComponent } from './site/vendor-page/vendor-page.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { WithoutHeaderAndFooterLayoutComponent } from './shared/layout/without-header-and-footer-layout/without-header-and-footer-layout.component';
import { NotFoundComponent } from './site/not-found/not-found.component';
import { OrderDetailsComponent } from './user-club/user-profile/my-order/order-details/order-details.component';
import { ListOfOrdersComponent } from './user-club/user-profile/my-order/list-of-orders/list-of-orders.component';
import { ChatComponent } from './user-club/user-profile/support/chat/chat.component';
import { ListOfTicketsComponent } from './user-club/user-profile/support/list-of-tickets/list-of-tickets.component';
import { AddressesComponent } from './user-club/user-profile/addresses/addresses.component';
import { TransactionComponent } from './user-club/user-profile/transaction/transaction.component';
import { AccountComponent } from './user-club/user-profile/account/account.component';
import { UserProfileComponent } from './user-club/user-profile/user-profile.component';
import { UnsuccessfullPaymentComponent } from './site/basket/unsuccessfull-payment/unsuccessfull-payment.component';
import { SuccessfullPaymentComponent } from './site/basket/successfull-payment/successfull-payment.component';
import { ContactUsComponent } from './site/contact-us/contact-us.component';
import { AboutUsComponent } from './site/about-us/about-us.component';
import { UserClubComponent } from './user-club/user-club.component';
import { ProductDetailsComponent } from './site/product-details/product-details.component';
import { HomeComponent } from './site/home/home.component';
import { SiteLayoutComponent } from './shared/layout/site-layout/site-layout.component';
import { ProductListComponent } from './site/product-list/product-list.component';
import { Guard } from './core/guards/guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/layout/auth-layout/auth-layout.component';
import { BasketComponent } from './site/basket/basket.component';
import { CategoryComponent } from './site/category/category.component';
import { SuccessfullRegisterComponent } from './site/seller/successfull-register/successfull-register.component';
import { SetRecommendationComponent } from './site/set-recommendation/set-recommendation.component';
import { ClosetComponent } from './site/closet/closet.component';

const routes: Routes = [
  {
    path: '', component: SiteLayoutComponent, children: [
      { path: '', component: HomeComponent },

      { path: 'cloth-founder', component: ClosetComponent },

      { path: 'about-us', component: AboutUsComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'basket', component: BasketComponent, canActivate: [Guard] },
      { path: 'basket/successfull-payment/:refId', component: SuccessfullPaymentComponent, canActivate: [Guard] },
      { path: 'basket/unsuccessfull-payment', component: UnsuccessfullPaymentComponent, canActivate: [Guard] },
      { path: 'product-list', component: ProductListComponent },
      { path: 'more/product-list', redirectTo: 'product-list' },
      { path: 'product/:link', component: ProductDetailsComponent },
      { path: 'set-recommendation', component: SetRecommendationComponent },
      { path: 'set-recommendation/:productId/:dressCode', component: SetRecommendationComponent },
      { path: 'menu', component: MenuComponent },
      { path: 'menu/:categoryLink', component: ChildMenuComponent },
      { path: 'category/:categoryLink', component: CategoryComponent },
      { path: 'vendor/:link', redirectTo: 'seller/:link' },
      { path: 'seller/:link', component: VendorPageComponent },
      {
        path: 'user-club', component: UserClubComponent, canActivate: [Guard], children: [
          {
            path: 'user-profile', component: UserProfileComponent, children: [
              { path: 'account', component: AccountComponent },
              { path: 'my-order', component: ListOfOrdersComponent },
              { path: 'order-details/:orderCode', component: OrderDetailsComponent },
              { path: 'transaction', component: TransactionComponent },
              { path: 'addresses', component: AddressesComponent },
              { path: 'support', component: ListOfTicketsComponent },
              { path: 'support/chat/:id', component: ChatComponent },
            ]
          },
        ]
      },
    ]
  },
  {
    path: 'auth', component: AuthLayoutComponent, children: [
      { path: '', component: SignInComponent },
    ]
  },
  {
    path: 'register-seller', component: AuthLayoutComponent, children: [
      { path: '', component: SellerComponent },
      { path: 'successfull', component: SuccessfullRegisterComponent },
    ]
  },
  {
    path: 'blog', component: BlogLayoutComponent, children: [
      { path: '', component: BlogComponent },
      { path: ':link', component: ArticleComponent },
      { path: 'category/:categoryLink', component: BlogCategoryComponent },
      { path: 'search/:searchedText', component: BlogSearchComponent },
    ]
  },
  //questionnaire
  { path: 'questionnaire', component: QuestionnaireComponent },

  //post
  { path: ':year/:month/:postLink', redirectTo: '/blog/:postLink' },
  { path: 'blog/:link', component: ArticleComponent },

  //category
  { path: 'category/:categroy1/:category2', redirectTo: 'blog' },
  { path: 'category/:categroy1/:category2/:category3', redirectTo: 'blog' },
  { path: 'blog/category/:categoryLink', redirectTo: 'blog' },
  {
    path: '**', component: WithoutHeaderAndFooterLayoutComponent, children: [

      { path: '', component: NotFoundComponent }

    ]
  }
];

// const routes: Routes = [
//   {
//     path: '**', component: WithoutHeaderAndFooterLayoutComponent, children: [

//       { path: '', component: WatingComponent }

//     ]
//   }
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
