<app-page-loading *ngIf="pageLoading"></app-page-loading>

<div class="row">
  <div class="col-12 col-xl-9">
    <div class="row">
      <article class="col-12">
        <div class="row article-main-row">
          <div class="col-12 article-main-picture-wrapper">
            <img class="article-main-picture" [title]="post?.pictureTitle" [src]="post?.picture"
              [alt]="post?.pictureAlt">
            <h2 class="article-main-title">{{ post?.title }}</h2>
            <div class="article-main-information">
              <a class="article-info">{{ post?.categoryChildTitle}}</a>
              <label class="article-info">{{ post?.createAt | jalali }}</label>
              <a class="article-info">{{ post?.userName }}</a>
              <label class="article-info">
                زمان مطالعه :
                5 دقیقه
              </label>
            </div>
          </div>
          <div class="col-12 article-main-content" [innerHTML]="post?.content | noSanitize"></div>
          <div class="col-12 article-sub-links my-2">
            <a href="#" class="article-source">
              <span class="span">بازدید :</span>
              {{ post?.visit }}
            </a>
          </div>
          <div class="col-12 article-sub-links my-2">
            <div class="row">
              <div class="col-12 col-xl-6">
                <a href="#" class="article-source">
                  <span class="span">منبع :</span>
                  کاریزما کالر | مجله تخصصی مد و پوشاک و رنگ
                </a>
              </div>
              <div class="col-12 col-xl-6 d-flex align-items-center justify-content-end">
                <button class="linke-and-dislike-buttons" type="button">
                  <img class="icon" src="../../../../assets/images/core/like.svg" alt="لایک">
                  <span class="text">{{ article.blogPostRatingViewModel?.countLike }}</span>
                </button>
                <button class="linke-and-dislike-buttons" type="button">
                  <img class="icon" src="../../../../assets/images/core/dislike.svg" alt="دیس لایک">
                  <span class="text">{{ article.blogPostRatingViewModel?.countDisLike }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 justify-content-between my-2">
            <div class="row">
              <div class="col-12 col-xl-6">
                <label class="share-title">اشتراک گذاری در :</label>
              </div>
              <div class="col-12 col-xl-6 d-flex align-items-center justify-content-end">
                <!-- <a href="#" class="share-links">
                  <img class="icon" src="../../../../assets/images/core/instagram.svg" alt="اینستاگرام">
                </a> -->
                <a href="https://t.me/share/url?url={{'https://carismacolor.com/blog/' + article.blogInfo?.link}}&text={{article.blogInfo?.title}}"
                  target="_blank" class="share-links">
                  <img class="icon" src="../../../../assets/images/core/telegram.svg" alt="تلگرام">
                </a>
                <a href="https://api.whatsapp.com/send?text={{'https://carismacolor.com/blog/' + article.blogInfo?.link}}"
                  target="_blank" class="share-links">
                  <img class="icon" src="../../../../assets/images/core/whatsapp.svg" alt="واتساپ">
                </a>
                <a href="https://www.facebook.com/sharer/sharer.php?u={{'https://carismacolor.com/blog/' + article.blogInfo?.link}}"
                  target="_blank" class="share-links">
                  <img class="icon" src="../../../../assets/images/core/facebook.svg" alt="فیسبوک">
                </a>

              </div>
            </div>
          </div>
          <div class="col-12 d-flex flex-wrap align-items-center">
            <label *ngIf="listOfLabels.length > 0" class="tags-title">برچسب ها :</label>
            <label *ngFor="let label of listOfLabels" class="tags">{{label}}</label>
          </div>
        </div>
      </article>
      <div class="col-12">
        <div class="row article-main-row">
          <div class="col-12 d-flex flex-column flex-md-row align-items-center">
            <img *ngIf="article.blogInfo?.userPicture" class="author-pic"
              [src]="baseUrl + '/image/' + article.blogInfo?.userPicture" alt="نویسنده">
            <img *ngIf="!article.blogInfo?.userPicture" class="author-pic"
              src="../../../../assets/images/core/avatar.jpg" alt="نویسنده">
            <p class="author-text">{{article.blogInfo?.userName}}</p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row article-main-row">
          <div class="col-12 article-main-titles-col">
            <label class="title">مطالب مرتبط</label>
          </div>
          <article *ngFor="let post of article?.listOfBlogsRelated" class="col-6 col-md-4 col-xl-4">
            <a [routerLink]="['/blog/' + post?.link]"
              class="main-post-cards main-post-cards-vertical main-post-short-height">
              <img class="image" [src]="post?.picture" alt="تصویر شاخص مقاله">
              <label class="article-information-text">منتشر شده در تاریخ {{ post?.createAt | jalali}}</label>
              <h3 class="article-title">{{ post?.title }}</h3>
            </a>
          </article>
        </div>
      </div>
      <div class="col-12 mb-2">
        <div class="row">
          <div *ngFor="let post of article?.listOfBlogsBeforeAndAfter; let i = index" class="col-6">
            <a [routerLink]="['/blog/' + post?.link]" title="{{ i === 0 ? 'بعدی' : 'قبلی'}}" class="sibling-articles">
              <img class="image" [src]="post?.picture" alt="مقاله بعدی و قبلی">
              <label class="title">{{post?.title}}</label>
            </a>
          </div>
          <!-- <div class="col-6">
            <a href="#" title="قبلی" class="sibling-articles">
              <img class="image" src="../../../../assets/images/blog/social-holding.jpg" alt="بعدی">
              <label class="title">جدیدترین مدل های کیف دوشی اسپرت زنانه</label>
            </a>
          </div> -->
        </div>
      </div>
      <div class="col-12">
        <form [formGroup]="commentInfo">
          <div class="row article-main-row">
            <div class="col-12 article-main-titles-col">
              <label class="title">پاسخ بگذارید</label>
            </div>
            <div class="col-12 col-md-6 col-xl-6">

              <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'مثلا محمد محمدی'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input '" [control]="commentInfo.get('fullName')"
                [minLength]="3" [maxLength]="2000" [id]="'fullName'" [name]="'fullName'" [formGroup]="commentInfo"
                [label]="'نام و نام خانوادگی'">
              </app-form-input>

            </div>
            <div class="col-12 col-md-6 col-xl-6">

              <!-- <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'شماره تماس را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input '" [control]="commentInfo.get('number')"
                [minLength]="3" [maxLength]="2000" [id]="'number'" [name]="'number'" [formGroup]="commentInfo"
                [label]="'شماره تماس '">
              </app-form-input> -->

              <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'example@gmail.com'" [labelCssClass]="'form-group-custome-label'"
                [inputCssClass]="' form-control form-group-custome-input '" [control]="commentInfo.get('email')"
                [minLength]="3" [maxLength]="2000" [id]="'email'" [name]="'email'" [formGroup]="commentInfo"
                [label]="'ایمیل'">
              </app-form-input>


            </div>
            <div class="col-12">

              <app-form-textarea [formGroupClass]="'form-group form-group-custome'"
                [placeholder]="'متن کامنت را وارد کنید'" [labelCssClass]="'form-group-custome-label'"
                [textareaCssClass]="' form-control form-group-custome-textarea '"
                [control]="commentInfo.get('description')" [minLength]="0" [maxLength]="1000000" [id]="'description'"
                [name]="'description'" [formGroup]="commentInfo" [cols]="'30'" [rows]="'7'" [label]="'متن پیام'">
              </app-form-textarea>

            </div>
            <div class="col-12 d-flex justify-content-end">
              <div class="comment-rechapta" [formGroup]="recaptchaReactiveInfo">
                <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"
                  siteKey="6LcZ3tUaAAAAAHTvCJlPfJT_1RizXNungVgpuSaF" required>
                </re-captcha>
                <span
                  *ngIf="recaptchaReactiveInfo.controls['recaptchaReactive'].hasError('required') && recaptchaReactiveInfo.get('recaptchaReactive').touched"
                  class="help-block">
                  روی منن ربات نیستم کلیک کنید
                </span>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-end mb-2">
              <button (click)="onCreateComment()" [disabled]="buttonLoading" class="medium-button" type="button">
                <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core//loading.gif"
                  alt="بارگذاری">
                <Span *ngIf="!buttonLoading">{{ textComment }}</Span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12">
        <div class="row article-main-row">
          <div class="col-12 article-main-titles-col">
            <label class="title">نظرات کاربران</label>
          </div>
          <div *ngIf="article?.listOfBlogComments?.length > 0" class="col-12">
            <div *ngFor="let comment of article.listOfBlogComments" class="row flex-column conversation-boxes">
              <div class="col-12 col-md-6 col-xl-10 my-2">
                <div class="blog-commnets-boxes">
                  <label class="blog-commnets-user-name">{{comment.fullName}}</label>
                  <p class="blog-commnets-text">{{comment.description}}</p>
                </div>
              </div>
              <div *ngFor="let answer of comment.listOfAnswerComments"
                class="col-12 col-md-6 col-xl-10 my-2 align-self-end">
                <div class="blog-commnets-boxes admin-answer-boxes">
                  <label class="blog-commnets-user-name">مدیر بلاگ</label>
                  <p class="blog-commnets-text">{{answer.message}}</p>
                </div>
              </div>
              <div class="col-10 align-self-center">
                <svg class="main-svg">
                  <line class="svg-line" x1="0" y1="0" x2="2000" y2="0" />
                </svg>
              </div>
            </div>
            <!-- <div class="row flex-column conversation-boxes">
              <div class="col-12 col-md-6 col-xl-10 my-2">
                <div class="blog-commnets-boxes">
                  <label class="blog-commnets-user-name">تست باشلویی</label>
                  <p class="blog-commnets-text">متن کامنت متن کامنت متن کامنت متن کامنتمتن کامنتمتن کامنت متن کامنت متن
                    کامنت متن کامنتمتن کامنت
                    متن کامنت متن کامنت متن کامنت </p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-10 my-2 align-self-end">
                <div class="blog-commnets-boxes admin-answer-boxes">
                  <label class="blog-commnets-user-name">مدیر بلاگ</label>
                  <p class="blog-commnets-text">متن کامنت متن کامنت متن کامنت متن کامنتمتن کامنتمتن کامنت متن کامنت متن
                    کامنت متن کامنتمتن کامنت
                    متن کامنت متن کامنت متن کامنت </p>
                </div>
              </div>
              <div class="col-10 align-self-center">
                <svg class="main-svg">
                  <line class="svg-line" x1="0" y1="0" x2="2000" y2="0" />
                </svg>
              </div>
            </div>
            <div class="row flex-column conversation-boxes">
              <div class="col-12 col-md-6 col-xl-10 my-2">
                <div class="blog-commnets-boxes">
                  <label class="blog-commnets-user-name">تست باشلویی</label>
                  <p class="blog-commnets-text">متن کامنت متن کامنت متن کامنت متن کامنتمتن کامنتمتن کامنت متن کامنت متن
                    کامنت متن کامنتمتن کامنت
                    متن کامنت متن کامنت متن کامنت </p>
                </div>
              </div>
              <div class="col-10 align-self-center">
                <svg class="main-svg">
                  <line class="svg-line" x1="0" y1="0" x2="2000" y2="0" />
                </svg>
              </div>
            </div>
            <div class="row flex-column conversation-boxes">
              <div class="col-12 col-md-6 col-xl-10 my-2">
                <div class="blog-commnets-boxes">
                  <label class="blog-commnets-user-name">تست باشلویی</label>
                  <p class="blog-commnets-text">متن کامنت متن کامنت متن کامنت متن کامنتمتن کامنتمتن کامنت متن کامنت متن
                    کامنت متن کامنتمتن کامنت
                    متن کامنت متن کامنت متن کامنت </p>
                </div>
              </div>
            </div> -->
          </div>
          <div *ngIf="article?.listOfBlogComments?.length <= 0" class="col-12">
            <p class="no-comment-text">تا کنون نظری برای این پست ثبت نشده است.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-xl-3">
    <div class="row">
      <div class="col-12 d-flex flex-column">
        <div class="quick-links-header-wrapper">
          <label class="quick-links-header">جدیدترین مطالب</label>
          <button [routerLink]="['/blog/category/مد-و-فشن']" class="blog-more-button" type="button">بیشتر</button>
        </div>
        <a *ngFor="let post of article?.listOfBlogsNewest" [routerLink]="['/blog/' + post.link]"
          class="posts-quick-links">
          <img class="image" [src]="post?.picture" alt="مقاله">
          <div>
            <h4 class="post-title">{{ post?.title }}</h4>
            <label class="post-title">
              تاریخ انتشار :
              {{ post?.createAt | jalali }}
            </label>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
