import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileManagerApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  postUplodaImages(formData: any) {
    return this.httpConnection.post("customer/CustomerPersonalInformations/PostUpdateImageProfile", formData);
  }

}
