import { ManageHeaderService } from './../../core/services/platform-browser/manage-header.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.less']
})
export class AboutUsComponent implements OnInit {
  headerTitle: string = 'کاریزماکالر | درباره ما'
  constructor(private manageHeader: ManageHeaderService) { }

  ngOnInit(): void {
    this.manageHeader.updateTitle(this.headerTitle);
  }

}
