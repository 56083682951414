import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  getSearchInProduct(productTitle: string) {
    return this.httpConnection.getParams('store/Site/GetSearchInProduct', productTitle);
  }

}
