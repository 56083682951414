import { recommendData, Recommend } from "./recommandStaticData";

export enum CharacterType {
    ETHICS = 1, // خلاق
    COMFORTABLE = 2, // راحت پوش
    NATURAL = 3, // طبیعی
    EMOTIONAL = 4, // عاطفی
    DRAMATIC = 5, // نمایشی
    CLASSIC = 6 // کلاسیک
}

// {id: number, title: string, score: number}

export interface Answer {
    answer: number | boolean,
    point: number,
    step?: number
};

export class EvaluateCharacters {

    protected ethicsScore: number;
    protected comfortableScore: number;
    protected naturalScore: number;
    protected emotionalScore: number;
    protected dramaticScore: number;
    protected classicScore: number;

    constructor() {
        this.ethicsScore = 0;
        this.comfortableScore = 0;
        this.naturalScore = 0;
        this.emotionalScore = 0;
        this.dramaticScore = 0;
        this.classicScore = 0;
    }

    public calculateCharacterScores(answerSheet: object): any {   // exapmle: { "personality": {answer: 1, points; 10, step: 2} }

        // Answer 1
        this.computingPersonality(answerSheet['personality']);

        // Answer 2
        this.computingLuxulyTaste(answerSheet['luxulyTaste']);

        // Answer 3
        this.computingTravelIntrest(answerSheet['travelIntrest']);

        // Answer 4
        this.computingFantasyTaste(answerSheet['fantasyTaste']);

        // Answer 5
        this.computingLifeStyle(answerSheet['lifeStyle']);

        // Answer 6
        this.computingFashionable(answerSheet['fashionable']);

        // Answer 7
        this.computingStyle(answerSheet['style']);

        // Answer 8
        this.computingUnusualStyle(answerSheet['unusualStyle']);

        // Answer 9
        this.computingHairStyle(answerSheet['hairStyle']);

        // Answer 9
        this.computingClothInfo(answerSheet['clothInfo']);

        // Answer 10
        (answerSheet['makeupInfo']['answer'] !== 0) ? this.computingMakeupInfo(answerSheet['makeupInfo']) : null;

        return {
            "classic": {
                id: CharacterType.CLASSIC,
                title: "کلاسیک",
                score: this.classicScore
            },
            "deramatic": {
                id: CharacterType.DRAMATIC,
                title: "نمایشی",
                score: this.dramaticScore
            },
            "romantic": {
                id: CharacterType.EMOTIONAL,
                title: "عاطفی",
                score: this.emotionalScore
            },
            "natural": {
                id: CharacterType.NATURAL,
                title: "طبیعی",
                score: this.naturalScore
            },
            "sports": {
                id: CharacterType.COMFORTABLE,
                title: "راحت پوش",
                score: this.comfortableScore
            },
            "ethics": {
                id: CharacterType.ETHICS,
                title: "خلاق",
                score: this.ethicsScore
            }
        }
    }

    private computingPersonality(personalityData: Answer): void {

        switch (Number(personalityData.answer)) {
            case 1:
                this.emotionalScore += personalityData.point;
                break;
            case 2:
                this.naturalScore += personalityData.point;
                break;
            case 3:
                this.dramaticScore += personalityData.point;
                break;
            case 4:
                this.comfortableScore += personalityData.point;
                break;
            case 5:
                this.classicScore += personalityData.point;
                break;
            case 6:
                this.ethicsScore += personalityData.point;
                break;
        }

    }

    private computingLuxulyTaste(luxulyTasteData: Answer): void {

        if (luxulyTasteData.answer) { // yes
            this.ethicsScore += luxulyTasteData.point;
            this.emotionalScore += luxulyTasteData.point;
            this.classicScore += luxulyTasteData.point;
        } else { // no
            this.comfortableScore += luxulyTasteData.point;
            this.naturalScore += luxulyTasteData.point;
            this.ethicsScore += luxulyTasteData.point;
        }
    }

    private computingTravelIntrest(travelIntrestData: Answer): void {

        if (travelIntrestData.answer) {
            this.naturalScore += travelIntrestData.point;
            this.comfortableScore += travelIntrestData.point;
            this.ethicsScore += travelIntrestData.point;
        } else {
            this.classicScore += travelIntrestData.point;
            this.emotionalScore += travelIntrestData.point;
            this.dramaticScore += travelIntrestData.point;
        }
    }

    private computingFantasyTaste(fantasyTasteData: Answer): void {

        if (fantasyTasteData.answer) {
            this.dramaticScore += fantasyTasteData.point;
        } else {
            this.ethicsScore += fantasyTasteData.point;
            this.emotionalScore += fantasyTasteData.point;
            this.classicScore += fantasyTasteData.point;
            this.naturalScore += fantasyTasteData.point;
            this.comfortableScore += fantasyTasteData.point;
        }
    }

    private computingLifeStyle(lifeStyleData: Answer): void {

        switch (Number(lifeStyleData.answer)) {
            case 1:
                this.emotionalScore += lifeStyleData.point;
                break;
            case 2:
                this.naturalScore += lifeStyleData.point;
                break;
            case 3:
                this.dramaticScore += lifeStyleData.point;
                break;
            case 4:
                this.comfortableScore += lifeStyleData.point;
                break;
            case 5:
                this.classicScore += lifeStyleData.point;
                break;
            case 6:
                this.ethicsScore += lifeStyleData.point;
                break;
        }
    }

    private computingFashionable(fashionableData: Answer): void {

        if (fashionableData.answer) {
            this.dramaticScore += fashionableData.point;
            this.emotionalScore += fashionableData.point;
        } else {
            this.naturalScore += fashionableData.point;
            this.ethicsScore += fashionableData.point;
            this.comfortableScore += fashionableData.point;
            this.classicScore += fashionableData.point;
        }
    }

    private computingStyle(styleData: Answer): void {

        switch (Number(styleData.answer)) {
            case 1:
                this.classicScore += styleData.point;
                break;
            case 2:
                this.dramaticScore += styleData.point;
                break;
            case 3:
                this.emotionalScore += styleData.point;
                break;
            case 4:
                this.naturalScore += styleData.point;
                break;
            case 5:
                this.comfortableScore += styleData.point;
                break;
            case 6:
                this.ethicsScore += styleData.point;
                break;
        }
    }

    private computingUnusualStyle(unsualStyleData: Answer): void {

        if (unsualStyleData.answer) {
            this.dramaticScore += unsualStyleData.point;
            this.ethicsScore += unsualStyleData.point;
        } else {
            this.naturalScore += unsualStyleData.point;
            this.emotionalScore += unsualStyleData.point;
            this.comfortableScore += unsualStyleData.point;
            this.classicScore += unsualStyleData.point;
        }
    }

    private computingHairStyle(hairStyleData: Answer): void {

        switch (Number(hairStyleData.answer)) {
            case 1:
                this.classicScore += hairStyleData.point;
                break;
            case 2:
                this.dramaticScore += hairStyleData.point;
                break;
            case 3:
                this.emotionalScore += hairStyleData.point;
                break;
            case 4:
                this.comfortableScore += hairStyleData.point;
                break;
            case 5:
                this.dramaticScore += hairStyleData.point;
                break;
            case 6:
                this.naturalScore += hairStyleData.point;
                break;
        }
    }

    private computingClothInfo(clothInfoData: Answer): void {

        switch (Number(clothInfoData.answer)) {
            case 1:
                this.classicScore += clothInfoData.point;
                break;
            case 2:
                this.naturalScore += clothInfoData.point;
                break;
            case 3:
                this.comfortableScore += clothInfoData.point;
                break;
            case 4:
                this.emotionalScore += clothInfoData.point;
                break;
            case 5:
                this.dramaticScore += clothInfoData.point;
                break;
            case 6:
                this.ethicsScore += clothInfoData.point;
                break;
        }

    }

    private computingMakeupInfo(makeupInfoData: Answer): void {

        switch (Number(makeupInfoData.answer)) {
            case 1:
                this.classicScore += makeupInfoData.point;
                break;
            case 2:
                this.naturalScore += makeupInfoData.point;
                this.comfortableScore += makeupInfoData.point;
                this.ethicsScore += makeupInfoData.point;
                break;
            case 3:
                this.emotionalScore += makeupInfoData.point;
                break;
            case 4:
                this.dramaticScore += makeupInfoData.point;
                break;
        }
    }

    public recommanded(id: number, gender?: number): Recommend | Recommend[] {
        return (gender === undefined) ? recommendData.filter(x => x.id === id) : recommendData.filter(x => x.id === id && x.gender === gender)[0];
    }
}