<div *ngIf="!pageLoding" class="row">
  <div class="col-12 col-md-6 col-xl-6 cols-with-container-inside">
    <div class="main-containers">
      <div class="sections-titles-wrapper sections-titles-wrapper-centered">

        <div class="title-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="30.495" height="29.883" viewBox="0 0 30.495 29.883">
            <path id="Union_1" data-name="Union 1"
              d="M29.484,29.9h-15v-.036H-1.009v0a15.815,15.815,0,0,0,15.5-15.746v.6a15.827,15.827,0,0,1,15-14.7V29.9h0Z"
              transform="translate(1.009 -0.017)" fill="#fff" />
          </svg>

          <div class="sections-title-label">
            اطلاعات کاربری من
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" width="30.747" height="29.883" viewBox="0 0 30.747 29.883">
            <path id="Union_1" data-name="Union 1"
              d="M30.493,29.883a15.772,15.772,0,0,1-15-14.689v.585A15.767,15.767,0,0,0-.252.036H15.494V0h15V29.882h0Z"
              transform="translate(30.495 29.883) rotate(180)" fill="#fff" />
          </svg>
        </div>

      </div>
      <!-- account info start -->
      <form [formGroup]="accountInfo">
        <!-- upload profile picture start -->
        <div class="upload-picture-wrapper">

          <label for="uploadProfilePicture" class="upload-picture-label" tabindex="4">
            <span *ngIf="!accountInfo.value.picture && !buttonLoading && sectionLoading  !== 'picture'">انتخاب تصویر
              شاخص</span>
            <img class="uploaded-picture" *ngIf="accountInfo.value.picture && sectionLoading  !== 'picture'"
              [src]="baseUrl + '/userImage/' + accountInfo.value.picture" alt="تصویر پروفایل">
            <a (change)="onUploadMainPicture($event)"
              *ngIf="accountInfo.value.picture && !buttonLoading && sectionLoading  !== 'picture'"
              class="upload-again-link">آپلود دوباره تصویر </a>
            <img *ngIf="buttonLoading && sectionLoading === 'picture'" class="button-loading"
              src="../../../../assets/images/core/pink-loading.gif" alt="بارگذاری">
            <input type="file" (change)="onUploadMainPicture($event)" class="hidden" id="uploadProfilePicture">
          </label>

        </div>
        <!-- upload profile picture end -->
        <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'"
          [placeholder]="'مثلا محمد رضا'" [labelCssClass]="'form-group-custome-label'"
          [inputCssClass]="' form-control form-group-custome-input '" [control]="accountInfo.get('firstName')"
          [minLength]="3" [maxLength]="2000" [id]="'firstName'" [name]="'firstName'" [formGroup]="accountInfo"
          [label]="'نام'">
        </app-form-input>

        <app-form-input [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا محمدی'"
          [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
          [control]="accountInfo.get('lastName')" [minLength]="3" [maxLength]="2000" [id]="'lastName'"
          [name]="'lastName'" [formGroup]="accountInfo" [label]="'نام خانوادگی'">
        </app-form-input>


        <div class="buttons-div">
          <div class="radio-div">
            <label for="woman" class="unactive-label active-buttons"
              [ngClass]="{'active-buttons': accountInfo.value.gender ==0 }">خانم</label>
            <input type="radio" formControlName="gender" name="gender" id="woman" [value]="0" class="hidden">
          </div>
          <div class="radio-div">
            <label for="man" class="unactive-label active-buttons"
              [ngClass]="{'active-buttons': accountInfo.value.gender ==1 }">آقا</label>
            <input type="radio" formControlName="gender" name="gender" id="man" [value]="1" class="hidden">
          </div>
          <div class="radio-div">
            <label for="both" class="unactive-label active-buttons"
              [ngClass]="{'active-buttons': accountInfo.value.gender ==2 }">هردو</label>
            <input type="radio" formControlName="gender" name="gender" id="both" [value]="2" class="hidden">
          </div>
        </div>


        <div class="d-flex justify-content-between">

          <div class="form-group form-group-custome">
            <label class="form-group-custome-label" for="day">روز</label>
            <input class="form-control form-group-custome-input" name="day" id="day" formControlName="day" type="number"
              placeholder="21">
            <span *ngIf="accountInfo.get('day').hasError('required') &&  accountInfo.get('day').touched"
              class="help-block">
              روز اجباریست</span>
            <span *ngIf="accountInfo.controls['day'].hasError('min') && accountInfo.get('day').touched"
              class="help-block">حداقل 1</span>
            <span *ngIf="accountInfo.controls['day'].hasError('max') && accountInfo.get('day').touched"
              class="help-block">حداکثر 31</span>
          </div>
          <div class="form-group form-group-custome mx-3 account-month-formgroup">
            <label class="form-group-custome-label" for="month">ماه</label>
            <input class="form-control form-group-custome-input" name="month" id="month" formControlName="month"
              type="number" placeholder="12">
            <span *ngIf="accountInfo.get('month').hasError('required') &&  accountInfo.get('month').touched"
              class="help-block">
              ماه اجباریست</span>
            <span *ngIf="accountInfo.controls['month'].hasError('min') && accountInfo.get('month').touched"
              class="help-block">حداقل 1</span>
            <span *ngIf="accountInfo.controls['month'].hasError('max') && accountInfo.get('month').touched"
              class="help-block">حداکثر 12</span>
          </div>
          <div class="form-group form-group-custome">
            <label class="form-group-custome-label" for="year">سال</label>
            <input class="form-control form-group-custome-input" name="year" id="year" formControlName="year"
              type="number" placeholder="1358">
            <span *ngIf="accountInfo.get('year').hasError('required') &&  accountInfo.get('year').touched"
              class="help-block">
              سال اجباریست</span>
            <span *ngIf="accountInfo.controls['year'].hasError('min') && accountInfo.get('year').touched"
              class="help-block">حداقل 1330</span>
            <span *ngIf="accountInfo.controls['year'].hasError('max') && accountInfo.get('year').touched"
              class="help-block">حداکثر 1395</span>
          </div>

        </div>

        <app-form-ng-select (click)="onFilterCities()"
          [formGroupClass]="'form-group form-group-custome basket-form-group-custome'"
          [placeholder]="'لطفا استان مورد نظر خود را انتخاب کنید'" [label]="'انتخاب استان'"
          [labelCssClass]="'form-group-custome-label'" [formGroup]="accountInfo" [control]="accountInfo.get('stateId')"
          [multiple]="false" [name]="'stateId'" [id]="'stateId'" [bindLabel]="'name'" [bindValue]="'id'"
          [items]="profile.listOfStates" [closeOnSelect]="true">
        </app-form-ng-select>

        <app-form-ng-select [formGroupClass]="'form-group form-group-custome basket-form-group-custome'"
          [placeholder]="'لطفا شهر مورد نظر خود را انتخاب کنید'" [label]="'انتخاب شهر'"
          [labelCssClass]="'form-group-custome-label'" [formGroup]="accountInfo" [control]="accountInfo.get('cityId')"
          [multiple]="false" [name]="'cityId'" [id]="'cityId'" [bindLabel]="'name'" [bindValue]="'id'"
          [items]="listofCities" [closeOnSelect]="true">
        </app-form-ng-select>

        <button (click)="onUpdate()" [disabled]="buttonLoading" class="large-button" type="button">
          <img *ngIf="buttonLoading && sectionLoading === 'update'" class="button-loading"
            src="../../../../assets/images/core/loading.gif" alt="بارگذاری">
          <span *ngIf="sectionLoading != 'update'">ذخیره تغییرات</span>
        </button>

      </form>
      <!-- account info end -->
    </div>
  </div>

  <!-- <div class="col-12 col-md-6 col-xl-6 cols-with-container-inside">
    <div class="main-containers">
      <div class="sections-titles-wrapper sections-titles-wrapper-centered">

        <div class="title-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="30.495" height="29.883" viewBox="0 0 30.495 29.883">
            <path id="Union_1" data-name="Union 1"
              d="M29.484,29.9h-15v-.036H-1.009v0a15.815,15.815,0,0,0,15.5-15.746v.6a15.827,15.827,0,0,1,15-14.7V29.9h0Z"
              transform="translate(1.009 -0.017)" fill="#fff" />
          </svg>

          <div class="sections-title-label">
            مشخصات استایل من
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" width="30.747" height="29.883" viewBox="0 0 30.747 29.883">
            <path id="Union_1" data-name="Union 1"
              d="M30.493,29.883a15.772,15.772,0,0,1-15-14.689v.585A15.767,15.767,0,0,0-.252.036H15.494V0h15V29.882h0Z"
              transform="translate(30.495 29.883) rotate(180)" fill="#fff" />
          </svg>
        </div>

      </div>

      <form [formGroup]="styleInfo">

        <div class="d-flex justify-content-between flex-wrap">

          <app-form-input [guidesLinks]="'راهنما'" [guidesLinksCssClass]="'guides-links'" class="profile-forms"
            [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 180'"
            [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
            [control]="styleInfo.get('height')" [minLength]="3" [maxLength]="2000" [id]="'height'" [name]="'height'"
            [formGroup]="styleInfo" [label]="'قد شما'">
          </app-form-input>

          <app-form-input [guidesLinks]="'راهنما'" [guidesLinksCssClass]="'guides-links'" class="profile-forms"
            [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 80'"
            [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
            [control]="styleInfo.get('weight')" [minLength]="3" [maxLength]="2000" [id]="'weight'" [name]="'weight'"
            [formGroup]="styleInfo" [label]="'وزن شما'">
          </app-form-input>



          <app-form-input [guidesLinks]="'راهنما'" [guidesLinksCssClass]="'guides-links'" class="profile-forms"
            [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 37'"
            [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
            [control]="styleInfo.get('waistCircumference')" [minLength]="3" [maxLength]="2000"
            [id]="'waistCircumference'" [name]="'waistCircumference'" [formGroup]="styleInfo" [label]="'دور کمر'">
          </app-form-input>

          <app-form-input [guidesLinks]="'راهنما'" [guidesLinksCssClass]="'guides-links'" class="profile-forms"
            [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 37'"
            [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
            [control]="styleInfo.get('armCircumference')" [minLength]="3" [maxLength]="2000" [id]="'armCircumference'"
            [name]="'armCircumference'" [formGroup]="styleInfo" [label]="'دور بازو'">
          </app-form-input>

          <app-form-input [guidesLinks]="'راهنما'" [guidesLinksCssClass]="'guides-links'" class="profile-forms"
            [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 50'"
            [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
            [control]="styleInfo.get('upperHeight')" [minLength]="3" [maxLength]="2000" [id]="'upperHeight'"
            [name]="'upperHeight'" [formGroup]="styleInfo" [label]="'قد بالا تنه'">
          </app-form-input>

          <app-form-input [guidesLinks]="'راهنما'" [guidesLinksCssClass]="'guides-links'" class="profile-forms"
            [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 50'"
            [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
            [control]="styleInfo.get('bustHeight')" [minLength]="3" [maxLength]="2000" [id]="'bustHeight'"
            [name]="'bustHeight'" [formGroup]="styleInfo" [label]="'قد پایین تنه'">
          </app-form-input>

          <app-form-input [guidesLinks]="'راهنما'" [guidesLinksCssClass]="'guides-links'" class="profile-forms"
            [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 50'"
            [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
            [control]="styleInfo.get('carol')" [minLength]="3" [maxLength]="2000" [id]="'carol'" [name]="'carol'"
            [formGroup]="styleInfo" [label]="'عرض شانه'">
          </app-form-input>

          <app-form-input [guidesLinks]="'راهنما'" [guidesLinksCssClass]="'guides-links'" class="profile-forms"
            [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 50'"
            [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
            [control]="styleInfo.get('thighSize')" [minLength]="3" [maxLength]="2000" [id]="'thighSize'"
            [name]="'thighSize'" [formGroup]="styleInfo" [label]="'دور ران'">
          </app-form-input>

          <app-form-input [guidesLinks]="'راهنما'" [guidesLinksCssClass]="'guides-links'" class="profile-forms"
            [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 50'"
            [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
            [control]="styleInfo.get('hipCircumference')" [minLength]="3" [maxLength]="2000" [id]="'hipCircumference'"
            [name]="'hipCircumference'" [formGroup]="styleInfo" [label]="'دور باسن'">
          </app-form-input>

          <app-form-input [guidesLinks]="'راهنما'" [guidesLinksCssClass]="'guides-links'" class="profile-forms"
            [type]="'text'" [formGroupClass]="'form-group form-group-custome'" [placeholder]="'مثلا 50'"
            [labelCssClass]="'form-group-custome-label'" [inputCssClass]="' form-control form-group-custome-input '"
            [control]="styleInfo.get('shoeSize')" [minLength]="3" [maxLength]="2000" [id]="'shoeSize'"
            [name]="'shoeSize'" [formGroup]="styleInfo" [label]="'سایز پا'">
          </app-form-input>



        </div>

        <button (click)="onUpdatePersonalInformation()" [disabled]="buttonLoading" class="large-button mt-4"
          type="button">
          <img *ngIf="buttonLoading" class="button-loading" src="../../../../assets/images/core/loading.gif">
          <span *ngIf="!buttonLoading">ذخیره تغییرات</span>
        </button>

      </form>

    </div>
  </div> -->
</div>

<div *ngIf="pageLoding" class="profile-loading">
  <img class="profile-loading-gif" src="../../../../assets/images/core/carisma-logo-top-with-typo.png" alt="loading gif"
    alt="بارگذاری">
</div>
