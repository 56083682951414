import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.less']
})
export class AuthLayoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {

    // let userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
    // if (userInfo) {
    //   this.router.navigate(["/intro"]);
    // }

  }

}
