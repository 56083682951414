import { AuthServiceApiService } from './../../core/services/api/auth.service.api.service';
import { Helper } from 'src/app/shared/utilities/helper';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { QuestionaireService } from 'src/app/core/services/api/questionaire.service';
import { LocalStorageService } from 'src/app/core/services/storage/local-storage.service';
import { FormValidatorsService } from 'src/app/shared/validators/form-validators.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.less']
})
export class QuestionnaireComponent implements OnInit {

  // questionnaireState: string = 'step-44';
  questionnaireState: number = 1;
  // questionnaireSections: number = 0;
  authState: string = 'mobile';
  authInfo: FormGroup;
  answerObj: any = {};
  answers: Object = {
    personalInfo: {},
    answerInfo: {},
    anatomy: {}
  };
  anatomy: string;
  anatomyStep1: number = 1;
  anatomyStep2: number = 2;
  buttonLoading: boolean = false;
  recaptchaReactiveInfo: FormGroup;
  personalInfo: FormGroup;
  textSign: string = 'ورود';
  isCaptchaVerified: boolean = false;
  imageSrc: string;
  answerInfo: FormGroup;
  answerRate: number = 0;
  options: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    rightToLeft: true,
    showOuterSelectionBars: true
  };

  constructor(
    private helper: Helper,
    private formValidator: FormValidatorsService,
    private authApiService: AuthServiceApiService,
    private questionainerService: QuestionaireService,
    private localStorageService: LocalStorageService,
  ) {
    this.checkInitialQuestionnaireStep();
  }

  ngOnInit(): void {
    this.onCreateAuthInfoForm();
    this.onCreateRecaptchaReactiveForm();
    this.onCreatepersonalInfoForm();
    this.onCreateAnswerInfoForm();
  }

  onCreateAuthInfoForm(): void {
    this.authInfo = new FormGroup({
      id: new FormControl(null),
      phoneNumber: new FormControl(null, [this.formValidator.requiredValidator('phoneNumber')]),
      password: new FormControl(null, [this.formValidator.requiredValidator('password')]),
    });
  }

  onCreateRecaptchaReactiveForm(): void {
    this.recaptchaReactiveInfo = new FormGroup({
      recaptchaReactive: new FormControl(null)
    });
  }

  resolved(captchaResponse: string): void {
    this.textSign = 'در حال برسی ...';
    this.authApiService.posVerifyToken(captchaResponse).subscribe(response => {
      this.isCaptchaVerified = response['isSuccess'];
      this.textSign = 'ورود';
    }, error => {
      this.textSign = 'ورود';
      this.isCaptchaVerified = false;
      this.helper.showErrorMessage(error.error.errorMessages);
    });
  }

  onCreatepersonalInfoForm(): void {
    this.personalInfo = new FormGroup({
      id: new FormControl(null),
      height: new FormControl(null, [this.formValidator.requiredValidator('height')]),
      weight: new FormControl(null, [this.formValidator.requiredValidator('weight')]),
      age: new FormControl(null, [this.formValidator.requiredValidator('age')]),
      shoomiz: new FormControl(null),
      manto: new FormControl(null),
      shalvar: new FormControl(null),
      kafsh: new FormControl(null),
      palto: new FormControl(null),
      skin: new FormControl(null),
    });
  }

  onCreateAnswerInfoForm(): void {
    this.answerInfo = new FormGroup({
      image: new FormControl(null, [this.formValidator.requiredValidator('image')]),
      point: new FormControl(null, [this.formValidator.requiredValidator('point')]),
      answer: new FormControl(null),
    });
  }

  async onRegister(): Promise<void> {

    if (this.authInfo.get('phoneNumber').value) {
      if (!this.isCaptchaVerified) {
        this.helper.toaster('لطفا گزینه من ربات نیستم را انتخاب کنید', ToasterType.INFO);
        return;
      }
      this.buttonLoading = true;
      this.questionainerService.getInitialSetup('73907fd6-7415-42cb-92e4-0dbf74d6d022').subscribe(async response => {
        this.buttonLoading = false;
        this.answerObj = response['data']['answer'];
        this.answerObj['verified'] = false;
        this.updateOrCreateLocalStorage(0);
        this.helper.toaster('... در حال ارسال کد', ToasterType.INFO);
        this.authState = 'password';
        await this.sendVerifyCode(response['data']['answer']['unverifiedUserId'], this.authInfo.get('phoneNumber').value);
      }, error => {
        this.buttonLoading = false;
        this.helper.showErrorMessage(error.error.errorMessages);
      });
    } else {
      this.helper.toaster('لطفا شماره موبایل را وارد کنید', ToasterType.WARNING);
    }
  }

  async sendVerifyCode(unverifiedUserId: string, mobile: string): Promise<void> {
    this.questionainerService.postSendVerifyCode({ id: unverifiedUserId, mobile: mobile }).subscribe(response => {
      this.updateOrCreateLocalStorage(1);
    }, error => {
      this.buttonLoading = false;
      this.helper.showErrorMessage(error.error.errorMessages);
    });
  }

  onLogin(): void {
    if (!this.authInfo.get('password').value) {
      this.helper.toaster('لطفا کد ارسالی را وارد کنید', ToasterType.WARNING);
      return;
    }
    this.buttonLoading = true;
    this.questionainerService.postVerifyCode({ id: this.localStorageService.get('answer')['unverifiedUserId'], mobile: this.authInfo.get('phoneNumber').value, password: this.authInfo.get('password').value }).subscribe(response => {
      this.buttonLoading = false;
      this.answerObj['verified'] = true;
      this.updateOrCreateLocalStorage(2);
      this.questionnaireState = 2;
    }, error => {
      this.buttonLoading = false;
      this.helper.showErrorMessage(error.error.errorMessages);
    });
  }

  updateOrCreateLocalStorage(step: number): void {
    this.answerObj['step'] = step;
    this.localStorageService.create('answer', this.answerObj);
  }

  next(currentState: number): void {
    if (this.questionnaireState < 46) {
      if (this.questionnaireState > 3 && this.questionnaireState <= 46) {
        if (this.answerRate === 0) {
          this.helper.toaster('لطفا مقدار امتیاز را مشخص کنید', ToasterType.WARNING);
          return;
        }
      }
      this.answerInfo.get('point').patchValue(this.answerRate);
    }
    if (this.questionnaireState > 45) {
      this.onPatchValuePersonalityInfoPoints(currentState);
    }
    this.answerRate = 0;
    this.updateAnswerStepQuestionnaire(currentState);
  }

  perv(currentState: number): void {
    if (currentState > 1) {
      this.questionnaireState = currentState;
      this.questionnaireState--;
    }
  }

  onShowImage(imageSrc: string): void {
    this.imageSrc = imageSrc;
  }

  checkInitialQuestionnaireStep(): void {
    this.answerObj = this.localStorageService.get('answer');
    if (!this.helper.isEmptyObject(this.answerObj)) {
      if (this.answerObj['verified'] && this.answerObj['step'] === 2) {
        this.questionnaireState = this.answerObj['step'];
        return;
      }
      this.answers = (JSON.parse(this.answerObj['answerSheet'])) ?? {
        personalInfo: {},
        answerInfo: {},
        anatomy: {}
      };
      this.questionnaireState = (this.answerObj['step'] === 0) ? 1 : this.answerObj['step'];
    }
  }

  updateAnswerStepQuestionnaire(currentState: number): void {
    let step = currentState;

    if (step === 2) {
      if (this.helper.checkInvalidForm(this.personalInfo)) {
        this.helper.toaster('لطفا فرم را با دقت پر کنید', ToasterType.WARNING);
        return;
      }
    }

    if (step > 3 && step <= 45) {
      if (this.helper.checkInvalidForm(this.answerInfo)) {
        this.helper.toaster('لطفا گزینه ها را با دقت انتخاب کنید', ToasterType.WARNING);
        return;
      }
    }

    this.updateOrCreateLocalStorage(++step);

    if (
      this.personalInfo.get('height').value !== null &&
      this.personalInfo.get('weight').value !== null &&
      this.personalInfo.get('age').value !== null
    ) {
      this.answers['personalInfo'] = this.personalInfo.value;
    }

    Object.assign(this.answers['answerInfo'], { ["question_" + currentState]: this.answerInfo.value ?? {} });
    this.answers['anatomy'] = { anatomy: this.anatomy };

    this.answerObj['answerSheet'] = JSON.stringify(
      this.answers
    );
    this.localStorageService.create('answer', this.answerObj);
    this.buttonLoading = true;
    let answers: any = this.localStorageService.get('answer');
    answers = JSON.parse(answers.answerSheet);

    this.questionainerService.postStep(this.answerObj).subscribe(response => {
      this.buttonLoading = false;
      this.onCreateAnswerInfoForm();
      this.questionnaireState = currentState;
      this.questionnaireState++;
    }, error => {
      this.buttonLoading = false;
      this.helper.showErrorMessage(error.error.errorMessages);
    });
  }

  checkAnatomyAnswer(questionId?: number): void {
    switch (questionId) {
      case 1:
        this.anatomyStep1 = 2;
        this.anatomy = '';
        this.answerInfo.get('answer').patchValue(this.anatomy);
        break;
      case 2:
        this.anatomy = 'مثلثی معکوس';
        this.anatomyStep1 = 1;
        this.anatomyStep2 = 1;
        this.answerInfo.get('answer').patchValue(this.anatomy);
        break;
      case 3:
        this.anatomy = 'گلابی (مثلثی)';
        this.anatomyStep1 = 1;
        this.answerInfo.get('answer').patchValue(this.anatomy);
        break;
      case 4:
        this.anatomy = 'ساعت شنی';
        this.anatomyStep2 = 2;
        this.answerInfo.get('answer').patchValue(this.anatomy);
        break;
      case 5:
        this.anatomyStep2 = 3;
        this.anatomy = '';
        this.answerInfo.get('answer').patchValue(this.anatomy);
        break;
      case 6:
        this.anatomy = 'سیب';
        this.answerInfo.get('answer').patchValue(this.anatomy);
        break;
      case 7:
        this.anatomy = 'مستطیلی';
        this.answerInfo.get('answer').patchValue(this.anatomy);
        break;
    }
  }

  onPatchValuePersonalityInfoPoints(currentState: number) {
    switch (currentState) {
      case 46:
        this.answerInfo.get('point').patchValue(15);
        break;
      case 47:
        this.answerInfo.get('point').patchValue(5);
        break;
      case 48:
        this.answerInfo.get('point').patchValue(5);
        break;
      case 49:
        this.answerInfo.get('point').patchValue(5);
        break;
      case 50:
        this.answerInfo.get('point').patchValue(15);
        break;
      case 51:
        this.answerInfo.get('point').patchValue(5);
        break;
      case 52:
        this.answerInfo.get('point').patchValue(15);
        break;
      case 53:
        this.answerInfo.get('point').patchValue(5);
        break;
      case 54:
        this.answerInfo.get('point').patchValue(15);
        break;
      case 55:
        this.answerInfo.get('point').patchValue(15);
        break;
      case 56:
        this.answerInfo.get('point').patchValue(5);
        break;
      case 57:
        this.answerInfo.get('point').patchValue(15);
        break;
    }
  }

}
