import { environment } from './../../../../../environments/environment';
import { OrderDetails } from './../../../../shared/interfaces/order-details';
import { ActivatedRoute } from '@angular/router';
import { OrderApiService } from './../../../../core/services/api/order-api.service';
import { Component, OnInit } from '@angular/core';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.less']
})
export class OrderDetailsComponent implements OnInit {

  tableLoading: boolean = false;
  orderDetails: OrderDetails;
  baseUrl = environment.baseUrl;
  headerTitle: string = 'کاریزماکالر | جزییات سفارش'

  constructor(
    private orderApiService: OrderApiService,
    private activeRoute: ActivatedRoute,
    private manageHeader: ManageHeaderService
  ) { }

  ngOnInit(): void {
    this.getfactorDetails();
    this.manageHeader.updateTitle(this.headerTitle);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getfactorDetails(): void {
    this.tableLoading = true;
    this.orderApiService.getFactorDetails(this.activeRoute.snapshot.params['orderCode']).subscribe(response => {
      this.orderDetails = response['data'];
      this.tableLoading = false;
    }, error => {
      this.tableLoading = false;
    })
  }
}
