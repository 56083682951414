import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SellerApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  postCreateNewSeller(sellerInfo) {
    return this.httpConnection.post('store/Site/PostCreateNewSeller', sellerInfo)
  }
}
