import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';
import { Comment } from 'src/app/shared/interfaces/comment';

@Injectable({
  providedIn: 'root'
})
export class ProductApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  // getVeiwProducts(productLink) {
  //   return this.httpConnection.get(`store/Site/Product/${productLink}`);
  // }

  getVeiwProducts(productLink) {
    return this.httpConnection.get(`store/Site/findSimilar/${productLink}`);
  }

  getListOfCommnets() {
    return this.httpConnection.get('store/Comments/GetListOfComments');
  }

  postCreateComment(commentInfo: Comment) {
    return this.httpConnection.post('store/Site/PostCreateComment', commentInfo);
  }

  getFilterCloth(queryParams: string) {
    return this.httpConnection.get(`store/Site/GetFilterCloth?${queryParams}`);
  }

  getProductList(queryParams: string) {
    return this.httpConnection.get(`store/Site/ProductList?${queryParams}`);
  }

  findNextSimilar(similarProductId) {
    return this.httpConnection.get(`store/Site/findNextSimilar/${similarProductId}`);
  }

  findMatch(similarProductId, dressCode) {
    return this.httpConnection.get(`store/Site/findMatch/${similarProductId}/${dressCode}`);
  }

}
