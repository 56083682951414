import { Guard } from './core/guards/guard';
import { AuthServiceApiService } from './core/services/api/auth.service.api.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthComponent } from './auth/auth.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { UserClubComponent } from './user-club/user-club.component';
import { AuthLayoutComponent } from './shared/layout/auth-layout/auth-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BasketComponent } from './site/basket/basket.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from './core/guards/auth.interceptor';
import { FormInputComponent } from './shared/components/form-input/form-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProductListComponent } from './site/product-list/product-list.component';
import { HomeComponent } from './site/home/home.component';
import { SiteLayoutComponent } from './shared/layout/site-layout/site-layout.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ProductDetailsComponent } from './site/product-details/product-details.component';
import { FormTextareaComponent } from './shared/components/form-textarea/form-textarea.component';
import { AboutUsComponent } from './site/about-us/about-us.component';
import { ContactUsComponent } from './site/contact-us/contact-us.component';
import { FormNgSelectComponent } from './shared/components/form-ng-select/form-ng-select.component';
import { SuccessfullPaymentComponent } from './site/basket/successfull-payment/successfull-payment.component';
import { UnsuccessfullPaymentComponent } from './site/basket/unsuccessfull-payment/unsuccessfull-payment.component';
import { EvaluateCharacters } from './shared/utilities/EvaluateCharacters';
import { UserProfileComponent } from './user-club/user-profile/user-profile.component';
import { AccountComponent } from './user-club/user-profile/account/account.component';
import { MyOrderComponent } from './user-club/user-profile/my-order/my-order.component';
import { ListOfOrdersComponent } from './user-club/user-profile/my-order/list-of-orders/list-of-orders.component';
import { OrderDetailsComponent } from './user-club/user-profile/my-order/order-details/order-details.component';
import { TransactionComponent } from './user-club/user-profile/transaction/transaction.component';
import { ListOfTransactionComponent } from './user-club/user-profile/transaction/list-of-transaction/list-of-transaction.component';
import { AddressesComponent } from './user-club/user-profile/addresses/addresses.component';
import { SupportComponent } from './user-club/user-profile/support/support.component';
import { ChatComponent } from './user-club/user-profile/support/chat/chat.component';
import { ListOfTicketsComponent } from './user-club/user-profile/support/list-of-tickets/list-of-tickets.component';
import { SeasonPipe } from './core/pipes/season.pipe';
import { PageLoadingComponent } from './shared/components/page-loading/page-loading.component';
import { ConfirmModalComponent } from './shared/components/confirm-modal/confirm-modal.component';
import { EmptyBasketComponent } from './site/basket/empty-basket/empty-basket.component';
import { Observable } from './core/services/observable/observable';
import { Helper } from './shared/utilities/helper';
import { JalaliPipe } from './core/pipes/jalali.pipe';
import { TicketStatusPipe } from './core/pipes/ticket-status.pipe';
import { GenderPipe } from './core/pipes/gender.pipe';
import { NgxImageCompressService } from 'ngx-image-compress';
import { RecommandProductPipe } from './core/pipes/recommand-product.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { WithoutHeaderAndFooterLayoutComponent } from './shared/layout/without-header-and-footer-layout/without-header-and-footer-layout.component';
import { NotFoundComponent } from './site/not-found/not-found.component';
import { TruncatePipe } from './core/pipes/truncate.pipe';
import { SeprateNumberPipe } from './core/pipes/seprate-number.pipe';
import { ProductGenderPipe } from './core/pipes/product-gender.pipe';
import { PinchZoomModule } from 'ngx-pinch-zoom';
// import { NgxStickySidebarModule } from '@smip/ngx-sticky-sidebar';
import { VendorPageComponent } from './site/vendor-page/vendor-page.component';
import { SuccessfulPipe } from './core/pipes/successful.pipe';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { LimitTextPipe } from './core/pipes/limit-text.pipe';
import { CalculateDiscountPipe } from './core/pipes/calculate-discount.pipe';
import { LatestModifiedFilePipe } from './core/pipes/latest-modified-file.pipe';
import { MenuComponent } from './site/menu/menu.component';
import { ChildMenuComponent } from './site/menu/child-menu/child-menu.component';
import { CategoryComponent } from './site/category/category.component';
import { SellerComponent } from './site/seller/seller.component';
import { SuccessfullRegisterComponent } from './site/seller/successfull-register/successfull-register.component';
import { BlogComponent } from './site/blog/blog.component';
import { BlogLayoutComponent } from './shared/layout/blog-layout/blog-layout.component';
import { ArticleComponent } from './site/blog/article/article.component';
import { BlogCategoryComponent } from './site/blog/blog-category/blog-category.component';
import { BlogSearchComponent } from './site/blog/blog-search/blog-search.component';
import { WatingComponent } from './site/wating/wating.component';
import { NoSanitizePipe } from './core/pipes/no-sanitize.pipe';
import { QuestionnaireComponent } from './questionnaire/questionnaire/questionnaire.component';
import { SetRecommendationComponent } from './site/set-recommendation/set-recommendation.component';
import { ClosetComponent } from './site/closet/closet.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    SignInComponent,
    UserClubComponent,
    AuthLayoutComponent,
    BasketComponent,
    FormInputComponent,
    ProductListComponent,
    HomeComponent,
    SiteLayoutComponent,
    ProductDetailsComponent,
    FormTextareaComponent,
    AboutUsComponent,
    ContactUsComponent,
    FormNgSelectComponent,
    SuccessfullPaymentComponent,
    UnsuccessfullPaymentComponent,
    UserProfileComponent,
    AccountComponent,
    MyOrderComponent,
    ListOfOrdersComponent,
    OrderDetailsComponent,
    TransactionComponent,
    ListOfTransactionComponent,
    AddressesComponent,
    SupportComponent,
    ChatComponent,
    ListOfTicketsComponent,
    SeasonPipe,
    PageLoadingComponent,
    ConfirmModalComponent,
    EmptyBasketComponent,
    JalaliPipe,
    TicketStatusPipe,
    GenderPipe,
    RecommandProductPipe,
    WithoutHeaderAndFooterLayoutComponent,
    NotFoundComponent,
    TruncatePipe,
    SeprateNumberPipe,
    ProductGenderPipe,
    VendorPageComponent,
    SuccessfulPipe,
    LimitTextPipe,
    CalculateDiscountPipe,
    LatestModifiedFilePipe,
    MenuComponent,
    ChildMenuComponent,
    CategoryComponent,
    SellerComponent,
    SuccessfullRegisterComponent,
    BlogComponent,
    BlogLayoutComponent,
    ArticleComponent,
    BlogCategoryComponent,
    BlogSearchComponent,
    WatingComponent,
    NoSanitizePipe,
    QuestionnaireComponent,
    SetRecommendationComponent,
    ClosetComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    NgSelectModule,
    NgxSliderModule,
    Ng2SearchPipeModule,
    PinchZoomModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    // NgxStickySidebarModule.withConfig({
    //   // topSpacing: 200,
    // }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-left',
      preventDuplicates: true,
    }),
  ],
  providers: [
    NgxImageCompressService,
    Helper,
    Observable,
    AuthServiceApiService,
    EvaluateCharacters,
    Guard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "fa", // use French language
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
