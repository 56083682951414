<div [class]="formGroupClass" [formGroup]="formGroup" [ngClass]="extraClass"
  [class.invalid]="control.touched && control.invalid">
  <label *ngIf="label" [class]="labelCssClass">{{label}}</label>
  <ng-select [multiple]="multiple" [closeOnSelect]="closeOnSelect" [searchable]="searchable" [bindLabel]="bindLabel"
    [formControl]="control" [attr.name]="name" [attr.id]="id" [class]="ngSelectClass" [placeholder]="placeholder"
    [bindValue]="bindValue" [items]="items">
    <!-- <ng-option *ngFor="let item of listArray" [value]="item.itemValue">{{item.itemTitle}}</ng-option> -->
  </ng-select>
  <div class="error-msg" *ngIf="control.errors && control.touched">
    <div>
      {{ '[' + label + ' ' + control.errors[objectFn.keys(control.errors)[0]] + ']' }}
    </div>
  </div>
</div>
