import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { staticMenu } from 'src/app/shared/statics/data';
import { Location } from '@angular/common';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-child-menu',
  templateUrl: './child-menu.component.html',
  styleUrls: ['./child-menu.component.less']
})
export class ChildMenuComponent implements OnInit {

  staticMenu: any = staticMenu[0];
  listOfParentCategories: any = [];
  headerTitle: string = 'کاریزماکالر | دسته بندی'

  constructor(
    private activeRoute: ActivatedRoute,
    private location: Location,
    private manageHeader: ManageHeaderService
  ) { }

  ngOnInit(): void {
    this.onGetChildCategory();
    this.manageHeader.updateTitle(this.headerTitle);
  }

  onGetChildCategory(): void {
    let categoryLink = this.activeRoute.snapshot.params['categoryLink'];
    this.listOfParentCategories = this.staticMenu.listOfParents.find(current => current.link == categoryLink);
  }

  onGoBack(): void {
    this.location.back();
  }

}
