import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BlogApiService } from 'src/app/core/services/api/blog-api.service';
import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/utilities/helper';
import { Paginate } from 'src/app/shared/interfaces/paginate';
import { environment } from 'src/environments/environment';
import { QueryParam } from 'src/app/core/enums/query-params.enum';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-blog-category',
  templateUrl: './blog-category.component.html',
  styleUrls: ['./blog-category.component.less']
})
export class BlogCategoryComponent implements OnInit {

  pages: number[] = [];
  currentPage: number = 1;
  pageSize: number = 10;
  totalPage: number;
  active: number = 1;
  pageLoading: boolean = false;
  pagination: any;
  queryParams: string = "";
  categoryLink: string = null;
  currentParentCategoryLink: string = null;
  currentChildCategoryLink: string = null;
  listOfBlogPosts: any[];
  listOfBlogsNewest: any[];
  baseUrl: any = environment.baseUrl;
  customeQueryParams: any = {};
  headerTitle: string = 'کاریزماکالر | '
  constructor(
    private blogApiService: BlogApiService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private helper: Helper,
    private manageHeader: ManageHeaderService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.categoryLink = this.activeRoute.snapshot.params["categoryLink"];
    if (!this.initQueryParamsInReloadPage(this.activeRoute.snapshot.queryParams)) {
      (this.categoryLink) ? await this.getBlogListByCategoryLink(this.categoryLink, this.currentPage, this.pageSize, this.active) : console.warn("undefined category link");
      await this.onAddTags();
    }
    this.router.events.subscribe(async response => {
      if (response instanceof NavigationEnd) {
        let categoryLink2: string = this.activeRoute.snapshot.params["categoryLink"];
        if (this.categoryLink !== categoryLink2) {
          this.categoryLink = categoryLink2;
          if (!this.initQueryParamsInReloadPage(this.activeRoute.snapshot.queryParams)) {
            this.currentPage = 1;
            (this.categoryLink) ? await this.getBlogListByCategoryLink(this.categoryLink, this.currentPage, this.pageSize, this.active) : console.warn("undefined category link");
            await this.onAddTags();
          }
        }
      }
    });
  }

  async onAddTags(): Promise<void> {
    let tags: any = [
      {
        name: 'twitter:description',
        content: 'دیسکریپشن',
      },
      {
        name: 'twitter:title',
        content: 'تایتل',
      },
      {
        property: 'og:locale',
        content: 'fa_IR',
      },
      {
        property: 'og:type',
        content: 'article',
      },
      {
        property: 'og:title',
        content: 'تایتل',
      },
      {
        property: 'og:description',
        content: 'توضیحات',
      },
      {
        property: 'og:url',
        content: 'https://carismacolor.com/blog/category/' + this.categoryLink,
      },
      {
        property: 'og:site_name',
        content: 'وبلاگ کاریزماکالر',
      },
    ]
    this.helper.addMetaTags(tags);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  initQueryParamsInReloadPage(queyrPararms: any): boolean {

    let isQueryParams: boolean = false;
    let queryValue: any;
    let checkedFlagsQueryParams: any = {
      page: "",
    }
    if (!this.helper.isEmptyObject(queyrPararms)) {
      isQueryParams = true;
      queryValue = Number(queyrPararms.page);
      checkedFlagsQueryParams.page = queryValue;
      this.customeQueryParams.page = queryValue;
      this.onGoToPage(checkedFlagsQueryParams.page, true);
    }
    return isQueryParams;
  }

  async getBlogListByCategoryLink(categoryLink?: string, currentPage?: number, pageSize?: number, active?: number) {
    this.pageLoading = true;
    if (categoryLink) {
      this.blogApiService.getBlogListByCategoryLink(categoryLink, currentPage, pageSize, active).subscribe(async response => {
        if (!response) {
          this.router.navigateByUrl('**', { skipLocationChange: true });
          this.pageLoading = false;
          return;
        }
        this.listOfBlogPosts = response['data']['listOfBlogPosts'];
        this.listOfBlogsNewest = response['data']['listOfBlogPosts'];
        this.pagination = await this.helper.paginate(response['data'].count, this.currentPage, this.pageSize);
        this.setPaginateValue(this.pagination);
        this.setCategoryLink(categoryLink);
        this.pageLoading = false;
        this.manageHeader.updateTitle(this.headerTitle + this.currentParentCategoryLink);
      }, error => {
        this.pageLoading = false;
        this.helper.showErrorMessage(error.error.errorMessages);
      });
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  setPaginateValue(pagination: Paginate): void {
    this.totalPage = pagination.totalPages;
    this.pages = pagination.pages;
    if (pagination.currentPage === 0) {
      this.currentPage = 1;
    } else {
      this.currentPage = pagination.currentPage;
    }
  }

  // setPaginateValue(pagination: Paginate): void {
  //   this.totalPage = pagination.totalPages;
  //   this.pages = pagination.pages;
  //   this.currentPage = pagination.currentPage;
  // }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  setCategoryLink(categoryLink: string): void {
    this.currentParentCategoryLink = (categoryLink.indexOf('-') !== -1) ? categoryLink.split('-').join(' ') : categoryLink;
    (this.currentParentCategoryLink === 'notes for attractive') ? this.currentParentCategoryLink = 'نکات جذابیت و خوش پوشی' : null;
    if (this.listOfBlogPosts.length !== 0) {
      this.currentChildCategoryLink = (this.listOfBlogPosts[0].categoryParentTitle === null) ? this.listOfBlogPosts[0].categoryChildTitle : "";
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onPreviousPage(): void {
    if (this.currentPage <= this.pagination.endPage && this.currentPage > this.pagination.startPage) {
      this.currentPage--;
      this.customeQueryParams.page = this.currentPage;
      this.getBlogListByCategoryLink(this.categoryLink, this.currentPage, this.pageSize, this.active);
      this.router.navigate(['/blog/category/' + this.categoryLink], { queryParams: this.customeQueryParams });
      window.scrollTo({ top: 0 });
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onGoToPage(currentPage: number, isReloadPage?: boolean): void {
    this.currentPage = currentPage;
    if (isReloadPage) {
      this.getBlogListByCategoryLink(this.categoryLink, this.currentPage, this.pageSize, this.active);
    } else {
      this.customeQueryParams.page = currentPage;
      this.getBlogListByCategoryLink(this.categoryLink, currentPage, this.pageSize, this.active);
    }
    this.router.navigate(['/blog/category/' + this.categoryLink], { queryParams: this.customeQueryParams });
    window.scrollTo({ top: 0 });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onNextPage(): void {
    if (this.currentPage < this.pagination.totalPages) {
      this.currentPage++;
      this.customeQueryParams.page = this.currentPage;
      this.getBlogListByCategoryLink(this.categoryLink, this.currentPage, this.pageSize, this.active);
      this.router.navigate(['/blog/category/' + this.categoryLink], { queryParams: this.customeQueryParams });
      window.scrollTo({ top: 0 });
    }
  }

}
