import { SellerApiService } from './../../core/services/api/seller-api.service';
import { FormValidatorsService } from 'src/app/shared/validators/form-validators.service';
import { Location } from '@angular/common';
import { AuthServiceApiService } from 'src/app/core/services/api/auth.service.api.service';
import { Helper } from 'src/app/shared/utilities/helper';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.less']
})
export class SellerComponent implements OnInit {

  sellerInfo: FormGroup;
  buttonLoading: boolean = false;
  sectionLoading: string = 'default';
  hoverShow: boolean = false;
  isCaptchaVerified: boolean = false;
  textSign: string = 'ثبت نام';
  recaptchaReactiveInfo: FormGroup;
  headerTitle: string = 'کاریزماکالر | ثبت نام فروشندگان'

  constructor(
    private helper: Helper,
    private authApiService: AuthServiceApiService,
    private sellerApiService: SellerApiService,
    private location: Location,
    private formValidation: FormValidatorsService,
    private manageHeader: ManageHeaderService
  ) {
    this.onCreateSellerInfoForm();
    this.onCreateRecaptchaReactiveForm();
  }

  ngOnInit(): void {
    this.manageHeader.updateTitle(this.headerTitle);
  }

  onCreateSellerInfoForm(): void {
    this.sellerInfo = new FormGroup({
      id: new FormControl(null),
      mobile: new FormControl(null, [this.formValidation.onlyNumber('mobile'), this.formValidation.requiredValidator('mobile'), this.formValidation.maxlengthValidator('mobile', 11), this.formValidation.minlengthValidator('mobile', 11)]),
      FullName: new FormControl(null, [this.formValidation.requiredValidator('FullName'), this.formValidation.maxlengthValidator('FullName', 100), this.formValidation.minlengthValidator('FullName', 3)]),
      productType: new FormControl(null, [this.formValidation.requiredValidator('productType'), this.formValidation.maxlengthValidator('productType', 200), this.formValidation.minlengthValidator('productType', 3)]),
    })
  }

  onCreateRecaptchaReactiveForm(): void {
    this.recaptchaReactiveInfo = new FormGroup({
      recaptchaReactive: new FormControl(null)
    });
  }

  resolved(captchaResponse: string): void {
    this.textSign = 'در حال برسی ...';
    this.authApiService.posVerifyToken(captchaResponse).subscribe(response => {
      this.isCaptchaVerified = response['isSuccess'];
      this.textSign = 'ثبت نام';
    }, error => {
      this.textSign = 'ثبت نام';
      this.isCaptchaVerified = false;
      this.helper.showErrorMessage(error.error.errorMessages);
    });
  }

  toggleHover(): void {
    this.hoverShow == true ? this.hoverShow = false : this.hoverShow = true;
  }

  onGoBack(): void {
    if (history.length > 2) {
      this.location.back();
    } else {
      this.helper.redirectTo('/');
    }
  }

  onCreate(): void {
    if (!this.helper.checkInvalidForm(this.sellerInfo)) {
      this.buttonLoading = true;
      if (!this.isCaptchaVerified) {
        this.helper.toaster('لطفا کد امنیتی را تکمیل و مجدد اقدام کنید', ToasterType.WARNING);
        this.recaptchaReactiveInfo.markAllAsTouched();
        this.buttonLoading = false;
        return;
      }
      this.sellerApiService.postCreateNewSeller(this.sellerInfo.value).subscribe(response => {
        this.onCreateSellerInfoForm();
        this.buttonLoading = false;
        this.helper.redirectTo('/register-seller/successfull');
      }, error => {
        this.buttonLoading = false;
        this.helper.showErrorMessage(error.error.errorMessages);
      })
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', 'error');
      this.buttonLoading = false;
    }
  }

}
