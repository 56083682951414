import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';
import { ContactUs } from 'src/app/shared/interfaces/contact-us';

@Injectable({
  providedIn: 'root'
})
export class ContactUsApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  postCreateContactUs(contactUsInfo: ContactUs) {
    return this.httpConnection.post('store/Site/PostCreateContactUs', contactUsInfo)
  }

}
