import { Component, OnInit } from '@angular/core';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.less']
})
export class NotFoundComponent implements OnInit {
  headerTitle: string = 'کاریزماکالر | صفحه پیدا نشد'

  constructor(private manageHeader: ManageHeaderService) { }

  ngOnInit(): void {
    this.manageHeader.updateTitle(this.headerTitle);
  }

}
