import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private toastr: ToastrService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        if (!window.navigator.onLine) {
            // this.toastr.error("اینترنت قطع است");
            return throwError(null);
        } else {
            let userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
            if (userInfo) {
                var token = userInfo.token;

            }
            var authRequest = req.clone({
                headers: req.headers.set("Authorization", `Bearer ${token}`)
            });
            return next.handle(authRequest);
        }


    }
}
