import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class FormValidatorsService {

  constructor() { }
  /** Validate the text passed */
  validateText(str: string, length?, maxLength?): boolean {
    str = str ? str.toString() : "";
    if (str) {
      if (
        !str.trim() ||
        str.trim() === "" ||
        (length && str.length < length) ||
        (maxLength && str.length > maxLength)
      ) {
        return false;
      }
      return true;
    }
    return false;
  }
  // Required validator function
  public requiredValidator(
    fieldName: string = ""
  ) {
    return (control: FormControl) => {
      const name = control.value;
      if (!name || !this.validateText(name)) {
        return {
          required: "  اجباریست "
        };
      }
      return null;
    };
  }

  // Email form control validator function
  public emailValidator = function (control: FormControl) {
    const email = control.value;
    const reg = /^([a-z0-9_\-\.]+)@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/;
    if (email && !reg.test(email)) {
      return {
        email: "Please enter a valid email address"
      };
    }
    return null;
  };

  // Required validator function
  public minlengthValidator(fieldName: string = "", length: number) {
    return (control: FormControl) => {
      const name = control.value;
      if (name && !this.validateText(name, length)) {
        return {
          minlength: `تعداد کاراکتر حداقل باید ${length} باشد`
        };
      }
      return null;
    };
  }

  // Required validator function
  public maxlengthValidator(fieldName: string = "", length: number) {
    return (control: FormControl) => {
      const name = control.value;
      if (name && !this.validateText(name, null, length)) {
        return {
          maxlength: `تعداد کاراکتر حداکثر باید ${length} باشد`
        };
      }
      return null;
    };
  }

  // Only alpha numeric hyphen validator
  public password(fieldName: string = "") {
    return (control: FormControl) => {
      const name = control.value;
      if (
        name &&
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{8,50}$/.test(
          name
        )
      ) {
        return {
          password:
            fieldName +
            " must contain minimum 8 and maximum 50 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
        };
      }
      return null;
    };
  }

  public onlyNumber = function (fieldName: string) {
    return (control: FormControl) => {
      const name = control.value;
      const regEn = /^[0-9]*$/;
      const regFa = /^[۰-۹]*$/;
      if (name) {

        if (regFa.test(name)) {
          return null;
        } else if (regEn.test(name)) {
          return null;
        } else {
          return {
            password:

              "فقط اعداد 0 تا 9 مجاز است"
          };
        }
      }
    };
  }

  public isMobileReg = function (fieldName: string) {
    return (control: FormControl) => {
      const name = control.value;
      var str = new String(name);
      let firstChar = str.charAt(0);
      let secondChar = str.charAt(1);

      if (
        (firstChar != '0' && firstChar != '۰') && (secondChar != '9' && secondChar != '۹')
      ) {
        return {
          password:

            "  فرمت تلفن همراه اشتباه است    "
        };
      }
      return null;

    };
  }

}
