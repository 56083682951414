import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransactionApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  getListOftransaction() {
    return this.httpConnection.get('customer/CustomerTransaction/GetListOfTransactions');
  }

}
