import { Router } from '@angular/router';
import { Helper } from './../../../shared/utilities/helper';
import { Injectable } from '@angular/core';
// import { CachestorageService } from '../storage/cachestorage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  versionNumber: number;
  versionKey: string = "version"

  constructor(
    private helper: Helper,
    private router: Router,
    // private cachestorageService: CachestorageService
  ) { }
  isWrite: boolean = false;
  create(key: string, value: any): void {
    this.isWrite = true;
    localStorage.setItem(key, JSON.stringify(value));
    this.isWrite = false;
  }

  get(key: string): void {
    return JSON.parse(localStorage.getItem(key));
  }

  remove(key: string): void {
    let remove: any = this.get(key);
    if (remove) {
      localStorage.removeItem(key);
    } else {
      console.log("cant find this key from storage", key);
    }
  }

  removeAll(): void {
    localStorage.clear();
    console.log("clear");
  }

  write() {
    return this.isWrite;
  }


  isLogIn(): boolean {
    let userInfo: any = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      if (userInfo.token) {
        return true;
      }
    }
    return false;
  }

  returnUrl(): void {
    this.create('url', this.router.url);
    this.helper.redirectTo('/auth');
  }

  checkAuthUrl(url: string, status: boolean): void | any {
    if (this.isLogIn()) {
      if (status) {
        this.helper.redirectTo(url);
      } else {
        return status;
      }
    } else {
      this.returnUrl();
    }
  }

  async checkVersion(versionNumber: number = 1): Promise<void> {
    this.versionNumber = versionNumber;
    let version: any = this.get(this.versionKey);
    if (version) {
      if (version !== this.versionNumber) {
        this.create(this.versionKey, this.versionNumber);
        // await this.cachestorageService.removeAllCaches();
      }
    }else {
      this.create(this.versionKey, this.versionNumber);
      // await this.cachestorageService.removeAllCaches();
    }
  }

}
