import { Ticket } from './../../../../shared/interfaces/ticket';
import { FormValidatorsService } from './../../../../shared/validators/form-validators.service';
import { TicketApiService } from './../../../../core/services/api/ticket-api.service';
import { Helper } from './../../../../shared/utilities/helper';
import { staticDepartments } from './../../../../shared/statics/data';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-list-of-tickets',
  templateUrl: './list-of-tickets.component.html',
  styleUrls: ['./list-of-tickets.component.less']
})
export class ListOfTicketsComponent implements OnInit {

  ticketInfo: FormGroup;
  buttonLoading: boolean = false;
  department: any = staticDepartments;
  listOfTickets: Ticket[] = [];
  tableLoading: boolean = false;
  @ViewChild('closebutton') closebutton: any;
  headerTitle: string = 'کاریزماکالر | تیکت های من'

  constructor(
    private helper: Helper,
    private ticketApiService: TicketApiService,
    private formValidators: FormValidatorsService,
    private manageHeader: ManageHeaderService
  ) {
    this.onCreateTicketInfoForm();
  }

  ngOnInit(): void {
    this.getListOfTickets();
    this.manageHeader.updateTitle(this.headerTitle);
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.back();
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateTicketInfoForm(): void {
    this.ticketInfo = new FormGroup({
      id: new FormControl(null),
      title: new FormControl(null, [this.formValidators.requiredValidator('title'), this.formValidators.minlengthValidator('title', 5), this.formValidators.maxlengthValidator('title', 20)]),
      department: new FormControl(null, [this.formValidators.requiredValidator('department')]),
      text: new FormControl(null, [this.formValidators.requiredValidator('text'), this.formValidators.minlengthValidator('text', 10), this.formValidators.maxlengthValidator('text', 500)]),
      status: new FormControl(1),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getListOfTickets(): void {
    this.tableLoading = true;
    this.ticketApiService.getListOfTickets().subscribe(response => {
      this.listOfTickets = response['data'];
      this.tableLoading = false;
    }, error => {
      this.tableLoading = false;
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreate(): void {
    if (!this.helper.checkInvalidForm(this.ticketInfo)) {
      this.buttonLoading = true;
      this.ticketApiService.postCeateTicket(this.ticketInfo.value).subscribe(response => {
        this.listOfTickets.push(response['data']);
        this.buttonLoading = false;
        this.helper.toaster('تیکت با موفقیت اضافه شد', ToasterType.SUCCESS);
        this.onCreateTicketInfoForm();
        this.closebutton.nativeElement.click();
        window.scroll(0, 0);
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      });
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', ToasterType.WARNING);
      this.buttonLoading = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onOpenModal(): void {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  @HostListener('window:popstate', ['$event'])
  dismissModal(): void {
    this.closebutton?.nativeElement.click();
  }

}
