import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router
} from "@angular/router";
import { Injectable } from "@angular/core";
@Injectable()
export class Guard implements CanActivate {
  // constructor(public auth: AuthService, public router: Router) { }
  // canActivate(): boolean {
  //     if (!this.auth.isAuthenticated()) {
  //         this.router.navigate(['']);
  //         return false;
  //     }
  //     return true;
  // }
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return this.auth.isAuthenticated;

    //   var token = localStorage.getItem("token");

    //   if (token) {
    //     return true;
    //   }

    //   this.router.navigate([""]);
    //   return false;
    // }
    var userInfo: any = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo) {
      if (userInfo.token) {
        return true;
      }
    }

    this.router.navigate(["/auth"]);
    return false;
  }
}
