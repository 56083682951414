import { OrderApiService } from './../../../../core/services/api/order-api.service';
import { Order } from './../../../../shared/interfaces/order';
import { Component, OnInit } from '@angular/core';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-list-of-orders',
  templateUrl: './list-of-orders.component.html',
  styleUrls: ['./list-of-orders.component.less']
})
export class ListOfOrdersComponent implements OnInit {

  ordersTabs: number = 1;
  listOfOrders: Order[];
  tableLoading: boolean = false;
  headerTitle: string = 'کاریزماکالر | سفارشات من'

  constructor(
    private orderApiService: OrderApiService,
    private manageHeader: ManageHeaderService
  ) { }

  ngOnInit(): void {
    this.getListOfFactors();
    this.manageHeader.updateTitle(this.headerTitle);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onChangeTabs(i: number): void {
    this.ordersTabs = i;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getListOfFactors(): void {
    this.tableLoading = true;
    this.orderApiService.getListOfFactors().subscribe(response => {
      this.listOfOrders = response['data'];
      this.tableLoading = false;
    }, error => {
      this.tableLoading = false;
    })
  }

}
