import { Injectable } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';

@Injectable({
  providedIn: 'root'
})

export class ImagesCompressService {

  constructor(
    private imageCompress: NgxImageCompressService
  ) { }

  imageFileForUpload: any;

  async convertFileToBase64(event: any) {
    return new Promise((resolve, reject) => {
      if (event.target.files.length == 0) {
        resolve(null);
      }
      let file = event.target.files[0];
      let fileName: any = file['name'];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      let model: any = {};
      model.fileName = fileName;
      reader.onload = async () => {
        model.result = reader.result;
        this.imageFileForUpload = await this.compressFile(model.result, fileName);
        resolve(this.imageFileForUpload);
      };
      reader.onerror = error => {
        model.result = error;
        reject(model)
      };
    });
  }

  async compressFile(image, fileName): Promise<any> {
    var orientation = -1;
    await this.imageCompress.compressFile(image, orientation, 70, 70).then(
      result => {
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);

        //imageFile created below is the new compressed file which can be send to API in form data
        const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
        this.imageFileForUpload = imageFile;
      });
    return (this.imageFileForUpload);
  }

  dataURItoBlob(dataURI) {

    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return (blob);
  }
}

