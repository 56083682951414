import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductListApiService {

  private filterStatus = new BehaviorSubject<boolean>(false);
  constructor() { }

  getFilterStatus() {
    return this.filterStatus.asObservable();
  }

  removeFromFilter() {
    this.filterStatus.next(false);
  }

  toggleFilterStatus(status: boolean) {
    this.filterStatus.next(status);
  }

}
