import { Ticket } from './../../../../shared/interfaces/ticket';
import { LocalStorageService } from './../../../../core/services/storage/local-storage.service';
import { Helper } from './../../../../shared/utilities/helper';
import { FormValidatorsService } from './../../../../shared/validators/form-validators.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TicketApiService } from './../../../../core/services/api/ticket-api.service';
import { Component, OnInit } from '@angular/core';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.less']
})
export class ChatComponent implements OnInit {

  ticket: Ticket;
  tableLoading: boolean = false;
  ticketInfo: FormGroup;
  buttonLoading: boolean = false;
  userId: string;
  headerTitle: string = 'کاریزماکالر | تیکت'
  constructor(
    private ticketApiService: TicketApiService,
    private activeroute: ActivatedRoute,
    private formvalidators: FormValidatorsService,
    private helper: Helper,
    private localstorageService: LocalStorageService,
    private manageHeader: ManageHeaderService
  ) {
    this.onCreateTicketInfoForm();
  }

  ngOnInit(): void {
    this.getListOfTicketMessages();
    this.manageHeader.updateTitle(this.headerTitle);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getListOfTicketMessages(): void {
    this.tableLoading = true;
    let userInfo = this.localstorageService.get('userInfo');
    this.userId = userInfo['id'];
    this.ticketApiService.getListOfTicketMessages(this.activeroute.snapshot.params['id']).subscribe(response => {
      this.ticket = response['data'];
      this.tableLoading = false;
    }, error => {
      this.tableLoading = false;
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateTicketInfoForm(): void {
    this.ticketInfo = new FormGroup({
      id: new FormControl(null),
      ticketId: new FormControl(null),
      text: new FormControl(null, [this.formvalidators.requiredValidator('text')]),
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreate(): void {
    if (!this.helper.checkInvalidForm(this.ticketInfo)) {
      this.buttonLoading = true;
      this.ticketInfo.get('ticketId').patchValue(this.ticket.id);
      this.ticketApiService.postTicketMessage(this.ticketInfo.value).subscribe(response => {
        this.buttonLoading = false;
        this.helper.toaster('پیام با موفقیت ارسال شد', ToasterType.SUCCESS);
        this.helper.redirectTo('/user-club/user-profile/support');
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      });
    } else {
      this.helper.toaster('متن پیام را وارد نمایید', ToasterType.WARNING);
      this.buttonLoading = false;
    }
  }

}
