import { FormValidatorsService } from './../../../shared/validators/form-validators.service';
import { environment } from './../../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BlogApiService } from 'src/app/core/services/api/blog-api.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';
import { Helper } from 'src/app/shared/utilities/helper';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { AuthServiceApiService } from 'src/app/core/services/api/auth.service.api.service';
import { Article } from 'src/app/shared/interfaces/article';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.less']
})
export class ArticleComponent implements OnInit {

  commentInfo: FormGroup;
  recaptchaReactiveInfo: FormGroup;
  baseUrl: any = environment.baseUrl;
  pageLoading: boolean = false;
  buttonLoading: boolean = false;
  isCaptchaVerified: boolean = false;
  textComment: string = 'ارسال نظر';
  listOfLabels: any = [];
  article: any = {};
  post: Article;
  postLink: string = '';
  constructor(
    private blogApiService: BlogApiService,
    private activeRoute: ActivatedRoute,
    private manageHeader: ManageHeaderService,
    private helper: Helper,
    private router: Router,
    private authApiService: AuthServiceApiService,
    private formValidators: FormValidatorsService,
  ) { }


  async ngOnInit(): Promise<void> {
    let postLink1: string = this.activeRoute.snapshot.params['link'];
    this.postLink = postLink1;
    await this.getBlogDetail(postLink1);
    this.router.events.subscribe(response => {
      if (response instanceof NavigationEnd) {
        let postLink2: string = this.activeRoute.snapshot.params["link"];
        this.postLink = postLink2;
        if (postLink1 != postLink2) {
          this.getBlogDetail(postLink2);
          // this.onAddTags();
        }
      }
    });
  }


  onCreateCommentInfoForm(): void {
    this.commentInfo = new FormGroup({
      id: new FormControl(null),
      fullName: new FormControl(null,
        [
          this.formValidators.requiredValidator('fullName'),
          this.formValidators.minlengthValidator('fullName', 2),
          this.formValidators.maxlengthValidator('fullName', 50)
        ]),
      email: new FormControl(null,
        [
          this.formValidators.requiredValidator('email'),
        ]),
      description: new FormControl(null,
        [
          this.formValidators.requiredValidator('description'),
          this.formValidators.minlengthValidator('description', 2),
          this.formValidators.maxlengthValidator('description', 1000)
        ]),
      blogPostId: new FormControl(null),
    });
  }

  onCreateRecaptchaReactiveForm(): void {
    this.recaptchaReactiveInfo = new FormGroup({
      recaptchaReactive: new FormControl(null)
    });
  }

  async getBlogDetail(postLink: string): Promise<void> {
    this.pageLoading = true;
    this.onCreateCommentInfoForm();
    this.onCreateRecaptchaReactiveForm();
    this.blogApiService.getBlogDetail(postLink).subscribe(response => {
      if (!response) {
        this.router.navigateByUrl('**', { skipLocationChange: true });
        this.pageLoading = false;
        return;
      }
      this.article = response['data'];
      this.post = response['data']['blogInfo'];
      !this.post.pictureTitle ? this.post.pictureTitle = this.post.title : null;
      this.onAddTags();
      if (this.article.blogInfo.pageTitle) {
        this.manageHeader.updateTitle(this.article.blogInfo.pageTitle);
      } else {
        this.manageHeader.updateTitle(this.article.blogInfo.title);
      }
      if (this.article.blogInfo.labels) {
        this.listOfLabels = (this.article.blogInfo.labels.indexOf(',') !== -1) ? this.article.blogInfo.labels.split(',') : this.article.blogInfo.labels;
      }
      this.pageLoading = false;
    }, error => {
      this.helper.showErrorMessage(error.error.errorMessages);
      this.pageLoading = false;
    })
  }

  readingTime(): number {
    const text = this.post.content;
    const wpm = String(this.post.content).length;
    const words = String(text.trim().split(/\s+/)).length;
    const time = Math.ceil(words / wpm);
    return time;
  }

  resolved(captchaResponse: string): void {
    this.textComment = 'در حال برسی ...';
    this.authApiService.posVerifyToken(captchaResponse).subscribe(response => {
      this.isCaptchaVerified = response['isSuccess'];
      this.textComment = 'ارسال نظر';
    }, error => {
      this.textComment = 'ارسال نظر';
      this.isCaptchaVerified = false;
      this.helper.showErrorMessage(error.error.errorMessages);
    });
  }

  onCreateComment(): void {
    if (!this.helper.checkInvalidForm(this.commentInfo)) {
      this.buttonLoading = true;
      if (!this.isCaptchaVerified) {
        this.helper.toaster('لطفا کد امنیتی را تکمیل و مجدد اقدام کنید', ToasterType.WARNING);
        this.recaptchaReactiveInfo.markAllAsTouched();
        this.buttonLoading = false;
        return;
      }
      this.commentInfo.get('blogPostId').patchValue(this.article.blogInfo.id);
      this.blogApiService.postCreateCommentByUser(this.commentInfo.value).subscribe(response => {
        this.buttonLoading = false;
        this.helper.toaster('نظر شما با موفقیت ثبت شد', ToasterType.SUCCESS);
        this.onCreateCommentInfoForm();
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      });
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', ToasterType.WARNING);
      this.buttonLoading = false;
    }
  }

  async onAddTags(): Promise<void> {
    let tags: any = [
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:site',
        content: 'https://carismacolor.com/blog/' + this.postLink,
      },
      {
        name: 'twitter:title',
        content: this.post.title,
      },
      {
        name: 'twitter:description',
        content: this.post.metaDescription,
      },
      {
        name: 'twitter:image',
        content: this.baseUrl + '/image/' + this.post.picture,
      },
      {
        property: 'og:locale',
        content: 'fa_IR',
      },
      {
        property: 'og:type',
        content: 'article',
      },
      {
        property: 'og:title',
        content: this.post.title,
      },
      {
        property: 'og:description',
        content: (this.post.metaDescription) ? this.post.metaDescription : this.post.title,
      },
      {
        property: 'og:url',
        content: 'https://carismacolor.com/blog/' + this.postLink,
      },
      {
        property: 'og:site_name',
        content: 'وبلاگ کاریزماکالر',
      },
      {
        property: 'og:image',
        content: this.baseUrl + '/image/' + this.post.picture,
      },
      {
        property: 'og:image:width',
        content: '500',
      },
      {
        property: 'og:image:height',
        content: '500',
      },
    ]
    this.helper.addMetaTags(tags);
  }


}
