import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BlogApiService } from 'src/app/core/services/api/blog-api.service';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';
import { Paginate } from 'src/app/shared/interfaces/paginate';
import { Helper } from 'src/app/shared/utilities/helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-search',
  templateUrl: './blog-search.component.html',
  styleUrls: ['./blog-search.component.less']
})
export class BlogSearchComponent implements OnInit {

  pages: number[] = [];
  currentPage: number = 1;
  pageSize: number = 10;
  totalPage: number;
  active: number = 1;
  pageLoading: boolean = false;
  pagination: any;
  searchLink: string = null;
  listOfBlogPosts: any[];
  listOfBlogsNewest: any[];
  baseUrl: any = environment.baseUrl;
  customeQueryParams: any = {};
  headerTitle: string = 'کاریزماکالر | '

  constructor(
    private blogApiService: BlogApiService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private helper: Helper,
    private manageHeader: ManageHeaderService,
  ) { }


  // async ngOnInit(): Promise<void> {
  //   let searchedText1: string = this.activeRoute.snapshot.params['searchedText'];
  //   await this.getBlogListBySearchedText(searchedText1, this.currentPage, this.pageSize, this.active);
  //   this.searchedText = searchedText1;
  //   this.router.events.subscribe(response => {
  //     if (response instanceof NavigationEnd) {
  //       let searchedText2: string = this.activeRoute.snapshot.params["searchedText"];
  //       if (searchedText1 != searchedText2) {
  //         this.getBlogListBySearchedText(searchedText2, this.currentPage, this.pageSize, this.active);
  //         this.searchedText = searchedText2;
  //       }
  //     }
  //   });
  // }

  async ngOnInit(): Promise<void> {
    this.searchLink = this.activeRoute.snapshot.params["searchedText"];
    if (!this.initQueryParamsInReloadPage(this.activeRoute.snapshot.queryParams)) {
      (this.searchLink) ? await this.getBlogListBySearchedText(this.searchLink, this.currentPage, this.pageSize, this.active) : console.warn("undefined search link");
    }
    this.router.events.subscribe(async response => {
      if (response instanceof NavigationEnd) {
        let searchLink2: string = this.activeRoute.snapshot.params["searchedText"];
        if (this.searchLink !== searchLink2) {
          this.searchLink = searchLink2;
          if (!this.initQueryParamsInReloadPage(this.activeRoute.snapshot.queryParams)) {
            this.currentPage = 1;
            (this.searchLink) ? await this.getBlogListBySearchedText(this.searchLink, this.currentPage, this.pageSize, this.active) : console.warn("undefined search link");
          }
        }
      }
    });
  }

  initQueryParamsInReloadPage(queyrPararms: any): boolean {

    let isQueryParams: boolean = false;
    let queryValue: any;
    let checkedFlagsQueryParams: any = {
      page: "",
    }
    if (!this.helper.isEmptyObject(queyrPararms)) {
      isQueryParams = true;
      queryValue = Number(queyrPararms.page);
      checkedFlagsQueryParams.page = queryValue;
      this.customeQueryParams.page = queryValue;
      this.onGoToPage(checkedFlagsQueryParams.page, true);
    }
    return isQueryParams;
  }

  async getBlogListBySearchedText(searchLink?: string, currentPage?: number, pageSize?: number, active?: number) {
    this.pageLoading = true;
    if (searchLink) {
      this.blogApiService.getSearchInBlogByPagination(searchLink, currentPage, pageSize, active).subscribe(async response => {
        if (!response) {
          this.router.navigateByUrl('**', { skipLocationChange: true });
          this.pageLoading = false;
          return;
        }
        this.listOfBlogPosts = response['data']['listOfBlogPosts'];
        this.listOfBlogsNewest = response['data']['listOfBlogPosts'];
        this.pagination = await this.helper.paginate(response['data'].count, this.currentPage, this.pageSize);
        this.setPaginateValue(this.pagination);
        this.pageLoading = false;
      }, error => {
        this.pageLoading = false;
        this.helper.showErrorMessage(error.error.errorMessages);
      });
    }
    this.manageHeader.updateTitle(this.headerTitle + this.searchLink);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  setPaginateValue(pagination: Paginate): void {
    this.totalPage = pagination.totalPages;
    this.pages = pagination.pages;
    if (pagination.currentPage === 0) {
      this.currentPage = 1;
    } else {
      this.currentPage = pagination.currentPage;
    }
  }

  // setPaginateValue(pagination: Paginate): void {
  //   this.totalPage = pagination.totalPages;
  //   this.pages = pagination.pages;
  //   this.currentPage = pagination.currentPage;
  // }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */

  onPreviousPage(): void {
    if (this.currentPage <= this.pagination.endPage && this.currentPage > this.pagination.startPage) {
      this.currentPage--;
      this.customeQueryParams.page = this.currentPage;
      this.getBlogListBySearchedText(this.searchLink, this.currentPage, this.pageSize, this.active);
      this.router.navigate(['/blog/search/' + this.searchLink], { queryParams: this.customeQueryParams });
      window.scrollTo({ top: 0 });
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */

  onGoToPage(currentPage: number, isReloadPage?: boolean): void {
    this.currentPage = currentPage;
    if (isReloadPage) {
      this.getBlogListBySearchedText(this.searchLink, this.currentPage, this.pageSize, this.active);
    } else {
      this.customeQueryParams.page = currentPage;
      this.getBlogListBySearchedText(this.searchLink, currentPage, this.pageSize, this.active);
    }
    this.router.navigate(['/blog/search/' + this.searchLink], { queryParams: this.customeQueryParams });
    window.scrollTo({ top: 0 });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onNextPage(): void {
    if (this.currentPage < this.pagination.totalPages) {
      this.currentPage++;
      this.customeQueryParams.page = this.currentPage;
      this.getBlogListBySearchedText(this.searchLink, this.currentPage, this.pageSize, this.active);
      this.router.navigate(['/blog/search/' + this.searchLink], { queryParams: this.customeQueryParams });
      window.scrollTo({ top: 0 });
    }
  }

}
