<section class="row">
  <div class="col-12 col-md-12 col-xl-9">
    <div class="row">
      <div class="col-12">
        <h1 class="blog-big-titles">
          شما برای
          «
          {{ searchLink }}
          »
          جستجو کردید
        </h1>
      </div>
    </div>
    <figure *ngFor="let post of listOfBlogPosts" class="row main-post-with-summery">
      <div class="col-12 col-md-4 p-0">
        <img class="post-image" [src]="post.picture" alt="تصویر شاخص مقاله">
      </div>
      <div class="col-12 col-md-8 post-info-container">
        <div class="author d-flex align-items-center my-2">
          <img *ngIf="post.userPicture === null" class="author-picture" src="../../../assets/images/blog/dolce.jpg"
            alt="نویسنده">
          <img *ngIf="post.userPicture !== null" class="author-picture" [src]="baseUrl + '/image/' + post.userPicture"
            alt="نویسنده">
          <label class="author-name">
            نویسنده :
            {{ post.userName }}
          </label>
        </div>
        <h2 [routerLink]="['/blog/' + post.link]" class="post-title">{{ post.title }}</h2>
        <p class="post-summery" [innerHTML]="post.content | limitText:150">
        </p>
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-center my-2">
          <label class="article-date-text align-self-start">منتشر شده در تاریخ {{ post.createAt | jalali }}</label>
          <button [routerLink]="['/blog/' + post.link]" type="button" class="medium-button align-self-end">ادامه
            مطلب</button>
        </div>
      </div>
    </figure>
    <!-- <figure class="row main-post-with-summery">
      <div class="col-12 col-md-4 p-0">
        <img class="post-image" src="../../../assets/images/blog/pretty.jpg" alt="تصویر شاخص مقاله">
      </div>
      <div class="col-12 col-md-8 post-info-container">
        <div class="author d-flex align-items-center my-2">
          <img class="author-picture" src="../../../assets/images/blog/dolce.jpg" alt="نویسنده">
          <label class="author-name">
            نویسنده :
            مصطفی بخشیان
          </label>
        </div>
        <h2 class="post-title">زنانه و نحوه انتخاب سایز کفش</h2>
        <p class="post-summery">
          امروزه با گسترش فضای مجازی، بسیاری از افراد کسب و کارهای مختلفی را در فضای مجازی شکل داده اند، که در این بین
          بلاگری یکی از پرطرفدارترین و در حین حال سخت نرین کارهاست،فق
          عمل…
        </p>
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-center my-2">
          <label class="article-date-text align-self-start">منتشر شده در تاریخ 1400/17/8</label>
          <button type="button" class="medium-button align-self-end">ادامه مطلب</button>
        </div>
      </div>
    </figure>
    <figure class="row main-post-with-summery">
      <div class="col-12 col-md-4 p-0">
        <img class="post-image" src="../../../assets/images/blog/pretty.jpg" alt="تصویر شاخص مقاله">
      </div>
      <div class="col-12 col-md-8 post-info-container">
        <div class="author d-flex align-items-center my-2">
          <img class="author-picture" src="../../../assets/images/blog/dolce.jpg" alt="نویسنده">
          <label class="author-name">
            نویسنده :
            مصطفی بخشیان
          </label>
        </div>
        <h2 class="post-title">زنانه و نحوه انتخاب سایز کفش</h2>
        <p class="post-summery">
          امروزه با گسترش فضای مجازی، بسیاری از افراد کسب و کارهای مختلفی را در فضای مجازی شکل داده اند، که در این بین
          بلاگری یکی از پرطرفدارترین و در حین حال سخت نرین کارهاست،فق
          عمل…
        </p>
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-center my-2">
          <label class="article-date-text align-self-start">منتشر شده در تاریخ 1400/17/8</label>
          <button type="button" class="medium-button align-self-end">ادامه مطلب</button>
        </div>
      </div>
    </figure> -->
    <!-- pagination start -->
    <div class="row">
      <div class="col-12 d-flex justify-content-center justify-content-md-end my-4">
        <ul class="pagination-ul">
          <li class="pagination-li">
            <a (click)="onPreviousPage()" class="links">
              <img class="icon" src="../../../assets/images/core/left-arrow-grey.svg" alt="صفحه قبلی">
            </a>
          </li>
          <li *ngIf="currentPage !== 1 && currentPage > 2" class="pagination-li">
            <a (click)="onGoToPage(1)">1</a>
          </li>
          <li *ngIf="currentPage > 1" class="pagination-li" (click)="onGoToPage(currentPage-1)"> {{ currentPage-1 }}
          </li>
          <li class="pagination-li">
            <a class="links active-page">
              صفحه
              <span class="mx-1">{{ currentPage }}</span>
              از
              <Span class="mx-1">{{ totalPage }}</Span>
            </a>
          </li>
          <li class="pagination-li" *ngIf="currentPage < totalPage-1">
            <a (click)="onGoToPage(currentPage+1)">
              {{ currentPage + 1 }}
            </a>
          </li>
          <li class="pagination-li" *ngIf="currentPage !== totalPage">
            <a (click)="onGoToPage(totalPage)">
              {{ totalPage }}
            </a>
          </li>
          <li class="pagination-li">
            <a class="links" (click)="onNextPage()">
              <img class="icon" src="../../../assets/images/core/left-arrow-grey.svg" alt="صفحه بعدی">
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- pagination end-->
  </div>
  <div class="col-12 col-md-12 col-xl-3">
    <div class="row">
      <div class="col-12 d-flex flex-column">
        <div class="quick-links-header-wrapper">
          <label class="quick-links-header">جدیدترین ترین مطالب</label>
          <button [routerLink]="['/blog']" class="blog-more-button" type="button">بیشتر</button>
        </div>
        <a *ngFor="let newPost of listOfBlogsNewest" [routerLink]="['/blog/' + newPost.link]" class="posts-quick-links">
          <img class="image" [src]="newPost.picture" alt="مقاله">
          <h4 class="post-title">{{ newPost.title }}</h4>
        </a>
      </div>
      <!-- <div class="col-12">
        <img class="advertise-picture" src="../../../assets/images/blog/notif-300-600.jpg" alt="تبلیغات">
      </div> -->
    </div>
  </div>
</section>

<app-page-loading *ngIf="pageLoading"></app-page-loading>
