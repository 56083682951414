import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import { Options } from '@angular-slider/ngx-slider';
import { ClosetApiService } from 'src/app/core/services/api/closet-api.service';
import { Helper } from 'src/app/shared/utilities/helper';
import { ToasterType } from 'src/app/core/enums/toaster.enum';
import { ImagesCompressService } from 'src/app/core/services/NgxImageCompress/images-compress.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-closet',
  templateUrl: './closet.component.html',
  styleUrls: ['./closet.component.less']
})
export class ClosetComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>
  activeStep: number = 1;
  slider: KeenSliderInstance = null
  currentSlide: number = 0;
  dotHelper: Array<Number> = []
  aiLoading: boolean = false;
  dataLoaded: boolean = false;
  options: Options = {
    floor: 0,
    ceil: 5,
    step: 1,
    rightToLeft: true,
    showOuterSelectionBars: true
  };

  @ViewChild('openBodyTypeModal') openBodyTypeModal: ElementRef;
  @ViewChild('closeBodyTypeModal') closeBodyTypeModal: ElementRef;
  @ViewChild('uploadImagelabel') uploadImagelabel: ElementRef;
  imageLoading: boolean = false;
  imageFileForUpload;
  baseUrl: string = environment.baseUrl;
  uploadedImage: string = '';
  closetInfo: FormGroup = this.closetInfoForm();
  imageSrc: string = '';
  buttonLoading: boolean = false;
  products: any = [];

  constructor(
    private closetApiService: ClosetApiService,
    private helper: Helper,
    private imageCompressService: ImagesCompressService
  ) {

  }


  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // this.onNewSlider();
  }

  onNewSlider(): void {
    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef?.nativeElement, {
        breakpoints: {
          "(min-width: 0px)": {
            slides: { perView: 2, spacing: 5 },
          },
          "(min-width: 400px)": {
            slides: { perView: 2, spacing: 5 },
          },
          "(min-width: 700px)": {
            slides: { perView: 3, spacing: 10 },
          },
          "(min-width: 1000px)": {
            slides: { perView: 4, spacing: 10 },
          },
          "(min-width: 1400px)": {
            slides: { perView: 5, spacing: 10 },
          },
        },
        // slides: {
        //   perView: 4,
        //   spacing: 15,
        // },
        loop: true,
        rtl: true,
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
        },

      })
    })
  }

  ngAfterViewChecked(): void {
    // if (this.intelligenceSimilarProducts.length > 0 && this.dataLoaded) {
    // this.slider?.update();
    //   this.dataLoaded = false;
    // }
  }

  ngOnDestroy(): void {
    if (this.slider) this.slider.destroy();
  }

  closetInfoForm(): FormGroup {
    return new FormGroup({
      'bodyPosition': new FormControl(null),
      'aiArray': new FormControl(null),
      'operation': new FormControl(null),
    })
  }

  onChnageSteps(currentStep: number): void {

    switch (currentStep) {
      case 1:
        if (this.uploadedImage) {
          this.activeStep++;
        } else {
          this.helper.toaster('لطفا اول عکس را آپلود کنید', ToasterType.WARNING);
        }
        break;
      case 2:
        if (this.closetInfo.get('operation')?.value) {
          this.sendCustomerImageForAI();
        } else {
          this.helper.toaster('لطفا نوع لباس را مشخص کنید', ToasterType.WARNING);
        }
        break;
      default:
        break;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  onToggleUploadImage(): void {
    this.openBodyTypeModal.nativeElement.click();
  }

  onToggleImagelabel(): void {
    if (!this.closetInfo.get('bodyPosition')?.value) {
      this.helper.toaster('لطفا ناحیه بدن را مشخص کنید', ToasterType.WARNING);
      return;
    }

    this.closeBodyTypeModal.nativeElement.click();
    this.uploadImagelabel.nativeElement.click();
  }

  async uploadImage(event: any): Promise<void> {

    const formData = new FormData();
    this.imageLoading = true;
    this.imageFileForUpload = await this.imageCompressService.convertFileToBase64(event);
    if (this.imageFileForUpload !== null) {
      formData.append("customerImage", this.imageFileForUpload);
      formData.append("bodyType", this.closetInfo.get('bodyPosition')?.value.toString());

      this.closetApiService.ImageForProcess(formData).subscribe(async response => {
        console.log(response);
        this.imageSrc = response['data']['source'];
        this.uploadedImage = this.baseUrl + '/customerImage/' + response['data']['source'];
        this.closetInfo.get('aiArray').patchValue(response['data']['ai']);
        this.helper.toaster('تصویر با موفقیت آپلود شد', ToasterType.SUCCESS);
        this.imageLoading = false;
        event.target.value = null;
        this.onChnageSteps(1);
      }, error => {
        this.imageLoading = false;
        this.helper.toaster('از سمت سرور خطای رخ داده', ToasterType.ERROR);
        this.helper.showErrorMessage(error.error.errorMessages);
        event.target.value = null;
      })
    }
  }

  sendCustomerImageForAI(): void {
    console.log(this.closetInfo.value);
    // this.activeStep++;
    // this.onNewSlider();

    if (!this.helper.checkInvalidForm(this.closetInfo)) {
      this.buttonLoading = true;
      this.closetApiService.SendCustomerImageForAI(this.closetInfo.value).subscribe(response => {
        console.log(response);
        this.products = response['data'];
        this.buttonLoading = false;
        this.activeStep++;
        this.onNewSlider();
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      });
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', 'error');
      this.buttonLoading = false;
    }
  }



}
