import { HttpConnectionService } from './http-connection.service';
import { Injectable } from '@angular/core';
import { Ticket } from 'src/app/shared/interfaces/ticket';

@Injectable({
  providedIn: 'root'
})
export class TicketApiService {

  constructor(private httpConnection: HttpConnectionService) { }

  getListOfTickets() {
    return this.httpConnection.get('customer/CustomerTickets/GetListOfTickets');
  }

  postCeateTicket(ticketInfo: Ticket) {
    return this.httpConnection.post('customer/CustomerTickets/PostCreateTicket', ticketInfo)
  }

  postTicketMessage(ticketInfo: Ticket) {
    return this.httpConnection.post('customer/CustomerTickets/PostTicketMessage', ticketInfo)
  }

  getListOfTicketMessages(ticketId: string) {
    return this.httpConnection.getParams('customer/CustomerTickets/GetListOfTicketMessages', ticketId)
  }

}
