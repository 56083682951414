import { staticMenu } from './../../shared/statics/data';
import { Component, OnInit } from '@angular/core';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {

  staticMenu: any = staticMenu[0];
  headerTitle: string = 'کاریزماکالر | دسته بندی'

  constructor(private manageHeader: ManageHeaderService) { }

  ngOnInit(): void {
    this.manageHeader.updateTitle(this.headerTitle);
  }

}
