import { LocalStorageService } from './../../../core/services/storage/local-storage.service';
import { City } from './../../../shared/interfaces/city';
import { State } from './../../../shared/interfaces/state';
import { UserAddress } from './../../../shared/interfaces/address';
import { FormValidatorsService } from './../../../shared/validators/form-validators.service';
import { Helper } from './../../../shared/utilities/helper';
import { AddressApiService } from './../../../core/services/api/address-api.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Status } from 'src/app/core/enums/status.enum';
import { Observable } from 'src/app/core/services/observable/observable';
import { staticListOfStates } from 'src/app/shared/statics/data';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';


@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.less']
})
export class AddressesComponent implements OnInit {
  addressInfo: FormGroup;
  listOfAdresses: UserAddress[] = [];
  tableLoading: boolean = false;
  address: UserAddress;
  confirmTitle: string = 'آیا میخواهید آدرس را حذف کنید؟';
  confirmMessage: string = 'با حذف آدرس امکان بازگشت آن وجود ندارد. آیا با حذف آن موافقید ؟';
  confirmbutton: string = 'بله، حذف شود';
  status = Status;
  buttonLoading: boolean = false;
  listOfStates: State[] = staticListOfStates;
  listofCities: City[] = [];
  headerTitle: string = 'کاریزماکالر | آدرس های من'

  constructor(
    private addressApiService: AddressApiService,
    private helper: Helper,
    private formValidators: FormValidatorsService,
    private observable: Observable,
    private locaStorageService: LocalStorageService,
    private manageHeader: ManageHeaderService
  ) {
    this.onCreateAddressForm();
  }

  ngOnInit(): void {
    this.getListOfAddresses();
    this.manageHeader.updateTitle(this.headerTitle);
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.back();
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreateAddressForm(): void {
    this.addressInfo = new FormGroup({
      id: new FormControl(null),
      recipientName: new FormControl(null,
        [
          this.formValidators.requiredValidator('recipientName'),
          this.formValidators.minlengthValidator('recipientName', 3),
          this.formValidators.maxlengthValidator('recipientName', 100)
        ]),
      city: new FormControl(null, [this.formValidators.requiredValidator('city')]),
      state: new FormControl(null, [this.formValidators.requiredValidator('state')]),
      phoneReciver: new FormControl(null,
        [
          this.formValidators.isMobileReg("phoneReciver"),
          this.formValidators.onlyNumber('phoneReciver'),
          this.formValidators.requiredValidator('phoneReciver'),
          this.formValidators.minlengthValidator('phoneReciver', 11),
          this.formValidators.maxlengthValidator('phoneReciver', 11),
        ]),
      houseNumber: new FormControl(null,
        [
          this.formValidators.requiredValidator('houseNumber'),
          this.formValidators.minlengthValidator('houseNumber', 1),
          this.formValidators.maxlengthValidator('houseNumber', 4),
        ]),
      postalCode: new FormControl(null,
        [
          this.formValidators.onlyNumber('postalCode'),
          this.formValidators.minlengthValidator('postalCode', 10),
          this.formValidators.maxlengthValidator('postalCode', 10),
        ]),
      addressDetails: new FormControl(null,
        [
          this.formValidators.requiredValidator('addressDetails'),
          this.formValidators.minlengthValidator('addressDetails', 5),
          this.formValidators.maxlengthValidator('addressDetails', 200)
        ]),
      index: new FormControl(null),
    })
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  getListOfAddresses(): void {
    this.tableLoading = true;
    this.addressApiService.getlistOfAdresses().subscribe(response => {
      this.listOfAdresses = response['data'];
      this.tableLoading = false;
    }, error => {
      this.tableLoading = false;
    });
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onCreate(): void {
    if (!this.helper.checkInvalidForm(this.addressInfo)) {
      let model: UserAddress = this.addressInfo.value;
      model.stateName = this.addressInfo.get("state").value?.name;
      model.cityName = this.addressInfo.get("city").value?.name;
      model.stateId = this.addressInfo.get("state").value?.id;
      model.cityId = this.addressInfo.get("city").value?.id;
      model.userIdentityId = this.locaStorageService.get('userInfo')?.['id'];
      this.buttonLoading = true;
      this.addressApiService.postCreateAddress(model).subscribe(response => {
        this.buttonLoading = false;
        this.helper.toaster('آدرس با موفقیت اضافه شد', 'success');
        model.id = response['data'];
        this.listOfAdresses.push(model);
        this.onCreateAddressForm();
        this.closebutton.nativeElement.click();
        window.scroll(0, 0);
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      });
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', 'error');
      this.buttonLoading = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onToggleDelete(address: any): void {
    this.address = address;
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onDelete(eventDeleted: boolean): void {
    if (eventDeleted) {
      if (!this.helper.isEmptyObject(this.address) && this.address.id !== null) {
        this.buttonLoading = eventDeleted;
        this.addressApiService.postChangeAddressStatus({ userId: this.address.userIdentityId, id: this.address.id, status: Status.DELETE }).subscribe(response => {
          this.listOfAdresses = this.listOfAdresses.filter(x => x.id !== this.address.id); // deleted item
          this.helper.toaster("حذف با موفقیت انجام شد", "success");
          this.observable.add({ eventModal: eventDeleted });
          this.buttonLoading = false;
        }, error => {
          this.helper.showErrorMessage(error.error.errorMessages);
          this.observable.add({ eventModal: eventDeleted });
          this.buttonLoading = false;
        });
      }
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onToggleUpdate(address: any, index: number): void {
    address.index = index;
    this.addressInfo.patchValue(address);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  @ViewChild('closebutton') closebutton: any;
  onUpdate(): void {
    let model: UserAddress = this.addressInfo.value;
    model.stateName = this.addressInfo.get("state").value.name;
    model.cityName = this.addressInfo.get("city").value.name;
    model.stateId = this.addressInfo.get("state").value.id;
    model.cityId = this.addressInfo.get("city").value.id;
    if (!this.helper.checkInvalidForm(this.addressInfo)) {
      this.buttonLoading = true;
      this.addressApiService.putUpdateAddress(model).subscribe(response => {
        this.buttonLoading = false;
        this.listOfAdresses.splice(this.addressInfo.get('index').value, 1, this.addressInfo.value);
        this.closebutton.nativeElement.click();
        this.onCreateAddressForm();
        window.scroll(0, 0);
        this.helper.toaster('آدرس با موفقیت ویرایش شد', 'success');
      }, error => {
        this.helper.showErrorMessage(error.error.errorMessages);
        this.buttonLoading = false;
      })
    } else {
      this.helper.toaster('فرم تکمیل نمی باشد', 'error');
      this.buttonLoading = false;
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onFilterCities(): void {
    if (this.addressInfo.get('state').value?.id) {
      this.addressInfo.get('city').patchValue(null);
      this.listofCities = this.listOfStates.find(current => current.id == this.addressInfo.get('state').value?.id)['listOfCities'];
    }
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onToggleAddAddress(): void {
    this.onCreateAddressForm();
    this.onOpenModal();
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  onOpenModal(): void {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  /**
  * Returns x raised to the n-th power.
  *
  * @param {number} x The number to raise.
  * @param {number} n The power, must be a natural number.
  * @return {number} x raised to the n-th power.
  */
  @HostListener('window:popstate', ['$event'])
  dismissModal(): void {
    this.closebutton?.nativeElement.click();
  }

}
