<div class="row about-us-main-row">
  <div class="col-12">
    <div class="row">
      <!-- about us big text and pictures start -->
      <div class="col-12 col-xl-5 about-us-main-text-col">
        <div class="about-us-text-container">
          <h3 class="store-name">فروشگاه</h3>
          <h1 class="store-name">کاریزما کالر</h1>
        </div>
        <p class="text-about-store">
          شما در کاریزما کالر میتوانید پوشاک و استایل مناسب شخصیت و با توجه به علاقه خود را پیدا کنید، با کاریزما کالر
          دیگر نگران زیبایی استایل خود نباشید
        </p>
      </div>
      <div class="col-12 col-xl-7 about-us-main-pictures-col">
        <img class="about-us-big-image" src="../../../assets/images/about-us/12.jpg" alt="درباره ما کاریزما">
        <img class="about-us-small-image" src="../../../assets/images/about-us/123.jpg" alt="درباره ما کاریزما">
      </div>
      <!-- about us big text and pictures end -->
    </div>
  </div>
  <div class="col-11">
    <!-- abouts us short texts start -->
    <div class="row">
      <div class="col-12 col-md-6 col-xl-3 about-us-cards-col">
        <img class="cards-img" src="../../../assets/images/about-us/buy-set-icon.svg" alt="خرید ست">
        <label class="cards-title">خرید ست</label>
        <p class="cards-text">در کاریزما کالر به راحتی ست های مناسب شخصیت و کاراکتر خود را پیدا کنید.</p>
      </div>
      <div class="col-12 col-md-6 col-xl-3 about-us-cards-col">
        <img class="cards-img" src="../../../assets/images/about-us/buy-cloth-icon.svg" alt="خرید لباس">
        <label class="cards-title">خرید لباس</label>
        <p class="cards-text">در بخش پوشاک در بین هزاران لباس، لباس مورد نظر خود را پیدا کنید</p>
      </div>
      <div class="col-12 col-md-6 col-xl-3 about-us-cards-col">
        <img class="cards-img" src="../../../assets/images/about-us/sale-icon.svg" alt="پایین ترین قیمت ها">
        <label class="cards-title">پایین ترین قیمت</label>
        <p class="cards-text">با خیال راحت در بیت بیش از 200 فروشنده مختلف میتوانید بهترین قیمت ها را انتخاب کنید.</p>
      </div>
      <div class="col-12 col-md-6 col-xl-3 about-us-cards-col">
        <img class="cards-img" src="../../../assets/images/about-us/hight-quality-icon.svg" alt="کیفیت بالا">
        <label class="cards-title">کیفیت بالا</label>
        <p class="cards-text">هدف اول کاریزما کالر ارایه بالاترین کیفیت محصولات میباشد و از این رو با فروشنده های خوبی
          همکاری دارد.</p>
      </div>
    </div>
    <!-- abouts us short texts end -->
  </div>
  <!-- about us main text start -->
  <div class="col-11 col-md-11 col-xl-10 about-us-long-text-col">
    <p class="about-us-long-text">
      کاریزما کالر یک وبسایت پیشنهاد لباس آنلاین هست که به شما بهترین لباس و استایل رو با توجه به شخصیتتون پیشنهاد میده،
      در کاریزما کالر خریدی مطمئن داشته باشید.
    </p>
  </div>
  <!-- about us main text end -->
</div>
