interface Static {
  name: string,
  status: number | string
};

export const staticStatus: Static[] = [
  {
    status: 1,
    name: "فعال"
  },
  {
    status: 2,
    name: "غیرفعال"
  },
];

export const staticBodyPosition: Static[] = [
  {
    name: 'سر تا گردن',
    status: '0'
  },
  {
    name: 'گردن تا کمر',
    status: 1
  },
  {
    name: 'کمر تا مچ پا',
    status: 2
  },
  {
    name: 'مچ پا تا کف پا',
    status: 3
  }
];

export const staticTableStatus: Static[] = [
  {
    name: 'فعال',
    status: 1
  },
  {
    name: 'غیر فعال',
    status: 2
  },
  {
    name: 'حذف',
    status: 3
  },
  {
    name: 'همه',
    status: 4
  }
];

export const staticSeasons: Static[] = [
  {
    name: 'بهار',
    status: 1
  },
  {
    name: 'تابستان',
    status: 2
  },
  {
    name: 'پاییز',
    status: 3
  },
  {
    name: 'زمستان',
    status: 4
  }
];

export const staticPaymentMethod: Static[] = [
  {
    name: 'درگاه پرداخت',
    status: 1
  },
  {
    name: 'نقدی',
    status: 2
  },
  {
    name: 'کارت به کارت',
    status: 3
  },
];

export const staticDepartments: Static[] = [
  {
    name: 'بخش فنی',
    status: 1
  },
  {
    name: 'بخش فروش',
    status: 2
  },
];


export const staticTicketStatus: Static[] = [
  {
    name: 'در انتظار بررسی',
    status: 1
  },
  {
    name: 'در حال بررسی',
    status: 2
  },
  {
    name: 'پاسخ داده شده',
    status: 3
  },
  {
    name: 'پاسخ مشتری',
    status: 4
  },
  {
    name: 'بسته شده',
    status: 5
  },
];

export const staticParentCategory: any[] = [
  {
    id: '2c83cfd3-e84d-4c86-9930-8dc863f84293',
    name: 'لباس زنانه',
    icon: 'gire-lebas'
  },
  {
    id: 'fbd5a91d-1f64-423e-ac27-fe83c21b3c16',
    name: 'کفش زنانه',
    icon: 'shoes'
  },
  {
    id: '28f9f661-abcc-4bd1-b0e5-1eeb67ae8c87',
    name: 'کیف زنانه',
    icon: 'handbag'
  },
  {
    id: 'd79cd21d-b561-4cc3-89b3-64f322a02089',
    name: 'اکسسوری زنانه',
    icon: 'diamond'
  },
];


export const staticMenu: any[] = [
  {
    title: "زنانه",
    link: "زنانه",
    listOfParents: [
      {
        id: '2c83cfd3-e84d-4c86-9930-8dc863f84293',
        name: 'لباس زنانه',
        icon: 'gire-lebas',
        link: 'لباس-زنانه',
        listOfChildern: [
          {
            id: '',
            name: 'بادی زنانه',
            link: 'بادی-زنانه'
          },
          {
            id: '',
            name: 'تاپ و نیم تنه زنانه',
            link: 'تاپ-نیم_تنه-زنانه'
          },
          {
            id: '',
            name: 'سرهمی، پیراهن و سارافون زنانه',
            link: 'سرهمی-پیراهن-سارافون-زنانه'
          },
          {
            id: '',
            name: 'بلوز، تونیک و شومیز زنانه',
            link: 'بلوز-تونیک-شومیز-زنانه'
          },
          {
            id: '',
            name: 'کت و شلوار، جلیقه و کت زنانه',
            link: 'کت-و-شلوار-جلیقه-کت-زنانه'
          },
          {
            id: '',
            name: 'شلوار و شلوارک زنانه',
            link: 'شلوار-شلوارک-زنانه'
          },
          {
            id: '',
            name: 'دامن زنانه',
            link: 'دامن-زنانه'
          },
          {
            id: '',
            name: 'لگ و ساپورت زنانه',
            link: 'لگ-و-ساپورت-زنانه'
          },
          {
            id: '',
            name: 'مانتو، پانچو و رویه زنانه',
            link: 'مانتو-پانچو-رویه-زنانه'
          },
          {
            id: '',
            name: 'ست مانتو زنانه',
            link: 'ست-مانتو-زنانه'
          },
          {
            id: '',
            name: 'تیشرت و پولوشرت زنانه',
            link: 'تیشرت-پولوشرت-زنانه'
          },
        ]
      },
      {
        id: 'fbd5a91d-1f64-423e-ac27-fe83c21b3c16',
        name: 'کفش زنانه',
        icon: 'shoes',
        link: 'کفش-زنانه',
        listOfChildern: [
          {
            id: '',
            name: 'نیم بوت و بوت زنانه',
            link: 'نیم_بوت-و-بوت-زنانه'
          },
          {
            id: '',
            name: 'کفش اسپرت زنانه',
            link: 'کفش-اسپرت-زنانه'
          },
          {
            id: '',
            name: 'کفش تخت و گیوه زنانه',
            link: 'کفش-تخت-و-گیوه-زنانه'
          },
          {
            id: '',
            name: 'کفش پاشنه دار زنانه',
            link: 'کفش-پاشنه-دار-زنانه'
          },
          {
            id: '',
            name: 'دمپایی و صندل زنانه',
            link: 'دمپایی-و-صندل-زنانه'
          }
        ]
      },
      {
        id: '28f9f661-abcc-4bd1-b0e5-1eeb67ae8c87',
        name: 'کیف زنانه',
        icon: 'handbag',
        link: 'کیف-زنانه',
        listOfChildern: [
          {
            id: '',
            name: 'کیف مجلسی زنانه',
            link: 'کیف-مجلسی-زنانه'
          },
          {
            id: '',
            name: 'کیف دستی و کیف دوشی زنانه',
            link: 'کیف-دستی-و-کیف-دوشی-زنانه'
          },
          {
            id: '',
            name: 'کیف پول و کیف لوازم آرایش زنانه',
            link: 'کیف-پول-و-کیف-لوازم-آرایش-زنانه'
          },
        ]
      },
      {
        id: 'd79cd21d-b561-4cc3-89b3-64f322a02089',
        name: 'اکسسوری زنانه',
        icon: 'diamond',
        link: 'اکسسوری-زنانه',
        listOfChildern: [
          {
            id: '',
            name: 'شال و روسری زنانه',
            link: 'شال-و-روسری-زنانه'
          },
          {
            id: '',
            name: 'عینک و قاب عینک زنانه',
            link: 'عینک-و-قاب-عینک-زنانه'
          },
          {
            id: '',
            name: 'ساعت زنانه',
            link: 'ساعت-زنانه'
          },
          {
            id: '',
            name: 'ساسبند و کمربند زنانه',
            link: 'ساسبند-و-کمربند-زنانه'
          },
          {
            id: '',
            name: 'اکسسوری متفرقه زنانه',
            link: 'اکسسوری-متفرقه-زنانه'
          },
          {
            id: '',
            name: 'کلاه و توربان زنانه',
            link: 'کلاه-و-توربان-زنانه'
          },
          {
            id: '',
            name: 'اکسسوری مو زنانه',
            link: 'اکسسوری-مو-زنانه'
          },
          {
            id: '',
            name: 'کلاه بافت و شال گردن و دستکش زنانه',
            link: 'کلاه-بافت-و-شال-گردن-و-دستکش-زنانه'
          },
          {
            id: '',
            name: 'زیورآلات زنانه',
            link: 'زیورآلات-زنانه'
          },
        ]
      },
      {
        id: '503476c7-930f-43ba-ae0e-4ab13f50e7b1',
        name: 'ست زنانه',
        icon: 'set',
        link: 'ست-زنانه',
        listOfChildern: [
          {
            id: '',
            name: 'ست اسپرت زنانه',
            link: 'ست-اسپرت-زنانه'
          },
          {
            id: '',
            name: 'ست هدیه زنانه',
            link: 'ست-هدیه-زنانه'
          },
          {
            id: '',
            name: 'ست چرم زنانه',
            link: 'ست-چرم-زنانه'
          },
          {
            id: '',
            name: 'ست لباس زنانه',
            link: 'ست-لباس-زنانه'
          },
          {
            id: '',
            name: 'ست کیف و کفش زنانه',
            link: 'ست-کیف-و-کفش-زنانه'
          },
        ]
      },
    ]
  }
];

export const staticListOfStates: any[] = [
  {
    "listOfCities": [
      {
        "name": "آبش احمد",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "dcb7648d-0851-48fd-8575-7b4963d08a56"
      },
      {
        "name": "اچاچی",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "1c137fec-63cd-4b81-81d1-957ff47d1392"
      },
      {
        "name": "آذرشهر",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "00af8e13-f7b8-4f8d-a570-b7475dfbadf0"
      },
      {
        "name": "اسکو",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "434181ef-0e41-45de-84f1-a2ed88b3cd0f"
      },
      {
        "name": "آقکند",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "990d1307-db06-4a1f-863d-de492e5a2242"
      },
      {
        "name": "ایلخچی",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "01173eee-010a-4641-b1d7-c7005a9353d4"
      },
      {
        "name": "یامچی",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "c5dcd089-425f-4f03-a266-cc790b5f9207"
      },
      {
        "name": "اهر",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "d1a3ed24-29d3-4f40-8226-9551ab33a4f0"
      },
      {
        "name": "باسمنج",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "51e8e2da-d396-496d-aab8-588fa5120649"
      },
      {
        "name": "بخشایش",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "f91e3668-590e-48c6-81da-0fb1af7c5cce"
      },
      {
        "name": "بستان آباد",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "157e1a92-78fb-4f17-ac8d-b85fb877c7b3"
      },
      {
        "name": "بناب مرند",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "71f819c5-186c-47cf-ae31-2f016ddba054"
      },
      {
        "name": "تبریز",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "98c00ed4-9cc6-4565-b3d3-6cb7ac77c9f6"
      },
      {
        "name": "ترکمانچای",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "62ce2b6f-a5f5-4edd-8e01-c72bd9f54f72"
      },
      {
        "name": "تسوج",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "43f31590-087d-4607-9c0e-937804c0d6a3"
      },
      {
        "name": "تیکمه داش",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "c5b1a298-78ab-4204-bcf3-dee7167563ff"
      },
      {
        "name": "تیمورلو",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "b5f4436e-0be2-470e-805b-1ab5c12bcac4"
      },
      {
        "name": "جلفا",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "65da3881-2420-41dd-aa49-a2271aa20155"
      },
      {
        "name": "جوان قلعه",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "3564f3ec-5817-4f52-94b7-65d4fd5473cc"
      },
      {
        "name": "خاروانا",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "a24a961c-348b-4c79-992a-4376c9f4e04d"
      },
      {
        "name": "خامنه",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "3e1ee223-2b62-47b3-9cbd-cc84b3cb73e2"
      },
      {
        "name": "خداجو(خراجو)",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "96ef3bdb-b1b8-43d8-af00-8a3ed0bdaf6f"
      },
      {
        "name": "خسروشاه",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "17917646-945a-42b1-be66-62313ed35fd9"
      },
      {
        "name": "خمارلو",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "148b028e-a2cc-455a-8ced-5f62b246a58b"
      },
      {
        "name": "خواجه",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "cec1bc96-3b60-4ece-88b5-42b6300d297f"
      },
      {
        "name": "دوزدوزان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "76e464bf-3dde-4471-91fe-6dfbceb0d583"
      },
      {
        "name": "زرنق",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "36c80dc8-610b-4ef1-98e9-590428dd251c"
      },
      {
        "name": "زنوز",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "4bc8626b-f535-4681-9ac9-0945041ae05b"
      },
      {
        "name": "سراب",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "d2ca3066-c6e3-4b40-b9e2-5afaa62ad64a"
      },
      {
        "name": "سردرود",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "51a520bc-6719-48eb-afcf-4a65fb51cf92"
      },
      {
        "name": "سیس",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "ff7ff8b2-f8e9-4085-a71f-57cee7e6e402"
      },
      {
        "name": "سیه رود",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "c4e52cad-70b1-4493-803e-c4182bdcde4d"
      },
      {
        "name": "سهند",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "b6e988f1-e820-458b-97a7-8ee6ed146bd2"
      },
      {
        "name": "شبستر",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "0c162673-0ed0-4e1a-922d-35ef07dd5504"
      },
      {
        "name": "شربیان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "b58429e9-cf71-4557-8445-8b7c64e54a55"
      },
      {
        "name": "شرفخانه",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "83b1d2a7-ff36-44bc-aee0-de54ea3c00c1"
      },
      {
        "name": "شندآباد",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "4ac6d918-3561-40af-b12c-faa8e8ae5b26"
      },
      {
        "name": "صوفیان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "18867275-bc4f-4ebd-9a31-b6627edd1eab"
      },
      {
        "name": "عجب شیر",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "475dc988-23e4-4648-a1f8-43167424e4aa"
      },
      {
        "name": "قره آغاج",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "5aa092db-821f-4abe-9cad-625e9ac12647"
      },
      {
        "name": "کشکسرای",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "eef986d7-085b-4038-b22f-7f33ea05be36"
      },
      {
        "name": "کلیبر",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "445f28b2-b02c-4dbd-abcb-a3da89e4f602"
      },
      {
        "name": "کلوانق",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "57a10fd9-3f01-4ff6-adca-8a55d14be895"
      },
      {
        "name": "کوزه کنان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "afcafead-e71c-45e4-aba5-a17ff1787bb6"
      },
      {
        "name": "گوگان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "aa9b743e-2227-4ffb-97bf-db826532a4e7"
      },
      {
        "name": "لیلان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "123b1cea-1ab8-4423-915b-dc18c1742c66"
      },
      {
        "name": "میانه",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "a9ec28bf-06a1-4f0b-b6a8-c270e4b7d140"
      },
      {
        "name": "مبارک شهر",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "1ec1feba-b310-4b11-8a0b-a14d5008f1d4"
      },
      {
        "name": "مراغه",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "e35bdacc-109a-44cf-89ef-071e7bc524b2"
      },
      {
        "name": "ملکان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "554d2ad9-b28e-4f79-ac6b-69c6f9219363"
      },
      {
        "name": "ممقان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "1695c7e1-c83c-4611-b18c-13958ee44812"
      },
      {
        "name": "مهربان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "fe0928cc-04e5-4095-9c5b-c46c6de34320"
      },
      {
        "name": "نظرکهریزی",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "a4839c7e-262f-45d3-84b7-0841a645b828"
      },
      {
        "name": "هادیشهر",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "3dabb212-34a2-4545-8a9e-91eb34b92d3d"
      },
      {
        "name": "هریس",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "d4093114-ac2f-405b-891d-2e65dd992df2"
      },
      {
        "name": "هشترود",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "1355abaa-8d96-4dcc-b956-325bd8accc4b"
      },
      {
        "name": "هوراند",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "f694bb96-91f8-45b8-a2d1-5c0670e66c23"
      },
      {
        "name": "وایقان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "56284cb8-889a-4bd9-9c40-7104739ab7f5"
      },
      {
        "name": "ورزقان",
        "stateId": "c0c5b626-66c3-409f-bab6-c52f83766feb",
        "id": "fc4a01f9-c8c5-4a32-9a3c-0a2eaeb6774b"
      }
    ],
    "name": "آذربایجان شرقی",
    "id": "c0c5b626-66c3-409f-bab6-c52f83766feb"
  },
  {
    "listOfCities": [
      {
        "name": "ارومیه",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "b4e08013-14bd-4aa8-ae33-91a83de0e3f5"
      },
      {
        "name": "اشنویه",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "91af2f33-ec02-48db-b508-6f06c94a641d"
      },
      {
        "name": "آواجیق",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "fc27474c-afed-4b56-a9cd-82994adc51f0"
      },
      {
        "name": "ایواوغلی",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "9db925f0-854c-47ee-94ed-290bc82a5a25"
      },
      {
        "name": "باروق",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "7c26bd0a-830f-4371-9c18-273cb69a9eef"
      },
      {
        "name": "بازرگان",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "5793535d-c0c2-44e6-b606-7e602b2398a6"
      },
      {
        "name": "بوکان",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "b49a2a0e-fb78-41b2-80ec-9aeec8e0fe0f"
      },
      {
        "name": "پیرانشهر",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "b4b9c64f-7eb8-46e8-bc8f-14947a15e779"
      },
      {
        "name": "پلدشت",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "bc0f7113-776e-486a-8404-478cf273c4d9"
      },
      {
        "name": "تازه شهر",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "fcbddac7-de9b-46c8-a6a0-611e0afb0abd"
      },
      {
        "name": "تکاب",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "1d3f0c6b-6c81-4c99-88b6-669c93b9c4c9"
      },
      {
        "name": "چهاربرج",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "b82ee20b-1f81-4777-896a-dbc57c36b631"
      },
      {
        "name": "خلخال",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "0aa3c14d-3f1a-4838-9453-a88b39a8e6e3"
      },
      {
        "name": "خلیفان",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "3d557fcc-a3f7-4d0c-aa49-6ddb4dee2a36"
      },
      {
        "name": "خوی",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "e01ac03a-0e5c-4222-8d5b-fa4c924b75af"
      },
      {
        "name": "دیزج دیز",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "bffa0b3b-7bae-4bff-a818-91a621b610f3"
      },
      {
        "name": "ربط",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "90650e56-bacc-40b1-b4e8-2f704c8950f4"
      },
      {
        "name": "رضی",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "bae7b80d-4867-4953-ac74-5a843ea650ca"
      },
      {
        "name": "زرآباد",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "8a779a79-2666-4333-8071-dfb644dbd1f8"
      },
      {
        "name": "سردشت",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "15cda821-f1a3-4a6a-b379-5aa54dd32d81"
      },
      {
        "name": "سلماس",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "18769078-dcb0-49df-8dfc-2c923b29369a"
      },
      {
        "name": "سیلوانه",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "43c86fa6-37f1-4a0f-b0f5-ec8bcc9dbbbc"
      },
      {
        "name": "سیمینه",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "814a317e-9560-4ec7-b878-d6c60c99bdcd"
      },
      {
        "name": "سیه چشمه",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "bbfbc2ef-3377-4c3a-ac46-423a3fc700d5"
      },
      {
        "name": "شاهین دژ",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "310ff90c-c0db-4801-a432-2d8239d66351"
      },
      {
        "name": "شوط",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "cb44b56f-0670-45cd-be2a-a806b58e3494"
      },
      {
        "name": "فیرورق",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "a9d3c44d-1c6f-4be1-873a-55bc1a297edd"
      },
      {
        "name": "قره ضیاءالدین",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "66a6ce74-a593-4189-a8f5-5fa9c46cebd3"
      },
      {
        "name": "قطور",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "adb05357-9570-4429-9a11-efaf83e5badf"
      },
      {
        "name": "قوشچی",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "11954626-2a47-412b-b924-43a2c7efd0af"
      },
      {
        "name": "کشاورز",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "a65a1e2c-98a2-448f-96e3-705aa3e2a0e1"
      },
      {
        "name": "کوراییم",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "7c3f0cbc-6fa0-4e50-ae59-aec62620d2ab"
      },
      {
        "name": "گردکشانه",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "c65c87e7-0bd5-49bc-8b49-b73a5a5d97d5"
      },
      {
        "name": "ماکو",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "970e1f96-f5a3-4ac6-8459-f468b5c5a441"
      },
      {
        "name": "میاندوآب",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "fe396512-b0c0-452a-89e0-51fdf22c8bda"
      },
      {
        "name": "محمدیار",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "2441a58e-478d-4777-b5b0-9d6c3ffe1c1d"
      },
      {
        "name": "محمودآباد",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "5a9c5154-a0d7-4a7e-abc7-f8d47e2513ad"
      },
      {
        "name": "میرآباد",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "bd94143e-671f-4362-9f17-4a7644fa0e01"
      },
      {
        "name": "مرادلو",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "88363a66-342f-435b-a6e9-f777c53c2f9f"
      },
      {
        "name": "مرگنلر",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "a907f23e-b796-425f-a65a-f5e74c93c69b"
      },
      {
        "name": "مهاباد",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "2926b0cd-62b1-4a50-a13b-86eb3364acf1"
      },
      {
        "name": "نازک علیا",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "e1c560e3-5fcd-4a69-abff-cd654432f8b3"
      },
      {
        "name": "نالوس",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "a23a44b0-34e2-4f6a-a3ff-505f5f3ec576"
      },
      {
        "name": "نقده",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "6ffc40fa-3606-4cb8-91ee-67dee456faf3"
      },
      {
        "name": "نوشین",
        "stateId": "96f56006-a7eb-471c-9e97-5bdb1d10e23d",
        "id": "f5521998-9af9-4f51-ad0d-be645cbfbb50"
      }
    ],
    "name": "آذربایجان غربی",
    "id": "96f56006-a7eb-471c-9e97-5bdb1d10e23d"
  },
  {
    "listOfCities": [
      {
        "name": "آبی بیگلو",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "1f1b2c76-863b-43a8-be4d-cbc4cc9353c7"
      },
      {
        "name": "اردبیل",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "4dfce467-8c08-4a23-9069-2fe3f3cc19cd"
      },
      {
        "name": "اسلام اباد",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "f03e090c-25df-4a6f-8e05-7a937311c324"
      },
      {
        "name": "اصلاندوز",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "52ffd2d3-a370-4b45-a998-b76fcf39f07c"
      },
      {
        "name": "بیله سوار",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "0098552f-2991-4d59-b900-1e00fbe0e331"
      },
      {
        "name": "پارس آباد",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "41f367a3-c8b0-4f4d-bb27-cc31f8fa9a37"
      },
      {
        "name": "تازه کندانگوت",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "05263080-6e80-4423-abac-633504d8ba54"
      },
      {
        "name": "جعفرآباد",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "de78bbd5-3499-4b38-9aca-197043fe2a09"
      },
      {
        "name": "سرعین",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "8766aeee-28d0-45fc-afa7-3279e69b8d50"
      },
      {
        "name": "عنبران",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "d0f78bfa-c59d-4a08-b41b-485cb44496a6"
      },
      {
        "name": "فخراباد",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "aba26703-b5aa-4fb2-9de0-c0a807ad7c92"
      },
      {
        "name": "قصابه",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "673d688a-1883-4490-8f1d-2c4988f446a9"
      },
      {
        "name": "کلور",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "f8cc8032-ca2a-43f1-bfd2-c641213d0369"
      },
      {
        "name": "گرمی",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "eca15633-d750-4061-9447-0a53934b25a8"
      },
      {
        "name": "گیوی",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "4a981fe6-6102-426f-94f3-631d0a9ee601"
      },
      {
        "name": "لاهرود",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "702043a1-b1fb-4769-bdc8-d80949e5593f"
      },
      {
        "name": "مشگین شهر",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "65fd96a7-4cad-4f9c-9d81-aa7f87b1c21f"
      },
      {
        "name": "نیر",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "64860b8d-4615-41ad-b0bd-bdec3ac31496"
      },
      {
        "name": "نمین",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "70857afe-b427-430f-83dc-dfe18eb7cb38"
      },
      {
        "name": "هیر",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "e688b127-6f4b-42a7-97c0-0bfdaedba34b"
      },
      {
        "name": "هشتجین",
        "stateId": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b",
        "id": "78d1b014-d774-43c2-a65f-6aec8178dc83"
      }
    ],
    "name": "اردبیل",
    "id": "ee2412f1-e319-49b1-b626-e2b8b6a2e47b"
  },
  {
    "listOfCities": [
      {
        "name": "ابریشم",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "fdb82668-2e07-4025-bc38-5e5ee3368402"
      },
      {
        "name": "ابوزیدآباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "83317791-3175-458d-871f-5ffdc3041ee7"
      },
      {
        "name": "آران وبیدگل",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "b3a658cc-b5fe-485d-9ffa-29756655f966"
      },
      {
        "name": "اردستان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "3c7f5d06-9e18-4d46-b89d-aacb428012b1"
      },
      {
        "name": "اژیه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "59dd5f22-512e-4878-b0ad-8da5da35445c"
      },
      {
        "name": "اصغرآباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "d0fd9a89-29b1-4173-88f5-2acdc208fdae"
      },
      {
        "name": "اصفهان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "d7a5c252-8b32-413f-ae94-0eed14ad0420"
      },
      {
        "name": "افوس",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "0fed1c1e-10bf-4b9b-a975-89a3459bfe2a"
      },
      {
        "name": "ایمانشهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "9aaa1ef4-2ddb-4842-9aba-51f45615b5bd"
      },
      {
        "name": "انارک",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "6bc3f567-7073-495b-aeba-c91500e325bb"
      },
      {
        "name": "بادرود",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "577dd9ab-38cb-48ee-8edb-6840fe2b8630"
      },
      {
        "name": "باغ بهادران",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "3b2de58c-c538-4923-92ec-76919d59d936"
      },
      {
        "name": "باغشاد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "27bc4768-7d0e-4bf5-bffb-1073e98654ed"
      },
      {
        "name": "بافران",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "25e4b795-a967-4928-aa76-ab2aac8bf1c8"
      },
      {
        "name": "برزک",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "6b9b3ada-d90c-4171-9317-c3f55bdc362f"
      },
      {
        "name": "برف انبار",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "de27337f-7d71-4360-b46c-c4372fcf4c4b"
      },
      {
        "name": "بهاران شهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "ae8e7194-532f-44ee-8ce8-779182a4217d"
      },
      {
        "name": "بهارستان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "aaac2ae4-4469-4ef6-8939-c174bc47e106"
      },
      {
        "name": "بویین ومیاندشت",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "961d1aec-7a6a-4358-b719-a8cbf4771b48"
      },
      {
        "name": "پیربکران",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "f0b4f644-3998-4457-97ea-40d1dc131d0e"
      },
      {
        "name": "تیران",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "bae67ad7-96a3-4115-af1e-c7a728d1e44e"
      },
      {
        "name": "تودشک",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "5f285458-a4a8-4081-b5fb-6aeb53dc06d7"
      },
      {
        "name": "جندق",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "948bbcf3-402c-4237-913e-c40f12e44e4e"
      },
      {
        "name": "جوزدان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "3c50d0bf-3e4c-40c9-9aa7-734eb56c1321"
      },
      {
        "name": "جوشقان قالی",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "94b0bf72-fec0-46c3-a9d0-f339e2f33fe5"
      },
      {
        "name": "چادگان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "5225b42d-3b59-4d57-b97a-c039b4cf28b9"
      },
      {
        "name": "چرمهین",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "a090418b-7970-443b-9f0e-7984e4cd341d"
      },
      {
        "name": "چمگردان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "000f2f8e-fbf8-4ae6-8d3d-3cfdc9c42773"
      },
      {
        "name": "حبیب آباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "2dd1fb3d-016b-4934-9961-eb12e55d2c9b"
      },
      {
        "name": "حسن اباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "07fbbf8a-aba1-41dc-88d0-77cb1d679f88"
      },
      {
        "name": "حنا",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "e962f4fc-40b4-443a-aad1-0369d507c8ab"
      },
      {
        "name": "خالدآباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "10ee84b2-ca48-458e-905a-4cbd08a6d01e"
      },
      {
        "name": "خمینی شهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "6745e2dd-bdbc-405d-bbe8-a2372f73f41b"
      },
      {
        "name": "خوانسار",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "e636c8c7-8847-4525-bd12-18a3ee0d6318"
      },
      {
        "name": "خور",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "256f0b67-009d-491f-9a29-8f1f56bbd2de"
      },
      {
        "name": "خورزوق",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "8cd208e5-bc89-4de2-924d-8a2a9e4938f0"
      },
      {
        "name": "داران",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "3bf92c61-f09a-4ba5-9877-7c19991228c9"
      },
      {
        "name": "دامنه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "ef1f3ec0-92de-48e1-a42a-d8f22cab4060"
      },
      {
        "name": "درچه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "70065be6-19a2-4122-815b-f5537890ceba"
      },
      {
        "name": "دیزیچه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "6fd2e45c-ccf2-4a1f-a487-019d1c239608"
      },
      {
        "name": "دستگرد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "644e4db7-8cbc-4912-a47e-1906fc3474ca"
      },
      {
        "name": "دهاقان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "f4fae476-0df7-4905-9873-509f802916db"
      },
      {
        "name": "دهق",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "8379c02e-4025-4f0b-9cfe-934ba1ed8d93"
      },
      {
        "name": "دولت آباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "c2a66c68-ab21-4118-8308-722e6bc05b7c"
      },
      {
        "name": "رزوه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "0a5ca27e-1bb0-4d92-b64d-4d4d71c356f4"
      },
      {
        "name": "رضوانشهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "462ca64b-0bf0-465d-9bb1-627bb5ee543e"
      },
      {
        "name": "زیار",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "d364346f-d773-4073-bb6f-a299ab247816"
      },
      {
        "name": "زازران",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "4ebd7dde-6fe1-498c-afd9-131aaa384121"
      },
      {
        "name": "زاینده رود",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "2863d00b-3c21-4892-b40e-21c0e7319c90"
      },
      {
        "name": "زیباشهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "bd642d8a-f45d-494f-9ed5-04bcdad463d1"
      },
      {
        "name": "زرین شهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "bd0bef8d-f357-47fd-be9b-ea23bd594aab"
      },
      {
        "name": "زواره",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "01e58ffb-bfa0-429f-be37-1fd53af910cc"
      },
      {
        "name": "سجزی",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "9b9964ef-bcf2-4f0d-953c-52289ec67ac8"
      },
      {
        "name": "سده لنجان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "ed38022a-3096-4b11-afea-8fd0cabfc987"
      },
      {
        "name": "سفیدشهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "fdb5376b-2728-4b46-9127-63164b8af845"
      },
      {
        "name": "سمیرم",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "b112078c-2e69-48ea-b2c1-19437f9ee30e"
      },
      {
        "name": "سین",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "ef33d962-7a99-4127-8533-86809e1549ed"
      },
      {
        "name": "شاپورآباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "9a8f9104-e1c2-4a08-8708-f5bc6047cc55"
      },
      {
        "name": "شاهین شهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "3a2e1a42-8abb-492f-a962-63674ad53eec"
      },
      {
        "name": "شهرضا",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "165ccbc6-4291-4a0e-bd25-b0bca2c15129"
      },
      {
        "name": "طالخونچه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "e11b2763-5055-4969-ae89-1c8e8c947018"
      },
      {
        "name": "طرق رود",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "fcf73d0e-9ff7-4397-875f-83fc4727b37e"
      },
      {
        "name": "عسگران",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "ee484172-4058-4913-b99f-79fc1ad7c3a1"
      },
      {
        "name": "علویجه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "e380fb60-7766-44c8-9f17-54091f734a60"
      },
      {
        "name": "فرخی",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "15c03cd9-6576-4c5d-8b3e-7a64024d640e"
      },
      {
        "name": "فریدونشهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "ce56f089-628c-43ac-a09a-e54c82118ff5"
      },
      {
        "name": "فلاورجان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "792feafd-c32e-4cba-97df-7ff0a5ff09c6"
      },
      {
        "name": "فولادشهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "04a5636c-c6c6-43af-8cb9-34403da8ecf0"
      },
      {
        "name": "قمصر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "2b34a848-51e7-4068-b7d4-c12b180f3b9c"
      },
      {
        "name": "قهجاورستان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "7c4e3660-6269-442c-83fa-5a8d35a073e7"
      },
      {
        "name": "قهدریجان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "0e2936a3-b47c-424d-a1b9-caf9f219f4a5"
      },
      {
        "name": "کاشان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "1b5525a4-0f80-4197-bb4e-482e9a8379f5"
      },
      {
        "name": "کامو و چوگان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "067e33b7-2d12-4852-8ef7-e64efebe0efd"
      },
      {
        "name": "کرکوند",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "dbed73cb-7ff6-4b33-b437-5131c38103a3"
      },
      {
        "name": "کلیشادوسودرجان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "ad08ef87-89ac-4b71-b527-ec8c2f73099e"
      },
      {
        "name": "کمشچه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "6dd95663-ee44-49a3-876f-1a935af04f5c"
      },
      {
        "name": "کهریزسنگ",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "144ebb5f-c1a0-4b71-a434-0d02f54e9eff"
      },
      {
        "name": "کوشک",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "8f1acede-a5ed-4d9d-a129-991f52c56cd8"
      },
      {
        "name": "کوهپایه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "12c8b7c8-4355-48fb-a568-a89345620875"
      },
      {
        "name": "گرگاب",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "d84ae575-00e6-465b-be0f-940d3a677a79"
      },
      {
        "name": "گزبرخوار",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "9da15396-f297-42d6-8bbf-9f8c6dc0df5c"
      },
      {
        "name": "گلپایگان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "dd0c3804-70d5-480e-8f5c-df6a46394369"
      },
      {
        "name": "گلدشت",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "2e90c505-3314-4257-9f46-b9458d66765b"
      },
      {
        "name": "گلشن",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "66c1f39e-c06e-4860-b69d-094ec738c3df"
      },
      {
        "name": "گلشهر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "5f9657d0-e286-4ec6-82bb-73f93160d0ff"
      },
      {
        "name": "گوگد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "e501d7df-2258-48b4-8f65-483e45ca2049"
      },
      {
        "name": "لای بید",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "fdd5661d-d912-4aa9-830c-948cf7a158ff"
      },
      {
        "name": "مبارکه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "90693a05-7c38-4691-9f8c-7646072814d9"
      },
      {
        "name": "مجلسی",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "e754386b-df64-4606-ad58-7864b926fd7b"
      },
      {
        "name": "محمدآباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "958afacd-0384-4d56-a3b6-320f963331af"
      },
      {
        "name": "مشکات",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "abde9393-2e62-49f0-9d2f-efd83d6b1408"
      },
      {
        "name": "میمه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "4a59376d-cda4-49ac-8286-f36c03943efd"
      },
      {
        "name": "منظریه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "6914df74-9d3a-4e65-906b-2547e1acb290"
      },
      {
        "name": "نیاسر",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "29a04432-9404-4937-9145-d17edc273192"
      },
      {
        "name": "نایین",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "d10b6829-2352-42ac-83a2-04bf7b9d7d5b"
      },
      {
        "name": "نجف آباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "5dfc3052-bb3d-4f89-9d87-ac1670113e48"
      },
      {
        "name": "نصرآباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "23d3c11b-94e4-4739-9e08-841741f53132"
      },
      {
        "name": "نطنز",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "fcf1fce2-7b63-4392-9cf1-86a846166ebf"
      },
      {
        "name": "نیک آباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "fa6ef363-83a9-41ff-bcc5-0eff0990a1e4"
      },
      {
        "name": "نوش آباد",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "8dc13db7-3f42-4440-ae50-81d429bb3791"
      },
      {
        "name": "هرند",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "1f3c468f-7b9d-4061-a381-df32065a00fa"
      },
      {
        "name": "ورزنه",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "e280dcea-4833-4ea4-997f-df25f26f900d"
      },
      {
        "name": "ورنامخواست",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "547387b5-01e3-4f08-bf1b-6dea4bdbee3f"
      },
      {
        "name": "وزوان",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "4a58ac73-e005-41f6-8df7-9e2cdba970a2"
      },
      {
        "name": "ونک",
        "stateId": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51",
        "id": "803b6032-3345-464f-b27d-5ce18bd737db"
      }
    ],
    "name": "اصفهان",
    "id": "7f0119d0-a9fc-4342-8c3e-d4c7df4eee51"
  },
  {
    "listOfCities": [
      {
        "name": "آبدانان",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "94688531-2542-4122-9fff-a6ece95b75c9"
      },
      {
        "name": "ارکواز",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "8095bb43-168b-4e7b-9fa2-a1ba63295b54"
      },
      {
        "name": "آسمان آباد",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "9230dd71-e129-4049-bb7f-a127d6253635"
      },
      {
        "name": "ایلام",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "3018d115-c9a6-4b27-8d47-4b3a05a8f399"
      },
      {
        "name": "ایوان",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "dc928136-cc0a-4314-9711-fe0040fb0ab0"
      },
      {
        "name": "بدره",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "490a5a9c-6ff7-417e-922c-2613b6c66fad"
      },
      {
        "name": "بلاوه",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "74194412-26c6-41c2-9408-a7d85448bdaf"
      },
      {
        "name": "پهله",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "84ece79f-b3c4-4ccc-a52a-9faa25a449c4"
      },
      {
        "name": "توحید",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "fc55c85a-a309-4f02-ba75-36d803ba1013"
      },
      {
        "name": "چوار",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "53df87ed-3dbc-4020-a9a2-ca9d8097c738"
      },
      {
        "name": "دره شهر",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "0f1fe56b-dbd0-4abb-8379-80deda74e49f"
      },
      {
        "name": "دلگشا",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "c0817054-3731-4690-977a-0e19864ffa71"
      },
      {
        "name": "دهلران",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "7f01a0b4-7302-4e47-9f6a-daed2c2a8b12"
      },
      {
        "name": "زرنه",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "d17223c8-05da-47e2-b170-d73102952e90"
      },
      {
        "name": "سراب باغ",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "865cd5fe-83a1-4524-80f5-9ea783ce94e9"
      },
      {
        "name": "سرابله",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "7fb9b5a7-1f49-496e-9393-6d6e52deca39"
      },
      {
        "name": "شباب",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "37a2e91d-00f4-49bb-8b31-bd04488bb101"
      },
      {
        "name": "صالح آباد",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "d1a7ade3-d765-4b18-aaf8-724f34de0695"
      },
      {
        "name": "لومار",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "4d4a65e1-5c3c-45f7-b93a-5e220da1c9e7"
      },
      {
        "name": "ماژین",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "6540d442-216b-4cfd-a7bf-3c7d2d9f0794"
      },
      {
        "name": "مهران",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "ec6b5c42-0588-4fef-bca4-448d36abc88c"
      },
      {
        "name": "مورموری",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "690fd7af-375f-4538-9bf7-29cda7faface"
      },
      {
        "name": "موسیان",
        "stateId": "80dfe8a7-a7fb-442f-986e-9452d3581015",
        "id": "6f428566-40e3-4e52-9d79-b6465fcc7ada"
      }
    ],
    "name": "ایلام",
    "id": "80dfe8a7-a7fb-442f-986e-9452d3581015"
  },
  {
    "listOfCities": [
      {
        "name": "آسارا",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "abc68d0a-fc16-4810-bcfa-681c254ff989"
      },
      {
        "name": "اشتهارد",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "9474f0c3-aba4-4bfc-a47f-07641a2e23b3"
      },
      {
        "name": "تنکمان",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "0d6074f6-6643-4455-be80-4d52fc49974d"
      },
      {
        "name": "چهارباغ",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "93716436-c361-4120-88d4-950097f9fe38"
      },
      {
        "name": "شهرجدیدهشتگرد",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "dc3bcfe1-5e1e-44db-ab81-b037e19673f1"
      },
      {
        "name": "طالقان",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "3c8c5f8f-a4b8-4964-be72-d3ba9c129f6f"
      },
      {
        "name": "فردیس",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "82bfeed5-dd75-4eb9-a3cf-600bdb3e2306"
      },
      {
        "name": "کرج",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "97dbe0b0-dfc8-4dbc-a789-71f7fae869d7"
      },
      {
        "name": "کمال شهر",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "1adbb0b1-c61a-4c72-9834-486543a495e2"
      },
      {
        "name": "کوهسار",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "5fbf6fe7-c96e-44b5-bfc8-ce2e2f9b6a74"
      },
      {
        "name": "گرمدره",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "ffd1303a-f8a3-45d4-bcd6-464e53cc78bd"
      },
      {
        "name": "گلسار",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "d0294435-3d84-4aee-b5f6-4ac30cc62bd2"
      },
      {
        "name": "ماهدشت",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "c15128f7-9f84-4462-acb6-faae59a672d4"
      },
      {
        "name": "محمدشهر",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "edec492e-6f51-4c71-944f-fa36c975d1df"
      },
      {
        "name": "مشکین دشت",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "b08238a6-dc5f-46be-bf39-bff4796d8065"
      },
      {
        "name": "نظرآباد",
        "stateId": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b",
        "id": "3c686d04-f371-4430-9ef6-06aa6f894a11"
      }
    ],
    "name": "البرز",
    "id": "011d3b8f-3346-4ca3-a9f0-9d23ed80fe2b"
  },
  {
    "listOfCities": [
      {
        "name": "آب پخش",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "ebc30024-7d39-4533-a98d-ee4077a3fd87"
      },
      {
        "name": "امام حسن",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "73161422-cf45-4143-acb9-fe06161802ee"
      },
      {
        "name": "انارستان",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "d5a62814-c1dc-43bb-8f63-38c385b4d20b"
      },
      {
        "name": "اهرم",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "56658fe7-4d7b-4271-9204-15c5e8d74aea"
      },
      {
        "name": "بادوله",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "274347ab-dfd4-4829-84d9-3a1032c963ee"
      },
      {
        "name": "برازجان",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "6c028d99-8c82-44f0-ba68-88b4bc2a205b"
      },
      {
        "name": "بردخون",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "34c57e0d-5088-4906-ad56-7e2e68001334"
      },
      {
        "name": "بردستان",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "4a7286ce-fdc2-49b9-b47a-3dcf878c6c21"
      },
      {
        "name": "بندردیر",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "badca0d0-f607-48c2-bf95-8e1f25e12e59"
      },
      {
        "name": "بندردیلم",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "00f1a8d7-d517-4dd2-b5fe-194de7f9fcaf"
      },
      {
        "name": "بندرریگ",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "ab702854-d0a9-4b05-be81-a2f607f3e785"
      },
      {
        "name": "بندرکنگان",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "656b2268-c304-405f-abd4-1a9edda06fba"
      },
      {
        "name": "بندرگناوه",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "3f6e7c47-9b77-42f6-bb90-102823d168eb"
      },
      {
        "name": "بنک",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "2257197f-2fc4-44df-a737-e952ad401dd8"
      },
      {
        "name": "بوشکان",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "6f24bd7d-0869-49da-a734-e7d68ce96c29"
      },
      {
        "name": "بوشهر",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "b1083af9-3c0c-41c0-9220-b4ea3b9a9b63"
      },
      {
        "name": "تنگ ارم",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "c88a3fc9-b39a-402b-b5ed-e36a5688715b"
      },
      {
        "name": "جم",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "ab5b5f4d-d008-4b46-9ccd-1d9a0448f892"
      },
      {
        "name": "چغادک",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "9e5aa567-d057-4712-91cd-350f7d03bb23"
      },
      {
        "name": "خارک",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "30639724-be6e-45de-92c3-296fdd4636af"
      },
      {
        "name": "خورموج",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "0006b6e9-67e3-4311-b6a0-50788a0ee2a0"
      },
      {
        "name": "دالکی",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "c9b76378-d0ca-40e7-a83c-540bf09c0d40"
      },
      {
        "name": "دلوار",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "9f165ca2-3bfd-4694-9d2f-91da0bcb5bee"
      },
      {
        "name": "دوراهک",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "5bfefd80-4b9b-4048-bd27-1f4f364da767"
      },
      {
        "name": "سیراف",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "f3ffd659-4ff8-4b4e-a44e-5e6e598004d6"
      },
      {
        "name": "سعد آباد",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "951e6f7b-894b-48ad-b4b3-9ac625059af6"
      },
      {
        "name": "شبانکاره",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "92d7254e-2304-4ab7-af60-28a4dc263812"
      },
      {
        "name": "شنبه",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "f1fe09cb-9b01-4723-bb2c-19ff29fbb5d0"
      },
      {
        "name": "عسلویه",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "541c6eb0-fe30-473c-a24d-8248a1133ce9"
      },
      {
        "name": "کاکی",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "26fd8707-f65a-4b81-bfcc-4e56e6ac64af"
      },
      {
        "name": "کلمه",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "0f83c594-d9e3-43bd-ac23-4656148b1419"
      },
      {
        "name": "نخل تقی",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "9bbdd8a1-235f-41bf-bb55-4a64885e31db"
      },
      {
        "name": "وحدتیه",
        "stateId": "81e20274-6930-4058-8a83-69050b8bba8a",
        "id": "4ec90e6a-e0d0-46b2-8115-fca7cf065cf9"
      }
    ],
    "name": "بوشهر",
    "id": "81e20274-6930-4058-8a83-69050b8bba8a"
  },
  {
    "listOfCities": [
      {
        "name": "آبسرد",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "3f636909-b3a2-4b10-b23d-34d61a5eefff"
      },
      {
        "name": "آبعلی",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "f04d0bfc-31f8-42e4-b215-969d56a405e4"
      },
      {
        "name": "احمد آباد مستوفی",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "d2481b51-9148-4cea-8145-1b4b266b5b1c"
      },
      {
        "name": "ارجمند",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "63a88474-df98-4760-9de1-b0c4f1b27fd5"
      },
      {
        "name": "اسلامشهر",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "c2bf181d-ed8f-4669-a65b-010f0dc541e4"
      },
      {
        "name": "اندیشه",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "e2eb044b-2851-49da-9d4a-b7e6f9af0b1e"
      },
      {
        "name": "باغستان",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "566f5543-70b7-4e7a-91b3-6bbd197f2774"
      },
      {
        "name": "باقرشهر",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "f5be31f3-57e6-4576-a1d4-32b3b1bc2ac9"
      },
      {
        "name": "بومهن",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "a048a257-c0da-4059-8434-85d8e2f7f675"
      },
      {
        "name": "پاکدشت",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "786ceeb4-1bcc-46aa-8b0d-073a4441fc29"
      },
      {
        "name": "پردیس",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "310cc8a0-8960-4c2a-b355-56d7967bfce2"
      },
      {
        "name": "پرند",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "a1a411ad-bf63-481c-bbb2-96aae72a6b46"
      },
      {
        "name": "پیشوا",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "eeee0bfa-8d46-43c0-9f47-9fbe17d09806"
      },
      {
        "name": "تجریش",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "1a072e34-c544-49a3-9987-39f9f86f5533"
      },
      {
        "name": "تهران",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "65cef87b-f553-4e76-b413-5c9aebdee816"
      },
      {
        "name": "جوادآباد",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "1ad35d91-bb32-40fe-9ca2-ecce96fed0e0"
      },
      {
        "name": "چهاردانگه",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "443bbcd9-47e3-4582-8b95-e5b0d8dad6c6"
      },
      {
        "name": "حسن آباد",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "6b624306-79cb-43dd-b059-589db45676a5"
      },
      {
        "name": "دماوند",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "7315e9e8-cced-42c5-84f0-b1532d63ed5c"
      },
      {
        "name": "رباطکریم",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "8d05a464-5c15-4b80-9b21-502b1fe70264"
      },
      {
        "name": "رودهن",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "f6d4c423-5d9b-4ed7-9f4b-0214eb2a4b2e"
      },
      {
        "name": "شاهدشهر",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "bbcc0238-ece4-4777-8857-8c11ef04066b"
      },
      {
        "name": "شریف آباد",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "7af91f69-e31c-4901-ab36-15603e86075e"
      },
      {
        "name": "شمشک",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "8ea1cbf2-b764-4489-8ced-7ad73e449d71"
      },
      {
        "name": "شهریار",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "2e38f846-2060-4d4c-962c-6e9bb652772d"
      },
      {
        "name": "صالحیه",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "2c2c8cc6-ac24-4299-8fe4-84b67e328674"
      },
      {
        "name": "صباشهر",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "a1d36869-89a1-481c-9a89-aa656e11fe43"
      },
      {
        "name": "صفادشت",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "fe641ace-f43f-452a-9a50-7b8c43c5d2a6"
      },
      {
        "name": "فردوسیه",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "c9898c97-3730-472e-829b-9ecda05af7bd"
      },
      {
        "name": "فیروزکوه",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "447c75f6-df08-41f5-9e6e-0effb4a8e93e"
      },
      {
        "name": "فرون اباد",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "e13461c0-1d51-46bc-97be-3961049c0c3e"
      },
      {
        "name": "فشم",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "18fddfd7-8a98-45b3-b9ef-9c6ce1c3e243"
      },
      {
        "name": "قدس",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "f68df6be-b162-47e8-9b61-dad54e47e0c2"
      },
      {
        "name": "قرچک",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "bf6c0e41-9c28-4a09-834e-23c8092a2e24"
      },
      {
        "name": "کیلان",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "eecb09fb-60cd-44ef-a4e2-bf2cba17dc83"
      },
      {
        "name": "کهریزک",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "1e3bc61c-e8a0-410f-9146-43dbd37c228d"
      },
      {
        "name": "گلستان",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "c0a87c8a-abd2-4f28-a39e-fb3a46611fb9"
      },
      {
        "name": "لواسان",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "aba539bc-0712-43e2-8bee-0f6dcb7ed71a"
      },
      {
        "name": "ملارد",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "63386f19-54dc-4c27-9e41-104f180d738b"
      },
      {
        "name": "نسیم شهر",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "7525e081-5246-40cc-81f6-797f189f6244"
      },
      {
        "name": "نصیرشهر",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "e41e40b1-adb2-45df-b9c6-c899bfa129c1"
      },
      {
        "name": "وحیدیه",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "9801fd36-f1f0-4310-baf9-8071793f7e7f"
      },
      {
        "name": "ورامین",
        "stateId": "e3d1ae4b-2135-4df1-836e-583a8d633cf7",
        "id": "35927550-0e97-4b63-86e7-2d058b2ba0bd"
      }
    ],
    "name": "تهران",
    "id": "e3d1ae4b-2135-4df1-836e-583a8d633cf7"
  },
  {
    "listOfCities": [
      {
        "name": "اردل",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "167b2c45-3b6f-4cc0-a069-335b6c4d656b"
      },
      {
        "name": "آلونی",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "67754179-51d5-4d52-9c46-02d44220eb06"
      },
      {
        "name": "باباحیدر",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "6d7d1c77-b52d-45de-ba5f-0e002873c71a"
      },
      {
        "name": "بازفت",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "04cc1d23-82f5-4f74-91e6-d73d13fd45d3"
      },
      {
        "name": "بروجن",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "c069ea81-e026-4ca5-a268-2208ed723be3"
      },
      {
        "name": "بلداجی",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "eaa4345f-1fc3-48d5-89e7-b16898adae1c"
      },
      {
        "name": "پردنجان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "c15cebdf-2ced-4892-8d2d-33dd06a39b97"
      },
      {
        "name": "جونقان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "034f54ea-a22f-40df-8fc1-85b4425d26d2"
      },
      {
        "name": "چلیچه",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "25fa8941-2680-40f7-af49-d2b393c0ceb1"
      },
      {
        "name": "چلگرد",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "7da44f6e-4382-4324-a539-dd6fb3d46bc8"
      },
      {
        "name": "دستنا",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "5f7a1c9b-8a37-4d80-a339-73b7d00021c8"
      },
      {
        "name": "دشتک",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "9c39e2d2-1ba8-4070-9477-aa1b5d5c324b"
      },
      {
        "name": "سامان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "f9363fbd-ee7e-4609-aaa7-8967efcc27e2"
      },
      {
        "name": "سرخون",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "e4157c0e-b0c3-486e-9076-d84b9b392e51"
      },
      {
        "name": "سفیددشت",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "00189242-df89-44cb-a83a-065a6645c9e7"
      },
      {
        "name": "سودجان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "cd4d7c6b-73fa-4d95-a830-07f0770a1952"
      },
      {
        "name": "سورشجان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "e379d918-7d4a-4187-8fd6-f589204dbb8f"
      },
      {
        "name": "شلمزار",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "7a6739b6-2c79-41f1-b6c0-110c4889d1c3"
      },
      {
        "name": "شهرکرد",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "c788dff2-7967-40de-b728-7a1ef13ed312"
      },
      {
        "name": "صمصامی",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "f79014a7-9ffb-47d3-8f2c-41488879c389"
      },
      {
        "name": "طاقانک",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "a7259227-7b2b-4549-9834-bff74ec74bc5"
      },
      {
        "name": "فارسان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "7f39ff50-39e5-4b6a-ab88-1bd94c94d159"
      },
      {
        "name": "فرادبنه",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "470b6f41-a3d1-4446-bc8a-6dac5cc23c66"
      },
      {
        "name": "فرخ شهر",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "6cf895ce-ea79-45d4-9515-27d3b5822cf9"
      },
      {
        "name": "کاج",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "d4780374-7dbd-4a76-a279-38cc8a6fa8f4"
      },
      {
        "name": "کیان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "d9446e45-d4aa-4a12-978c-7e070f4df944"
      },
      {
        "name": "گندمان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "65935380-f1f5-40cc-9a9e-aececd367317"
      },
      {
        "name": "گهرو",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "dabba64e-43f3-4834-a4ec-17705d9ce1e1"
      },
      {
        "name": "گوجان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "d53a3352-dfe4-45bb-a4ce-c6a02c156fd1"
      },
      {
        "name": "لردگان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "62a331e0-d1ae-4a78-8df8-077d8378fc9f"
      },
      {
        "name": "مال خلیفه",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "a4a37401-837d-49c6-9ed7-1b43d735bc75"
      },
      {
        "name": "ناغان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "19333ec0-e7cb-4833-be52-5e0fe312a339"
      },
      {
        "name": "نافچ",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "5a1577a3-f88e-4833-a391-638f4a54c296"
      },
      {
        "name": "نقنه",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "b9c11934-3d9a-4be2-971f-ad6e5d9aff49"
      },
      {
        "name": "هارونی",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "4d43cfeb-6780-4118-9f9c-cbb9947e3d53"
      },
      {
        "name": "هفشجان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "4ec209c5-23ae-451a-9b00-ebdfe47e0971"
      },
      {
        "name": "وردنجان",
        "stateId": "c116b3d4-6566-4368-964d-3f5a0190c1e2",
        "id": "96492723-e270-41ba-93b0-a9a5d73cf76f"
      }
    ],
    "name": "چهارمحال و بختیاری",
    "id": "c116b3d4-6566-4368-964d-3f5a0190c1e2"
  },
  {
    "listOfCities": [
      {
        "name": "ارسک",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "dfeb3926-4e95-4716-8592-b081268850a7"
      },
      {
        "name": "آرین شهر",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "2cb3f2db-5193-44d0-bdfa-429778ce8339"
      },
      {
        "name": "اسدیه",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "d2f73133-83ab-48e6-89cd-7b952f543aab"
      },
      {
        "name": "اسفدن",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "4d8781e8-2dd8-4b33-9813-1deddae73324"
      },
      {
        "name": "آیسک",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "19e80ac9-0462-4018-b75f-1a3ce9cd4856"
      },
      {
        "name": "اسلامیه",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "39844a3b-2508-4839-9337-b15b8bb23f49"
      },
      {
        "name": "بیرجند",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "e5b9f3c3-0f46-437f-abb5-425eb4e0fb88"
      },
      {
        "name": "بشرویه",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "eaddb69a-b83a-4fad-bc8c-f08f7c14b801"
      },
      {
        "name": "حاجی آباد",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "76c5f8b0-ba46-45f3-967d-6d9cf3c97b01"
      },
      {
        "name": "خضری دشت بیاض",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "47daef9e-4e71-4f5c-be31-8d441a855f55"
      },
      {
        "name": "خوسف",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "0a510495-f4f8-4eb8-b68d-87e9a12ec631"
      },
      {
        "name": "دیهوک",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "b025b3d4-22b0-477c-9ae3-0a010d8599e7"
      },
      {
        "name": "زهان",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "6677aafd-d669-4be9-a169-7f5ddf861c82"
      },
      {
        "name": "سرایان",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "c3e63d02-34ca-48c4-839f-c26f4aafc02c"
      },
      {
        "name": "سربیشه",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "91b8cc79-bb80-4e67-b773-a72398fe82d6"
      },
      {
        "name": "سه قلعه",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "995322b4-cf00-4666-b82f-3bac01c9482b"
      },
      {
        "name": "شوسف",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "fcc49bf2-b1ed-473f-8f76-76a632486e33"
      },
      {
        "name": "طبس مسینا",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "5274899c-a3fd-429b-8dfa-0576d4f166ad"
      },
      {
        "name": "عشق آباد",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "2c5c93af-edbf-4789-916f-04c328879318"
      },
      {
        "name": "قاین",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "0d8633e5-3fd4-4fd1-9632-4ea093f5f8d3"
      },
      {
        "name": "قهستان",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "82db245a-1022-4465-a0dd-b5bb5054e68c"
      },
      {
        "name": "گزیک",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "25986a8a-5b0b-478a-9602-ce4cea688ead"
      },
      {
        "name": "نیمبلوک",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "e2613d13-d746-4584-ab90-a6aff01f73d4"
      },
      {
        "name": "نهبندان",
        "stateId": "c7286b08-70dc-429e-828b-1c9f7bb5e415",
        "id": "1268a9bf-2e93-4a79-8e96-e6e93a2fba45"
      }
    ],
    "name": "خراسان جنوبی",
    "id": "c7286b08-70dc-429e-828b-1c9f7bb5e415"
  },
  {
    "listOfCities": [
      {
        "name": "احمدابادصولت",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "d84b2fb7-1a81-4781-a7ed-8e76dc95dab4"
      },
      {
        "name": "انابد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "d13dd424-0505-4663-904d-6bc600f4a595"
      },
      {
        "name": "باجگیران",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "f7162bb3-9a2b-4b08-8b27-8e7b55a3bad8"
      },
      {
        "name": "باخرز",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "3f5c872a-0532-48da-a1ae-12b164938052"
      },
      {
        "name": "بایک",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "3ab94c63-f512-44fb-8d49-0a9ca4efeea7"
      },
      {
        "name": "بجستان",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "73bcd7e3-a98f-4b24-b4e4-e306630d1bfc"
      },
      {
        "name": "بیدخت",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "2c43a5dc-00d1-4d15-95e1-5cf58c94fa50"
      },
      {
        "name": "بردسکن",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "aa0210fa-fcd3-4a45-818c-1812b4431ebf"
      },
      {
        "name": "تایباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "4815c7da-f6ec-4c39-ba46-c0f5c59c439e"
      },
      {
        "name": "تربت جام",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "f44a9e66-fe32-4f03-bd61-4aa72db93e45"
      },
      {
        "name": "تربت حیدریه",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "bf0298a5-3b92-486a-8e56-bc5797a6da61"
      },
      {
        "name": "جغتای",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "eb3d5f97-ebf6-4569-a071-8947d3319550"
      },
      {
        "name": "جنگل",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "9c97d556-4734-445e-920c-539d9ce8ee2e"
      },
      {
        "name": "چاپشلو",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "c252472e-1a23-41a7-be77-c2bea98062a1"
      },
      {
        "name": "چکنه",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "4545fc64-0b7c-4e8a-90a1-3c2570e70ddb"
      },
      {
        "name": "چناران",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "d6148d18-db69-4c7b-9bdb-db5aee980314"
      },
      {
        "name": "خرو",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "8222404f-4622-4f45-b041-a78a1cf6a541"
      },
      {
        "name": "خلیل آباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "1d25d368-33eb-4036-ba56-4590c346a0b8"
      },
      {
        "name": "خواف",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "a5138f31-3ecd-4eed-985b-2db6dea31667"
      },
      {
        "name": "داورزن",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "a2e40ebd-7728-4218-8d7a-2dd026f95d1a"
      },
      {
        "name": "درگز",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "e7554526-416d-43e7-805d-db8727b9a30d"
      },
      {
        "name": "رباط سنگ",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "8df5b216-1bd3-4efa-811b-c28f81fc4ac2"
      },
      {
        "name": "رشتخوار",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "96bcaf03-408e-4c69-b06f-027ec46056b2"
      },
      {
        "name": "رضویه",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "d093305a-7f96-4cc0-a95a-a52aaf3f56b7"
      },
      {
        "name": "روداب",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "bf455545-c896-44f7-9d45-50506d542699"
      },
      {
        "name": "ریوش",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "88a958df-daf3-43bc-ade1-40d0bceb93fe"
      },
      {
        "name": "سبزوار",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "983cf7b9-497c-45db-ad1f-d1a2d9b18698"
      },
      {
        "name": "سرخس",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "5b59c7c7-459f-4c58-a5f3-e950d224d10c"
      },
      {
        "name": "سفیدسنگ",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "3b0309d9-bd74-4e00-af28-bdd9e73aea9d"
      },
      {
        "name": "سلطان آباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "4d47b08e-3830-4195-9b16-ea1f23a85529"
      },
      {
        "name": "سنگان",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "07b207fe-d112-4d03-a837-82b9d6a50aa4"
      },
      {
        "name": "شادمهر",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "fbadd74e-6a8e-49c4-8b48-3ffe14da9bd8"
      },
      {
        "name": "شاندیز",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "c422ded2-77b6-4fd1-a82f-2a6b64fa7734"
      },
      {
        "name": "ششتمد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "3604f24d-0221-4882-8b12-bbbe7a6d36bd"
      },
      {
        "name": "شهراباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "1c9abb56-e295-48b7-b068-9d7d4fe349fc"
      },
      {
        "name": "شهرزو",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "6801ed5e-f9e3-49f4-9be4-1332666dc84c"
      },
      {
        "name": "طرقبه",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "58ca1bfa-aa0e-4186-b818-95bb5907bc93"
      },
      {
        "name": "فریمان",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "07fca73d-a69b-41da-9967-ffd5ec161b7d"
      },
      {
        "name": "فرهادگرد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "4c727a19-856c-4057-bae5-2ec6616116e6"
      },
      {
        "name": "فیروزه",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "2aa63673-956a-4b75-86f0-a7607a549577"
      },
      {
        "name": "فیض آباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "43577dd3-1197-4625-867c-301f0825413f"
      },
      {
        "name": "قاسم آباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "199212e3-2a72-42cf-a187-4a19c7dd3e20"
      },
      {
        "name": "قدمگاه",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "61ddf852-de32-410b-832d-7db6e767625f"
      },
      {
        "name": "قلندرآباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "b3dd8a36-6475-4cef-9274-60eb168b1cea"
      },
      {
        "name": "قوچان",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "2fc7a660-6e17-4c61-b9f0-dbc758c00b3a"
      },
      {
        "name": "کاخک",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "8c9a8f35-6a23-4845-b368-f14fd662bb9c"
      },
      {
        "name": "کاریز",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "b9d9638e-4fc7-4504-b952-5560ff5b9973"
      },
      {
        "name": "کاشمر",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "06f2bd9e-80ed-45f0-87c4-699a29320855"
      },
      {
        "name": "کدکن",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "1b10d427-f667-4370-b9f4-7744b9d9ef66"
      },
      {
        "name": "کلات",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "8feffd34-29b7-43fa-a35d-f4e9299f647a"
      },
      {
        "name": "کندر",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "36e7e9c5-434e-4fc6-85ba-87f0255f16b4"
      },
      {
        "name": "گلمکان",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "abc2cc6f-069a-4a3f-9b2c-917c63c51fa4"
      },
      {
        "name": "گناباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "ee32fee7-73c2-45d1-9259-c9260cc958e8"
      },
      {
        "name": "لطف آباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "3bd94e76-2847-4c43-90d4-1e28330f2705"
      },
      {
        "name": "مزدآوند",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "79763f5e-c481-4c67-95a9-275149165073"
      },
      {
        "name": "مشهد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "d531b38f-cde3-4ebb-87ac-953094924ae1"
      },
      {
        "name": "مشهدریزه",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "269abe67-2679-4f76-9d64-3d82554e3a17"
      },
      {
        "name": "ملک آباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "e8203b66-dc4e-4f6e-8a52-783586a97a0c"
      },
      {
        "name": "نیشابور",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "75749d28-a11f-4e73-ba3c-62bfc462903e"
      },
      {
        "name": "نشتیفان",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "54a2ef2d-7b32-4eef-85d9-89618b16b7ef"
      },
      {
        "name": "نصر آباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "c5c8e61b-a6f5-417a-9078-a924cd5eac42"
      },
      {
        "name": "نقاب",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "18e6ae1f-b679-4c84-9fd7-8178c12fbb6b"
      },
      {
        "name": "نیل شهر",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "e2c84dd6-fb0b-493d-ac94-2da07ecb7278"
      },
      {
        "name": "نوخندان",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "56a7e8bd-db59-4498-9aa9-012982bf2e84"
      },
      {
        "name": "همت آباد",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "4f7f98b9-a5ca-4e52-a5db-fcb82342ad16"
      },
      {
        "name": "یونسی",
        "stateId": "28d0f6fc-9264-406a-a68e-b958be1805e5",
        "id": "ae6f59f3-57e6-4b07-aaf0-0f47f0f74fd5"
      }
    ],
    "name": "خراسان رضوی",
    "id": "28d0f6fc-9264-406a-a68e-b958be1805e5"
  },
  {
    "listOfCities": [
      {
        "name": "اسفراین",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "9afbceec-e7a9-46b4-8824-34c06978fb97"
      },
      {
        "name": "آشخانه",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "03475828-4797-4e28-9ae1-816c0d80aa84"
      },
      {
        "name": "ایور",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "ec20231b-a8c7-4593-a382-48b001150996"
      },
      {
        "name": "بجنورد",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "0ebe88c0-5ad0-4893-be4b-05c0ad36e7ff"
      },
      {
        "name": "بیدروبه",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "16c38887-d0d8-4e4c-8f4e-6b710ca2f7eb"
      },
      {
        "name": "پیش قلعه",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "533d7220-9781-4f02-ad1f-c7f3b3334838"
      },
      {
        "name": "تیتکانلو",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "946aa1ee-af61-4bb4-a5e6-3d6077533490"
      },
      {
        "name": "جاجرم",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "924d53a3-5d09-4340-a8cd-9da5bdb1c3e8"
      },
      {
        "name": "چناران شهر",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "43d4e69e-a6c5-4ec3-806b-0c5acd078385"
      },
      {
        "name": "حصارگرمخان",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "34d34941-e0a9-4f44-8787-053c1815569f"
      },
      {
        "name": "درق",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "f2cbab38-1638-4c2d-8109-eadd5e188c46"
      },
      {
        "name": "زیارت",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "e5cd9c89-135d-4a2b-bfb3-b2bb7f66a470"
      },
      {
        "name": "سنخواست",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "ab9cd41e-d622-45fe-a079-7d1242619af9"
      },
      {
        "name": "شیروان",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "b7afaee6-d7db-446a-a7e3-8b03a4b4c4a1"
      },
      {
        "name": "شوقان",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "274e9b31-8ab1-4d54-8746-9b929658d1c1"
      },
      {
        "name": "صفی آباد",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "dfa39474-5689-4c75-bd21-e411290029fb"
      },
      {
        "name": "فاروج",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "fe9dbb24-6457-465e-a14f-b26cb95762d7"
      },
      {
        "name": "قاضی",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "701a0a85-85ab-4add-9d21-77aa526c248c"
      },
      {
        "name": "قوشخانه",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "5bd214e6-618f-4680-ba2a-85df8535da64"
      },
      {
        "name": "گرمه",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "186cb8f3-28f2-44a5-9876-c6e3d66b2d7d"
      },
      {
        "name": "لوجلی",
        "stateId": "93d12ffa-3a9e-4542-bee5-960d07aefcf2",
        "id": "74bed958-fc72-4257-9b97-a2d2204e3428"
      }
    ],
    "name": "خراسان شمالی",
    "id": "93d12ffa-3a9e-4542-bee5-960d07aefcf2"
  },
  {
    "listOfCities": [
      {
        "name": "آبادان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "405a813f-2284-49fe-a8a0-9bb2fb014736"
      },
      {
        "name": "آبژدان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "1301e0e8-5374-487e-a8b6-e28bae8b332c"
      },
      {
        "name": "ابوحمیظه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "4823dc30-a3f3-4d58-a078-4889824d6d7b"
      },
      {
        "name": "ایذه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "676659e4-d6b3-4e34-a8e2-4d2ffd50fb3b"
      },
      {
        "name": "اروندکنار",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "a205c3c1-f1d9-4294-928f-2c3cd1b42e0d"
      },
      {
        "name": "آزادی",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "17c4b741-0a3a-4168-8d1c-969b0f100439"
      },
      {
        "name": "آغاجاری",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "129de4e1-49a7-4dfa-825a-7b4f255fe2d9"
      },
      {
        "name": "الهایی",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "c303066e-450f-4c82-ac44-82ea9d2c9b06"
      },
      {
        "name": "الوان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "d1168212-afd5-41f5-9348-382975efb76e"
      },
      {
        "name": "امیدیه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "3e4d0364-7c90-4adb-9419-a07c0d20270d"
      },
      {
        "name": "اندیمشک",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "7d11ea22-63e7-4967-aede-6dc319cce9ef"
      },
      {
        "name": "اهواز",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "c80a13ca-4ced-47bb-80ed-64582033f20b"
      },
      {
        "name": "باغ ملک",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "a423ba75-5807-4f66-b6ba-f5bdd1f9749e"
      },
      {
        "name": "بندرامام خمینی",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "4d75393d-e785-4fa2-981b-ac2fca791259"
      },
      {
        "name": "بندرماهشهر",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "4da0e696-6ba3-4ce3-be22-c79966a6f27e"
      },
      {
        "name": "بهبهان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "90e389df-b19a-4292-9123-25d4f41bee72"
      },
      {
        "name": "ترکالکی",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "a33aa32c-752f-4bec-a00b-ff0bcec55dfe"
      },
      {
        "name": "تشان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "95600aab-b775-48f0-a2d4-650c7dc12100"
      },
      {
        "name": "جایزان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "9639c29b-b726-4ea3-8ff6-2728a311e623"
      },
      {
        "name": "جنت مکان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "0174bea2-7092-4021-b3c5-1589d65f6ada"
      },
      {
        "name": "چغامیش",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "c7d09312-10bc-4f76-8c45-19b2e9cdd4db"
      },
      {
        "name": "چمران",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "39d6328a-7da5-4be8-8757-7a8e9cf759df"
      },
      {
        "name": "چم گلک",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "66eafdb0-42fb-4844-9a11-087098eb6638"
      },
      {
        "name": "چویبده",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "0296d44d-f669-4e65-a03b-10f489a77b38"
      },
      {
        "name": "حر",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "d83fc0a0-0ec5-4823-a53f-2524946db394"
      },
      {
        "name": "حسینیه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "be6836c4-0edd-495a-94dc-eb75c5c30924"
      },
      {
        "name": "حمیدیه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "40e5906a-883a-41e7-83e8-56492556dc73"
      },
      {
        "name": "حمزه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "5845c8e7-e25a-4bf2-a5c6-cbf87525c2a6"
      },
      {
        "name": "خرمشهر",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "e74e1d75-381a-4980-8c2a-716329950d06"
      },
      {
        "name": "خنافره",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "dc01206e-4e80-4657-8868-61c7fe901186"
      },
      {
        "name": "دارخوین",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "2abc2964-8e27-4803-a030-bc0ad9b2e983"
      },
      {
        "name": "دزفول",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "23a932a4-eaf8-47b6-a8ce-785d20c6c2f6"
      },
      {
        "name": "دهدز",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "5e1b66a2-bfbd-4906-9c68-21972da6012e"
      },
      {
        "name": "رامشیر",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "35782d8f-1619-44c5-a3ed-55912d6b26c4"
      },
      {
        "name": "رامهرمز",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "d52827f2-b798-4363-94ed-4348761b611b"
      },
      {
        "name": "رفیع",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "c67e2d68-732f-473b-bb2c-1ed06c378beb"
      },
      {
        "name": "زهره",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "04657cd1-0ba2-4be7-b388-3ecc8992f095"
      },
      {
        "name": "سالند",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "436e919f-1fa8-499b-baa3-a3895c1c0d9b"
      },
      {
        "name": "سیاه منصور",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "d8e6cbca-f181-4c47-a9c5-8019eef686e3"
      },
      {
        "name": "سرداران",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "d66622e9-0e40-4f21-82ae-1bdf8401cf00"
      },
      {
        "name": "سماله",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "eb72cdd7-ad3e-48ab-b6dc-54f6b79e1f0c"
      },
      {
        "name": "سوسنگرد",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "5841e914-6f91-4cef-860c-0092611ad6fc"
      },
      {
        "name": "شادگان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "9395dcbb-3885-4cc0-a829-d98332f28ace"
      },
      {
        "name": "شاوور",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "23f6d924-ecc7-42c4-8be5-a075f8537eee"
      },
      {
        "name": "شیبان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "3db394c7-faa0-4479-b6ee-c3367309ef4b"
      },
      {
        "name": "شرافت",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "b26c56e8-d81f-4d13-8938-ba6dab7dc5e3"
      },
      {
        "name": "شمس آباد",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "23c950b6-269d-4d98-8477-0522a4ad0a98"
      },
      {
        "name": "شهر امام",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "2e425af7-b54c-43d2-860c-815bc4d418fb"
      },
      {
        "name": "شوشتر",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "fe953aae-bc81-4727-a97b-191c900e6382"
      },
      {
        "name": "صالح شهر",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "206528e8-9fa7-4a6e-931a-3fa364172647"
      },
      {
        "name": "صیدون",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "7abd555b-b399-4d55-b91b-b0fba7a317e5"
      },
      {
        "name": "فتح المبین",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "69e97a71-aeac-42d4-95e5-3de054f15113"
      },
      {
        "name": "قلعه تل",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "7b7158ef-49ec-44c6-81af-513b5ab504c3"
      },
      {
        "name": "قلعه خواجه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "6e341d0d-9217-4e72-9b80-bdb8ec0ba831"
      },
      {
        "name": "کوت سیدنعیم",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "f2e3d2ff-79b2-4f1b-be7e-1e267521e42a"
      },
      {
        "name": "کوت عبداله",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "e71a1473-085a-456d-ab07-d62e1ecc99d4"
      },
      {
        "name": "گتوند",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "f1032683-9d03-4711-8f01-95726591dd84"
      },
      {
        "name": "گلگیر",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "d51747b9-d475-4e72-b7d6-1a442defb813"
      },
      {
        "name": "گوریه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "006c171a-b8e3-4e3a-921d-9ecf0b564ea1"
      },
      {
        "name": "لالی",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "aa695955-3e43-4e50-ba09-a0e8952eebf4"
      },
      {
        "name": "میانرود",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "6d81bd8a-6c01-4317-822b-a1c0c08df158"
      },
      {
        "name": "میداود",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "19e8dc08-cc6d-445d-a5d1-6991c9392000"
      },
      {
        "name": "مسجدسلیمان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "571f2d0e-bc01-440a-a973-02fdb6999e67"
      },
      {
        "name": "مشراگه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "eb6d5ebd-7160-45b2-91ef-cc227790f076"
      },
      {
        "name": "مقاومت",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "92d89c72-be93-4c19-ae3a-ed562b9ec04d"
      },
      {
        "name": "ملاثانی",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "86453ae0-94e6-42fa-bb8c-e0a6828260b9"
      },
      {
        "name": "منصوریه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "66797bd7-fd7a-42b4-ae12-c56b9b0f1ff6"
      },
      {
        "name": "مینوشهر",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "5b1138b5-d79a-4bf7-aa04-77013baffa35"
      },
      {
        "name": "هفتگل",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "f86c163c-aeae-497e-8939-1050d46a44c2"
      },
      {
        "name": "هندیجان",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "cd00e4f6-a07f-46e7-b69d-91a266b83567"
      },
      {
        "name": "هویزه",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "51449bed-d48c-4fa1-83e3-317cc4664f8f"
      },
      {
        "name": "ویس",
        "stateId": "138f4afd-989f-4e69-93e1-8bf6b2835db4",
        "id": "1fcc48cb-8482-4eda-b41a-c028e952f772"
      }
    ],
    "name": "خوزستان",
    "id": "138f4afd-989f-4e69-93e1-8bf6b2835db4"
  },
  {
    "listOfCities": [
      {
        "name": "ابرکوه",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "c60e1d1d-78fc-46b1-a1f5-08443d5b75e6"
      },
      {
        "name": "احمدآباد",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "dc6ff2e7-9127-4fd4-809e-27759d21f3f2"
      },
      {
        "name": "اردکان",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "bd33cc0f-2d98-45d0-b905-ea68c63f6cf6"
      },
      {
        "name": "اردکان",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "e057587c-1c71-4abc-aaac-a40c5bad3c63"
      },
      {
        "name": "بافق",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "3cc6a294-5333-4977-8235-5abb2b44aefb"
      },
      {
        "name": "بفروییه",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "c63b04f7-60e8-4440-9de2-af6e375e5492"
      },
      {
        "name": "بهاباد",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "8071c2b5-d052-4ec9-97d5-cb62c832b667"
      },
      {
        "name": "تفت",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "fbae83fc-ffd8-4819-b287-597b59ef1193"
      },
      {
        "name": "حمیدیا",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "b90bc189-3ca2-434a-b29e-2773b7c95da7"
      },
      {
        "name": "خضرآباد",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "d0d21051-5512-448d-a03a-855f3acfa6d6"
      },
      {
        "name": "زارچ",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "f3944b1d-931a-465b-8d67-d96b25a4d530"
      },
      {
        "name": "یزد",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "93e21177-71c3-477e-843a-e22aa2b71df0"
      },
      {
        "name": "شاهدیه",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "9815e19f-9adf-49ae-99e3-1217531d5d17"
      },
      {
        "name": "عقدا",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "1fac0ac0-ddcf-4f57-9710-cb434efa0052"
      },
      {
        "name": "میبد",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "e77d95b8-2085-463a-858c-43e62d1cee90"
      },
      {
        "name": "مروست",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "9e4b3909-390f-4600-8998-89e706b1cdd8"
      },
      {
        "name": "مهردشت",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "7823bc48-a0c0-40c6-ba89-70132cb6ebec"
      },
      {
        "name": "مهریز",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "d6d0e0d4-7d8e-4484-bdd2-fe573b8fdbd1"
      },
      {
        "name": "نیر",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "34e1b22d-dabb-45f1-af71-462405db84da"
      },
      {
        "name": "هرات",
        "stateId": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3",
        "id": "9a9be13b-8988-4d01-93fc-3578cb428fe5"
      }
    ],
    "name": "یزد",
    "id": "2fb5b6a1-3ceb-41f2-b604-677b7da683d3"
  },
  {
    "listOfCities": [
      {
        "name": "آب بر",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "e29feac1-7017-4472-be56-bb34884b41aa"
      },
      {
        "name": "ابهر",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "b7ce340a-84dd-4f49-af08-ec6eb088ca33"
      },
      {
        "name": "ارمغانخانه",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "cd41904c-6831-4b4f-8746-ce9a598219e3"
      },
      {
        "name": "چورزق",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "90d20a1d-55c6-4f1f-8517-dafe0fe4e910"
      },
      {
        "name": "حلب",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "921aa4e9-6f86-485c-abb1-4c4f2149e020"
      },
      {
        "name": "خرمدره",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "72a6c905-ad18-49b3-a9d9-3e2cd2501907"
      },
      {
        "name": "دندی",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "03466625-a25d-4969-9859-ab9491451e85"
      },
      {
        "name": "زرین آباد",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "569f009c-1d8f-430c-9270-a67cc6f35132"
      },
      {
        "name": "زرین رود",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "878f87ab-504a-4cd9-b827-371ec03123b0"
      },
      {
        "name": "زنجان",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "6afde3e0-d0ac-41bd-b026-a7423165a337"
      },
      {
        "name": "سجاس",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "bf9b10ed-4f79-40c7-b17d-952f5ed0929d"
      },
      {
        "name": "سلطانیه",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "a6ec5b38-e9d3-456f-9f7e-92888e387998"
      },
      {
        "name": "سهرورد",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "75e4f872-65cc-4c3f-bcbc-ff551dd3eb83"
      },
      {
        "name": "صایین قلعه",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "9329804a-db80-43a2-a3d6-a639a14ee3c9"
      },
      {
        "name": "قیدار",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "8f17d1d8-8649-4cf5-926e-509cd79049b5"
      },
      {
        "name": "کرسف",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "6f607d90-dc30-4096-a217-a56acfafddd1"
      },
      {
        "name": "گرماب",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "7a0d8962-4023-4d1b-980f-1811c02fa273"
      },
      {
        "name": "ماه نشان",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "0aa77f5d-694f-46b4-9a8f-c728f9bc2cf2"
      },
      {
        "name": "نیک پی",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "61aeaf4a-885f-48fb-97fa-afc8329e1f0b"
      },
      {
        "name": "نوربهار",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "87f90817-d123-4eb5-94bd-ef4b5f1d259c"
      },
      {
        "name": "هیدج",
        "stateId": "c09be98f-9a52-47eb-9700-2193d47bbc40",
        "id": "47d5957d-496b-4883-8f4a-781d0de74ce3"
      }
    ],
    "name": "زنجان",
    "id": "c09be98f-9a52-47eb-9700-2193d47bbc40"
  },
  {
    "listOfCities": [
      {
        "name": "ادیمی",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "963b6c62-2323-4625-a63a-ae434d0c069f"
      },
      {
        "name": "ایرانشهر",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "9ee9363f-ff1b-4609-abae-d6d04f516e0a"
      },
      {
        "name": "اسپکه",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "27a94093-baef-49a7-a5ef-8cb43952f08f"
      },
      {
        "name": "بزمان",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "e0d9c2d8-e4da-4b06-8c20-7eacd895c95c"
      },
      {
        "name": "بمپور",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "5577fd00-9c04-49ad-ac1a-7369ca2430fa"
      },
      {
        "name": "بنت",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "3981ab7a-885a-4f9d-b4a2-ec8cd5eceba4"
      },
      {
        "name": "بنجار",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "85e43aa5-cca7-4a45-8686-04d77f25a10d"
      },
      {
        "name": "پیشین",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "ec50c43c-82ee-4926-9da3-c2fc700e8b38"
      },
      {
        "name": "جالق",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "3bd719aa-d221-43a8-ade7-2aa5e2c16c1e"
      },
      {
        "name": "چاه بهار",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "13e95b97-ed44-4900-ba38-be825a766a66"
      },
      {
        "name": "خاش",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "3de9b854-7aaa-4d17-8f4c-0fa82ccc89ee"
      },
      {
        "name": "دوست محمد",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "ec34a50d-429a-4d70-8e28-fae9c9b42544"
      },
      {
        "name": "راسک",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "7a85d638-599d-45ce-8cf1-537912268c2c"
      },
      {
        "name": "زابل",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "5c7a5c1b-bb33-4625-a1e9-738364dce510"
      },
      {
        "name": "زابلی",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "b6e0b68d-92bf-47c6-a07d-ce03a0fc19ba"
      },
      {
        "name": "زاهدان",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "a139cbe2-f58b-4821-83ca-4af639181d91"
      },
      {
        "name": "زهک",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "80c10066-7983-439c-9ef5-e8291034f6ce"
      },
      {
        "name": "سراوان",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "a393c257-08ca-4e89-8f71-5746f61b8cc2"
      },
      {
        "name": "سرباز",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "a2336708-6f69-4d26-bc3d-823a990cf582"
      },
      {
        "name": "سیرکان",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "93f575ae-8337-48bb-908d-595a2f336032"
      },
      {
        "name": "سوران",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "210b2193-8f8e-4862-97f5-14316ce7c588"
      },
      {
        "name": "شهرک علی اکبر",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "5056183d-42f9-4a85-92cf-202e124d8947"
      },
      {
        "name": "فنوج",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "a19a7d18-3fd2-49b4-9f6d-e026046dcf6e"
      },
      {
        "name": "قصرقند",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "3c770211-e4e9-43fd-bdd3-e0c94aba1ff1"
      },
      {
        "name": "کنارک",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "bdd5a9f3-286d-4a88-b331-6c57685ad834"
      },
      {
        "name": "گشت",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "3d1e3198-d92f-4b50-90a1-4f68bf1a6441"
      },
      {
        "name": "گلمورتی",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "bfffc4d3-6f81-418c-9608-27a80dad5dfb"
      },
      {
        "name": "محمد آباد",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "6c61cc07-da8b-4c42-8cca-35f4330509fa"
      },
      {
        "name": "محمدان",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "ef710436-ea81-45a3-b8f9-42f89848ca42"
      },
      {
        "name": "میرجاوه",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "49eec16e-fcfd-4bf0-8b5c-c2ca28424e7a"
      },
      {
        "name": "نصرت آباد",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "6274d8a3-1cb1-4d9c-b310-61fc0cfe166b"
      },
      {
        "name": "نیک شهر",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "6a959973-7ac7-43bc-914a-d13ca0b5b7c8"
      },
      {
        "name": "نگور",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "90975ea3-0b59-4e8c-bbb6-0354e2b74432"
      },
      {
        "name": "نوک آباد",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "7aa76a4c-6150-494a-8fdb-18c37819e99d"
      },
      {
        "name": "هیدوچ",
        "stateId": "06af296b-8229-4923-a055-f514c4f3ee99",
        "id": "0b745fed-29ae-487c-986b-27eedb3cb491"
      }
    ],
    "name": "سیستان و بلوچستان",
    "id": "06af296b-8229-4923-a055-f514c4f3ee99"
  },
  {
    "listOfCities": [
      {
        "name": "آرادان",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "733cc182-35ce-4d9e-becd-e5aaca9dd412"
      },
      {
        "name": "امیریه",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "5b97de94-1ecf-4861-933f-4aaef11a4227"
      },
      {
        "name": "ایوانکی",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "260a1155-5c25-4a91-9970-1db971cda513"
      },
      {
        "name": "بیارجمند",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "cfb63f92-8ddf-4c1c-a220-d6c3f040b1ed"
      },
      {
        "name": "بسطام",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "47ccbeab-0e4d-45c9-a615-ef60c3b882bd"
      },
      {
        "name": "دامغان",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "4c7e531b-9e59-4658-bc7b-7ac18cd53a75"
      },
      {
        "name": "دیباج",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "7063b8dd-afc0-41ea-91bd-dec9ab49b2a9"
      },
      {
        "name": "درجزین",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "c66492f1-cb0a-4520-a7b2-dcc257993414"
      },
      {
        "name": "رودیان",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "175e0637-0d50-4723-8c3b-e44f6a7d14d6"
      },
      {
        "name": "سرخه",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "cb68b738-459f-42b9-ad7c-d1971a806845"
      },
      {
        "name": "سمنان",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "71042ce1-3b4e-4c56-893a-38fd3dbd053e"
      },
      {
        "name": "شاهرود",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "2b99adf9-f1c7-42a4-b56a-4df2fc434e50"
      },
      {
        "name": "شهمیرزاد",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "6770fbc2-1e2b-4517-b08c-144b2a6387c4"
      },
      {
        "name": "کلاته خیج",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "d1aa7ed4-7ce7-44d6-8646-c187a43da2e4"
      },
      {
        "name": "کهن آباد",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "918a6f1a-77b7-4f16-a39e-4516d634469a"
      },
      {
        "name": "گرمسار",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "74f486ff-aefc-4ec3-8112-ba391e665c50"
      },
      {
        "name": "میامی",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "ea33aaf9-14db-4dcb-87a1-b9b3ac7e318a"
      },
      {
        "name": "مجن",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "d839db42-68f6-40b3-adc3-0134972be066"
      },
      {
        "name": "مهدی شهر",
        "stateId": "93431650-0d52-4882-af69-bcb36e6ddd20",
        "id": "725c03bd-ca4a-4a98-8cef-dcc45dace3e2"
      }
    ],
    "name": "سمنان",
    "id": "93431650-0d52-4882-af69-bcb36e6ddd20"
  },
  {
    "listOfCities": [
      {
        "name": "آباده طشک",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "f3ce741b-aa09-4c84-9251-fede06936c98"
      },
      {
        "name": "ارسنجان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "205759bc-fd3b-4f85-aed0-40ece6c6d8c5"
      },
      {
        "name": "ایزدخواست",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "638765ae-e336-4c01-931c-b5b09028c689"
      },
      {
        "name": "استهبان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "513c333e-7c1a-4bdc-afbf-eb6e22f3d6d2"
      },
      {
        "name": "اسیر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "48baffd2-7558-4a50-90dc-912ce7cca106"
      },
      {
        "name": "اشکنان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "3ded4aff-3464-4456-98f1-96c8302ac282"
      },
      {
        "name": "افزر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "ee326ef7-5a8c-4cce-a31d-b2086d76c358"
      },
      {
        "name": "اقلید",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "5482ef51-614e-4a0b-8520-382ba4ba59fa"
      },
      {
        "name": "امام شهر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "afd1dd57-fd7a-47ae-a0c7-2c1b6444d21d"
      },
      {
        "name": "اهل",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "6fd2cea9-3c17-4784-9d85-8f76d1b92a2c"
      },
      {
        "name": "اوز",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "f216a9a1-481f-442a-82aa-545b717c4b62"
      },
      {
        "name": "بابامنیر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "a9ab4e5e-f07a-4421-bffa-5a9f16fb6316"
      },
      {
        "name": "باب انار",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "69b7357d-b28f-48b9-b959-4b067c61ab02"
      },
      {
        "name": "بالاده",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "910e5444-d0a7-4b18-a904-5031e0c40b06"
      },
      {
        "name": "بیرم",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "64b32f69-677f-4578-a47d-2d2328f5ac59"
      },
      {
        "name": "بیضا",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "6afefdc1-8778-4811-bd48-781aa4d192de"
      },
      {
        "name": "بنارویه",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "9875a377-25a9-4891-9389-ea03fcad0b9e"
      },
      {
        "name": "بهمن",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "a86c85d9-868b-4359-b90d-c3447f98ce42"
      },
      {
        "name": "بوانات",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "bf134839-1fcf-4485-ace4-14b0ce627756"
      },
      {
        "name": "جنت شهر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "d79d687b-23d9-4e19-a0c9-1025f4d799f7"
      },
      {
        "name": "جهرم",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "813cff37-d116-4e9e-9cd2-41460524654b"
      },
      {
        "name": "جویم",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "48dec1eb-8f75-4f80-8fa1-626b28b27ad7"
      },
      {
        "name": "حسامی",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "b8fdc709-f896-44ac-8cce-3e04f3cf8d49"
      },
      {
        "name": "خانیمن",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "f7a97da1-d60b-4298-aee0-c4077876c4fc"
      },
      {
        "name": "خانه زنیان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "2d1d57ba-ad7f-43f9-afa3-93bd1bf46cc2"
      },
      {
        "name": "خاوران",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "7d31ee7f-91ae-4414-9805-10b7a08ee241"
      },
      {
        "name": "خرامه",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "54d0a7b9-515a-4f5e-b141-96e9156ee08d"
      },
      {
        "name": "خشت",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "d0f85a37-354d-4853-b836-85b5caeff7ba"
      },
      {
        "name": "خنج",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "d1c6ccab-d98f-486b-b13f-42cf858f4708"
      },
      {
        "name": "خوزی",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "69da43a3-f169-480f-a794-bc9c649834ef"
      },
      {
        "name": "خومه زار",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "6bd15557-fdbd-4b5d-a58d-581626fc0433"
      },
      {
        "name": "داراب",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "6d20369b-59b5-4ef5-9361-95048e629a85"
      },
      {
        "name": "داریان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "c9bbf194-f7bf-4ddb-9516-f44b3b595baa"
      },
      {
        "name": "دبیران",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "b28deb41-5ccf-4a7a-8bb4-fc9b2c20e631"
      },
      {
        "name": "دژکرد",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "169e384f-42a3-4fc7-b633-b2b6d3f20cbe"
      },
      {
        "name": "دهرم",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "0291a115-d850-4132-9ebd-93a2af30bcd9"
      },
      {
        "name": "دوبرجی",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "09bbdab9-87a1-4e7d-b6ab-fa2e6fb11952"
      },
      {
        "name": "دوزه",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "006ccd10-4cfc-459e-8c84-641c076908fb"
      },
      {
        "name": "رامجرد",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "a201649f-6c66-488c-ae9a-cbc5e50e849f"
      },
      {
        "name": "رونیز",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "2235d95d-4539-4671-a053-3b715c4a350e"
      },
      {
        "name": "زاهدشهر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "89bec8dc-3887-4bbf-bff4-ec4e4ad90763"
      },
      {
        "name": "زرقان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "fb21ff5b-082f-4a57-8bc5-130b0dd9be8e"
      },
      {
        "name": "سیدان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "fe8c2d71-1658-44f6-b0c3-dfaca502008c"
      },
      {
        "name": "سروستان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "60dbb9fc-86f0-47fb-a204-b0c8a2b7133c"
      },
      {
        "name": "سعادت شهر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "204134eb-a403-4018-b76f-04afd304571d"
      },
      {
        "name": "سلطان شهر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "bca21b30-8610-4b3e-8b35-b80d4d86589b"
      },
      {
        "name": "سورمق",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "026e983f-ec66-403c-ba4a-436bccbd93ad"
      },
      {
        "name": "شیراز",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "5df2acee-7e8a-4b34-ad89-b321cc9d912e"
      },
      {
        "name": "ششده",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "53a92ff3-a11a-4a28-8556-69043956140e"
      },
      {
        "name": "شهرپیر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "341005f0-82a4-4244-866e-f3d7532877b9"
      },
      {
        "name": "شهرصدرا",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "01dd21de-16ba-436b-ba39-5468ed0914d4"
      },
      {
        "name": "صغاد",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "db5b25f3-4f6b-46a6-b0dd-b5a5274702c4"
      },
      {
        "name": "صفاشهر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "8b683f0a-7449-483e-9373-7f5819de1943"
      },
      {
        "name": "علامرودشت",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "38ed846b-7e33-42ae-979c-6f5bff76572f"
      },
      {
        "name": "عمادده",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "7a5ca7b7-2892-44c4-aba3-99ee59c3d6fa"
      },
      {
        "name": "فدامی",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "392c05de-163e-4ca1-8c22-2197ee6e322f"
      },
      {
        "name": "فراشبند",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "26a30560-3577-4808-8e7d-a49e148abfd6"
      },
      {
        "name": "فیروزآباد",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "7821fa25-90a4-48e0-b7be-cb48ca78b675"
      },
      {
        "name": "فسا",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "ee4b9938-1c81-4e90-a355-d3fc419e29a5"
      },
      {
        "name": "قادراباد",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "79892470-cadd-4f9f-989a-be9d6bec0a01"
      },
      {
        "name": "قایمیه",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "72bfddd3-ab0c-4285-859c-cccefcf4fcd7"
      },
      {
        "name": "قیر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "6e8f0774-ff9a-4b6b-b8ed-f7c0a760a3a4"
      },
      {
        "name": "قره بلاغ",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "523d6044-08af-4440-bc46-00de34eca596"
      },
      {
        "name": "قطب آباد",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "ac3a7647-5d19-4bdd-96e3-2b9e3a38216b"
      },
      {
        "name": "قطرویه",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "a20333f1-83d6-4ca5-a3ba-7a24d6315ba2"
      },
      {
        "name": "کارزین (فتح آباد)",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "02cc61e0-fbc7-46b0-824b-026a91ce9d88"
      },
      {
        "name": "کازرون",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "bd1205dc-86ae-4596-ae17-d8c3c7c7be9e"
      },
      {
        "name": "کامفیروز",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "c3a393c1-97ba-4f99-9e9b-eeadf05fda81"
      },
      {
        "name": "کره ای",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "f1b4c85c-3f12-4124-9b6d-1e1e96a1c925"
      },
      {
        "name": "کنارتخته",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "2dd2cc2a-e2b6-496f-ae1e-393e0fe5182d"
      },
      {
        "name": "کوار",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "59cbc199-d57a-4f4d-b463-5a90e41ac734"
      },
      {
        "name": "کوپن",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "d68974e4-3273-4b6f-8810-5561a513d540"
      },
      {
        "name": "کوهنجان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "a2b12be3-d0b3-481e-8dd1-a562a00b86b7"
      },
      {
        "name": "گراش",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "e56ebaf4-9739-4a55-8ef8-a7b9e70b2bf9"
      },
      {
        "name": "گله دار",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "73c91e06-e8b0-4208-a546-43a2cff49668"
      },
      {
        "name": "لامرد",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "a2e5eebe-22f7-442f-8212-4380ab886525"
      },
      {
        "name": "لپویی",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "bbc3f0ae-f533-4b45-8a71-043ffc297cea"
      },
      {
        "name": "لطیفی",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "9651b5d8-bbc3-4cba-84af-99ecf9c74050"
      },
      {
        "name": "مادرسلیمان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "d43429ac-b899-44a6-840b-bdce544f6491"
      },
      {
        "name": "میانشهر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "aeaef142-de97-4228-a8f7-35a2ba6ceaa2"
      },
      {
        "name": "مبارک آباددیز",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "5f7f1b0c-8abd-4f6f-b6fc-9c90fb5f2afb"
      },
      {
        "name": "مزایجان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "ad108b90-8add-4634-a3e9-72ca2e3ddad8"
      },
      {
        "name": "مشکان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "a0b0a1ff-a906-4205-aa23-acdeb16eb003"
      },
      {
        "name": "مصیری",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "3fa75c8b-c9c3-4f71-afdf-9bb5e6e1a86e"
      },
      {
        "name": "میمند",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "90ed8759-8709-41ac-acf3-45515fa887fb"
      },
      {
        "name": "نی ریز",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "1f27a81e-7d6a-4a51-b9a6-932540174a26"
      },
      {
        "name": "نوبندگان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "051f330a-8117-459f-8e3e-710fffc7cbfa"
      },
      {
        "name": "نوجین",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "1a0bb76f-60b2-497a-8f92-06dc96cd5cae"
      },
      {
        "name": "نودان",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "4033d92d-7877-4d97-a8a0-f90e8f5f8e81"
      },
      {
        "name": "نورآباد",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "5c5c631b-cd00-4c08-bf3c-42465e95f6ce"
      },
      {
        "name": "هماشهر",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "d2df960b-087e-4dbd-a871-8495b37cb9c1"
      },
      {
        "name": "وراوی",
        "stateId": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf",
        "id": "7bdebb84-b00f-4746-97a2-50841ae2c801"
      }
    ],
    "name": "فارس",
    "id": "7a0d4fd1-3072-489e-9609-c1a44f5aa4cf"
  },
  {
    "listOfCities": [
      {
        "name": "آبیک",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "ad3a7b63-39be-4c30-9a05-973b46c5b2e4"
      },
      {
        "name": "آبگرم",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "9070923a-f891-4fa2-83ce-60cad8155380"
      },
      {
        "name": "ارداق",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "65f06f67-2633-4292-a907-1039b1beda5c"
      },
      {
        "name": "اسفرورین",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "ee648ce3-c8be-4ac0-b938-e048da5f34fd"
      },
      {
        "name": "اقبالیه",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "dd709abd-531d-4622-b53d-9e475f7ffe5d"
      },
      {
        "name": "الوند",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "207dfe87-d3ef-47b0-99a0-9167f0a69b19"
      },
      {
        "name": "آوج",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "7fbddd76-5982-4711-9311-e38e603d966a"
      },
      {
        "name": "بیدستان",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "e987a494-0b5a-454d-971e-89bc1b723965"
      },
      {
        "name": "بویین زهرا",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "60f417ba-b58c-41b6-b527-5d5bf0adb998"
      },
      {
        "name": "تاکستان",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "386b33f8-3b4b-41fa-b2c5-5dcefb74aca3"
      },
      {
        "name": "خاکعلی",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "6509c25c-735e-4307-91d5-b70697a1e392"
      },
      {
        "name": "خرمدشت",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "1eb912f3-bfc7-4c2b-a19b-35a15854e73f"
      },
      {
        "name": "دانسفهان",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "51ba40b7-3eb4-4d16-ac9e-acc34433887d"
      },
      {
        "name": "رازمیان",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "1c244348-fb05-449a-ad71-aeae970b9ad7"
      },
      {
        "name": "سیردان",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "0063a982-4f2e-4dc7-8a69-12a00fa2aeac"
      },
      {
        "name": "سگزآباد",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "3d6c8b10-aff9-45fa-867e-f4b7cd707361"
      },
      {
        "name": "شال",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "0a34bdb6-5208-4664-87a5-6fc7d8deebe9"
      },
      {
        "name": "شریفیه",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "cda88fe7-6918-4ad8-a8d2-a940755c3e76"
      },
      {
        "name": "ضیادآباد",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "92b6bf72-dd89-42fa-a542-0901eaccef9d"
      },
      {
        "name": "قزوین",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "3322d193-c9ec-4e08-8510-b738e16b5b85"
      },
      {
        "name": "کوهین",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "91443e62-da44-4b30-9690-08bf2c3ab41c"
      },
      {
        "name": "محمدیه",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "03f45fd5-ab0d-4299-aa3e-57b9f3bdc5a5"
      },
      {
        "name": "محمودآبادنمونه",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "782cca6a-61ac-4314-a9c6-7bc98e3c0d9b"
      },
      {
        "name": "معلم کلایه",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "7f963009-3ef1-4b67-9877-5628ae4ffc90"
      },
      {
        "name": "نرجه",
        "stateId": "5e3dd54b-1750-492f-9627-54ca3fe48ce6",
        "id": "9654c971-161f-4fc7-9f99-df5f71d17320"
      }
    ],
    "name": "قزوین",
    "id": "5e3dd54b-1750-492f-9627-54ca3fe48ce6"
  },
  {
    "listOfCities": [
      {
        "name": "جعفریه",
        "stateId": "9aebb8b1-2c9c-4a0d-bf9d-08fcf9beeeb4",
        "id": "130468e5-5023-4b9c-93d3-70e77a7c2fa6"
      },
      {
        "name": "دستجرد",
        "stateId": "9aebb8b1-2c9c-4a0d-bf9d-08fcf9beeeb4",
        "id": "f2ee88f2-689b-431b-a1a1-5a91b84fbf69"
      },
      {
        "name": "سلفچگان",
        "stateId": "9aebb8b1-2c9c-4a0d-bf9d-08fcf9beeeb4",
        "id": "737b6ccd-4bcd-4504-af3a-37fa7d473aee"
      },
      {
        "name": "قم",
        "stateId": "9aebb8b1-2c9c-4a0d-bf9d-08fcf9beeeb4",
        "id": "dad5c0d2-3101-438a-ae0a-781f8b324d6a"
      },
      {
        "name": "قنوات",
        "stateId": "9aebb8b1-2c9c-4a0d-bf9d-08fcf9beeeb4",
        "id": "903623fb-297b-4ed0-84fb-2c0032ca7d90"
      },
      {
        "name": "کهک",
        "stateId": "9aebb8b1-2c9c-4a0d-bf9d-08fcf9beeeb4",
        "id": "b01a8079-4b3b-4633-8bc0-27e2f3761565"
      }
    ],
    "name": "قم",
    "id": "9aebb8b1-2c9c-4a0d-bf9d-08fcf9beeeb4"
  },
  {
    "listOfCities": [
      {
        "name": "آرمرده",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "789537c4-e602-4351-8156-75a902e8b6a6"
      },
      {
        "name": "یاسوکند",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "09f8698e-b5f6-493d-a299-ddef83ce611c"
      },
      {
        "name": "اورامان تخت",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "2e7c447c-da3d-4d06-9023-eddb2e1275cf"
      },
      {
        "name": "بابارشانی",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "9e512449-7aab-40b0-99d2-19fd46bbeba3"
      },
      {
        "name": "بانه",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "febad6e4-d4c7-44b5-82d5-77d4a56f3ca4"
      },
      {
        "name": "بیجار",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "606bc05f-5314-4a58-b72b-247ce781f8c3"
      },
      {
        "name": "برده رشه",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "a5b647fb-37a6-4073-ac83-e4a2dfd31ad1"
      },
      {
        "name": "بلبان آباد",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "73528aae-7fcf-49a4-b20f-005aedb1eacd"
      },
      {
        "name": "بویین سفلی",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "04dbaaec-2119-42dc-94f0-517a8effb525"
      },
      {
        "name": "پیرتاج",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "eb8a8fce-35ca-4662-af9e-6746ce7881b7"
      },
      {
        "name": "توپ آغاج",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "563a607c-d65d-4f53-a3a0-e56eb9e0c70e"
      },
      {
        "name": "چناره",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "e7837246-8c2f-4243-87fa-35b781e88376"
      },
      {
        "name": "دزج",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "016d8ce3-8b09-45f4-b3a0-1e2e8b6a8a40"
      },
      {
        "name": "دلبران",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "530791a8-87f4-4932-a880-483c276bf9dc"
      },
      {
        "name": "دهگلان",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "a0ee8b85-f335-4f7f-928e-f2c45de0f8f8"
      },
      {
        "name": "دیواندره",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "924cff24-b0ea-4d8e-90f3-2635c0026c06"
      },
      {
        "name": "زرینه",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "8f1ca405-969d-460e-ad84-3b29ebcb90e8"
      },
      {
        "name": "سریش آباد",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "131859ee-718c-4ba9-918e-bd5f80bcf53e"
      },
      {
        "name": "سروآباد",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "4c4cdf6d-e3c4-4d50-b63d-0ad21b3fcc7f"
      },
      {
        "name": "سقز",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "ac64c4c0-a99c-4403-8e3d-b70bd03a5f68"
      },
      {
        "name": "سنندج",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "0a515adb-043e-4918-b924-73d00cd92617"
      },
      {
        "name": "شویشه",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "2fb39890-92dc-4511-9e79-b303a1f18598"
      },
      {
        "name": "صاحب",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "a6772de0-9410-485a-a573-fc07bcde21f6"
      },
      {
        "name": "قروه",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "ab309795-82bd-4f56-932a-9c17adeb97d8"
      },
      {
        "name": "کامیاران",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "3d8ba622-2f5f-4918-9ca9-e23615702d61"
      },
      {
        "name": "کانی دینار",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "0224beb8-8951-4446-a28b-7f6f7ab4a0a9"
      },
      {
        "name": "کانی سور",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "bb679d58-a085-47f5-a71f-12d86cfe0dc1"
      },
      {
        "name": "مریوان",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "3dafbda1-5e42-493a-903a-ae828d8a4e00"
      },
      {
        "name": "موچش",
        "stateId": "bd1145d7-9514-4ed5-a954-217c11e54ea3",
        "id": "e278d615-dfea-4038-8292-6fd852d9bdaa"
      }
    ],
    "name": "کردستان",
    "id": "bd1145d7-9514-4ed5-a954-217c11e54ea3"
  },
  {
    "listOfCities": [
      {
        "name": "اختیارآباد",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "92278f92-9dd9-4788-804a-35b7e61dc8d8"
      },
      {
        "name": "ارزوییه",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "624969e6-c5ac-4365-bd7c-8d8dab967105"
      },
      {
        "name": "امین شهر",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "19893d5f-e5e6-48d3-9277-db60b0513709"
      },
      {
        "name": "انار",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "3a068b89-0634-40a5-b681-a679b162cf22"
      },
      {
        "name": "اندوهجرد",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "aa045bd2-f2a8-4838-979d-9a8020a287a6"
      },
      {
        "name": "باغین",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "8b484b1d-8490-4fb7-b4ca-db0ba0807a82"
      },
      {
        "name": "بافت",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "87efaac4-28e2-4fc5-9dac-fb9deba63859"
      },
      {
        "name": "بردسیر",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "7199fae1-79f1-4f4b-86b7-b58b7c64f368"
      },
      {
        "name": "بروات",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "e05b4f4c-3cf8-4120-b096-5e81fb6f190d"
      },
      {
        "name": "بزنجان",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "aca5d54d-7fca-44bb-a294-95501e36b259"
      },
      {
        "name": "بلورد",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "b793c2a5-7f68-4344-8353-0d9611bbf7ef"
      },
      {
        "name": "بهرمان",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "cb0ed636-6fcd-4545-9c03-82648a9d5fa6"
      },
      {
        "name": "پاریز",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "e511e619-6ab9-4de4-80bf-483b1d337b4e"
      },
      {
        "name": "جبالبارز",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "5e3b020c-c44f-4884-952d-7ad778678eed"
      },
      {
        "name": "جیرفت",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "2e13ead3-dea9-4cba-8934-a4f3f15abeb3"
      },
      {
        "name": "جیرفت",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "afa5492e-1d82-4604-bbc2-770ac9864a20"
      },
      {
        "name": "جوپار",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "4af2a85a-2ecb-4753-9283-6f6fcb6151a2"
      },
      {
        "name": "جوزم",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "df2feb79-c1aa-4928-a31a-40a87f636023"
      },
      {
        "name": "چترود",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "c01d0e10-ebf1-4bb9-a249-30c46280c917"
      },
      {
        "name": "خاتون اباد",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "939697b5-7445-41ce-a005-1c2d12d3e2e7"
      },
      {
        "name": "خانوک",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "751eb192-80cf-4e33-abc1-2e26d644d150"
      },
      {
        "name": "خواجو شهر",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "96c434ff-ba56-4b31-b6fc-e02b10db2340"
      },
      {
        "name": "خورسند",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "43ca92a7-3d00-49b3-9321-9466747e4568"
      },
      {
        "name": "درب بهشت",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "5f024265-d3b1-432f-852a-a01c7a48499e"
      },
      {
        "name": "دشتکار",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "e1ffe629-41ed-472f-aff0-ac21da94d9e6"
      },
      {
        "name": "دهج",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "98871673-5659-49dd-af60-71adb2b03926"
      },
      {
        "name": "دوساری",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "1ec011c5-cfdf-4f6b-8fbd-1502c1ae376f"
      },
      {
        "name": "رابر",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "d8c6a20f-5df5-4dc0-bb06-84f1ace18acf"
      },
      {
        "name": "راین",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "2681958c-5dea-446b-811c-c11b3afd281f"
      },
      {
        "name": "راور",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "532402d6-aa65-4770-81e7-ad70e49c4835"
      },
      {
        "name": "ریحان",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "0e3dc11d-96e1-45b5-90bc-7227b829e944"
      },
      {
        "name": "رفسنجان",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "1b66afb9-0702-44b1-ba95-2a36b5ea4548"
      },
      {
        "name": "رودبار",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "ee3b20d6-f250-4f53-9df9-b97487c97c17"
      },
      {
        "name": "زید آباد",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "d4522e0d-c1ce-49d2-8692-af1bbe6f7e4c"
      },
      {
        "name": "یزدان شهر",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "5b2e36a1-ab23-4c17-b225-100477993c1d"
      },
      {
        "name": "زرند",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "512f2ced-6523-4adf-9e32-4b33b6b7f269"
      },
      {
        "name": "زنگی آباد",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "8d9b0c6c-f73d-4aa7-b7bc-40c564cb19a4"
      },
      {
        "name": "زهکلوت",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "36c66c5e-6544-423c-99e4-224a1e69085f"
      },
      {
        "name": "سیرجان",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "52ad03b3-a052-4077-9bae-7cba98c1ad43"
      },
      {
        "name": "شهداد",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "e11a7b68-1809-4c9b-8d7c-02df99c21f75"
      },
      {
        "name": "شهربابک",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "38685235-56ca-49e8-a03c-4595cd5afc81"
      },
      {
        "name": "شهربابک",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "e71d1e66-791f-4da3-8544-021d82ad0416"
      },
      {
        "name": "صفاییه",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "a6b229cf-7d9e-4de3-8eee-7c9e63306098"
      },
      {
        "name": "عنبرآباد",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "b134965b-add5-45e3-93ac-c074dcf8ae56"
      },
      {
        "name": "فاریاب",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "f25ebc83-c3d4-43c1-8885-dfa15e193538"
      },
      {
        "name": "فهرج",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "ec385e85-4803-480d-a80f-f61dbb39723c"
      },
      {
        "name": "قلعه گنج",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "2b4d58ba-c2b2-4270-8986-38c86a550276"
      },
      {
        "name": "کاظم آباد",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "8eecde3b-87ed-46db-8b77-967e12dc8c90"
      },
      {
        "name": "کیانشهر",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "1282c3ee-3351-435a-b58d-a08e8ff66e44"
      },
      {
        "name": "کرمان",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "69bd61e8-cd8b-4a1b-93a7-a4c2ea01a057"
      },
      {
        "name": "کشکوییه",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "d6b099e3-ade2-4020-ac60-091ab0ecc56a"
      },
      {
        "name": "کهنوج",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "f60f0f46-dfdc-49cc-bba6-359c15de27fb"
      },
      {
        "name": "کوهبنان",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "84f7d1fc-963a-426d-9b37-6227ceef0861"
      },
      {
        "name": "گلباف",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "4625fcca-d018-4c22-a568-e9461f9123f1"
      },
      {
        "name": "گلزار",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "32ef4780-5a55-4aa9-a3fa-4ef86952b5a4"
      },
      {
        "name": "گنبکی",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "809f1b42-a50a-4a4c-9bb3-0af773870979"
      },
      {
        "name": "لاله زار",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "83afa716-9986-4566-9f38-51286ac7c510"
      },
      {
        "name": "ماهان",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "0476aead-1ed6-4142-a1d0-a7dd85c2d6e4"
      },
      {
        "name": "محی آباد",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "84a39311-bccf-4863-9916-b530a86ce143"
      },
      {
        "name": "مردهک",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "08ab57e6-be2e-40fe-87c3-0bb86b868430"
      },
      {
        "name": "مس سرچشمه",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "9896648f-96c7-4a47-85f5-f62fc0a0acfc"
      },
      {
        "name": "منوجان",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "e700cc3d-dcb0-4520-94c5-ad9e588f0122"
      },
      {
        "name": "نجف شهر",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "3ca1cd3a-586c-4ff0-9c13-9e7b571f140f"
      },
      {
        "name": "نرماشیر",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "0f2e7b6b-71a6-4c24-a2da-521a687f786f"
      },
      {
        "name": "نظام شهر",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "9c8581b0-9821-45e9-8b53-f5a44c16859d"
      },
      {
        "name": "نگار",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "b6627e10-467b-4f32-b16d-cdeb282b831f"
      },
      {
        "name": "نودژ",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "920c1a1d-8d3b-4f5a-9036-28a93ec1defa"
      },
      {
        "name": "هجدک",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "42b1d211-d0a8-416b-9f0a-df65c49f025f"
      },
      {
        "name": "هنزا",
        "stateId": "590075cd-b968-4ee7-b937-7f22b101d3e4",
        "id": "51054323-db7b-4a6b-b4fb-f8c3626fd47d"
      }
    ],
    "name": "کرمان",
    "id": "590075cd-b968-4ee7-b937-7f22b101d3e4"
  },
  {
    "listOfCities": [
      {
        "name": "ازگله",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "f71ab4ae-093f-4115-a334-8c86aee8578d"
      },
      {
        "name": "اسلام آبادغرب",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "cb4bf728-5750-41f5-a59e-d65d2b4a5895"
      },
      {
        "name": "باینگان",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "add85172-5343-43dc-95f3-46491651ce81"
      },
      {
        "name": "بانوره",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "968b7573-3447-440f-8117-34f1fc3e4a47"
      },
      {
        "name": "بیستون",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "54dc38f5-568e-4e58-b4ae-9d7dc7ba60e9"
      },
      {
        "name": "پاوه",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "06fe1de4-18ad-42ed-8d43-6e1064c74de9"
      },
      {
        "name": "پاوه",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "40205fbb-b6c3-4124-9651-65015cb8d61e"
      },
      {
        "name": "تازه آباد",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "4f170153-ac79-478a-b83f-b11bd569392b"
      },
      {
        "name": "جوانرود",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "d417a473-e709-47fe-8093-c60156bf28da"
      },
      {
        "name": "حمیل",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "9ff78d2e-7b22-4c6f-b80e-ded15890f1a8"
      },
      {
        "name": "رباط",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "263cdf67-6a2d-4ab2-aacf-ebe747b2ab62"
      },
      {
        "name": "ریجاب",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "8dc0603c-fab8-428d-bdfd-daee9adce6a3"
      },
      {
        "name": "سرپل ذهاب",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "f1277413-6e8c-40a1-8720-603106540e7a"
      },
      {
        "name": "سرمست",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "bea15968-52b8-42c4-b1b7-7b0348925691"
      },
      {
        "name": "سطر",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "54890b8b-c168-442f-a50f-8f82a70d30af"
      },
      {
        "name": "سطر",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "eea1b6b5-66b5-4e70-87d0-bb90eb8eb51d"
      },
      {
        "name": "سنقر",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "af827c91-067b-40fa-9778-2a0395f85d12"
      },
      {
        "name": "سومار",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "56544c80-4336-4f7b-98a3-bbfbd73640ed"
      },
      {
        "name": "شاهو",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "99434a1e-43f2-447e-9217-3337bb539a0a"
      },
      {
        "name": "صحنه",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "43edb31a-b381-4b1e-a2d1-40757b5479c0"
      },
      {
        "name": "قصرشیرین",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "eb00aab6-82a4-4fe1-ad3a-c1caa7554480"
      },
      {
        "name": "کرمانشاه",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "3594946f-16f7-4b46-90a0-447f317cfd36"
      },
      {
        "name": "کرمانشاه",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "e34a31ef-5117-4e9b-ad5a-fbbffb9ea71e"
      },
      {
        "name": "کرند",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "de4e2d6e-b910-46d6-be69-8d676ebbbbc3"
      },
      {
        "name": "کنگاور",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "3f6b8ada-fb4c-47f0-9374-e7556fd67e38"
      },
      {
        "name": "کوزران",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "716b8d02-f475-4e7b-860b-ef9163b39574"
      },
      {
        "name": "گیلانغرب",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "f5caee35-6cde-438b-8340-b469b299d2ca"
      },
      {
        "name": "گهواره",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "2d3bb7a9-343e-4edd-a15d-56783fac1854"
      },
      {
        "name": "گودین",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "5a086fd2-1fc5-4e31-9b63-c7772704bf70"
      },
      {
        "name": "میان راهان",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "37fd3f22-6f79-40c1-968e-23a402e0ae78"
      },
      {
        "name": "نودشه",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "f12b628a-9b00-4bf8-adb1-98c88ebacff6"
      },
      {
        "name": "نوسود",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "e6ca944b-a130-4cc5-9336-2f694c799153"
      },
      {
        "name": "هرسین",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "59062796-2ce9-4ea1-a2b0-837b96cc3fcf"
      },
      {
        "name": "هلشی",
        "stateId": "777caec1-8de8-49ff-8702-84d4fcca2d90",
        "id": "61f5a869-f8a0-4c70-9f7d-cef6e0a6a1e3"
      }
    ],
    "name": "کرمانشاه",
    "id": "777caec1-8de8-49ff-8702-84d4fcca2d90"
  },
  {
    "listOfCities": [
      {
        "name": "یاسوج",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "9e72cc1e-3d71-4fd2-a729-ba5def830965"
      },
      {
        "name": "یاسوج",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "f293a5f0-6c39-40fa-843e-3ad086a7415e"
      },
      {
        "name": "باشت",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "1f2e4cb7-3dc6-4de3-9f41-85271c111444"
      },
      {
        "name": "پاتاوه",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "66ca7919-2713-475c-9754-185112968708"
      },
      {
        "name": "چیتاب",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "762df5e1-76ee-4756-9f09-da2d7c4ee2d7"
      },
      {
        "name": "چرام",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "d42e1a41-0d41-41ec-81a4-5a7d1616814e"
      },
      {
        "name": "دیشموک",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "7f97e7e6-7b52-49d0-9793-4b2d56d1b397"
      },
      {
        "name": "دهدشت",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "d98ce53a-6834-439a-a58b-08b7febb6506"
      },
      {
        "name": "دوگنبدان",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "8b384077-5691-40a6-96c1-c298ab8c0ff0"
      },
      {
        "name": "سرفاریاب",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "411f6bfd-4696-4e8f-83ec-628e12b50425"
      },
      {
        "name": "سی سخت",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "abbe7308-758c-4250-8991-43c71be35790"
      },
      {
        "name": "سوق",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "f95009be-8d00-4ec8-9179-2d15182ac727"
      },
      {
        "name": "قلعه رییسی",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "e39b2623-77e2-4929-9090-7a9141743d38"
      },
      {
        "name": "گراب سفلی",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "381f1976-978a-4ceb-abc5-aa13c426921f"
      },
      {
        "name": "لیکک",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "2a4af8dd-aa1b-4dad-b810-6655191576ec"
      },
      {
        "name": "لنده",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "033f17d8-7805-4d5e-b73d-cbe2a1e1da8e"
      },
      {
        "name": "مادوان",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "1ce41ec0-2554-485e-af34-d7f315c881d3"
      },
      {
        "name": "مارگون",
        "stateId": "caf5086c-8738-4ce6-8a13-433e9e38d999",
        "id": "d7b6057f-057b-4252-bc30-247ee58dedc8"
      }
    ],
    "name": "کهگیلویه وبویراحمد\t",
    "id": "caf5086c-8738-4ce6-8a13-433e9e38d999"
  },
  {
    "listOfCities": [
      {
        "name": "احمدسرگوراب",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "ed7228d2-0b03-4850-9dfb-cff5f268a020"
      },
      {
        "name": "اسالم",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "9b1b5e43-8962-4b5a-b82c-711d35612690"
      },
      {
        "name": "آستارا",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "120662e0-abc3-49f1-bd6d-2309fc86b9db"
      },
      {
        "name": "آستانه اشرفیه",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "18e9403e-4f05-4947-9076-ec8240823132"
      },
      {
        "name": "اطاقور",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "5363738b-19bf-41eb-bca5-dda070cfe1f2"
      },
      {
        "name": "املش",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "bd285242-0b83-4fee-b342-eb61cac57148"
      },
      {
        "name": "بازار جمعه",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "a7b78dbd-8bb6-4908-b928-e459b68e3047"
      },
      {
        "name": "بره سر",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "9f2061cd-5f1a-45d3-9987-6a5b86236ada"
      },
      {
        "name": "بندرانزلی",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "52d4f614-3272-457d-8550-ef37000c6928"
      },
      {
        "name": "بندرانزلی",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "da5b00aa-b51e-4ff9-a5e8-384eb16cfc36"
      },
      {
        "name": "پره سر",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "bb2fce79-8ab0-4c3c-8bb5-fb96e5decc54"
      },
      {
        "name": "توتکابن",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "7b531d6e-4132-4077-ab52-f5902d588bda"
      },
      {
        "name": "جیرنده",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "3678d762-cd67-4758-8e74-04f89cac3576"
      },
      {
        "name": "چابکسر",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "cb5ea0e2-66a1-4e8b-bf9a-bc4b0b10861e"
      },
      {
        "name": "چاف و چمخاله",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "0df72506-5c39-4845-b96e-79c2bca68112"
      },
      {
        "name": "چوبر",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "0555af7f-0d5c-4aa1-bf8b-6568dcade392"
      },
      {
        "name": "حویق",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "d877fb92-b10d-449c-a39c-44c05c4301fc"
      },
      {
        "name": "خشکبیجار",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "d7136d7c-7233-4f03-8f7d-9ce2b4ad2c99"
      },
      {
        "name": "خمام",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "30bf5cf0-b464-4a3a-884e-c915fa3c1030"
      },
      {
        "name": "دیلمان",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "d4c51208-4aca-4b45-a063-bd8abfe3b202"
      },
      {
        "name": "رانکوه",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "62db40cd-8756-4a9e-b251-1e1bc2c56b37"
      },
      {
        "name": "رحیم آباد",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "395c3dff-7fd0-4151-82dc-75313f5f397b"
      },
      {
        "name": "رشت",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "7f3a570c-4d48-4e92-a621-f18c35adc58b"
      },
      {
        "name": "رشت",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "c1ab6c83-35c7-4fa9-9f8a-ba6054330b4a"
      },
      {
        "name": "رضوانشهر",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "9aea51e2-6a52-4141-90a3-a2f82e194e4f"
      },
      {
        "name": "رودبار",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "2e527d6b-db6d-4fbd-8e87-3a6b99d91937"
      },
      {
        "name": "رودبنه",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "771a7ae1-7886-40a7-8716-a4f7edea7770"
      },
      {
        "name": "رودسر",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "1f075e48-1bc7-4cb6-a148-3676efc51885"
      },
      {
        "name": "سیاهکل",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "ed82ae1f-5f0b-4eb6-8fa3-9ee8c321a0c7"
      },
      {
        "name": "سنگر",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "886dacf3-a700-4ecc-a767-f761f35e9ab8"
      },
      {
        "name": "شفت",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "27605922-2356-4df7-9a63-9c9129b8a376"
      },
      {
        "name": "شلمان",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "b92c0567-3cc0-4ded-a6a9-bf4d645e3fcc"
      },
      {
        "name": "صومعه سرا",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "f41299b9-8736-4d69-b17f-72398e463487"
      },
      {
        "name": "فومن",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "fa03eb67-229c-4ee5-ab20-950bab3b8dae"
      },
      {
        "name": "کیاشهر",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "58a34e53-f1d9-44a2-8f33-2f9578fc1811"
      },
      {
        "name": "کلاچای",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "3acf71ef-89a7-4b76-ad3c-becd83bdbeff"
      },
      {
        "name": "کوچصفهان",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "6ec65b4e-a32a-4d11-82d6-fe73c44edef2"
      },
      {
        "name": "کومله",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "7222a664-c052-4fb2-aa28-8a7663b9a7a2"
      },
      {
        "name": "گوراب زرمیخ",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "7760b030-e715-48db-b9dc-1bd5a59cb0fc"
      },
      {
        "name": "لاهیجان",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "f17f073d-1fd5-4c48-8409-00600eb9927b"
      },
      {
        "name": "لیسار",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "b5f8e284-db46-431a-9d55-bd90186fac56"
      },
      {
        "name": "لشت نشاء",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "4317078a-8272-4f9a-994c-4e04321e70c6"
      },
      {
        "name": "لنگرود",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "6213f3b8-653e-4e21-9e5b-5d885c18073d"
      },
      {
        "name": "لوشان",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "f4c7281a-6a36-462a-880a-5d4742dfb45e"
      },
      {
        "name": "لولمان",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "d7485f6d-6f4f-4b28-9178-37752f490f0b"
      },
      {
        "name": "لوندویل",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "25acd732-ae2b-4a77-accb-b0ee963514ca"
      },
      {
        "name": "ماسال",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "ad8cf734-b832-4a8c-ad20-5094d8fc0e37"
      },
      {
        "name": "ماسوله",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "363e89be-3ea7-4f0c-a018-2beea470654b"
      },
      {
        "name": "ماکلوان",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "2acf53b3-19e0-4cfb-aff1-bcabedbd9fa5"
      },
      {
        "name": "مرجقل",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "3238ec2e-4ac2-4c2d-993b-fe65a46a3f4b"
      },
      {
        "name": "منجیل",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "938b1a48-6e1e-4aa8-8996-46aaaa61df32"
      },
      {
        "name": "هشتپر (تالش)",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "6c606443-6ee3-4fcd-8409-07aa216d14cd"
      },
      {
        "name": "واجارگاه",
        "stateId": "b41b196e-67eb-4338-b24c-be2cf16db0f0",
        "id": "99121384-36a3-43f9-82c0-9780e6d7a1f3"
      }
    ],
    "name": "گیلان",
    "id": "b41b196e-67eb-4338-b24c-be2cf16db0f0"
  },
  {
    "listOfCities": [
      {
        "name": "آزادشهر",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "c477c501-9ea5-4a3f-af1f-d81d2468a848"
      },
      {
        "name": "آق قلا",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "839e3839-aaa6-4990-b216-64f9cb0ed286"
      },
      {
        "name": "انبارآلوم",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "00a52832-0f64-4655-8faa-ae9075997dc1"
      },
      {
        "name": "اینچه برون",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "e885deba-c6e7-4399-b83f-988c9ab5a002"
      },
      {
        "name": "بندرترکمن",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "2eb8006c-41c6-4d4a-a17b-1666e3d9fcd1"
      },
      {
        "name": "بندرترکمن",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "feb6dd50-6a1e-4c69-abd4-756a35ca5ce4"
      },
      {
        "name": "بندرگز",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "17f6f3ea-3a6a-4bc3-9b25-c0ac8c38833b"
      },
      {
        "name": "تاتارعلیا",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "71070bca-3c4d-4d64-b165-423569c41032"
      },
      {
        "name": "جلین",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "157b0383-7079-4f59-adbd-94e46a1932f0"
      },
      {
        "name": "خان ببین",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "b19556fe-c2f9-45aa-8498-d97c01bfa893"
      },
      {
        "name": "دلند",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "3a7fed9a-41c0-4b61-becf-05fd83fd012e"
      },
      {
        "name": "رامیان",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "6e62c9d0-0cb3-4bf7-a273-af6a36c4f1d6"
      },
      {
        "name": "سرخنکلاته",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "81640d57-dd13-4e78-b1ab-14e9fa7cfe52"
      },
      {
        "name": "سیمین شهر",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "c582aa8a-cafb-4bc4-9e80-549da54811d0"
      },
      {
        "name": "سنگدوین",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "9e50c4f8-ef63-46f3-b270-92f183e243b8"
      },
      {
        "name": "علی اباد",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "198631d7-eb90-4a22-b33b-c0921d1a29e7"
      },
      {
        "name": "فاضل آباد",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "7f2bb580-d976-4b72-9a27-de77784951b4"
      },
      {
        "name": "فراغی",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "1741de27-0ff3-4038-b9ca-ae55b88d9975"
      },
      {
        "name": "کردکوی",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "83c04d08-521e-424c-8faf-83ab8026e926"
      },
      {
        "name": "کلاله",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "0b856a00-3b67-4be9-8c24-2f2d5776f095"
      },
      {
        "name": "گالیکش",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "2168beec-5b14-4fa3-8038-914ae4780337"
      },
      {
        "name": "گرگان",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "909099c2-9599-4721-9d34-4dd6c55e96fa"
      },
      {
        "name": "گرگان",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "ba37e0d0-2cb5-4fa8-84cc-0143544b2d5b"
      },
      {
        "name": "گمیش تپه",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "76ab6ce6-39b0-4141-a77b-d22d0d256a73"
      },
      {
        "name": "گنبدکاووس",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "4a9d9a07-3a29-4ae5-b9f0-33c2d60335db"
      },
      {
        "name": "مراوه",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "f5403f6d-b7bc-4fef-8dba-3d9e69419473"
      },
      {
        "name": "مزرعه",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "2293dbd0-9c2c-4f7b-8bd0-466a40d58a29"
      },
      {
        "name": "مینودشت",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "e5a73b06-a589-484f-b92c-d5785659cda1"
      },
      {
        "name": "نگین شهر",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "117bd13e-47d8-47ba-b52a-31973204571a"
      },
      {
        "name": "نوده خاندوز",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "91bf3095-f0fa-481d-8f66-de03738bc642"
      },
      {
        "name": "نوکنده",
        "stateId": "56f6138e-d8cc-4273-97f8-a48d032a613b",
        "id": "4cac2851-5f7b-4c38-871a-9cabc6d7c355"
      }
    ],
    "name": "گلستان",
    "id": "56f6138e-d8cc-4273-97f8-a48d032a613b"
  },
  {
    "listOfCities": [
      {
        "name": "ازنا",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "2e88bb4a-703f-46b3-8340-82d00e3d611f"
      },
      {
        "name": "اشترینان",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "20a0004a-30a3-4dc5-a21a-eef7002af48d"
      },
      {
        "name": "الشتر",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "320949e1-602f-4a1c-be40-5b14fcca0bd9"
      },
      {
        "name": "الیگودرز",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "910b1c8f-d647-4945-84dd-073490310ef0"
      },
      {
        "name": "بروجرد",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "5939ba54-1908-4ee9-aa71-dd908d4ae3f8"
      },
      {
        "name": "پلدختر",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "00449950-51ee-47e4-a574-0e85c7802d7c"
      },
      {
        "name": "چالانچولان",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "68d4c4ee-3f72-48dc-81e4-a4dd3a478f47"
      },
      {
        "name": "چقابل",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "fbfaa7f4-4db6-41f2-b1ba-06f33db40393"
      },
      {
        "name": "خرم آباد",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "af1163f1-b9a5-40cf-808b-8e74820403bf"
      },
      {
        "name": "خرم آباد",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "f1afb0cc-01a1-4fc6-a2bb-7f5e768f62c6"
      },
      {
        "name": "دابودشت",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "19e2fa41-2fff-47ff-8ce0-88dfc0db4ac9"
      },
      {
        "name": "درب گنبد",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "217eff46-89f7-4839-882a-559388a40ffd"
      },
      {
        "name": "دورود",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "4a5e91e8-4192-4dc9-b4fc-de91cc1eba6d"
      },
      {
        "name": "زاغه",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "3fbd1b40-de04-4786-b577-572e5879b06a"
      },
      {
        "name": "زمعمولان",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "97d6cd19-71e7-4a5c-b935-1a7860971c0b"
      },
      {
        "name": "سپیددشت",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "c45eafac-889e-4985-960b-b7294740cc6b"
      },
      {
        "name": "سراب دوره",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "de91203e-1b31-45ad-9555-12d3f32b01d0"
      },
      {
        "name": "شول آباد",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "98742a7b-1398-4ca2-97e7-2fb869650f26"
      },
      {
        "name": "فیروزآباد",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "1ba82faf-e74f-4d2a-87c2-2784a0edcb21"
      },
      {
        "name": "کوهدشت",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "89d62cfb-53bd-4460-83eb-1d26ae77007a"
      },
      {
        "name": "کوهنانی",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "577285f2-6e4f-49cc-a21e-6bdb3f2140c9"
      },
      {
        "name": "کوهنانی",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "da1b566e-5714-4fbc-abf0-3fe21edd9550"
      },
      {
        "name": "گراب",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "fb2ccf8a-27b9-4206-8c7c-7df306d9829e"
      },
      {
        "name": "مومن آباد",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "99b475aa-9fc7-462a-a244-19e9362025c3"
      },
      {
        "name": "هفت چشمه",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "bfd5bb56-0532-4272-8bb5-594f1a587b61"
      },
      {
        "name": "ویسیان",
        "stateId": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895",
        "id": "18672142-d40e-4201-a6cf-16dcc0c3642f"
      }
    ],
    "name": "لرستان",
    "id": "9d329203-0e70-4cc7-b0e5-f0d77ab0f895"
  },
  {
    "listOfCities": [
      {
        "name": "ارطه",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "b59bd056-15a8-4425-a46c-603afe95c2b1"
      },
      {
        "name": "ایزدشهر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "f022c68c-49cb-45dc-88aa-19e80bd23872"
      },
      {
        "name": "آلاشت",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "12dcdaf4-f518-4f36-b617-debe0a0aade1"
      },
      {
        "name": "امامزاده عبدالله",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "a1e6eaa6-e74a-4289-b444-bbfe4c4b048e"
      },
      {
        "name": "امیرکلا",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "97c59a29-93f3-4eb6-abc4-9c9b9bac58a0"
      },
      {
        "name": "آمل",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "ce25ad96-0ff2-454f-8982-7fbab44572ce"
      },
      {
        "name": "آمل",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "da28e06f-aa23-47a5-a291-ab361bdf050a"
      },
      {
        "name": "بابل",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "b89bf844-6631-4544-8939-97881af7fe2a"
      },
      {
        "name": "بابل",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "fc447fcf-7406-472f-a20a-e6180206519a"
      },
      {
        "name": "بابلسر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "a5d77089-d841-4e0e-b01e-81bf3f214f8e"
      },
      {
        "name": "بابلسر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "d5f5fe95-083d-4cbc-8eac-df80d3deff25"
      },
      {
        "name": "بلده",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "c9236e1b-652f-40c8-9d24-09eac90afdfb"
      },
      {
        "name": "بهشهر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "469acf66-1315-4bcb-9d89-dbda3626fa8e"
      },
      {
        "name": "بهنمیر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "4b057779-6b98-48c7-8ccb-95d3b8688af3"
      },
      {
        "name": "پایین هولار",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "b80c4da1-c04d-46f7-affb-5d9bb4281f4e"
      },
      {
        "name": "پل سفید",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "28a5db39-3432-44e6-926a-e6d3624210a3"
      },
      {
        "name": "پول",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "a0c8a278-5cab-4cf5-bee7-b694df10881a"
      },
      {
        "name": "تنکابن",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "25ad05b1-cc85-4c5b-9461-b5654a03d038"
      },
      {
        "name": "جویبار",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "37a75235-351a-4420-849c-f9e03a089590"
      },
      {
        "name": "چالوس",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "5146696f-283c-48c8-8f6d-05ba883ce964"
      },
      {
        "name": "چالوس",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "bee3a418-1ac4-4269-8ab9-ab55bd9a6502"
      },
      {
        "name": "چمستان",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "dd9de2c1-ae2c-482c-b25f-364c33702fc0"
      },
      {
        "name": "خرم آباد",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "6037b6e2-c729-4f70-a70f-5eda1fdf39d2"
      },
      {
        "name": "خلیل شهر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "17f7f3aa-be46-4bd4-aa7d-808633343f79"
      },
      {
        "name": "خوش رودپی",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "fa8f68d9-ecf3-4a5e-befb-506248823114"
      },
      {
        "name": "رامسر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "64878705-fa65-4fab-930a-12d53787ae59"
      },
      {
        "name": "رامسر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "6c489f9e-f3ec-4de3-8ebc-ceea1103fe08"
      },
      {
        "name": "رستمکلا",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "c3905b28-799d-4ed9-b171-42e40bdae697"
      },
      {
        "name": "رینه",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "cef9e200-352b-41dc-a612-8f58fe09eb9f"
      },
      {
        "name": "رویان",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "5aa21106-eb16-40a4-afc1-20c50bbe89eb"
      },
      {
        "name": "زیرآب",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "b35ce906-57dc-4168-a240-15a78eddcf7d"
      },
      {
        "name": "زرگرمحله",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "db0232e8-ce17-4249-a2a9-9f0971d696c7"
      },
      {
        "name": "ساری",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "0caed4d8-2cfb-4ae2-b480-8662ea3f146c"
      },
      {
        "name": "ساری",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "d9ca2ef3-381f-4551-a22d-90e400bab303"
      },
      {
        "name": "سرخرود",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "a5a92a5d-cf86-4029-a98f-fbbd637526a8"
      },
      {
        "name": "سلمان شهر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "6aa75d37-7a37-47dd-9fde-dd175a0f0df5"
      },
      {
        "name": "سورک",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "e42b5852-d349-4eba-b04e-2058c26338d4"
      },
      {
        "name": "شیرگاه",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "1eb6e098-6e70-473b-ab15-9f83786f99f6"
      },
      {
        "name": "شیرود",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "edbd8ad0-1f74-4a57-b9e1-8379a9425646"
      },
      {
        "name": "عباس اباد",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "cbe0ac9a-136a-423e-8036-b3c3fb6fc293"
      },
      {
        "name": "فریدونکنار",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "b84fcbcc-bdb0-4731-8caf-81c5bd5e0a95"
      },
      {
        "name": "فریم",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "8d2b67c2-031c-4a07-843e-893d4d8a6cad"
      },
      {
        "name": "قایم شهر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "5d524b20-bfd0-4b50-96a0-035b84fee40a"
      },
      {
        "name": "کیاسر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "0983087d-71e0-4e2d-aef0-674b4107f68a"
      },
      {
        "name": "کیاکلا",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "e235f3e2-9140-456a-b374-288735cfdd1c"
      },
      {
        "name": "کتالم وسادات شهر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "15cc3e9b-e8d3-432f-b21a-a354c3e36655"
      },
      {
        "name": "کجور",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "73fb4edd-3f62-447b-a037-e5891cb84350"
      },
      {
        "name": "کلارآباد",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "55ef50de-bcbf-49b5-8c76-4a3a348fcf11"
      },
      {
        "name": "کلاردشت",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "478a0b09-fe14-4adc-baa7-8f62cabc6fb9"
      },
      {
        "name": "کلاردشت",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "743a7a37-f081-4786-933f-b716700e0f43"
      },
      {
        "name": "کوهی خیل",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "ebfa5dbd-930a-48ef-8366-2f10b6c36fea"
      },
      {
        "name": "گتاب",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "03e65489-6cc3-4b11-952a-41687d5708af"
      },
      {
        "name": "گزنک",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "77b7e980-0059-4650-bd8b-8b38d83deee4"
      },
      {
        "name": "گلوگاه",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "7906f9c0-0a3c-4862-b2b8-f846a8320987"
      },
      {
        "name": "محمودآباد",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "7f96c96c-ba96-4b9c-aac7-e4b3f5c030e6"
      },
      {
        "name": "مرزیکلا",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "e869430e-c3d5-45b9-8491-64f7847368de"
      },
      {
        "name": "مرزن آباد",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "259e6f29-d276-4786-b93c-de1551a5651b"
      },
      {
        "name": "نشتارود",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "04d45fec-dee6-49b4-b3d4-c00e31890dd8"
      },
      {
        "name": "نکا",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "5629c08b-0a5e-46be-9c1b-42227046bc8e"
      },
      {
        "name": "نور",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "e17f41db-fb95-4c90-a1af-aba4ac63812b"
      },
      {
        "name": "نوشهر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "38db150e-8207-47bc-80d3-bb85e90aec58"
      },
      {
        "name": "هادی شهر",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "2ffa1b12-de0f-4d42-976e-c13259b017e2"
      },
      {
        "name": "هچیرود",
        "stateId": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d",
        "id": "047d632b-4262-4397-bffb-b348964d2552"
      }
    ],
    "name": "مازندران",
    "id": "b6aa11d5-6cd9-41f4-9a0e-82cf0a21883d"
  },
  {
    "listOfCities": [
      {
        "name": "اراک",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "53181cb1-0b1a-49ec-91c7-a8b7d207e6ca"
      },
      {
        "name": "اراک",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "aafb1b42-9d01-458f-aaa8-681b8cb05574"
      },
      {
        "name": "آستانه",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "b363e9a8-7db1-4381-b5fc-1843bb4e673c"
      },
      {
        "name": "آشتیان",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "5c684eae-37f0-4659-a0eb-2c749fcf077b"
      },
      {
        "name": "آوه",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "b75dd519-2feb-456c-b215-aef11ab28164"
      },
      {
        "name": "پرندک",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "7d2b9907-7a05-4481-a0fa-5049b00286df"
      },
      {
        "name": "تفرش",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "abdfd911-e343-45bf-a691-49639cda3d95"
      },
      {
        "name": "توره",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "c23d7c80-f3f5-4028-9acb-c068617598a8"
      },
      {
        "name": "جاورسیان",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "3a626651-ffe7-4973-b054-eb1001ab26ea"
      },
      {
        "name": "خشکرود",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "04a7dd95-c4ca-46ae-b7f3-4efe312032ad"
      },
      {
        "name": "خمین",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "12303fbc-1b64-46fd-9ed4-4e50279a68e5"
      },
      {
        "name": "خنجین",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "ac804f46-af0f-442c-8583-e58d01fe27b1"
      },
      {
        "name": "خنداب",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "de296a93-30e4-464f-a6e9-efe3a0a3512e"
      },
      {
        "name": "داودآباد",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "1820cb3d-2421-4a0b-b415-9b115a8c238f"
      },
      {
        "name": "دلیجان",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "f72aacbb-8a41-48b2-bd31-cda248ff862c"
      },
      {
        "name": "رازقان",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "71b1fc0b-e8aa-4110-a37a-166c4a9b7211"
      },
      {
        "name": "زاویه",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "883d928b-093b-458f-bb14-8a328ce1d39a"
      },
      {
        "name": "ساروق",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "d25c1784-28f3-4d5c-98f9-317be793d26e"
      },
      {
        "name": "ساوه",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "7404f30e-4f78-45d7-9cb4-e6faf3d519e3"
      },
      {
        "name": "شازند",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "97f5ab16-865e-422e-9cf5-384302367630"
      },
      {
        "name": "شهباز",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "faf3a673-e9fd-4c70-a2a2-580fb9da965c"
      },
      {
        "name": "غرق آباد",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "34b93c75-95e3-4fa6-bac5-915bfcc1118c"
      },
      {
        "name": "فرمهین",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "5dd56472-f1e3-4eb6-ba9e-da43a2ec9f80"
      },
      {
        "name": "قورچی باشی",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "e9345c2b-8816-425e-859d-b31c8e8fd3b3"
      },
      {
        "name": "کارچان",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "3650c53a-3cce-4af0-a29b-b97f2efb19ed"
      },
      {
        "name": "کمیجان",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "d1f31137-cca6-4eba-a7c5-473a1110977c"
      },
      {
        "name": "مامونیه",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "066f966b-9a7c-4d84-babd-592d3f25ebf4"
      },
      {
        "name": "محلات",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "d4820b71-0470-4428-9481-bd859f4187c3"
      },
      {
        "name": "میلاجرد",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "f69a739d-9cc6-430b-bd70-c7ae9d33e4ec"
      },
      {
        "name": "مهاجران",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "b2dc7a1b-944f-4b56-964e-f66efdfa6ef3"
      },
      {
        "name": "نراق",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "9401cfba-0bde-425f-8229-1d027eaa3e1a"
      },
      {
        "name": "نیمور",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "c5abcefd-07e5-466d-ac58-52f35811f247"
      },
      {
        "name": "نوبران",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "1cc46fbd-56ee-4d9a-819a-d2df6cf97827"
      },
      {
        "name": "هندودر",
        "stateId": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758",
        "id": "0ac99fd5-641e-4403-a067-2f66b0b5dcc2"
      }
    ],
    "name": "مرکزی",
    "id": "980f0a6c-1b3e-4178-9df5-fe2bb8a51758"
  },
  {
    "listOfCities": [
      {
        "name": "ابوموسی",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "c8ba32f6-bb6c-465a-982a-1ca69652eaf6"
      },
      {
        "name": "بستک",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "098ed999-2063-48b1-be97-761e8b0cf253"
      },
      {
        "name": "بیکاء",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "510f69b8-a6c4-4dfa-a56d-0b78e99f393f"
      },
      {
        "name": "بندرجاسک",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "02a6baf7-46a9-43ab-9713-ebb691127ecc"
      },
      {
        "name": "بندرعباس",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "77034622-d815-406f-9f0f-ac1d9d5132b3"
      },
      {
        "name": "بندرعباس",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "79c73a15-d1b0-4e50-96c0-ae5783a2797a"
      },
      {
        "name": "بندرلنگه",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "6cc5ad96-105d-4b31-bc57-b07bfe1696c8"
      },
      {
        "name": "پارسیان",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "6006d5c5-cc30-447c-93d8-ab8204896316"
      },
      {
        "name": "تازیان پایین",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "7f4120a7-8c4a-49f9-a6ea-1326bbdc0881"
      },
      {
        "name": "تخت",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "e9cb77c3-3ab2-48ea-b782-1f3e639a79cf"
      },
      {
        "name": "تیرور",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "84e7d17f-28fd-451b-ae8c-f6ab9b329440"
      },
      {
        "name": "جناح",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "e0a5e732-4f74-4652-a2ea-5e9e36f84fc0"
      },
      {
        "name": "جهان بستک",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "ffcd9eb7-d32d-42bc-a162-f221e18b86b0"
      },
      {
        "name": "چارک",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "2e17bf48-1502-4794-a20f-9c84297b62c8"
      },
      {
        "name": "حاجی اباد",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "36914114-240c-4cad-86f4-48b54c1153cc"
      },
      {
        "name": "خمیر",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "e9b74c90-31ab-4543-be23-41a5687303ce"
      },
      {
        "name": "درگهان",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "bf429e82-2a17-41e5-94f4-17ba6f3bbe7a"
      },
      {
        "name": "دشتی",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "af8cb696-ae6c-48c0-876f-08ff560f9321"
      },
      {
        "name": "دهبارز",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "8d5fece1-4497-45a7-b07f-3868a9d95b90"
      },
      {
        "name": "رویدر",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "b4ffb364-53b2-439f-94f1-545f37a43341"
      },
      {
        "name": "زیارتعلی",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "7da291be-f092-4787-968e-97656ec685e9"
      },
      {
        "name": "سردشت",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "e04a3e1b-f19d-4041-aad5-557bb9d34948"
      },
      {
        "name": "سیریک",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "65a02b45-543e-4bf2-8da1-36255de31963"
      },
      {
        "name": "سرگز",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "66d05514-20dd-4dee-b2b2-2d25f7ffa0f2"
      },
      {
        "name": "سندرک",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "31f6265d-3316-4de1-99be-5eefade9c229"
      },
      {
        "name": "سوزا",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "3dd2b716-f141-49eb-b246-510e95845d1a"
      },
      {
        "name": "فارغان",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "afca45e6-bafb-4d3d-b750-18fa5ebdd7a0"
      },
      {
        "name": "فین",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "80eb851a-38f6-453f-a12e-a23ef82fc4a2"
      },
      {
        "name": "قشم",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "ae20961e-bb1a-4432-a165-85d6621f5372"
      },
      {
        "name": "قشم",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "f8d5e96c-b0a6-4031-8cf7-5a9d535014b3"
      },
      {
        "name": "قلعه قاضی",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "7eb88016-e24c-4a1b-8f61-ae213a5af255"
      },
      {
        "name": "کیش",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "9a8fdbf7-370c-48d6-8962-c3770b54d7b8"
      },
      {
        "name": "کیش",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "d92ecf87-a0f4-403d-8778-5c7ad8405158"
      },
      {
        "name": "کنگ",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "e19e5527-8e1d-4a8c-92b9-7ee74670dbdb"
      },
      {
        "name": "کوشکنار",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "be049193-4b30-4eac-93fc-e3fbcf467b32"
      },
      {
        "name": "کوهستک",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "778861c1-a65d-454c-bcd2-9b9f836a3024"
      },
      {
        "name": "گروک",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "52060b78-d17f-4fc3-9060-6ae11ab90c12"
      },
      {
        "name": "گوهران",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "e540b058-9596-4bca-9816-aff7d01ff006"
      },
      {
        "name": "لمزان",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "5799c063-fdc7-43ef-804b-f6446b0ef63a"
      },
      {
        "name": "میناب",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "879db455-a491-4205-a158-9beea7a46655"
      },
      {
        "name": "هرمز",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "b78be7f2-69d5-4f44-a702-808d5b8f2cd1"
      },
      {
        "name": "هشتبندی",
        "stateId": "2751b914-3dcb-442a-b0e9-a96351080d5e",
        "id": "fdd26e8f-a4c8-459c-b540-ee371a9782a2"
      }
    ],
    "name": "هرمزگان",
    "id": "2751b914-3dcb-442a-b0e9-a96351080d5e"
  },
  {
    "listOfCities": [
      {
        "name": "آجین",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "b5f08749-2c79-423d-932b-19c5d6b835c0"
      },
      {
        "name": "ازندریان",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "dc1e0668-c38a-48b2-97d4-6f359ccd1d9d"
      },
      {
        "name": "اسدآباد",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "d7d26f67-3a8a-41f1-bf47-241e060d38d7"
      },
      {
        "name": "برزول",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "726526ff-e299-40a5-902d-10a501455557"
      },
      {
        "name": "بهار",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "f2fcbed8-ed1c-408e-a699-54cbeb43bab0"
      },
      {
        "name": "تویسرکان",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "2d56aedb-8042-4c64-bb20-e253945df8fb"
      },
      {
        "name": "جورقان",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "0f61298d-b215-4afd-adda-9eb15078a763"
      },
      {
        "name": "جوکار",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "c88289bf-8710-4c90-ae39-32d385dec6a2"
      },
      {
        "name": "دمق",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "04d2c3a2-876b-46b0-a40d-dc65f9d22d41"
      },
      {
        "name": "رزن",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "64017426-f091-49e9-a880-550d050fc197"
      },
      {
        "name": "زنگنه",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "c8131a50-a2a6-4301-809e-eac153e4a901"
      },
      {
        "name": "سامن",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "dc0b3847-f155-439e-8d62-9d17fdcf78eb"
      },
      {
        "name": "سرکان",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "d63f7a8f-1498-443b-ba8a-33f1089b90e3"
      },
      {
        "name": "شیرین سو",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "86c1c23a-8301-4ef2-8201-6445cf7d5687"
      },
      {
        "name": "صالح آباد",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "2cade6e7-8604-4fdf-ad9e-329598518a46"
      },
      {
        "name": "فامنین",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "40c66c68-df7f-4fe4-8d99-4ab46353bed1"
      },
      {
        "name": "فرسفج",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "02f30c5e-6078-4605-a778-f3c3593a8f35"
      },
      {
        "name": "فیروزان",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "864776eb-5897-48b5-9cce-1bf7686be6dc"
      },
      {
        "name": "قروه درجزین",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "615817a5-f273-4fd4-845f-e6f426066c7c"
      },
      {
        "name": "قهاوند",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "e1b62466-1dfd-4960-bb18-bf4f6fe15af0"
      },
      {
        "name": "کبودرآهنگ",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "d82e0ebd-c602-4455-b9eb-81b52a9a749e"
      },
      {
        "name": "گیان",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "94e1471b-0cdd-4fc1-811e-faa782d7db69"
      },
      {
        "name": "گل تپه",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "f1caa696-8435-438f-bc68-02655d61bd2b"
      },
      {
        "name": "لالجین",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "9b7947b6-d829-4a72-afe8-7b9d2c430b87"
      },
      {
        "name": "مریانج",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "8fd6686a-13fa-4c91-8908-4f3d55727ce9"
      },
      {
        "name": "ملایر",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "ea287210-15c5-446a-88c0-b5bdf5c0bd46"
      },
      {
        "name": "مهاجران",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "00bd11c3-1277-4dca-8360-ebd062492056"
      },
      {
        "name": "نهاوند",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "eedd5826-5453-4f78-a152-111c005499df"
      },
      {
        "name": "همدان",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "26ad1c24-f2a5-48b3-8aec-1a86d66384dc"
      },
      {
        "name": "همدان",
        "stateId": "39275183-9c0e-49c3-9bbd-54ce01370a22",
        "id": "a01c599f-8857-46f2-9832-69bd0a7799db"
      }
    ],
    "name": "همدان",
    "id": "39275183-9c0e-49c3-9bbd-54ce01370a22"
  }
]

export const staticBlogMenu: any[] = [
  {
    id: '',
    title: 'دکوراسیون',
    link: 'دکوراسیون',
    listOfChildern: [
      {
        id: '',
        title: 'شهری',
        link: 'شهری',
      },
      {
        id: '',
        title: 'منزل',
        link: 'منزل',
      }
    ]
  },
  {
    id: '',
    title: 'مد و فشن',
    link: 'مد-و-فشن',
    listOfChildern: [
      {
        id: '',
        title: 'زنانه',
        link: 'زنانه',
      },
      {
        id: '',
        title: 'مردانه',
        link: 'مردانه',
      },
      {
        id: '',
        title: 'متفرقه مد و فشن',
        link: 'متفرقه-مد-و-فشن',
      },
      {
        id: '',
        title: 'اکسسوری مد و فشن',
        link: 'اکسسوری-مد-و-فشن',
      }
    ]
  },
  {
    id: '',
    title: 'پوشاک کودک و نوزاد',
    link: 'پوشاک-کودک-و-نوزاد',
  },
  {
    id: '',
    title: 'آشنایی با برندها',
    link: 'آشنایی-با-برند-ها',
  },
  {
    id: '',
    title: 'جشنواره های دنیای مد و رنگ',
    link: 'جشنواره-های-دنیای-مد-و-رنگ',
  },
  {
    id: '',
    title: 'آرایشی و زیبایی',
    link: 'آرایشی-و-زیبایی',
  },
  {
    id: '',
    title: 'نکات جذابیت و خوش پوشی',
    link: 'notes-for-attractive',
  },
];
