import { Helper } from 'src/app/shared/utilities/helper';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { BlogApiService } from 'src/app/core/services/api/blog-api.service';
import { ManageHeaderService } from 'src/app/core/services/platform-browser/manage-header.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.less']
})
export class BlogComponent implements OnInit {
  blog: any = {};
  headerTitle: string = 'کاریزماکالر | وبلاگ';
  baseUrl: any = environment.baseUrl;
  pageLoading: boolean = false;
  constructor(
    private manageHeader: ManageHeaderService,
    private blogApiService: BlogApiService,
    private helper: Helper
  ) { }

  ngOnInit(): void {
    this.getInfoBlogHome();
    this.manageHeader.updateTitle(this.headerTitle);
    this.onAddTags();
  }

  getInfoBlogHome() {
    this.pageLoading = true;
    this.blogApiService.getInfoBlogHome().subscribe(response => {
      this.blog = response['data'];
      this.pageLoading = false;
    }, error => {
      this.pageLoading = false;
      console.log(error);
    })
  }

  async onAddTags(): Promise<void> {
    let tags: any = [
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:description',
        content: 'دیسکریپشن',
      },
      {
        name: 'twitter:title',
        content: 'وبلاگ کاریزما کالر',
      },
      {
        name: 'twitter:image',
        content: 'https://api.carismacolor.com/image/carisma-logo-solo.png',
      },
      {
        property: 'og:locale',
        content: 'fa_IR',
      },
      {
        property: 'og:image',
        content: 'https://api.carismacolor.com/image/carisma-logo-solo.png',
      },
      {
        property: 'og:image:width',
        content: '500',
      },
      {
        property: 'og:image:height',
        content: '500',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:title',
        content: 'وبلاگ کاریزما کالر',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:url',
        content: 'https://www.carismacolor.com/blog/',
      },
      {
        property: 'og:url',
        content: 'https://www.carismacolor.com/blog/',
      },
      {
        property: 'og:site_name',
        content: 'وبلاگ کاریزماکالر',
      },
      {
        property: 'og:site_name',
        content: 'وبلاگ کاریزماکالر',
      },
    ]
    this.helper.addMetaTags(tags);
  }

}
