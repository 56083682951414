import { BehaviorSubject } from 'rxjs';

export class Observable extends BehaviorSubject<any> {

  public id: string = this.makeid(10);
  private observableSimilarProductDeleted = new BehaviorSubject<any>(false);
  private observableFilter = new BehaviorSubject<any>(false);
  private observableData = new BehaviorSubject<any>(this.id);

  public getFilter(): any {
    return this.observableFilter.asObservable();
  }

  public addFilter(data: boolean): void {
    this.observableFilter.next(data);
  }

  public removeFilter(): void {
    this.observableFilter.next(false);
  }

  public getDeleted(): any {
    return this.observableSimilarProductDeleted.asObservable();
  }

  public addSimilarProdcutDeleted(data: boolean): void {
    this.observableSimilarProductDeleted.next(data);
  }

  public removeSimilarProdcutDeleted(): void {
    this.observableSimilarProductDeleted.next(false);
  }

  public get(): any {
    return this.observableData.asObservable();
  }

  public add(data: any): void {
    this.observableData.next(data);
  }
  public remove(): void {
    this.observableData.next("");
  }

  public makeid(length: number): string {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
